import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
 Box,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 Fab,
 FormControl,
 TextField,
 Modal,
 Fade,
 Backdrop,
 Button,
 Snackbar,
 Alert,
} from "@mui/material";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
 addTermsConditions,
 deleteTermsConditions,
 fetchTermsConditions,
 updateTermsConditions,
} from "../../redux/about-termsConditions-privacyPolicy/action";

const style = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

const TermsConditions = () => {
 const { terms_conditions, isLoading, isError } = useSelector(
  (state) => state.aboutReducer
 );
 const dispatch = useDispatch();

 const [open, setOpen] = useState(false);
 const [openAdd, setOpenAdd] = useState(false);
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [snackbarSeverity, setSnackbarSeverity] = useState("success");
 const [editId, setEditId] = useState(null);

 const { register, handleSubmit, reset, setValue } = useForm();

 useEffect(() => {
  dispatch(fetchTermsConditions());
 }, [dispatch]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setOpen(false);
  reset();
 };

 const handleOpenAdd = () => {
  setOpenAdd(true);
 };

 const handleCloseAdd = () => {
  setOpenAdd(false);
  reset();
 };

 const handleSnackbarClose = (event, reason) => {
  if (reason === "clickaway") {
   return;
  }
  setSnackbarOpen(false);
 };

 const onSubmit = (data) => {
  if (editId) {
   dispatch(updateTermsConditions(editId, data));
   setEditId(null);
  } else {
   dispatch(addTermsConditions(data));
  }

  setSnackbarMessage("Form submitted successfully!");
  setSnackbarSeverity("success");
  setSnackbarOpen(true);
  handleCloseAdd();
  handleClose();
 };

 const handleOpenEdit = (id) => {
  setEditId(id);
  const item = terms_conditions.find((item) => item._id === id);
  setValue("title", item.title);
  setValue("description", item.description);
  setOpen(true);
 };

 const handleDelete = (id) => {
  dispatch(deleteTermsConditions(id));
  setSnackbarMessage("Deleted successfully!");
  setSnackbarSeverity("success");
  setSnackbarOpen(true);
 };

 return (
  <Box sx={{ width: "100%", height: "40vh" }}>
   <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
    Terms & Conditions
   </Typography>
   <Box
    sx={{
     border: "1px solid #1976D2",
     padding: "10px",
     display: "flex",
     alignItems: "center",
     gap: "50px",
    }}
   >
    <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
     <AddIcon />
    </Fab>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>Title</TableCell>
       <TableCell sx={{ color: "white" }}>Description</TableCell>
       <TableCell sx={{ color: "white" }}>Action</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {terms_conditions.map((item) => (
       <TableRow key={item._id}>
        <TableCell>{item.title}</TableCell>
        <TableCell>{item.description}</TableCell>
        <TableCell
         sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
         }}
        >
         <Fab
          color="secondary"
          aria-label="edit"
          onClick={() => handleOpenEdit(item._id)}
         >
          <EditIcon />
         </Fab>
         <Fab
          color="secondary"
          aria-label="delete"
          sx={{ marginLeft: "5px" }}
          onClick={() => handleDelete(item._id)}
         >
          <DeleteIcon />
         </Fab>
        </TableCell>
       </TableRow>
      ))}
     </TableBody>
    </Table>
   </TableContainer>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
   >
    <Fade in={open}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       Edit Terms & Conditions
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <FormControl fullWidth>
        <TextField
         {...register("title", { required: "Title is required" })}
         label="Title"
         margin="normal"
        />
        <TextField
         {...register("description", { required: "Description is required" })}
         label="Description"
         margin="normal"
         multiline
         rows={4}
        />
       </FormControl>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={openAdd}
    onClose={handleCloseAdd}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
   >
    <Fade in={openAdd}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       Add Terms & Conditions
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <FormControl fullWidth>
        <TextField
         {...register("title", { required: "Title is required" })}
         label="Title"
         margin="normal"
        />
        <TextField
         {...register("description", { required: "Description is required" })}
         label="Description"
         margin="normal"
         multiline
         rows={4}
        />
       </FormControl>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         Add
        </Button>
        <Button variant="outlined" onClick={handleCloseAdd}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={3000}
    onClose={handleSnackbarClose}
   >
    <Alert
     onClose={handleSnackbarClose}
     severity={snackbarSeverity}
     variant="filled"
     sx={{ width: "100%" }}
    >
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Box>
 );
};

export default TermsConditions;

import React, { useEffect, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";

const modalStyle = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 boxShadow: 24,
 p: 4,
 borderRadius: 2,
};

const CarPriceUpdateForm = ({ open, onClose, onSubmit, selectedCar }) => {
 const [formData, setFormData] = useState({
  mrp: "",
  givenPrice: "",
  variant: "",
  transmission: "",
 });

 useEffect(() => {
  if (selectedCar) {
   setFormData({
    mrp: selectedCar.mrp || "",
    givenPrice: selectedCar.givenPrice || "",
    variant: selectedCar.variant || "",
    transmission: selectedCar.transmission || "",
   });
  }
 }, [selectedCar]);

 const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
   ...prevData,
   [name]: value,
  }));
 };

 const handleSubmit = (e) => {
  e.preventDefault();
  onSubmit(formData);
 };

 return (
  <Modal open={open} onClose={onClose}>
   <Box sx={modalStyle}>
    <Typography variant="h6" gutterBottom>
     Update Car Price
    </Typography>
    <form onSubmit={handleSubmit}>
     <TextField
      label="MRP"
      name="mrp"
      value={formData.mrp}
      onChange={handleChange}
      fullWidth
      margin="normal"
     />
     <TextField
      label="Given Price"
      name="givenPrice"
      value={formData.givenPrice}
      onChange={handleChange}
      fullWidth
      margin="normal"
     />
     <TextField
      label="Variant"
      name="variant"
      value={formData.variant}
      onChange={handleChange}
      fullWidth
      margin="normal"
     />
     <TextField
      label="Transmission"
      name="transmission"
      value={formData.transmission}
      onChange={handleChange}
      fullWidth
      margin="normal"
     />
     <Button type="submit" variant="contained" color="primary" fullWidth>
      Update
     </Button>
    </form>
   </Box>
  </Modal>
 );
};

export default CarPriceUpdateForm;

import { combineReducers } from "redux";
import * as types from "./actionTypes";

const initialState = {
 about: [],
 terms_conditions: [],
 privacy_policy: [],
 isLoading: false,
 isError: false,
};

const aboutReducer = (state = initialState, action) => {
 const { payload, type } = action;
 switch (type) {
  case types.GET_ABOUT_REQUEST:
  case types.ADD_ABOUT_REQUEST:
  case types.UPDATE_ABOUT_REQUEST:
  case types.DELETE_ABOUT_REQUEST:
  case types.GET_TERMS_CONDITIONS_REQUEST:
  case types.ADD_TERMS_CONDITIONS_REQUEST:
  case types.UPDATE_TERMS_CONDITIONS_REQUEST:
  case types.DELETE_TERMS_CONDITIONS_REQUEST:
  case types.GET_PRIVACY_POLICY_REQUEST:
  case types.ADD_PRIVACY_POLICY_REQUEST:
  case types.UPDATE_PRIVACY_POLICY_REQUEST:
  case types.DELETE_PRIVACY_POLICY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_ABOUT_SUCCESS:
   return {
    ...state,
    isLoading: false,
    about: payload,
   };
  case types.ADD_ABOUT_SUCCESS:
  case types.UPDATE_ABOUT_SUCCESS:
   return {
    ...state,
    isLoading: false,
    about: [...state.about, payload],
   };
  case types.DELETE_ABOUT_SUCCESS:
   return {
    ...state,
    isLoading: false,
    about: state.about.filter((item) => item._id !== payload),
   };
  case types.GET_TERMS_CONDITIONS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    terms_conditions: payload,
   };
  case types.ADD_TERMS_CONDITIONS_SUCCESS:
  case types.UPDATE_TERMS_CONDITIONS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    terms_conditions: [...state.terms_conditions, payload],
   };
  case types.DELETE_TERMS_CONDITIONS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    terms_conditions: state.terms_conditions.filter(
     (item) => item._id !== payload
    ),
   };
  case types.GET_PRIVACY_POLICY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    privacy_policy: payload,
   };
  case types.ADD_PRIVACY_POLICY_SUCCESS:
  case types.UPDATE_PRIVACY_POLICY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    privacy_policy: [...state.privacy_policy, payload],
   };
  case types.DELETE_PRIVACY_POLICY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    privacy_policy: state.privacy_policy.filter((item) => item._id !== payload),
   };

  case types.GET_ABOUT_FAILURE:
  case types.ADD_ABOUT_FAILURE:
  case types.UPDATE_ABOUT_FAILURE:
  case types.DELETE_ABOUT_FAILURE:
  case types.GET_TERMS_CONDITIONS_FAILURE:
  case types.ADD_TERMS_CONDITIONS_FAILURE:
  case types.UPDATE_TERMS_CONDITIONS_FAILURE:
  case types.DELETE_TERMS_CONDITIONS_FAILURE:
  case types.GET_PRIVACY_POLICY_FAILURE:
  case types.ADD_PRIVACY_POLICY_FAILURE:
  case types.UPDATE_PRIVACY_POLICY_FAILURE:
  case types.DELETE_PRIVACY_POLICY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };
  default:
   return state;
 }
};
export { aboutReducer };

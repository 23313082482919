import React, { useState } from "react";
import {
 Box,
 Stepper,
 Step,
 StepLabel,
 Button,
 Typography,
 TextField,
 Grid,
 RadioGroup,
 FormControlLabel,
 Radio,
 MenuItem,
 Select,
 InputLabel,
 FormControl,
 Paper,
} from "@mui/material";

const steps = [
 "Personal Details",
 "Business Details",
 "Banking Details",
 "Franchise Details",
 "Create User & Document Upload",
 "Payment",
 "Review",
];

const stepContent = [
 {
  label: "Personal Details",
  content: (
   <>
    <Grid container spacing={2}>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="First Name" required />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Last Name" required />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField
       fullWidth
       label="Date of Birth"
       type="date"
       InputLabelProps={{ shrink: true }}
      />
     </Grid>
     <Grid item xs={12} sm={6}>
      <RadioGroup row>
       <FormControlLabel value="male" control={<Radio />} label="Male" />
       <FormControlLabel value="female" control={<Radio />} label="Female" />
      </RadioGroup>
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Contact Number" required />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Alternative Contact Number" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Email Address" required />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Address" required />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Additional Address" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Pan Number" required />
     </Grid>
     <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
       <InputLabel>Document Type</InputLabel>
       <Select defaultValue="">
        <MenuItem value="aadhar">Aadhar</MenuItem>
        <MenuItem value="voterid">Voter ID</MenuItem>
        <MenuItem value="dl">Driving License</MenuItem>
       </Select>
      </FormControl>
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Document Number" />
     </Grid>
    </Grid>
   </>
  ),
 },
 {
  label: "Business Details",
  content: (
   <>
    <Grid container spacing={2}>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Company Name" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
       <InputLabel>Company Type</InputLabel>
       <Select defaultValue="">
        <MenuItem value="pvtltd">Pvt Ltd</MenuItem>
        <MenuItem value="limited">Limited</MenuItem>
        <MenuItem value="partnership">Partnership</MenuItem>
        <MenuItem value="sole">Sole Proprietor</MenuItem>
       </Select>
      </FormControl>
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Company Address" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Contact Number" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
       <InputLabel>Document Type</InputLabel>
       <Select defaultValue="">
        <MenuItem value="gst">GST Certificate</MenuItem>
        <MenuItem value="coi">COI</MenuItem>
        <MenuItem value="msme">MSME Certificate</MenuItem>
        <MenuItem value="other">Other</MenuItem>
       </Select>
      </FormControl>
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Document Number" />
     </Grid>
    </Grid>
   </>
  ),
 },
 {
  label: "Banking Details",
  content: (
   <>
    <Grid container spacing={2}>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Account Name" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Account Number" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="IFSC Code" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
       <InputLabel>Account Type</InputLabel>
       <Select defaultValue="">
        <MenuItem value="saving">Saving</MenuItem>
        <MenuItem value="current">Current</MenuItem>
       </Select>
      </FormControl>
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Branch Name" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Bank Name" />
     </Grid>
    </Grid>
   </>
  ),
 },
 {
  label: "Franchise Details",
  content: (
   <>
    <Grid container spacing={2}>
     <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
       <InputLabel>Franchise Type</InputLabel>
       <Select defaultValue="">
        <MenuItem value="car-detailing">Car Detailing</MenuItem>
        <MenuItem value="car-service">Car Service</MenuItem>
        <MenuItem value="complete">Complete Detailing + Service</MenuItem>
       </Select>
      </FormControl>
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Franchise Applicant Name" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Franchise Pincode" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Franchise Area Name" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Franchise City" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
       <InputLabel>Franchise Radius</InputLabel>
       <Select defaultValue="">
        <MenuItem value="0-15">0 to 15 KM</MenuItem>
        <MenuItem value="0-20">0 to 20 KM</MenuItem>
        <MenuItem value="0-30">0 to 30 KM</MenuItem>
       </Select>
      </FormControl>
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Approved Investment Amount" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Registration Amount" defaultValue="₹15,000" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Pending Amount" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Quality Parameter" defaultValue="Above 90%" />
     </Grid>
    </Grid>
   </>
  ),
 },
 {
  label: "Create User & Document Upload",
  content: (
   <>
    <Grid container spacing={2}>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Username" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Email ID" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Password" type="password" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField fullWidth label="Current Work Profession" />
     </Grid>
     <Grid item xs={12} sm={6}>
      <TextField
       fullWidth
       label="Why do you want a franchise?"
       multiline
       rows={4}
      />
     </Grid>
     <Grid item xs={12} sm={6}>
      <Typography>Upload Documents:</Typography>
      <Button variant="contained" component="label">
       Upload Pan Card
       <input type="file" hidden />
      </Button>
      <Button variant="contained" component="label" sx={{ mt: 2 }}>
       Upload Aadhar / Voter ID / DL
       <input type="file" hidden />
      </Button>
      <Button variant="contained" component="label" sx={{ mt: 2 }}>
       Upload Business Docs
       <input type="file" hidden />
      </Button>
      <Button variant="contained" component="label" sx={{ mt: 2 }}>
       Upload Bank Account Proof
       <input type="file" hidden />
      </Button>
      <Button variant="contained" component="label" sx={{ mt: 2 }}>
       Upload Photo
       <input type="file" hidden />
      </Button>
     </Grid>
    </Grid>
   </>
  ),
 },
 {
  label: "Payment",
  content: (
   <>
    <Typography>Registration Amount: ₹15,000</Typography>
    <Typography>Pending Amount: AIA - RA</Typography>
    <Button variant="contained" sx={{ mt: 2 }}>
     Pay Now
    </Button>
   </>
  ),
 },
 {
  label: "Review",
  content: (
   <>
    <Typography>Review your form before submission:</Typography>
    <Button variant="contained" sx={{ mt: 2 }}>
     Submit Form
    </Button>
   </>
  ),
 },
];

export default function HorizontalLinearAlternativeLabelStepper() {
 const [activeStep, setActiveStep] = useState(0);

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setActiveStep(0);
 };

 return (
  <Box sx={{ width: "80%", margin: "100px auto" }}>
   <Typography variant="h4" component="h1" align="center" gutterBottom>
    FRANCHISE FORM
   </Typography>
   <Typography variant="subtitle1" align="center" gutterBottom>
    Create the contract terms with this guided process
   </Typography>
   {/* Horizontal Stepper */}
   <Stepper sx={{ marginTop: "40px" }} activeStep={activeStep} alternativeLabel>
    {steps.map((label, index) => (
     <Step key={index}>
      <StepLabel>{label}</StepLabel>
     </Step>
    ))}
   </Stepper>

   <Box sx={{ mt: 3, mb: 3 }}>
    {/* Display form content based on the active step */}
    {stepContent[activeStep] && (
     <>
      {stepContent[activeStep].content}
      <Box
       sx={{
        mt: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
       }}
      >
       <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
        {activeStep === steps.length - 1 ? "Finish" : "Next"}
       </Button>
       <Button
        variant="outlined"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mt: 1, mr: 1 }}
       >
        Back
       </Button>
      </Box>
     </>
    )}
   </Box>

   {/* Stepper Reset and Completion */}
   {activeStep === steps.length && (
    <Paper square elevation={0} sx={{ p: 3 }}>
     <Typography>All steps completed - you're finished</Typography>
     <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
      Reset
     </Button>
    </Paper>
   )}
   <Box
    sx={{ border: "5px solid blue", width: "200px", margin: "50px auto" }}
   />
  </Box>
 );
}

// import React, { useState } from "react";
// import {
//  Box,
//  Stepper,
//  Step,
//  StepLabel,
//  Button,
//  Typography,
//  TextField,
//  Grid,
//  RadioGroup,
//  FormControlLabel,
//  Radio,
//  MenuItem,
//  Select,
//  InputLabel,
//  FormControl,
//  Paper,
// } from "@mui/material";

// const steps = [
//  "Personal Details",
//  "Business Details",
//  "Banking Details",
//  "Franchise Details",
//  "Create User & Document Upload",
//  "Payment",
//  "Review",
// ];

// const stepContent = [
//  {
//   label: "Personal Details",
//   content: (
//    <>
//     <Grid container spacing={2}>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="First Name" required />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Last Name" required />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField
//        fullWidth
//        label="Date of Birth"
//        type="date"
//        InputLabelProps={{ shrink: true }}
//       />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <RadioGroup row>
//        <FormControlLabel value="male" control={<Radio />} label="Male" />
//        <FormControlLabel value="female" control={<Radio />} label="Female" />
//       </RadioGroup>
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Contact Number" required />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Alternative Contact Number" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Email Address" required />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Address" required />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Additional Address" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Pan Number" required />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <FormControl fullWidth>
//        <InputLabel>Document Type</InputLabel>
//        <Select defaultValue="">
//         <MenuItem value="aadhar">Aadhar</MenuItem>
//         <MenuItem value="voterid">Voter ID</MenuItem>
//         <MenuItem value="dl">Driving License</MenuItem>
//        </Select>
//       </FormControl>
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Document Number" />
//      </Grid>
//     </Grid>
//    </>
//   ),
//  },
//  {
//   label: "Business Details",
//   content: (
//    <>
//     <Grid container spacing={2}>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Company Name" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <FormControl fullWidth>
//        <InputLabel>Company Type</InputLabel>
//        <Select defaultValue="">
//         <MenuItem value="pvtltd">Pvt Ltd</MenuItem>
//         <MenuItem value="limited">Limited</MenuItem>
//         <MenuItem value="partnership">Partnership</MenuItem>
//         <MenuItem value="sole">Sole Proprietor</MenuItem>
//        </Select>
//       </FormControl>
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Company Address" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Contact Number" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <FormControl fullWidth>
//        <InputLabel>Document Type</InputLabel>
//        <Select defaultValue="">
//         <MenuItem value="gst">GST Certificate</MenuItem>
//         <MenuItem value="coi">COI</MenuItem>
//         <MenuItem value="msme">MSME Certificate</MenuItem>
//         <MenuItem value="other">Other</MenuItem>
//        </Select>
//       </FormControl>
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Document Number" />
//      </Grid>
//     </Grid>
//    </>
//   ),
//  },
//  {
//   label: "Banking Details",
//   content: (
//    <>
//     <Grid container spacing={2}>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Account Name" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Account Number" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="IFSC Code" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <FormControl fullWidth>
//        <InputLabel>Account Type</InputLabel>
//        <Select defaultValue="">
//         <MenuItem value="saving">Saving</MenuItem>
//         <MenuItem value="current">Current</MenuItem>
//        </Select>
//       </FormControl>
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Branch Name" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Bank Name" />
//      </Grid>
//     </Grid>
//    </>
//   ),
//  },
//  {
//   label: "Franchise Details",
//   content: (
//    <>
//     <Grid container spacing={2}>
//      <Grid item xs={12} sm={6}>
//       <FormControl fullWidth>
//        <InputLabel>Franchise Type</InputLabel>
//        <Select defaultValue="">
//         <MenuItem value="car-detailing">Car Detailing</MenuItem>
//         <MenuItem value="car-service">Car Service</MenuItem>
//         <MenuItem value="complete">Complete Detailing + Service</MenuItem>
//        </Select>
//       </FormControl>
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Franchise Applicant Name" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Franchise Pincode" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Franchise Area Name" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Franchise City" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <FormControl fullWidth>
//        <InputLabel>Franchise Radius</InputLabel>
//        <Select defaultValue="">
//         <MenuItem value="0-15">0 to 15 KM</MenuItem>
//         <MenuItem value="0-20">0 to 20 KM</MenuItem>
//         <MenuItem value="0-30">0 to 30 KM</MenuItem>
//        </Select>
//       </FormControl>
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Approved Investment Amount" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Registration Amount" defaultValue="₹15,000" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Pending Amount" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Quality Parameter" defaultValue="Above 90%" />
//      </Grid>
//     </Grid>
//    </>
//   ),
//  },
//  {
//   label: "Create User & Document Upload",
//   content: (
//    <>
//     <Grid container spacing={2}>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Username" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Email ID" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Password" type="password" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField fullWidth label="Current Work Profession" />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <TextField
//        fullWidth
//        label="Why do you want a franchise?"
//        multiline
//        rows={4}
//       />
//      </Grid>
//      <Grid item xs={12} sm={6}>
//       <Typography>Upload Documents:</Typography>
//       <Button variant="contained" component="label">
//        Upload Pan Card
//        <input type="file" hidden />
//       </Button>
//       <Button variant="contained" component="label" sx={{ mt: 2 }}>
//        Upload Aadhar / Voter ID / DL
//        <input type="file" hidden />
//       </Button>
//       <Button variant="contained" component="label" sx={{ mt: 2 }}>
//        Upload Business Docs
//        <input type="file" hidden />
//       </Button>
//       <Button variant="contained" component="label" sx={{ mt: 2 }}>
//        Upload Bank Account Proof
//        <input type="file" hidden />
//       </Button>
//       <Button variant="contained" component="label" sx={{ mt: 2 }}>
//        Upload Photo
//        <input type="file" hidden />
//       </Button>
//      </Grid>
//     </Grid>
//    </>
//   ),
//  },
//  {
//   label: "Payment",
//   content: (
//    <>
//     <Typography>Registration Amount: ₹15,000</Typography>
//     <Typography>Pending Amount: AIA - RA</Typography>
//     <Button variant="contained" sx={{ mt: 2 }}>
//      Pay Now
//     </Button>
//    </>
//   ),
//  },
//  {
//   label: "Review",
//   content: (
//    <>
//     <Typography>Review your form before submission:</Typography>
//     {/* Add any review content or summary here */}
//     <Button variant="contained" sx={{ mt: 2 }}>
//      Submit Form
//     </Button>
//    </>
//   ),
//  },
// ];

// export default function HorizontalLinearAlternativeLabelStepper() {
//  const [activeStep, setActiveStep] = useState(0);

//  const handleNext = () => {
//   setActiveStep((prevActiveStep) => prevActiveStep + 1);
//  };

//  const handleBack = () => {
//   setActiveStep((prevActiveStep) => prevActiveStep - 1);
//  };

//  const handleReset = () => {
//   setActiveStep(0);
//  };

//  return (
//   <Box sx={{ width: "80%", margin: "0 auto" }}>
//    <Typography variant="h2" component="h1">
//     Accelerate Your Success with Our Partnership
//    </Typography>
//    {/* Horizontal Stepper */}
//    <Stepper activeStep={activeStep} alternativeLabel>
//     {steps.map((label, index) => (
//      <Step key={index}>
//       <StepLabel>{label}</StepLabel>
//      </Step>
//     ))}
//    </Stepper>

//    <Box sx={{ mt: 3, mb: 3 }}>
//     {/* Display form content based on the active step */}
//     {stepContent[activeStep] && (
//      <>
//       {stepContent[activeStep].content}
//       <Box sx={{ mt: 2 }}>
//        <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
//         {activeStep === steps.length - 1 ? "Finish" : "Next"}
//        </Button>
//        <Button
//         variant="outlined"
//         disabled={activeStep === 0}
//         onClick={handleBack}
//         sx={{ mt: 1, mr: 1 }}
//        >
//         Back
//        </Button>
//       </Box>
//      </>
//     )}
//    </Box>

//    {/* Stepper Reset and Completion */}
//    {activeStep === steps.length && (
//     <Paper square elevation={0} sx={{ p: 3 }}>
//      <Typography>All steps completed - you're finished</Typography>
//      <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//       Reset
//      </Button>
//     </Paper>
//    )}
//   </Box>
//  );
// }

// // import React, { useState } from "react";
// // import {
// //  Box,
// //  Stepper,
// //  Step,
// //  StepLabel,
// //  StepContent,
// //  Button,
// //  Paper,
// //  Typography,
// //  TextField,
// //  Grid,
// //  RadioGroup,
// //  FormControlLabel,
// //  Radio,
// //  MenuItem,
// //  Select,
// //  InputLabel,
// //  FormControl,
// // } from "@mui/material";

// // const steps = [
// //  {
// //   label: "Personal Details",
// //   content: (
// //    <>
// //     <Grid container spacing={2}>
// //      <Grid item xs={12} sm={6}>
// //       <TextField fullWidth label="First Name" required />
// //      </Grid>
// //      <Grid item xs={12} sm={6}>
// //       <TextField fullWidth label="Last Name" required />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField
// //        fullWidth
// //        label="Date of Birth"
// //        type="date"
// //        InputLabelProps={{ shrink: true }}
// //       />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <RadioGroup row>
// //        <FormControlLabel value="male" control={<Radio />} label="Male" />
// //        <FormControlLabel value="female" control={<Radio />} label="Female" />
// //       </RadioGroup>
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Contact Number" required />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Alternative Contact Number" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Email Address" required />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Address" required />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Additional Address" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Pan Number" required />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <FormControl fullWidth>
// //        <InputLabel>Document Type</InputLabel>
// //        <Select defaultValue="">
// //         <MenuItem value="aadhar">Aadhar</MenuItem>
// //         <MenuItem value="voterid">Voter ID</MenuItem>
// //         <MenuItem value="dl">Driving License</MenuItem>
// //        </Select>
// //       </FormControl>
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Document Number" />
// //      </Grid>
// //     </Grid>
// //    </>
// //   ),
// //  },
// //  {
// //   label: "Business Details",
// //   content: (
// //    <>
// //     <Grid container spacing={2}>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Company Name" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <FormControl fullWidth>
// //        <InputLabel>Company Type</InputLabel>
// //        <Select defaultValue="">
// //         <MenuItem value="pvtltd">Pvt Ltd</MenuItem>
// //         <MenuItem value="limited">Limited</MenuItem>
// //         <MenuItem value="partnership">Partnership</MenuItem>
// //         <MenuItem value="sole">Sole Proprietor</MenuItem>
// //        </Select>
// //       </FormControl>
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Company Address" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Contact Number" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <FormControl fullWidth>
// //        <InputLabel>Document Type</InputLabel>
// //        <Select defaultValue="">
// //         <MenuItem value="gst">GST Certificate</MenuItem>
// //         <MenuItem value="coi">COI</MenuItem>
// //         <MenuItem value="msme">MSME Certificate</MenuItem>
// //         <MenuItem value="other">Other</MenuItem>
// //        </Select>
// //       </FormControl>
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Document Number" />
// //      </Grid>
// //     </Grid>
// //    </>
// //   ),
// //  },
// //  {
// //   label: "Banking Details",
// //   content: (
// //    <>
// //     <Grid container spacing={2}>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Account Name" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Account Number" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="IFSC Code" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <FormControl fullWidth>
// //        <InputLabel>Account Type</InputLabel>
// //        <Select defaultValue="">
// //         <MenuItem value="saving">Saving</MenuItem>
// //         <MenuItem value="current">Current</MenuItem>
// //        </Select>
// //       </FormControl>
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Branch Name" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Bank Name" />
// //      </Grid>
// //     </Grid>
// //    </>
// //   ),
// //  },
// //  {
// //   label: "Franchise Details",
// //   content: (
// //    <>
// //     <Grid container spacing={2}>
// //      <Grid item xs={12}>
// //       <FormControl fullWidth>
// //        <InputLabel>Franchise Type</InputLabel>
// //        <Select defaultValue="">
// //         <MenuItem value="car-detailing">Car Detailing</MenuItem>
// //         <MenuItem value="car-service">Car Service</MenuItem>
// //         <MenuItem value="complete">Complete Detailing + Service</MenuItem>
// //        </Select>
// //       </FormControl>
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Franchise Applicant Name" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Franchise Pincode" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Franchise Area Name" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Franchise City" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <FormControl fullWidth>
// //        <InputLabel>Franchise Radius</InputLabel>
// //        <Select defaultValue="">
// //         <MenuItem value="0-15">0 to 15 KM</MenuItem>
// //         <MenuItem value="0-20">0 to 20 KM</MenuItem>
// //         <MenuItem value="0-30">0 to 30 KM</MenuItem>
// //        </Select>
// //       </FormControl>
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Approved Investment Amount" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Registration Amount" defaultValue="₹15,000" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Pending Amount" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Quality Parameter" defaultValue="Above 90%" />
// //      </Grid>
// //     </Grid>
// //    </>
// //   ),
// //  },
// //  {
// //   label: "Create User & Document Upload",
// //   content: (
// //    <>
// //     <Grid container spacing={2}>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Username" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Email ID" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Password" type="password" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField fullWidth label="Current Work Profession" />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <TextField
// //        fullWidth
// //        label="Why do you want a franchise?"
// //        multiline
// //        rows={4}
// //       />
// //      </Grid>
// //      <Grid item xs={12}>
// //       <Typography>Upload Documents:</Typography>
// //       <Button variant="contained" component="label">
// //        Upload Pan Card
// //        <input type="file" hidden />
// //       </Button>
// //       <Button variant="contained" component="label" sx={{ mt: 2 }}>
// //        Upload Aadhar / Voter ID / DL
// //        <input type="file" hidden />
// //       </Button>
// //       <Button variant="contained" component="label" sx={{ mt: 2 }}>
// //        Upload Business Docs
// //        <input type="file" hidden />
// //       </Button>
// //       <Button variant="contained" component="label" sx={{ mt: 2 }}>
// //        Upload Bank Account Proof
// //        <input type="file" hidden />
// //       </Button>
// //       <Button variant="contained" component="label" sx={{ mt: 2 }}>
// //        Upload Photo
// //        <input type="file" hidden />
// //       </Button>
// //      </Grid>
// //     </Grid>
// //    </>
// //   ),
// //  },
// //  {
// //   label: "Payment",
// //   content: (
// //    <>
// //     <Typography>Registration Amount: ₹15,000</Typography>
// //     <Typography>Pending Amount: AIA - RA</Typography>
// //     <Button variant="contained" sx={{ mt: 2 }}>
// //      Pay Now
// //     </Button>
// //    </>
// //   ),
// //  },
// //  {
// //   label: "Review",
// //   content: (
// //    <>
// //     <Typography>Review your form before submission:</Typography>
// //     {/* Add any review content or summary here */}
// //     <Button variant="contained" sx={{ mt: 2 }}>
// //      Submit Form
// //     </Button>
// //    </>
// //   ),
// //  },
// // ];

// // export default function VerticalLinearStepper() {
// //  const [activeStep, setActiveStep] = useState(0);

// //  const handleNext = () => {
// //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
// //  };

// //  const handleBack = () => {
// //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
// //  };

// //  const handleReset = () => {
// //   setActiveStep(0);
// //  };

// //  return (
// //   <Box sx={{ width: "100%", margin: "0 auto" }}>
// //    <Typography variant="h2" component="h1">
// //     Accelerate Your Success with Our Partnership
// //    </Typography>
// //    <Stepper activeStep={activeStep} orientation="vertical">
// //     {steps.map((step, index) => (
// //      <Step key={step.label}>
// //       <StepLabel>{step.label}</StepLabel>
// //       <StepContent>
// //        {step.content}
// //        <Box sx={{ mb: 2 }}>
// //         <div>
// //          <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
// //           {index === steps.length - 1 ? "Finish" : "Continue"}
// //          </Button>
// //          <Button
// //           disabled={index === 0}
// //           onClick={handleBack}
// //           sx={{ mt: 1, mr: 1 }}
// //          >
// //           Back
// //          </Button>
// //         </div>
// //        </Box>
// //       </StepContent>
// //      </Step>
// //     ))}
// //    </Stepper>
// //    {activeStep === steps.length && (
// //     <Paper square elevation={0} sx={{ p: 3 }}>
// //      <Typography>All steps completed - you&apos;re finished</Typography>
// //      <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
// //       Reset
// //      </Button>
// //     </Paper>
// //    )}
// //   </Box>
// //  );
// // }

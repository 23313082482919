import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
 Box,
 Typography,
 IconButton,
 Button,
 MenuItem,
 FormControl,
 InputLabel,
 Select,
 TextField,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ProductModal from "./ProductModal"; // Import the modal component
import ProductPreviewModal from "./ProductPreviewModal"; // Import the preview modal component
import {
 addProduct,
 getProducts,
 deleteProduct,
 updateProduct,
} from "../../redux/products/action"; // Import the Redux actions
import PreviewIcon from "@mui/icons-material/Preview";

const ProductList = () => {
 const dispatch = useDispatch();
 const { products } = useSelector((store) => store.product);
 const [open, setOpen] = useState(false);
 const [previewOpen, setPreviewOpen] = useState(false);
 const [selectedProduct, setSelectedProduct] = useState(null);

 useEffect(() => {
  dispatch(getProducts());
 }, [dispatch]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setOpen(false);
  setSelectedProduct(null);
 };

 const handlePreviewOpen = () => setPreviewOpen(true);
 const handlePreviewClose = () => setPreviewOpen(false);

 const handleAddProduct = (productData) => {
  dispatch(addProduct(productData));
  handleClose();
 };

 const handleDelete = (id) => {
  dispatch(deleteProduct(id));
 };

 const handleUpdate = (product) => {
  setSelectedProduct(product);
  handleOpen();
 };

 const handleView = (product) => {
  setSelectedProduct(product);
  handlePreviewOpen();
 };

 const handleUpdateProduct = (productData) => {
  dispatch(updateProduct(productData));
  handleClose();
 };

 const columns = [
  {
   field: "productImage",
   headerName: "Product Image",
   width: 150,
   renderCell: (params) => (
    <Box
     display="flex"
     justifyContent="center"
     alignItems="center"
     width="100%"
     height="100%"
    >
     <img
      src={params.value}
      alt="Product"
      style={{
       maxWidth: "100%",
       maxHeight: "100%",
       objectFit: "contain",
      }}
     />
    </Box>
   ),
  },
  { field: "productName", headerName: "Product Name", width: 180 },
  { field: "dummyPriceMrp", headerName: "MRP", width: 120 },
  { field: "dummyPriceActual", headerName: "Selling Price", width: 120 },
  {
   field: "categoryName",
   headerName: "Category Name",
   width: 180,
   renderCell: (params) => (
    <Typography variant="body2">
     {params?.row?.categoryId?.categoryName}
    </Typography>
   ),
  },
  {
   field: "subCategoryName",
   headerName: "Sub-Category Name",
   width: 180,
   renderCell: (params) => (
    <Typography variant="body2">
     {params?.row?.subCategoryId?.subCategoryName}
    </Typography>
   ),
  },
  {
   field: "highlights",
   headerName: "Highlights",
   width: 300,
   renderCell: (params) => (
    <Box>
     {params.value.map((highlight, index) => (
      <Typography key={index} variant="body2">
       {highlight.text}
      </Typography>
     ))}
    </Box>
   ),
  },
  {
   field: "includedService",
   headerName: "Included Services",
   width: 300,
   renderCell: (params) => (
    <Box>
     {params.value.map((service, index) => (
      <Box key={index} mb={1}>
       <Typography variant="body2">{service.name}</Typography>
       {service.services.map((s, idx) => (
        <Typography key={idx} variant="body2" style={{ marginLeft: "10px" }}>
         {s.title}
        </Typography>
       ))}
      </Box>
     ))}
    </Box>
   ),
  },
  {
   field: "additionalServices",
   headerName: "Additional Services",
   width: 250,
   renderCell: (params) => (
    <Box>
     {params.value.map((service, index) => (
      <Typography key={index} variant="body2">
       {service}
      </Typography>
     ))}
    </Box>
   ),
  },
  {
   field: "stepsAfterBooking",
   headerName: "Steps After Booking",
   width: 250,
   renderCell: (params) => (
    <Box>
     {params.value.map((step, index) => (
      <Typography key={index} variant="body2">
       {step}
      </Typography>
     ))}
    </Box>
   ),
  },
  {
   field: "ratings",
   headerName: "Ratings",
   width: 100,
   renderCell: (params) => (
    <Typography variant="body2">{params.value}</Typography>
   ),
  },
  //   {
  //    field: "discountPercent",
  //    headerName: "Discount %",
  //    width: 120,
  //    renderCell: (params) => (
  //     <Typography variant="body2">{params.value}%</Typography>
  //    ),
  //   },
  {
   field: "actions",
   headerName: "Actions",
   width: 100,
   renderCell: (params) => (
    <Box display="flex" justifyContent="center">
     <IconButton onClick={() => handleView(params.row)}>
      <PreviewIcon />
     </IconButton>
     <IconButton onClick={() => handleUpdate(params.row)}>
      <EditIcon />
     </IconButton>
     <IconButton onClick={() => handleDelete(params.row._id)}>
      <DeleteIcon />
     </IconButton>
    </Box>
   ),
  },
 ];

 return (
  <Box p={3}>
   <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    mb={3}
   >
    <Typography variant="h4">Product List</Typography>
    <Button
     variant="contained"
     color="primary"
     startIcon={<AddIcon />}
     onClick={handleOpen}
    >
     New product
    </Button>
   </Box>
   <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    mb={3}
   >
    <Box display="flex" alignItems="center">
     <FormControl variant="outlined" size="small" style={{ marginRight: 10 }}>
      <InputLabel>Stock</InputLabel>
      <Select label="Stock">
       <MenuItem value="in_stock">In Stock</MenuItem>
       <MenuItem value="out_of_stock">Out of Stock</MenuItem>
      </Select>
     </FormControl>
     <FormControl variant="outlined" size="small" style={{ marginRight: 10 }}>
      <InputLabel>Publish</InputLabel>
      <Select label="Publish">
       <MenuItem value="published">Published</MenuItem>
       <MenuItem value="draft">Draft</MenuItem>
      </Select>
     </FormControl>
    </Box>
    <TextField variant="outlined" size="small" placeholder="Search..." />
   </Box>
   <Box height={500}>
    <DataGrid
     rows={products}
     columns={columns}
     pageSize={5}
     rowsPerPageOptions={[5, 10, 20]}
     checkboxSelection
     getRowId={(row) => row._id} // Use _id as the unique row identifier
    />
   </Box>
   <ProductModal
    open={open}
    onClose={handleClose}
    onSubmit={selectedProduct ? handleUpdateProduct : handleAddProduct}
    product={selectedProduct}
   />
   <ProductPreviewModal
    open={previewOpen}
    onClose={handlePreviewClose}
    product={selectedProduct}
   />
  </Box>
 );
};

export default ProductList;

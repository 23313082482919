import React, { useEffect, useState } from "react";
import {
 Box,
 Button,
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 TextField,
 Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

const CompanyLogo = () => {
 const [companies, setCompanies] = useState([]); // List of companies
 const [loading, setLoading] = useState(false); // Loading state
 const [open, setOpen] = useState(false); // Modal state
 const [formData, setFormData] = useState({
  _id: null,
  name: "",
  description: "",
  country: "",
  specialties: "",
  website: "",
  logo: null, // To store the uploaded file
 });

 // Fetch companies data
 const fetchCompanies = async () => {
  try {
   setLoading(true);
   const response = await axios.get(
    "https://app-api.carexpert.org.in/api/company"
   );
   setCompanies(response.data);
  } catch (error) {
   console.error("Error fetching companies:", error);
  } finally {
   setLoading(false);
  }
 };

 useEffect(() => {
  fetchCompanies();
 }, []);

 // Handle input changes
 const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
 };

 // Handle file upload
 const handleFileChange = (e) => {
  setFormData({ ...formData, logo: e.target.files[0] });
 };

 // Add or update a company
 const handleSaveCompany = async () => {
  const data = new FormData();
  data.append("name", formData.name);
  data.append("description", formData.description);
  data.append("country", formData.country);
  data.append("specialties", formData.specialties);
  data.append("website", formData.website);
  if (formData.logo) {
   data.append("logo", formData.logo); // Add the uploaded file
  }

  try {
   if (formData._id) {
    // Update company
    const response = await axios.patch(
     `https://app-api.carexpert.org.in/api/company/${formData._id}`,
     data,
     {
      headers: { "Content-Type": "multipart/form-data" },
     }
    );
    setCompanies((prev) =>
     prev.map((company) =>
      company._id === formData._id ? { ...company, ...response.data } : company
     )
    );
   } else {
    // Add company
    const response = await axios.post(
     "https://app-api.carexpert.org.in/api/company",
     data,
     {
      headers: { "Content-Type": "multipart/form-data" },
     }
    );
    setCompanies((prev) => [...prev, response.data]);
   }

   // Close modal and reset form
   setOpen(false);
   setFormData({
    _id: null,
    name: "",
    description: "",
    country: "",
    specialties: "",
    website: "",
    logo: null,
   });
  } catch (error) {
   console.error("Error saving company:", error);
  }
 };

 // Delete a company
 const handleDeleteCompany = async (id) => {
  try {
   await axios.delete(`https://app-api.carexpert.org.in/api/company/${id}`);
   setCompanies((prev) => prev.filter((company) => company._id !== id));
  } catch (error) {
   console.error("Error deleting company:", error);
  }
 };

 // Define columns for the DataGrid
 const columns = [
  { field: "name", headerName: "Name", flex: 1 },
  {
   field: "logoUrl",
   headerName: "Logo",
   flex: 1,
   renderCell: (params) => (
    <img
     src={params.value}
     alt={params.row.name}
     style={{ width: "50px", height: "50px", objectFit: "contain" }}
    />
   ),
  },
  {
   field: "actions",
   headerName: "Actions",
   flex: 1,
   renderCell: (params) => (
    <Box>
     <Button
      color="primary"
      variant="outlined"
      size="small"
      onClick={() => {
       setFormData({
        _id: params.row._id,
        name: params.row.name,
        description: params.row.description,
        country: params.row.country,
        specialties: params.row.specialties,
        website: params.row.website,
        logo: null, // Skip for now
       });
       setOpen(true);
      }}
     >
      Edit
     </Button>
     <Button
      color="secondary"
      variant="outlined"
      size="small"
      onClick={() => handleDeleteCompany(params.row._id)}
      sx={{ ml: 1 }}
     >
      Delete
     </Button>
    </Box>
   ),
  },
 ];

 return (
  <Box p={3}>
   <Typography variant="h4" gutterBottom>
    Company Management
   </Typography>
   <Box mb={2}>
    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
     Add Company
    </Button>
   </Box>
   <DataGrid
    rows={companies}
    columns={columns}
    getRowId={(row) => row._id} // Ensure to use a unique field as ID
    autoHeight
    loading={loading}
    pageSize={5}
   />

   {/* Add or Update Company Modal */}
   <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>
     {formData._id ? "Update Company" : "Add New Company"}
    </DialogTitle>
    <DialogContent>
     <TextField
      label="Name"
      name="name"
      value={formData.name}
      onChange={handleChange}
      fullWidth
      margin="normal"
     />

     <Button variant="contained" component="label" sx={{ mt: 2 }}>
      Upload Logo
      <input type="file" hidden accept="image/*" onChange={handleFileChange} />
     </Button>
     {formData.logo && (
      <Typography sx={{ mt: 1 }}>
       Selected File: {formData.logo.name}
      </Typography>
     )}
    </DialogContent>
    <DialogActions>
     <Button onClick={() => setOpen(false)} color="secondary">
      Cancel
     </Button>
     <Button onClick={handleSaveCompany} color="primary" variant="contained">
      {formData._id ? "Update" : "Add"}
     </Button>
    </DialogActions>
   </Dialog>
  </Box>
 );
};

export default CompanyLogo;

import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const BulkUploadCarPriceForm = () => {
 const {
  register,
  handleSubmit,
  reset,
  formState: { errors },
 } = useForm();

 const onSubmit = async (data) => {
  const formData = new FormData();
  formData.append("file", data.file[0]);

  try {
   const response = await axios.post(
    "https://app-api.carexpert.org.in/api/bulk-price/create",
    formData,
    {
     headers: {
      "Content-Type": "multipart/form-data",
     },
    }
   );

   if (response.data.success) {
    // alert("Prices uploaded successfully!");
   } else {
    // alert("Error: " + response.data.message);
   }
  } catch (error) {
   console.error("Error uploading file:", error);
   alert("Error uploading file");
  } finally {
   reset(); // Reset form after successful upload
  }
 };

 return (
  <form onSubmit={handleSubmit(onSubmit)}>
   <div>
    <label>Upload Excel File:</label>
    <input
     type="file"
     {...register("file", { required: true })}
     accept=".xlsx, .xls"
    />
    {errors.file && <span>This field is required</span>}
   </div>
   <button type="submit">Upload</button>
  </form>
 );
};

export default BulkUploadCarPriceForm;

// Category actions
export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

// Subcategory actions
export const GET_SUBCATEGORY_REQUEST = "GET_SUBCATEGORY_REQUEST";
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS";
export const GET_SUBCATEGORY_FAILURE = "GET_SUBCATEGORY_FAILURE";

export const CREATE_SUBCATEGORY_REQUEST = "CREATE_SUBCATEGORY_REQUEST";
export const CREATE_SUBCATEGORY_SUCCESS = "CREATE_SUBCATEGORY_SUCCESS";
export const CREATE_SUBCATEGORY_FAILURE = "CREATE_SUBCATEGORY_FAILURE";

export const UPDATE_SUBCATEGORY_REQUEST = "UPDATE_SUBCATEGORY_REQUEST";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAILURE = "UPDATE_SUBCATEGORY_FAILURE";

export const DELETE_SUBCATEGORY_REQUEST = "DELETE_SUBCATEGORY_REQUEST";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAILURE = "DELETE_SUBCATEGORY_FAILURE";

// Product actions
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const GET_SUB_CATEGORY_BASED_ON_CATEGORY_REQUEST =
 "GET_SUB_CATEGORY_BASED_ON_CATEGORY_REQUEST";
export const GET_SUB_CATEGORY_BASED_ON_CATEGORY_SUCCESS =
 "GET_SUB_CATEGORY_BASED_ON_CATEGORY_SUCCESS";
export const GET_SUB_CATEGORY_BASED_ON_CATEGORY_FAILURE =
 "GET_SUB_CATEGORY_BASED_ON_CATEGORY_FAILURE";

export const GET_PRODUCT_BY_ID_REQUEST = "GET_PRODUCT_BY_ID_REQUEST";
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS";
export const GET_PRODUCT_BY_ID_FAILURE = "GET_PRODUCT_BY_ID_FAILURE";

export const GET_PRICE_BY_CAR_REQUEST = "GET_PRICE_BY_CAR_REQUEST";
export const GET_PRICE_BY_CAR_SUCCESS = "GET_PRICE_BY_CAR_SUCCESS";
export const GET_PRICE_BY_CAR_FAILURE = "GET_PRICE_BY_CAR_FAILURE";

export const ADD_PRICE_BY_CAR_REQUEST = "ADD_PRICE_BY_CAR_REQUEST";
export const ADD_PRICE_BY_CAR_SUCCESS = "ADD_PRICE_BY_CAR_SUCCESS";
export const ADD_PRICE_BY_CAR_FAILURE = "ADD_PRICE_BY_CAR_FAILURE";

export const UPDATE_PRICE_BY_CAR_REQUEST = "UPDATE_PRICE_BY_CAR_REQUEST";
export const UPDATE_PRICE_BY_CAR_SUCCESS = "UPDATE_PRICE_BY_CAR_SUCCESS";
export const UPDATE_PRICE_BY_CAR_FAILURE = "UPDATE_PRICE_BY_CAR_FAILURE";

export const DELETE_PRICE_BY_CAR_REQUEST = "DELETE_PRICE_BY_CAR_REQUEST";
export const DELETE_PRICE_BY_CAR_SUCCESS = "DELETE_PRICE_BY_CAR_SUCCESS";
export const DELETE_PRICE_BY_CAR_FAILURE = "DELETE_PRICE_BY_CAR_FAILURE";

// types.js

export const GET_ALL_PRICES_REQUEST = "GET_ALL_PRICES_REQUEST";
export const GET_ALL_PRICES_SUCCESS = "GET_ALL_PRICES_SUCCESS";
export const GET_ALL_PRICES_FAILURE = "GET_ALL_PRICES_FAILURE";

export const UPDATE_PRICE_REQUEST = "UPDATE_PRICE_REQUEST";
export const UPDATE_PRICE_SUCCESS = "UPDATE_PRICE_SUCCESS";
export const UPDATE_PRICE_FAILURE = "UPDATE_PRICE_FAILURE";

export const DELETE_PRICE_REQUEST = "DELETE_PRICE_REQUEST";
export const DELETE_PRICE_SUCCESS = "DELETE_PRICE_SUCCESS";
export const DELETE_PRICE_FAILURE = "DELETE_PRICE_FAILURE";

export const GET_CAR_BY_BRAND_REQUEST = "GET_CAR_BY_BRAND_REQUEST";
export const GET_CAR_BY_BRAND_SUCCESS = "GET_CAR_BY_BRAND_SUCCESS";
export const GET_CAR_BY_BRAND_FAILURE = "GET_CAR_BY_BRAND_FAILURE";

export const GET_CARS_BY_BRAND_AND_TITLE_REQUEST =
 "GET_CARS_BY_BRAND_AND_TITLE_REQUEST";
export const GET_CARS_BY_BRAND_AND_TITLE_SUCCESS =
 "GET_CARS_BY_BRAND_AND_TITLE_SUCCESS";
export const GET_CARS_BY_BRAND_AND_TITLE_FAILURE =
 "GET_CARS_BY_BRAND_AND_TITLE_FAILURE";

//  sparePart
export const GET_SPARE_PARTS_REQUEST = "GET_SPARE_PARTS_REQUEST";
export const GET_SPARE_PARTS_SUCCESS = "GET_SPARE_PARTS_SUCCESS";
export const GET_SPARE_PARTS_FAILURE = "GET_SPARE_PARTS_FAILURE";

export const GET_SPARE_PART_REQUEST = "GET_SPARE_PART_REQUEST";
export const GET_SPARE_PART_SUCCESS = "GET_SPARE_PART_SUCCESS";
export const GET_SPARE_PART_FAILURE = "GET_SPARE_PART_FAILURE";

export const ADD_SPARE_PART_REQUEST = "ADD_SPARE_PART_REQUEST";
export const ADD_SPARE_PART_SUCCESS = "ADD_SPARE_PART_SUCCESS";
export const ADD_SPARE_PART_FAILURE = "ADD_SPARE_PART_FAILURE";

export const UPDATE_SPARE_PART_REQUEST = "UPDATE_SPARE_PART_REQUEST";
export const UPDATE_SPARE_PART_SUCCESS = "UPDATE_SPARE_PART_SUCCESS";
export const UPDATE_SPARE_PART_FAILURE = "UPDATE_SPARE_PART_FAILURE";

export const DELETE_SPARE_PART_REQUEST = "DELETE_SPARE_PART_REQUEST";
export const DELETE_SPARE_PART_SUCCESS = "DELETE_SPARE_PART_SUCCESS";
export const DELETE_SPARE_PART_FAILURE = "DELETE_SPARE_PART_FAILURE";

// actionTypes.js
export const CREATE_SPARE_PART_PRICE_REQUEST =
 "CREATE_SPARE_PART_PRICE_REQUEST";
export const CREATE_SPARE_PART_PRICE_SUCCESS =
 "CREATE_SPARE_PART_PRICE_SUCCESS";
export const CREATE_SPARE_PART_PRICE_FAILURE =
 "CREATE_SPARE_PART_PRICE_FAILURE";
// Spare Part Price Actions

export const GET_SPARE_PART_PRICES_REQUEST = "GET_SPARE_PART_PRICES_REQUEST";
export const GET_SPARE_PART_PRICES_SUCCESS = "GET_SPARE_PART_PRICES_SUCCESS";
export const GET_SPARE_PART_PRICES_FAILURE = "GET_SPARE_PART_PRICES_FAILURE";

export const UPDATE_SPARE_PART_PRICE_REQUEST =
 "UPDATE_SPARE_PART_PRICE_REQUEST";
export const UPDATE_SPARE_PART_PRICE_SUCCESS =
 "UPDATE_SPARE_PART_PRICE_SUCCESS";
export const UPDATE_SPARE_PART_PRICE_FAILURE =
 "UPDATE_SPARE_PART_PRICE_FAILURE";

export const DELETE_SPARE_PART_PRICE_REQUEST =
 "DELETE_SPARE_PART_PRICE_REQUEST";
export const DELETE_SPARE_PART_PRICE_SUCCESS =
 "DELETE_SPARE_PART_PRICE_SUCCESS";
export const DELETE_SPARE_PART_PRICE_FAILURE =
 "DELETE_SPARE_PART_PRICE_FAILURE";

import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginRegister from "./LoginRegister";
import Dashboard from "./Dashboard";
import ReqAuth from "../components/users/PrivetRoute";
import ResetPassword from "../components/users/ResetPassword";

const Mainroutes = () => {
 return (
  <Routes>
   <Route path="/" element={<LoginRegister />} />
   <Route path="reset-password/:token" element={<ResetPassword />} />

   <Route
    path="/dashboard/*"
    element={
     <ReqAuth>
      <Dashboard />
     </ReqAuth>
    }
   />

   <Route path="*" element={<h1>Not Found!</h1>} />
  </Routes>
 );
};

export default Mainroutes;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  FormControl,
  TextField,
  Modal,
  Fade,
  Backdrop,
  Button,
  MenuItem,
  Select,
  InputLabel,
  Snackbar,
  Alert
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import {
  getCategories,
  addCategory,
  updateCategory,
  deleteCategory,
} from "../../redux/products/action"; // Update with your action paths

const Categories = () => {
  const dispatch = useDispatch();
  const { categories = [] } = useSelector((store) => store.product);

  const [searchTerm, setSearchTerm] = useState("");
  const [editCategory, setEditCategory] = useState({
    _id: "",
    variantType: "",
    categoryName: "",
    categoryImage: null,
    categoryImagePreview: "",
    tag: "",
  });
  const [newCategory, setNewCategory] = useState({
    variantType: "",
    categoryName: "",
    categoryImage: null,
    categoryImagePreview: "",
    tag: "",
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleEditClick = (id) => {
    const category = categories.find((cat) => cat._id === id);
    if (category) {
      setEditCategory({
        ...category,
        categoryImage: null,
        categoryImagePreview: category.categoryImage || ""
      });
      setOpenEditModal(true);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await dispatch(deleteCategory(id));
      setSnackbar({
        open: true,
        message: "Category deleted successfully!",
        severity: "success"
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error deleting category.",
        severity: "error"
      });
      console.error("Error deleting category:", error);
    }
  };

  const handleEditSubmit = async () => {
    const formData = new FormData();
    formData.append("_id", editCategory._id);
    formData.append("variantType", editCategory.variantType);
    formData.append("categoryName", editCategory.categoryName);
    formData.append("tag", editCategory.tag);
    if (editCategory.categoryImage) {
      formData.append("categoryImage", editCategory.categoryImage);
    }

    try {
      await dispatch(updateCategory(formData));
      setEditCategory({
        _id: "",
        variantType: "",
        categoryName: "",
        categoryImage: null,
        categoryImagePreview: "",
        tag: "",
      });
      setOpenEditModal(false);
      setSnackbar({
        open: true,
        message: "Category updated successfully!",
        severity: "success"
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error updating category.",
        severity: "error"
      });
      console.error("Error updating category:", error);
    }
  };

  const handleAddSubmit = async () => {
    const formData = new FormData();
    formData.append("variantType", newCategory.variantType);
    formData.append("categoryName", newCategory.categoryName);
    formData.append("tag", newCategory.tag);
    if (newCategory.categoryImage) {
      formData.append("categoryImage", newCategory.categoryImage);
    }

    try {
      await dispatch(addCategory(formData));
      setNewCategory({
        variantType: "",
        categoryName: "",
        categoryImage: null,
        categoryImagePreview: "",
        tag: "",
      });
      setOpenAddModal(false);
      setSnackbar({
        open: true,
        message: "Category added successfully!",
        severity: "success"
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error adding category.",
        severity: "error"
      });
      console.error("Error adding category:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Ensure categories is an array before filtering
  const filteredCategories =
    Array.isArray(categories) ? categories.filter((category) =>
      category.categoryName.toLowerCase().includes(searchTerm)
    ) : [];

  // Handle file input change and preview
  const handleFileChange = (e, isEdit) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      if (isEdit) {
        setEditCategory({
          ...editCategory,
          categoryImage: file,
          categoryImagePreview: previewUrl,
        });
      } else {
        setNewCategory({
          ...newCategory,
          categoryImage: file,
          categoryImagePreview: previewUrl,
        });
      }
    }
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ width: "100%", height: "40vh" }}>
      <Typography variant="h5" paddingLeft="5px">
        Categories
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <IconButton
          color="primary"
          aria-label="add"
          onClick={() => setOpenAddModal(true)}
        >
          <Add />
        </IconButton>
        <FormControl sx={{ width: "50%" }}>
          <TextField
            label="Search Category"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </FormControl>
      </Box>
      <TableContainer component={Paper} sx={{ margin: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Variant Type</TableCell>
              <TableCell sx={{ color: "white" }}>Category Name</TableCell>
              <TableCell sx={{ color: "white" }}>Category Image</TableCell>
              <TableCell sx={{ color: "white" }}>Tag</TableCell>
              <TableCell sx={{ color: "white" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.map((category) => (
              <TableRow key={category._id}>
                <TableCell>{category.variantType}</TableCell>
                <TableCell>{category.categoryName}</TableCell>
                <TableCell>
                  <img
                    src={category.categoryImage}
                    alt={category.categoryName}
                    width="50"
                    height="50"
                  />
                </TableCell>
                <TableCell>{category.tag}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(category._id)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(category._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Category Modal */}
      <Modal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openEditModal}>
          <Box sx={{ ...modalStyle }}>
            <Typography variant="h6" component="h2">
              Edit Category
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="edit-variant-label">Variant Type</InputLabel>
              <Select
                labelId="edit-variant-label"
                id="edit-variant-type"
                value={editCategory.variantType}
                label="Variant Type"
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    variantType: e.target.value,
                  })
                }
              >
                <MenuItem value="Car">Car</MenuItem>
                <MenuItem value="Bike">Bike</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Category Name"
              fullWidth
              margin="normal"
              value={editCategory.categoryName}
              onChange={(e) =>
                setEditCategory({
                  ...editCategory,
                  categoryName: e.target.value,
                })
              }
            />
            <TextField
              label="Category Tag"
              fullWidth
              margin="normal"
              value={editCategory.tag}
              onChange={(e) =>
                setEditCategory({
                  ...editCategory,
                  tag: e.target.value,
                })
              }
            />
            <Button
              variant="contained"
              color="primary"
              component="label"
              fullWidth
              margin="normal"
            >
              Upload Category Image
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, true)} // Indicate edit mode
              />
            </Button>
            {editCategory.categoryImagePreview && (
              <Box mt={2} sx={{ textAlign: "center" }}>
                <img
                  src={editCategory.categoryImagePreview}
                  alt="Category Preview"
                  width="200"
                  height="150"
                />
              </Box>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditSubmit}
            >
              Update
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Add Category Modal */}
      <Modal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openAddModal}>
          <Box sx={{ ...modalStyle }}>
            <Typography variant="h6" component="h2">
              Add Category
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="add-variant-label">Variant Type</InputLabel>
              <Select
                labelId="add-variant-label"
                id="add-variant-type"
                value={newCategory.variantType}
                label="Variant Type"
                onChange={(e) =>
                  setNewCategory({
                    ...newCategory,
                    variantType: e.target.value,
                  })
                }
              >
                <MenuItem value="Car">Car</MenuItem>
                <MenuItem value="Bike">Bike</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Category Name"
              fullWidth
              margin="normal"
              value={newCategory.categoryName}
              onChange={(e) =>
                setNewCategory({
                  ...newCategory,
                  categoryName: e.target.value,
                })
              }
              disabled={!newCategory.variantType}
            />
            <TextField
              label="Category Tag"
              fullWidth
              margin="normal"
              value={newCategory.tag}
              onChange={(e) =>
                setNewCategory({
                  ...newCategory,
                  tag: e.target.value,
                })
              }
              disabled={!newCategory.variantType}
            />
            <Button
              variant="contained"
              color="primary"
              component="label"
              fullWidth
              margin="normal"
            >
              Upload Category Image
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, false)} // Indicate add mode
              />
            </Button>
            {newCategory.categoryImagePreview && (
              <Box mt={2} sx={{ textAlign: "center" }}>
                <img
                  src={newCategory.categoryImagePreview}
                  alt="Category Preview"
                  width="200"
                  height="150"
                />
              </Box>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSubmit}
            >
              Add
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Snackbar for Notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default Categories;

 
// src/redux/city/action.js
import axios from "axios";
import * as types from "./actionTypes";
const API_URL_FAQ = "https://app-api.carexpert.org.in/api/faqs";

const API_URL = "https://app-api.carexpert.org.in/api/cities";

export const fetchCities = () => async (dispatch) => {
 dispatch({ type: types.GET_CITIES_REQUEST });
 try {
  const res = await axios.get(API_URL);
  dispatch({ type: types.GET_CITIES_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_CITIES_FAILURE, payload: err.message });
 }
};

export const addCity = (formData) => async (dispatch) => {
 dispatch({ type: types.ADD_CITY_REQUEST });
 try {
  const res = await axios.post(`${API_URL}`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.ADD_CITY_SUCCESS, payload: res.data });
  dispatch(fetchCities());
 } catch (err) {
  dispatch({ type: types.ADD_CITY_FAILURE, payload: err.message });
 }
};

export const updateCity = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_CITY_REQUEST });
 try {
  const res = await axios.put(`${API_URL}/${id}`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.UPDATE_CITY_SUCCESS, payload: res.data });
  dispatch(fetchCities());
 } catch (err) {
  dispatch({ type: types.UPDATE_CITY_FAILURE, payload: err.message });
 }
};

export const deleteCity = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_CITY_REQUEST });
 try {
  await axios.delete(`${API_URL}/${id}`);
  dispatch({ type: types.DELETE_CITY_SUCCESS, payload: id });
  dispatch(fetchCities());
 } catch (err) {
  dispatch({ type: types.DELETE_CITY_FAILURE, payload: err.message });
 }
};

export const voteCity = (id) => async (dispatch) => {
 dispatch({ type: types.VOTE_CITY_REQUEST });
 try {
  const res = await axios.post(`${API_URL}/${id}/vote`);
  dispatch({ type: types.VOTE_CITY_SUCCESS, payload: res.data });
  dispatch(fetchCities());
 } catch (err) {
  dispatch({ type: types.VOTE_CITY_FAILURE, payload: err.message });
 }
};
export const toggleServiceStatus = (id, isServiced) => async (dispatch) => {
 dispatch({ type: "TOGGLE_SERVICE_STATUS_REQUEST" });

 try {
  const response = await axios.put(`${API_URL}/${id}/toggle-service`, {
   isServiced,
  });
  dispatch({ type: "TOGGLE_SERVICE_STATUS_SUCCESS", payload: response.data });
 } catch (error) {
  dispatch({ type: "TOGGLE_SERVICE_STATUS_FAILURE", error });
 }
};

export const removePincode = (id, pincode) => async (dispatch) => {
 dispatch({ type: "REMOVE_PINCODE_REQUEST" });

 try {
  const response = await axios.put(`${API_URL}/${id}/remove-pincode`, {
   pincode,
  });
  dispatch({ type: "REMOVE_PINCODE_SUCCESS", payload: response.data });
 } catch (error) {
  dispatch({ type: "REMOVE_PINCODE_FAILURE", error });
 }
};

//FAQ
export const fetchFAQs = () => async (dispatch) => {
 dispatch({ type: types.GET_FAQS_REQUEST });
 try {
  const res = await axios.get(API_URL_FAQ);
  dispatch({ type: types.GET_FAQS_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_FAQS_FAILURE, payload: err.message });
 }
};

export const addFAQ = (faq) => async (dispatch) => {
 dispatch({ type: types.ADD_FAQ_REQUEST });
 try {
  const res = await axios.post(API_URL_FAQ, faq);
  dispatch({ type: types.ADD_FAQ_SUCCESS, payload: res.data });
  dispatch(fetchFAQs());
 } catch (err) {
  dispatch({ type: types.ADD_FAQ_FAILURE, payload: err.message });
 }
};

export const updateFAQ = (id, faq) => async (dispatch) => {
 dispatch({ type: types.UPDATE_FAQ_REQUEST });
 try {
  const res = await axios.put(`${API_URL_FAQ}/${id}`, faq);
  dispatch({ type: types.UPDATE_FAQ_SUCCESS, payload: res.data });
  dispatch(fetchFAQs());
 } catch (err) {
  dispatch({ type: types.UPDATE_FAQ_FAILURE, payload: err.message });
 }
};

export const deleteFAQ = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_FAQ_REQUEST });
 try {
  await axios.delete(`${API_URL_FAQ}/${id}`);
  dispatch({ type: types.DELETE_FAQ_SUCCESS, payload: id });
  dispatch(fetchFAQs());
 } catch (err) {
  dispatch({ type: types.DELETE_FAQ_FAILURE, payload: err.message });
 }
};

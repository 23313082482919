import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
 Avatar,
 Box,
 Card,
 CardContent,
 Typography,
 IconButton,
 Dialog,
 DialogTitle,
 DialogContent,
 DialogActions,
 Button,
} from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import { CheckCircle, Cancel, Delete, Visibility } from "@mui/icons-material";

const FranchiseDataGrid = ({ rowData }) => {
 const [rows, setRows] = useState([]);
 const [openDialog, setOpenDialog] = useState(false);
 const [selectedVendor, setSelectedVendor] = useState(null);
 const [confirmationDialog, setConfirmationDialog] = useState({
  open: false,
  type: "", // "approve", "disapprove", "delete"
  vendor: null,
 });

 useEffect(() => {
  const formattedRows = rowData.map((item) => ({
   id: item._id,
   firstName: item.firstName || "N/A",
   lastName: item.lastName || "N/A",
   contactNumber: item.contactNumber || "N/A",
   email: item.email || "N/A",
   companyName: item.companyName || "N/A",
   companyType: item.companyType || "N/A",
   companyAddress: item.companyAddress || "N/A",
   franchiseApplicantName: item.franchiseApplicantName || "N/A",
   franchiseType: item.franchiseType || "N/A",
   franchiseCity: item.franchiseCity || "N/A",
   investmentAmount: item.investmentAmount || "N/A",
   registrationAmount: item.registrationAmount || "N/A",
   pendingAmount: item.pendingAmount || "N/A",
   aadharUpload: item.aadharUpload?.[0]?.filePath || "", // First image path if exists
   isApproved: item.isApproved || false, // Additional field for approval status
  }));

  setRows(formattedRows);
 }, [rowData]);

 const handleToggleApprove = (vendor) => {
  setConfirmationDialog({
   open: true,
   type: vendor.isApproved ? "disapprove" : "approve",
   vendor,
  });
 };

 const handleDelete = (vendor) => {
  setConfirmationDialog({
   open: true,
   type: "delete",
   vendor,
  });
 };

 const handleConfirmAction = () => {
  const { type, vendor } = confirmationDialog;
  if (type === "approve" || type === "disapprove") {
   // Toggle approval status
   setRows((prevRows) =>
    prevRows.map((row) =>
     row.id === vendor.id ? { ...row, isApproved: type === "approve" } : row
    )
   );
  } else if (type === "delete") {
   // Delete vendor
   setRows((prevRows) => prevRows.filter((row) => row.id !== vendor.id));
  }
  setConfirmationDialog({ open: false, type: "", vendor: null });
 };

 const handleViewDetails = (vendor) => {
  setSelectedVendor(vendor);
  setOpenDialog(true);
 };

 const columns = [
  {
   field: "vendorDetails",
   headerName: "Vendor Details",
   width: 350,
   renderCell: (params) => (
    <Card
     style={{
      width: "100%",
      height: "100%",
      margin: 0,
      padding: 0,
      boxShadow: "none",
      display: "flex",
      alignItems: "center",
     }}
    >
     <Avatar
      sx={{ bgcolor: deepPurple[500], width: 60, height: 60, marginRight: 2 }}
      src={params.row.aadharUpload || ""}
     >
      {params.row.firstName ? params.row.firstName[0] : ""}
     </Avatar>

     <CardContent style={{ textAlign: "left" }}>
      <Typography variant="caption" component="div" gutterBottom>
       Name: {params.row.firstName} {params.row.lastName}
      </Typography>
      <Typography variant="caption" component="div" gutterBottom>
       Contact: {params.row.contactNumber}
      </Typography>
      <Typography variant="caption" component="div" gutterBottom>
       Email: {params.row.email}
      </Typography>
     </CardContent>
    </Card>
   ),
  },
  {
   field: "CompanyDetails",
   headerName: "Company Details",
   width: 300,
   renderCell: (params) => (
    <Card
     style={{
      width: "100%",
      height: "100%",
      margin: 0,
      padding: 0,
      boxShadow: "none",
      display: "flex",
      alignItems: "center",
     }}
    >
     <CardContent style={{ textAlign: "left" }}>
      <Typography variant="caption" component="div" gutterBottom>
       Company Name: {params.row.companyName}
      </Typography>
      <Typography variant="caption" component="div" gutterBottom>
       Company Type: {params.row.companyType}
      </Typography>
      <Typography
       variant="caption"
       component="div"
       gutterBottom
       sx={{
        wordWrap: "break-word",
        overflow: "hidden",
        whiteSpace: "normal",
       }}
      >
       Company Address: {params.row.companyAddress}
      </Typography>
     </CardContent>
    </Card>
   ),
  },
  {
   field: "FranchiseDetails",
   headerName: "Franchise Details",
   width: 300,
   renderCell: (params) => (
    <Card
     style={{
      width: "100%",
      height: "100%",
      margin: 0,
      padding: 0,
      boxShadow: "none",
      display: "flex",
      alignItems: "center",
     }}
    >
     <CardContent style={{ textAlign: "left", fontSize: "12px" }}>
      <Typography variant="caption" component="div" gutterBottom>
       Applicant Name: {params.row.franchiseApplicantName}
      </Typography>
      <Typography variant="caption" component="div" gutterBottom>
       Franchise Type: {params.row.franchiseType}
      </Typography>
      <Typography variant="caption" component="div" gutterBottom>
       Franchise City: {params.row.franchiseCity}
      </Typography>
      <Typography variant="caption" component="div" gutterBottom>
       Investment Amount: {params.row.investmentAmount}
      </Typography>
      <Typography variant="caption" component="div" gutterBottom>
       Registration Amount: {params.row.registrationAmount}
      </Typography>
      <Typography variant="caption" component="div" gutterBottom>
       Pending Amount: {params.row.pendingAmount}
      </Typography>
     </CardContent>
    </Card>
   ),
  },
  {
   field: "action",
   headerName: "Actions",
   width: 150,
   renderCell: (params) => (
    <Box display="flex" justifyContent="space-between" width="100%">
     <IconButton
      color={params.row.isApproved ? "success" : "error"}
      onClick={() => handleToggleApprove(params.row)}
     >
      {params.row.isApproved ? <CheckCircle /> : <Cancel />}
     </IconButton>
     <IconButton color="primary" onClick={() => handleViewDetails(params.row)}>
      <Visibility />
     </IconButton>
     <IconButton color="secondary" onClick={() => handleDelete(params.row)}>
      <Delete />
     </IconButton>
    </Box>
   ),
  },
 ];

 return (
  <Box>
   <DataGrid
    rows={rows}
    columns={columns}
    rowHeight={180}
    headerHeight={60}
    sx={{
     "& .MuiDataGrid-cell:focus": {
      outline: "none",
     },
     "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "inherit",
     },
     "& .MuiDataGrid-cell": {
      padding: 0,
     },
    }}
   />

   {/* Confirmation Dialog */}
   <Dialog
    open={confirmationDialog.open}
    onClose={() =>
     setConfirmationDialog({ open: false, type: "", vendor: null })
    }
   >
    <DialogTitle>Confirm Action</DialogTitle>
    <DialogContent>
     Are you sure you want to{" "}
     {confirmationDialog.type === "approve" && "approve"}
     {confirmationDialog.type === "disapprove" && "disapprove"}
     {confirmationDialog.type === "delete" && "delete"} this vendor?
    </DialogContent>
    <DialogActions>
     <Button
      onClick={() =>
       setConfirmationDialog({ open: false, type: "", vendor: null })
      }
     >
      Cancel
     </Button>
     <Button onClick={handleConfirmAction} color="primary">
      Confirm
     </Button>
    </DialogActions>
   </Dialog>

   {/* View Details Dialog */}
   <Dialog
    open={openDialog}
    onClose={() => setOpenDialog(false)}
    maxWidth="sm"
    fullWidth
   >
    <DialogTitle>Vendor Details</DialogTitle>
    <DialogContent>
     {selectedVendor && (
      <>
       <Typography variant="body1">
        Name: {selectedVendor.firstName} {selectedVendor.lastName}
       </Typography>
       <Typography variant="body1">
        Contact: {selectedVendor.contactNumber}
       </Typography>
       <Typography variant="body1">Email: {selectedVendor.email}</Typography>
       <Typography variant="body1">
        Company: {selectedVendor.companyName}
       </Typography>
       <Typography variant="body1">
        Company Address: {selectedVendor.companyAddress}
       </Typography>
       {/* Additional details as needed */}
      </>
     )}
    </DialogContent>
    <DialogActions>
     <Button onClick={() => setOpenDialog(false)}>Close</Button>
    </DialogActions>
   </Dialog>
  </Box>
 );
};

export default FranchiseDataGrid;

// import React, { useState, useEffect } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
// import { deepPurple } from "@mui/material/colors";

// const FranchiseDataGrid = ({ rowData }) => {
//  const [rows, setRows] = useState([]);

//  useEffect(() => {
//   const formattedRows = rowData.map((item) => ({
//    id: item._id,
//    firstName: item.firstName || "N/A",
//    lastName: item.lastName || "N/A",
//    contactNumber: item.contactNumber || "N/A",
//    email: item.email || "N/A",
//    companyName: item.companyName || "N/A",
//    companyType: item.companyType || "N/A",
//    companyAddress: item.companyAddress || "N/A",
//    franchiseApplicantName: item.franchiseApplicantName || "N/A",
//    franchiseType: item.franchiseType || "N/A",
//    franchiseCity: item.franchiseCity || "N/A",
//    investmentAmount: item.investmentAmount || "N/A",
//    registrationAmount: item.registrationAmount || "N/A",
//    pendingAmount: item.pendingAmount || "N/A",
//    aadharUpload: item.aadharUpload?.[0]?.filePath || "", // First image path if exists
//   }));

//   setRows(formattedRows);
//  }, [rowData]);

//  const columns = [
//   {
//    field: "vendorDetails",
//    headerName: "Vendor Details",
//    width: 350,
//    renderCell: (params) => (
//     <Card
//      style={{
//       width: "100%",
//       height: "100%", // Ensures card fills cell height completely
//       margin: 0,
//       padding: 0,
//       boxShadow: "none", // Removes any shadow around the card
//       display: "flex",
//       alignItems: "center",
//      }}
//     >
//      <Avatar
//       sx={{ bgcolor: deepPurple[500], width: 60, height: 60, marginRight: 2 }}
//       src={params.row.aadharUpload || ""}
//      >
//       {params.row.firstName ? params.row.firstName[0] : ""}
//      </Avatar>

//      <CardContent style={{ textAlign: "left" }}>
//       <Typography variant="caption" component="div" gutterBottom>
//        Name: {params.row.firstName} {params.row.lastName}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Contact: {params.row.contactNumber}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        email: {params.row.email}
//       </Typography>
//      </CardContent>
//     </Card>
//    ),
//   },
//   {
//    field: "CompanyDetails",
//    headerName: "Company Details",
//    width: 300,
//    renderCell: (params) => (
//     <Card
//      style={{
//       width: "100%",
//       height: "100%",
//       margin: 0,
//       padding: 0,
//       boxShadow: "none",
//       display: "flex",
//       alignItems: "center",
//      }}
//     >
//      <CardContent style={{ textAlign: "left" }}>
//       <Typography variant="caption" component="div" gutterBottom>
//        Company Name: {params.row.companyName}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Company Type: {params.row.companyType}
//       </Typography>
//       <Typography
//        variant="caption"
//        component="div"
//        gutterBottom
//        sx={{
//         wordWrap: "break-word",
//         overflow: "hidden",
//         whiteSpace: "normal",
//        }}
//       >
//        Company Address: {params.row.companyAddress}
//       </Typography>
//      </CardContent>
//     </Card>
//    ),
//   },
//   {
//    field: "FranchiseDetails",
//    headerName: "Franchise Details",
//    width: 300,
//    renderCell: (params) => (
//     <Card
//      style={{
//       width: "100%",
//       height: "100%",
//       margin: 0,
//       padding: 0,
//       boxShadow: "none",
//       display: "flex",
//       alignItems: "center",
//      }}
//     >
//      <CardContent style={{ textAlign: "left", fontSize: "12px" }}>
//       <Typography variant="caption" component="div" gutterBottom>
//        Applicant Name: {params.row.franchiseApplicantName}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Franchise Type: {params.row.franchiseType}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Franchise City: {params.row.franchiseCity}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Investment Amount: {params.row.investmentAmount}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Registration Amount: {params.row.registrationAmount}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Pending Amount: {params.row.pendingAmount}
//       </Typography>
//      </CardContent>
//     </Card>
//    ),
//   },
//   {
//    field: "action",
//    headerName: "Action",
//    width: 300,
//    renderCell: (params) => (
//     <Card
//      style={{
//       width: "100%",
//       height: "100%",
//       margin: 0,
//       padding: 0,
//       boxShadow: "none",
//       display: "flex",
//       alignItems: "center",
//      }}
//     >
//      <CardContent style={{ textAlign: "left", fontSize: "12px" }}>
//       <Button></Button>
//       {/* <Typography variant="caption" component="div" gutterBottom>
//        Franchise Type: {params.row.franchiseType}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Franchise City: {params.row.franchiseCity}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Investment Amount: {params.row.investmentAmount}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Registration Amount: {params.row.registrationAmount}
//       </Typography>
//       <Typography variant="caption" component="div" gutterBottom>
//        Pending Amount: {params.row.pendingAmount}
//       </Typography> */}
//      </CardContent>
//     </Card>
//    ),
//   },
//  ];

//  return (
//   <Box>
//    <DataGrid
//     rows={rows}
//     columns={columns}
//     rowHeight={180} // Adjust this to match card height
//     headerHeight={60}
//     sx={{
//      "& .MuiDataGrid-cell:focus": {
//       outline: "none",
//      },
//      "& .MuiDataGrid-row.Mui-selected": {
//       backgroundColor: "inherit",
//      },
//      "& .MuiDataGrid-cell": {
//       padding: 0, // Remove cell padding to ensure no gap
//      },
//     }}
//    />
//   </Box>
//  );
// };

// export default FranchiseDataGrid;

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import FranchiseDataGrid from "./Franchise/FranchiseDataGrid";

const VendorDataGrid = () => {
 const [vendors, setVendors] = useState([]);

 useEffect(() => {
  const fetchData = async () => {
   try {
    const response = await fetch(
     "https://app-api.carexpert.org.in/api/franchise"
    );
    if (!response.ok) {
     throw new Error(`Error: ${response.status}`);
    }
    const result = await response.json();
    setVendors(result.data);
   } catch (error) {
    console.error("Fetch error:", error);
   }
  };

  fetchData();
 }, []);

 return (
  <Box sx={{ width: "100%" }}>
   <FranchiseDataGrid rowData={vendors} />;
  </Box>
 );
};

export default VendorDataGrid;

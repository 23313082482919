// src/components/Charts/OrdersChart.js
import React from "react";
import {
 BarChart,
 Bar,
 XAxis,
 YAxis,
 CartesianGrid,
 Tooltip,
 Legend,
} from "recharts";

const data = [
 { name: "Jan", orders: 30 },
 { name: "Feb", orders: 20 },
 // Add more data points
];

const OrdersChart = () => (
 <BarChart
  width={500}
  height={300}
  data={data}
  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
 >
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="name" />
  <YAxis />
  <Tooltip />
  <Legend />
  <Bar dataKey="orders" fill="#82ca9d" />
 </BarChart>
);

export default OrdersChart;

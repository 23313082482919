import React, { useEffect, useState } from "react";
import axios from "axios";
import {
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 IconButton,
 Typography,
 Box,
 Grid,
 Fab,
 TextField,
 Modal,
 tableCellClasses,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
 [`&.${tableCellClasses.head}`]: {
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
 },
 [`&.${tableCellClasses.body}`]: {
  fontSize: 14,
 },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
 "&:nth-of-type(odd)": {
  backgroundColor: theme.palette.action.hover,
 },
 "&:last-child td, &:last-child th": {
  border: 0,
 },
}));

const OrderManagement = () => {
 const [orders, setOrders] = useState([]);
 const [latestOrders, setLatestOrders] = useState([]);
 const [selectedUserOrders, setSelectedUserOrders] = useState([]);
 const [carDetails, setCarDetails] = useState({}); // State to store car details
 const [openModal, setOpenModal] = useState(false);

 const [orderIdFilter, setOrderIdFilter] = useState("");
 const [userNameFilter, setUserNameFilter] = useState("");
 const [statusFilter, setStatusFilter] = useState("");

 useEffect(() => {
  const fetchOrders = async () => {
   try {
    const response = await axios.get(
     "https://app-api.carexpert.org.in/api/orders"
    );
    const ordersData = response.data;

    // Get the latest order for each user
    const latestOrdersMap = ordersData.reduce((acc, order) => {
     if (
      !acc[order.user._id] ||
      new Date(order.createdAt) > new Date(acc[order.user._id].createdAt)
     ) {
      acc[order.user._id] = order;
     }
     return acc;
    }, {});

    setOrders(ordersData);
    setLatestOrders(Object.values(latestOrdersMap));

    // Fetch car details for orders with currentCar
    ordersData.forEach((order) => {
     if (order.user.currentCar) {
      fetchCarDetails(order.user.currentCar);
     }
    });
   } catch (error) {
    console.error("Error fetching orders:", error);
   }
  };

  fetchOrders();
 }, []);

 // Function to fetch car details based on carId (currentCar)
 const fetchCarDetails = async (carId) => {
  try {
   const response = await axios.get(
    `https://app-api.carexpert.org.in/api/cars/${carId}`
   );
   setCarDetails((prevState) => ({
    ...prevState,
    [carId]: response.data.data, // Store car details by carId
   }));
  } catch (error) {
   console.error(`Error fetching car details for ${carId}:`, error);
  }
 };

 const handleViewOrders = (userId) => {
  const userOrders = orders.filter((order) => order.user._id === userId);
  setSelectedUserOrders(userOrders);
  setOpenModal(true);
 };

 const handleCloseModal = () => {
  setOpenModal(false);
 };

 const handleOrderIdChange = (e) => {
  setOrderIdFilter(e.target.value);
 };

 const handleUserNameChange = (e) => {
  setUserNameFilter(e.target.value);
 };

 const handleStatusChange = (e) => {
  setStatusFilter(e.target.value);
 };

 const handleResetFilters = () => {
  setOrderIdFilter("");
  setUserNameFilter("");
  setStatusFilter("");
 };

 return (
  <Box
   sx={{
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
   }}
  >
   <TableContainer component={Paper}>
    <Typography variant="h6" gutterBottom component="div">
     Latest Orders
    </Typography>
    <Grid container spacing={2} alignItems="center">
     <Grid item xs={12} sm>
      <TextField
       label="Order ID"
       value={orderIdFilter}
       onChange={handleOrderIdChange}
       fullWidth
      />
     </Grid>
     <Grid item xs={12} sm>
      <TextField
       label="User Name"
       value={userNameFilter}
       onChange={handleUserNameChange}
       fullWidth
      />
     </Grid>
     <Grid item xs={12} sm>
      <TextField
       label="Status"
       value={statusFilter}
       onChange={handleStatusChange}
       fullWidth
      />
     </Grid>
     <Grid item xs={12} sm="auto">
      <Fab color="primary" aria-label="clear" onClick={handleResetFilters}>
       <ClearIcon />
      </Fab>
     </Grid>
    </Grid>
    <Table sx={{ minWidth: 700 }} aria-label="customized table">
     <TableHead>
      <TableRow>
       <StyledTableCell>S.No</StyledTableCell>
       <StyledTableCell>Order ID</StyledTableCell>
       {/* <StyledTableCell>User Name</StyledTableCell> */}
       <StyledTableCell>Mobile</StyledTableCell>
       <StyledTableCell>Car Details</StyledTableCell>
       <StyledTableCell>Slot Time</StyledTableCell>
       <StyledTableCell>Slot Date</StyledTableCell>
       <StyledTableCell>Payment Method</StyledTableCell>
       <StyledTableCell>Address</StyledTableCell>
       <StyledTableCell>Balance Due</StyledTableCell>
       <StyledTableCell>Discounts</StyledTableCell>

       <StyledTableCell>Order Status</StyledTableCell>
       <StyledTableCell>Payment Status</StyledTableCell>
       <StyledTableCell>Cart Items</StyledTableCell>
       <StyledTableCell>Actions</StyledTableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {latestOrders.map((order, index) => (
       <StyledTableRow key={order.orderId}>
        <StyledTableCell component="th" scope="row">
         {index + 1}
        </StyledTableCell>
        {/* <StyledTableCell>{order.orderId}</StyledTableCell> */}
        <StyledTableCell>{order.user._id}</StyledTableCell>
        <StyledTableCell>{order.user.mobile}</StyledTableCell>
        <StyledTableCell>
         {order.user.currentCar && carDetails[order.user.currentCar] ? (
          <Box>
           <Typography variant="body2">
            Brand: {carDetails[order.user.currentCar].brand.title}
           </Typography>
           <img
            src={carDetails[order.user.currentCar].brand.logo}
            alt={`${carDetails[order.user.currentCar].brand.title} logo`}
            style={{ width: "50px", height: "auto" }}
           />
           <Typography variant="body2">
            Model: {carDetails[order.user.currentCar].title}
           </Typography>
           <Typography variant="body2">
            Transmission: {carDetails[order.user.currentCar].transmissionType}
           </Typography>
           <Typography variant="body2">
            Fuel Type: {carDetails[order.user.currentCar].fuelType}
           </Typography>
          </Box>
         ) : (
          "Loading..."
         )}
        </StyledTableCell>
        <StyledTableCell>{order.slot?.time || "N/A"}</StyledTableCell>
        <StyledTableCell>
         {order.slot?.date || order.slotDate || "N/A"}
        </StyledTableCell>
        <StyledTableCell>{order.paymentMethod}</StyledTableCell>
        <StyledTableCell>
         {order.address?.completeAddress || "N/A"}
        </StyledTableCell>
        <StyledTableCell>{order.balanceDue}</StyledTableCell>
        <StyledTableCell>
         <Box>
          {" "}
          <Typography variant="body2">
           Coupon: {order.discounts.coupon}
          </Typography>
          <Typography variant="body2">
           Referral: {order.discounts.referral}
          </Typography>
          <Typography variant="body2">Slot: {order.discounts.slot}</Typography>
          <Typography variant="body2">
           Wallet Used: {order.discounts.walletUsed}
          </Typography>
          <Typography variant="body2">
           Total Discount: {order.discounts.totalDiscount}
          </Typography>
         </Box>
        </StyledTableCell>
        <StyledTableCell>{order.orderStatus}</StyledTableCell>
        <StyledTableCell>{order.paymentStatus}</StyledTableCell>
        <StyledTableCell>
         {order.cart?.items.map((item, idx) => (
          <Box key={idx}>
           <Typography variant="body2">Product ID: {item.productId}</Typography>
           <Typography variant="body2">Quantity: {item.quantity}</Typography>
           <Typography variant="body2">Price: {item.price}</Typography>
          </Box>
         )) || "N/A"}
        </StyledTableCell>
        <StyledTableCell>
         <IconButton
          color="primary"
          onClick={() => handleViewOrders(order.user._id)}
         >
          <VisibilityIcon />
         </IconButton>
        </StyledTableCell>
       </StyledTableRow>
      ))}
     </TableBody>
    </Table>
   </TableContainer>

   <Modal open={openModal} onClose={handleCloseModal}>
    <Box
     sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      height: "80vh",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      overflow: "auto",
     }}
    >
     <Typography variant="h6" gutterBottom component="div">
      All Orders for Selected User
     </Typography>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="user orders table">
       <TableHead>
        <TableRow>
         <StyledTableCell>S.No</StyledTableCell>
         <StyledTableCell>Order ID</StyledTableCell>
         <StyledTableCell>Mobile</StyledTableCell>

         <StyledTableCell>Car Details</StyledTableCell>
         <StyledTableCell>Slot Time</StyledTableCell>
         <StyledTableCell>Slot Date</StyledTableCell>
         <StyledTableCell>Payment Method</StyledTableCell>
         <StyledTableCell>Address</StyledTableCell>
         <StyledTableCell>Balance Due</StyledTableCell>
         <StyledTableCell>Discounts</StyledTableCell>
         <StyledTableCell>Order Status</StyledTableCell>
         <StyledTableCell>Payment Status</StyledTableCell>
         <StyledTableCell>Cart Items</StyledTableCell>
        </TableRow>
       </TableHead>
       <TableBody>
        {selectedUserOrders.map((order, index) => (
         <StyledTableRow key={order.orderId}>
          <StyledTableCell component="th" scope="row">
           {index + 1}
          </StyledTableCell>
          <StyledTableCell>{order.orderId}</StyledTableCell>
          <StyledTableCell>{order.user.mobile}</StyledTableCell>

          <StyledTableCell>
           {order.user.currentCar && carDetails[order.user.currentCar] ? (
            <Box>
             <Typography variant="body2">
              Brand: {carDetails[order.user.currentCar].brand.title}
             </Typography>
             <img
              src={carDetails[order.user.currentCar].brand.logo}
              alt={`${carDetails[order.user.currentCar].brand.title} logo`}
              style={{ width: "50px", height: "auto" }}
             />
             <Typography variant="body2">
              Model: {carDetails[order.user.currentCar].title}
             </Typography>
             <Typography variant="body2">
              Transmission: {carDetails[order.user.currentCar].transmissionType}
             </Typography>
             <Typography variant="body2">
              Fuel Type: {carDetails[order.user.currentCar].fuelType}
             </Typography>
            </Box>
           ) : (
            "Loading..."
           )}
          </StyledTableCell>
          <StyledTableCell>{order.slot?.time || "N/A"}</StyledTableCell>
          <StyledTableCell>
           {order.slot?.date || order.slotDate || "N/A"}
          </StyledTableCell>
          <StyledTableCell>{order.paymentMethod}</StyledTableCell>
          <StyledTableCell>
           {order.address?.completeAddress || "N/A"}
          </StyledTableCell>
          <StyledTableCell>{order.balanceDue}</StyledTableCell>
          <StyledTableCell>
           <Box>
            {" "}
            <Typography variant="body2">
             Coupon: {order.discounts.coupon}
            </Typography>
            <Typography variant="body2">
             Referral: {order.discounts.referral}
            </Typography>
            <Typography variant="body2">
             Slot: {order.discounts.slot}
            </Typography>
            <Typography variant="body2">
             Wallet Used: {order.discounts.walletUsed}
            </Typography>
            <Typography variant="body2">
             Total Discount: {order.discounts.totalDiscount}
            </Typography>
           </Box>
          </StyledTableCell>
          <StyledTableCell>{order.orderStatus}</StyledTableCell>
          <StyledTableCell>{order.paymentStatus}</StyledTableCell>
          <StyledTableCell>
           {order.cart?.items.map((item, idx) => (
            <Box key={idx}>
             <Typography variant="body2">
              Product ID: {item.productId}
             </Typography>
             <Typography variant="body2">Quantity: {item.quantity}</Typography>
             <Typography variant="body2">Price: {item.price}</Typography>
            </Box>
           )) || "N/A"}
          </StyledTableCell>
         </StyledTableRow>
        ))}
       </TableBody>
      </Table>
     </TableContainer>
    </Box>
   </Modal>
  </Box>
 );
};

export default OrderManagement;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   Typography,
//   Box,
//   Grid,
//   Fab,
//   TextField,
//   Modal,
//   tableCellClasses,
// } from "@mui/material";
// import ClearIcon from "@mui/icons-material/Clear";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { styled } from "@mui/material/styles";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// const OrderManagement = () => {
//   const [orders, setOrders] = useState([]);
//   const [latestOrders, setLatestOrders] = useState([]);
//   const [selectedUserOrders, setSelectedUserOrders] = useState([]);
//   const [carDetails, setCarDetails] = useState({}); // State to store car details
//   const [openModal, setOpenModal] = useState(false);

//   const [orderIdFilter, setOrderIdFilter] = useState("");
//   const [userNameFilter, setUserNameFilter] = useState("");
//   const [statusFilter, setStatusFilter] = useState("");

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await axios.get("https://app-api.carexpert.org.in/api/orders");
//         const ordersData = response.data;

//         // Get the latest order for each user
//         const latestOrdersMap = ordersData.reduce((acc, order) => {
//           if (
//             !acc[order.user._id] ||
//             new Date(order.createdAt) > new Date(acc[order.user._id].createdAt)
//           ) {
//             acc[order.user._id] = order;
//           }
//           return acc;
//         }, {});

//         setOrders(ordersData);
//         setLatestOrders(Object.values(latestOrdersMap));

//         // Fetch car details for orders with currentCar
//         ordersData.forEach((order) => {
//           if (order.user.currentCar) {
//             fetchCarDetails(order.user.currentCar);
//           }
//         });
//       } catch (error) {
//         console.error("Error fetching orders:", error);
//       }
//     };

//     fetchOrders();
//   }, []);

//   // Function to fetch car details based on carId (currentCar)
//   const fetchCarDetails = async (carId) => {
//     try {
//       const response = await axios.get(
//         `https://app-api.carexpert.org.in/api/cars/${carId}`
//       );
//       setCarDetails((prevState) => ({
//         ...prevState,
//         [carId]: response.data, // Store car details by carId
//       }));
//     } catch (error) {
//       console.error(`Error fetching car details for ${carId}:`, error);
//     }
//   };

//   const handleViewOrders = (userId) => {
//     const userOrders = orders.filter((order) => order.user._id === userId);
//     setSelectedUserOrders(userOrders);
//     setOpenModal(true);
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//   };

//   const handleOrderIdChange = (e) => {
//     setOrderIdFilter(e.target.value);
//   };

//   const handleUserNameChange = (e) => {
//     setUserNameFilter(e.target.value);
//   };

//   const handleStatusChange = (e) => {
//     setStatusFilter(e.target.value);
//   };

//   const handleResetFilters = () => {
//     setOrderIdFilter("");
//     setUserNameFilter("");
//     setStatusFilter("");
//   };

//   return (
//     <Box
//       sx={{
//         padding: "10px",
//         display: "flex",
//         flexDirection: "column",
//         gap: "20px",
//       }}
//     >
//       <TableContainer component={Paper}>
//         <Typography variant="h6" gutterBottom component="div">
//           Latest Orders
//         </Typography>
//         <Grid container spacing={2} alignItems="center">
//           <Grid item xs={12} sm>
//             <TextField
//               label="Order ID"
//               value={orderIdFilter}
//               onChange={handleOrderIdChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={12} sm>
//             <TextField
//               label="User Name"
//               value={userNameFilter}
//               onChange={handleUserNameChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={12} sm>
//             <TextField
//               label="Status"
//               value={statusFilter}
//               onChange={handleStatusChange}
//               fullWidth
//             />
//           </Grid>
//           <Grid item xs={12} sm="auto">
//             <Fab
//               color="primary"
//               aria-label="clear"
//               onClick={handleResetFilters}
//             >
//               <ClearIcon />
//             </Fab>
//           </Grid>
//         </Grid>
//         <Table sx={{ minWidth: 700 }} aria-label="customized table">
//           <TableHead>
//             <TableRow>
//               <StyledTableCell>S.No</StyledTableCell>
//               <StyledTableCell>Order ID</StyledTableCell>
//               <StyledTableCell>User ID</StyledTableCell>
//               <StyledTableCell>Mobile</StyledTableCell>
//               <StyledTableCell>Car Details</StyledTableCell>
//               <StyledTableCell>Slot Time</StyledTableCell>
//               <StyledTableCell>Slot Date</StyledTableCell>
//               <StyledTableCell>Payment Method</StyledTableCell>
//               <StyledTableCell>Address</StyledTableCell>
//               <StyledTableCell>Balance Due</StyledTableCell>
//               <StyledTableCell>Order Status</StyledTableCell>
//               <StyledTableCell>Payment Status</StyledTableCell>
//               <StyledTableCell>Cart Items</StyledTableCell>
//               <StyledTableCell>Actions</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {latestOrders.map((order, index) => (
//               <StyledTableRow key={order.orderId}>
//                 <StyledTableCell component="th" scope="row">
//                   {index + 1}
//                 </StyledTableCell>
//                 <StyledTableCell>{order.orderId}</StyledTableCell>
//                 <StyledTableCell>{order.user._id}</StyledTableCell>
//                 <StyledTableCell>{order.user.mobile}</StyledTableCell>
//                 <StyledTableCell>
//                   {order.user.currentCar &&
//                   carDetails[order.user.currentCar] ? (
//                     <Box>
//                       <Typography variant="body2">
//                         Title: {carDetails[order.user.currentCar].title}
//                       </Typography>
//                       <Typography variant="body2">
//                         Transmission:{" "}
//                         {carDetails[order.user.currentCar].transmissionType}
//                       </Typography>
//                       <Typography variant="body2">
//                         Fuel Type: {carDetails[order.user.currentCar].fuelType}
//                       </Typography>
//                     </Box>
//                   ) : (
//                     "Loading..."
//                   )}
//                 </StyledTableCell>
//                 <StyledTableCell>{order.slot?.time || "N/A"}</StyledTableCell>
//                 <StyledTableCell>
//                   {order.slot?.date || order.slotDate || "N/A"}
//                 </StyledTableCell>
//                 <StyledTableCell>{order.paymentMethod}</StyledTableCell>
//                 <StyledTableCell>
//                   {order.address?.completeAddress || "N/A"}
//                 </StyledTableCell>
//                 <StyledTableCell>{order.balanceDue}</StyledTableCell>
//                 <StyledTableCell>{order.orderStatus}</StyledTableCell>
//                 <StyledTableCell>{order.paymentStatus}</StyledTableCell>
//                 <StyledTableCell>
//                   {order.cart?.items.map((item, idx) => (
//                     <Box key={idx}>
//                       <Typography variant="body2">
//                         Product ID: {item.productId}
//                       </Typography>
//                       <Typography variant="body2">
//                         Quantity: {item.quantity}
//                       </Typography>
//                       <Typography variant="body2">
//                         Price: {item.price}
//                       </Typography>
//                     </Box>
//                   )) || "N/A"}
//                 </StyledTableCell>
//                 <StyledTableCell>
//                   <IconButton
//                     color="primary"
//                     onClick={() => handleViewOrders(order.user._id)}
//                   >
//                     <VisibilityIcon />
//                   </IconButton>
//                 </StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       <Modal open={openModal} onClose={handleCloseModal}>
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "90%",
//             height: "80vh",
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: 4,
//             overflow: "auto",
//           }}
//         >
//           <Typography variant="h6" gutterBottom component="div">
//             All Orders for Selected User
//           </Typography>
//           <TableContainer component={Paper}>
//             <Table sx={{ minWidth: 700 }} aria-label="user orders table">
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell>S.No</StyledTableCell>
//                   <StyledTableCell>Order ID</StyledTableCell>
//                   <StyledTableCell>Car Details</StyledTableCell>
//                   <StyledTableCell>Slot Time</StyledTableCell>
//                   <StyledTableCell>Slot Date</StyledTableCell>
//                   <StyledTableCell>Payment Method</StyledTableCell>
//                   <StyledTableCell>Address</StyledTableCell>
//                   <StyledTableCell>Balance Due</StyledTableCell>
//                   <StyledTableCell>Order Status</StyledTableCell>
//                   <StyledTableCell>Payment Status</StyledTableCell>
//                   <StyledTableCell>Cart Items</StyledTableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {selectedUserOrders.map((order, index) => (
//                   <StyledTableRow key={order.orderId}>
//                     <StyledTableCell component="th" scope="row">
//                       {index + 1}
//                     </StyledTableCell>
//                     <StyledTableCell>{order.orderId}</StyledTableCell>
//                     <StyledTableCell>
//                       {order.user.currentCar &&
//                       carDetails[order.user.currentCar] ? (
//                         <Box>
//                           <Typography variant="body2">
//                             Title: {carDetails[order.user.currentCar].title}
//                           </Typography>
//                           <Typography variant="body2">
//                             Transmission:{" "}
//                             {carDetails[order.user.currentCar].transmissionType}
//                           </Typography>
//                           <Typography variant="body2">
//                             Fuel Type:{" "}
//                             {carDetails[order.user.currentCar].fuelType}
//                           </Typography>
//                         </Box>
//                       ) : (
//                         "Loading..."
//                       )}
//                     </StyledTableCell>
//                     <StyledTableCell>
//                       {order.slot?.time || "N/A"}
//                     </StyledTableCell>
//                     <StyledTableCell>
//                       {order.slot?.date || order.slotDate || "N/A"}
//                     </StyledTableCell>
//                     <StyledTableCell>{order.paymentMethod}</StyledTableCell>
//                     <StyledTableCell>
//                       {order.address?.completeAddress || "N/A"}
//                     </StyledTableCell>
//                     <StyledTableCell>{order.balanceDue}</StyledTableCell>
//                     <StyledTableCell>{order.orderStatus}</StyledTableCell>
//                     <StyledTableCell>{order.paymentStatus}</StyledTableCell>
//                     <StyledTableCell>
//                       {order.cart?.items.map((item, idx) => (
//                         <Box key={idx}>
//                           <Typography variant="body2">
//                             Product ID: {item.productId}
//                           </Typography>
//                           <Typography variant="body2">
//                             Quantity: {item.quantity}
//                           </Typography>
//                           <Typography variant="body2">
//                             Price: {item.price}
//                           </Typography>
//                         </Box>
//                       )) || "N/A"}
//                     </StyledTableCell>
//                   </StyledTableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default OrderManagement;

// // import React, { useEffect, useState } from "react";
// // import axios from "axios";
// // import {
// //  Table,
// //  TableBody,
// //  TableCell,
// //  TableContainer,
// //  TableHead,
// //  TableRow,
// //  Paper,
// //  IconButton,
// //  Typography,
// //  Box,
// //  Grid,
// //  Fab,
// //  TextField,
// //  Modal,
// //  tableCellClasses,
// // } from "@mui/material";
// // import ClearIcon from "@mui/icons-material/Clear";
// // import VisibilityIcon from "@mui/icons-material/Visibility";
// // import { styled } from "@mui/material/styles";

// // const StyledTableCell = styled(TableCell)(({ theme }) => ({
// //  [`&.${tableCellClasses.head}`]: {
// //   backgroundColor: theme.palette.common.black,
// //   color: theme.palette.common.white,
// //  },
// //  [`&.${tableCellClasses.body}`]: {
// //   fontSize: 14,
// //  },
// // }));

// // const StyledTableRow = styled(TableRow)(({ theme }) => ({
// //  "&:nth-of-type(odd)": {
// //   backgroundColor: theme.palette.action.hover,
// //  },
// //  "&:last-child td, &:last-child th": {
// //   border: 0,
// //  },
// // }));

// // const OrderManagement = () => {
// //  const [orders, setOrders] = useState([]);
// //  const [latestOrders, setLatestOrders] = useState([]);
// //  const [selectedUserOrders, setSelectedUserOrders] = useState([]);
// //  const [openModal, setOpenModal] = useState(false);

// //  const [orderIdFilter, setOrderIdFilter] = useState("");
// //  const [userNameFilter, setUserNameFilter] = useState("");
// //  const [statusFilter, setStatusFilter] = useState("");

// //  useEffect(() => {
// //   const fetchOrders = async () => {
// //    try {
// //     const response = await axios.get(
// //      "https://app-api.carexpert.org.in/api/orders"
// //     );
// //     const ordersData = response.data;

// //     // Get the latest order for each user
// //     const latestOrdersMap = ordersData.reduce((acc, order) => {
// //      if (
// //       !acc[order.userId._id] ||
// //       new Date(order.createdAt) > new Date(acc[order.userId._id].createdAt)
// //      ) {
// //       acc[order.userId._id] = order;
// //      }
// //      return acc;
// //     }, {});

// //     setOrders(ordersData);
// //     setLatestOrders(Object.values(latestOrdersMap));
// //    } catch (error) {
// //     console.error("Error fetching orders:", error);
// //    }
// //   };

// //   fetchOrders();
// //  }, []);

// //  const handleViewOrders = (userId) => {
// //   const userOrders = orders.filter((order) => order.userId._id === userId);
// //   setSelectedUserOrders(userOrders);
// //   setOpenModal(true);
// //  };

// //  const handleCloseModal = () => {
// //   setOpenModal(false);
// //  };

// //  const handleOrderIdChange = (e) => {
// //   setOrderIdFilter(e.target.value);
// //  };

// //  const handleUserNameChange = (e) => {
// //   setUserNameFilter(e.target.value);
// //  };

// //  const handleStatusChange = (e) => {
// //   setStatusFilter(e.target.value);
// //  };

// //  const handleResetFilters = () => {
// //   setOrderIdFilter("");
// //   setUserNameFilter("");
// //   setStatusFilter("");
// //  };

// //  return (
// //   <Box
// //    sx={{
// //     padding: "10px",
// //     display: "flex",
// //     flexDirection: "column",
// //     gap: "20px",
// //    }}
// //   >
// //    <TableContainer component={Paper}>
// //     <Typography variant="h6" gutterBottom component="div">
// //      Latest Orders
// //     </Typography>
// //     <Grid container spacing={2} alignItems="center">
// //      <Grid item xs={12} sm>
// //       <TextField
// //        label="Order ID"
// //        value={orderIdFilter}
// //        onChange={handleOrderIdChange}
// //        fullWidth
// //       />
// //      </Grid>
// //      <Grid item xs={12} sm>
// //       <TextField
// //        label="User Name"
// //        value={userNameFilter}
// //        onChange={handleUserNameChange}
// //        fullWidth
// //       />
// //      </Grid>
// //      <Grid item xs={12} sm>
// //       <TextField
// //        label="Status"
// //        value={statusFilter}
// //        onChange={handleStatusChange}
// //        fullWidth
// //       />
// //      </Grid>
// //      <Grid item xs={12} sm="auto">
// //       <Fab color="primary" aria-label="clear" onClick={handleResetFilters}>
// //        <ClearIcon />
// //       </Fab>
// //      </Grid>
// //     </Grid>
// //     <Table sx={{ minWidth: 700 }} aria-label="customized table">
// //      <TableHead>
// //       <TableRow>
// //        <StyledTableCell>S.No</StyledTableCell>
// //        <StyledTableCell>Order ID</StyledTableCell>
// //        <StyledTableCell>User ID</StyledTableCell>
// //        <StyledTableCell>Mobile</StyledTableCell>
// //        <StyledTableCell>Car ID</StyledTableCell>
// //        <StyledTableCell>Slot Time</StyledTableCell>
// //        <StyledTableCell>Slot Date</StyledTableCell>
// //        <StyledTableCell>Payment Method</StyledTableCell>
// //        <StyledTableCell>Address</StyledTableCell>
// //        <StyledTableCell>Balance Due</StyledTableCell>
// //        <StyledTableCell>Order Status</StyledTableCell>
// //        <StyledTableCell>Payment Status</StyledTableCell>
// //        <StyledTableCell>Actions</StyledTableCell>
// //       </TableRow>
// //      </TableHead>
// //      <TableBody>
// //       {latestOrders.map((order, index) => (
// //        <StyledTableRow key={order._id}>
// //         <StyledTableCell component="th" scope="row">
// //          {index + 1}
// //         </StyledTableCell>
// //         <StyledTableCell>{order.orderId}</StyledTableCell>
// //         <StyledTableCell>{order.userId}</StyledTableCell>
// //         <StyledTableCell>{order.mobile}</StyledTableCell>
// //         <StyledTableCell>{order.carId}</StyledTableCell>
// //         <StyledTableCell>{order.slot?.time || "N/A"}</StyledTableCell>
// //         <StyledTableCell>
// //          {order.slot?.date || order.slotDate || "N/A"}
// //         </StyledTableCell>
// //         <StyledTableCell>{order.paymentMethod}</StyledTableCell>
// //         <StyledTableCell>
// //          {order.address?.completeAddress || "N/A"}
// //         </StyledTableCell>
// //         <StyledTableCell>{order.balanceDue}</StyledTableCell>
// //         <StyledTableCell>{order.orderStatus}</StyledTableCell>
// //         <StyledTableCell>{order.paymentStatus}</StyledTableCell>
// //         <StyledTableCell>
// //          <IconButton
// //           color="primary"
// //           onClick={() => handleViewOrders(order.userId._id)}
// //          >
// //           <VisibilityIcon />
// //          </IconButton>
// //         </StyledTableCell>
// //        </StyledTableRow>
// //       ))}
// //      </TableBody>
// //     </Table>
// //    </TableContainer>

// //    <Modal open={openModal} onClose={handleCloseModal}>
// //     <Box
// //      sx={{
// //       position: "absolute",
// //       top: "50%",
// //       left: "50%",
// //       transform: "translate(-50%, -50%)",
// //       width: "90%",
// //       height: "80vh",
// //       bgcolor: "background.paper",
// //       border: "2px solid #000",
// //       boxShadow: 24,
// //       p: 4,
// //       overflow: "auto",
// //      }}
// //     >
// //      <Typography variant="h6" gutterBottom component="div">
// //       All Orders for Selected User
// //      </Typography>
// //      <TableContainer component={Paper}>
// //       <Table sx={{ minWidth: 700 }} aria-label="user orders table">
// //        <TableHead>
// //         <TableRow>
// //          <StyledTableCell>S.No</StyledTableCell>
// //          <StyledTableCell>Order ID</StyledTableCell>
// //          <StyledTableCell>Car ID</StyledTableCell>
// //          <StyledTableCell>Slot Time</StyledTableCell>
// //          <StyledTableCell>Slot Date</StyledTableCell>
// //          <StyledTableCell>Payment Method</StyledTableCell>
// //          <StyledTableCell>Address</StyledTableCell>
// //          <StyledTableCell>Balance Due</StyledTableCell>
// //          <StyledTableCell>Order Status</StyledTableCell>
// //          <StyledTableCell>Payment Status</StyledTableCell>
// //         </TableRow>
// //        </TableHead>
// //        <TableBody>
// //         {selectedUserOrders.map((order, index) => (
// //          <StyledTableRow key={order.orderId}>
// //           <StyledTableCell component="th" scope="row">
// //            {index + 1}
// //           </StyledTableCell>
// //           <StyledTableCell>{order.orderId}</StyledTableCell>
// //           <StyledTableCell>{order.carId}</StyledTableCell>
// //           <StyledTableCell>{order.slot?.time || "N/A"}</StyledTableCell>
// //           <StyledTableCell>
// //            {order.slot?.date || order.slotDate || "N/A"}
// //           </StyledTableCell>
// //           <StyledTableCell>{order.paymentMethod}</StyledTableCell>
// //           <StyledTableCell>
// //            {order.address?.completeAddress || "N/A"}
// //           </StyledTableCell>
// //           <StyledTableCell>{order.balanceDue}</StyledTableCell>
// //           <StyledTableCell>{order.orderStatus}</StyledTableCell>
// //           <StyledTableCell>{order.paymentStatus}</StyledTableCell>
// //          </StyledTableRow>
// //         ))}
// //        </TableBody>
// //       </Table>
// //      </TableContainer>
// //     </Box>
// //    </Modal>
// //   </Box>
// //  );
// // };

// // export default OrderManagement;

// // // import React, { useEffect, useState } from "react";
// // // import axios from "axios";
// // // import {
// // //  Table,
// // //  TableBody,
// // //  TableCell,
// // //  TableContainer,
// // //  TableHead,
// // //  TableRow,
// // //  Paper,
// // //  IconButton,
// // //  Typography,
// // //  Box,
// // //  Grid,
// // //  Fab,
// // //  TextField,
// // //  Avatar,
// // //  Modal,
// // //  tableCellClasses,
// // // } from "@mui/material";
// // // import ClearIcon from "@mui/icons-material/Clear";
// // // import VisibilityIcon from "@mui/icons-material/Visibility";
// // // import { styled } from "@mui/material/styles";

// // // const StyledTableCell = styled(TableCell)(({ theme }) => ({
// // //  [`&.${tableCellClasses.head}`]: {
// // //   backgroundColor: theme.palette.common.black,
// // //   color: theme.palette.common.white,
// // //  },
// // //  [`&.${tableCellClasses.body}`]: {
// // //   fontSize: 14,
// // //  },
// // // }));

// // // const StyledTableRow = styled(TableRow)(({ theme }) => ({
// // //  "&:nth-of-type(odd)": {
// // //   backgroundColor: theme.palette.action.hover,
// // //  },
// // //  "&:last-child td, &:last-child th": {
// // //   border: 0,
// // //  },
// // // }));

// // // const OrderManagement = () => {
// // //  const [orders, setOrders] = useState([]);
// // //  const [latestOrders, setLatestOrders] = useState([]);
// // //  const [selectedUserOrders, setSelectedUserOrders] = useState([]);
// // //  const [openModal, setOpenModal] = useState(false);

// // //  const [orderIdFilter, setOrderIdFilter] = useState("");
// // //  const [userNameFilter, setUserNameFilter] = useState("");
// // //  const [statusFilter, setStatusFilter] = useState("");

// // //  useEffect(() => {
// // //   const fetchOrders = async () => {
// // //    try {
// // //     const response = await axios.get("https://app-api.carexpert.org.in/api/orders");
// // //     const ordersData = response.data;

// // //     // Group orders by userId and get the latest order for each user
// // //     const latestOrdersMap = ordersData.reduce((acc, order) => {
// // //      if (
// // //       !acc[order.userId] ||
// // //       new Date(order.createdAt) > new Date(acc[order.userId].createdAt)
// // //      ) {
// // //       acc[order.userId] = order;
// // //      }
// // //      return acc;
// // //     }, {});

// // //     setOrders(ordersData);
// // //     setLatestOrders(Object.values(latestOrdersMap));
// // //    } catch (error) {
// // //     console.error("Error fetching orders:", error);
// // //    }
// // //   };

// // //   fetchOrders();
// // //  }, []);

// // //  const handleViewOrders = (userId) => {
// // //   // Filter orders for the selected user
// // //   const userOrders = orders.filter((order) => order.userId === userId);
// // //   setSelectedUserOrders(userOrders);
// // //   setOpenModal(true);
// // //  };

// // //  const handleCloseModal = () => {
// // //   setOpenModal(false);
// // //  };

// // //  const handleOrderIdChange = (e) => {
// // //   setOrderIdFilter(e.target.value);
// // //  };

// // //  const handleUserNameChange = (e) => {
// // //   setUserNameFilter(e.target.value);
// // //  };

// // //  const handleStatusChange = (e) => {
// // //   setStatusFilter(e.target.value);
// // //  };

// // //  const handleResetFilters = () => {
// // //   setOrderIdFilter("");
// // //   setUserNameFilter("");
// // //   setStatusFilter("");
// // //  };

// // //  return (
// // //   <Box
// // //    sx={{
// // //     padding: "10px",
// // //     display: "flex",
// // //     flexDirection: "column",
// // //     gap: "20px",
// // //    }}
// // //   >
// // //    <TableContainer component={Paper}>
// // //     <Typography variant="h6" gutterBottom component="div">
// // //      Orders
// // //     </Typography>
// // //     <Grid container spacing={2} alignItems="center">
// // //      <Grid item xs={12} sm>
// // //       <TextField
// // //        label="Order ID"
// // //        value={orderIdFilter}
// // //        onChange={handleOrderIdChange}
// // //        fullWidth
// // //       />
// // //      </Grid>
// // //      <Grid item xs={12} sm>
// // //       <TextField
// // //        label="User Name"
// // //        value={userNameFilter}
// // //        onChange={handleUserNameChange}
// // //        fullWidth
// // //       />
// // //      </Grid>
// // //      <Grid item xs={12} sm>
// // //       <TextField
// // //        label="Status"
// // //        value={statusFilter}
// // //        onChange={handleStatusChange}
// // //        fullWidth
// // //       />
// // //      </Grid>
// // //      <Grid item xs={12} sm="auto">
// // //       <Fab color="primary" aria-label="clear" onClick={handleResetFilters}>
// // //        <ClearIcon />
// // //       </Fab>
// // //      </Grid>
// // //     </Grid>
// // //     <Table sx={{ minWidth: 700 }} aria-label="customized table">
// // //      <TableHead>
// // //       <TableRow>
// // //        <StyledTableCell>S.No</StyledTableCell>
// // //        <StyledTableCell>User Name</StyledTableCell>
// // //        <StyledTableCell>Car</StyledTableCell>
// // //        <StyledTableCell>Slot</StyledTableCell>
// // //        <StyledTableCell>Date</StyledTableCell>
// // //        <StyledTableCell>Payment Method</StyledTableCell>
// // //        {/* <StyledTableCell>Mode</StyledTableCell> */}
// // //        <StyledTableCell>Address</StyledTableCell>
// // //        <StyledTableCell>Tracking Status</StyledTableCell>
// // //        <StyledTableCell>Booking Fee</StyledTableCell> {/* New */}
// // //        <StyledTableCell>Final Price</StyledTableCell> {/* New */}
// // //        <StyledTableCell>Service Charge</StyledTableCell> {/* New */}
// // //        <StyledTableCell>Actions</StyledTableCell>
// // //       </TableRow>
// // //      </TableHead>
// // //      <TableBody>
// // //       {latestOrders.map((order, index) => (
// // //        <StyledTableRow key={order._id}>
// // //         <StyledTableCell component="th" scope="row">
// // //          {index + 1}
// // //         </StyledTableCell>
// // //         <StyledTableCell>{order.userId}</StyledTableCell>
// // //         <StyledTableCell>
// // //          <Avatar src={order.cart?.[0]?.imageUrl} alt="Car Image" />
// // //         </StyledTableCell>
// // //         <StyledTableCell>{order.slot}</StyledTableCell>
// // //         <StyledTableCell>
// // //          {new Date(order.createdAt).toLocaleDateString()}
// // //         </StyledTableCell>
// // //         <StyledTableCell>{order.paymentMethod}</StyledTableCell>
// // //         {/* <StyledTableCell>{order.mode}</StyledTableCell> */}
// // //         <StyledTableCell>{order.address.completeAddress}</StyledTableCell>
// // //         <StyledTableCell>{order.trackingStatus}</StyledTableCell>
// // //         <StyledTableCell>{order.bookingFee}</StyledTableCell> {/* New */}
// // //         <StyledTableCell>{order.finalPrice}</StyledTableCell> {/* New */}
// // //         <StyledTableCell>{order.serviceCharge}</StyledTableCell> {/* New */}
// // //         <StyledTableCell>
// // //          <IconButton
// // //           color="primary"
// // //           onClick={() => handleViewOrders(order.userId)}
// // //          >
// // //           <VisibilityIcon />
// // //          </IconButton>
// // //         </StyledTableCell>
// // //        </StyledTableRow>
// // //       ))}
// // //      </TableBody>
// // //     </Table>
// // //    </TableContainer>

// // //    {/* Modal to show all orders of a user */}
// // //    <Modal open={openModal} onClose={handleCloseModal}>
// // //     <Box
// // //      sx={{
// // //       border: "1px solid red",
// // //       position: "absolute",
// // //       top: "50%",
// // //       left: "50%",
// // //       transform: "translate(-50%, -50%)",
// // //       width: "90%",
// // //       height: "80vh",
// // //       bgcolor: "background.paper",
// // //       border: "2px solid #000",
// // //       boxShadow: 24,
// // //       p: 4,
// // //       overflow: "auto",
// // //       paddingRight: "17px",
// // //       marginRight: "-17px",
// // //      }}
// // //     >
// // //      <Typography variant="h6" gutterBottom component="div">
// // //       User Orders
// // //      </Typography>
// // //      <TableContainer component={Paper}>
// // //       <Table sx={{ minWidth: 700 }} aria-label="user orders table">
// // //        <TableHead>
// // //         <TableRow>
// // //          <StyledTableCell>S.No</StyledTableCell>
// // //          <StyledTableCell>Order ID</StyledTableCell>
// // //          <StyledTableCell>Car</StyledTableCell>
// // //          <StyledTableCell>Slot</StyledTableCell>
// // //          <StyledTableCell>Date</StyledTableCell>
// // //          <StyledTableCell>Payment Method</StyledTableCell>
// // //          {/* <StyledTableCell>Mode</StyledTableCell> */}
// // //          <StyledTableCell>Address</StyledTableCell>
// // //          <StyledTableCell>Tracking Status</StyledTableCell>
// // //          <StyledTableCell>Booking Fee</StyledTableCell> {/* New */}
// // //          <StyledTableCell>Final Price</StyledTableCell> {/* New */}
// // //          <StyledTableCell>Service Charge</StyledTableCell> {/* New */}
// // //         </TableRow>
// // //        </TableHead>
// // //        <TableBody>
// // //         {selectedUserOrders.map((order, index) => (
// // //          <StyledTableRow key={order._id}>
// // //           <StyledTableCell component="th" scope="row">
// // //            {index + 1}
// // //           </StyledTableCell>
// // //           <StyledTableCell>{order._id}</StyledTableCell>
// // //           <StyledTableCell>
// // //            <Avatar src={order.cart?.[0]?.imageUrl} alt="Car Image" />
// // //           </StyledTableCell>
// // //           <StyledTableCell>{order.slot}</StyledTableCell>
// // //           <StyledTableCell>
// // //            {new Date(order.createdAt).toLocaleDateString()}
// // //           </StyledTableCell>
// // //           <StyledTableCell>{order.paymentMethod}</StyledTableCell>
// // //           {/* <StyledTableCell>{order.mode}</StyledTableCell> */}
// // //           <StyledTableCell>{order.address.completeAddress}</StyledTableCell>
// // //           <StyledTableCell>{order.trackingStatus}</StyledTableCell>
// // //           <StyledTableCell>{order.bookingFee}</StyledTableCell> {/* New */}
// // //           <StyledTableCell>{order.finalPrice}</StyledTableCell> {/* New */}
// // //           <StyledTableCell>{order.serviceCharge}</StyledTableCell> {/* New */}
// // //          </StyledTableRow>
// // //         ))}
// // //        </TableBody>
// // //       </Table>
// // //      </TableContainer>
// // //     </Box>
// // //    </Modal>
// // //   </Box>
// // //  );
// // // };

// // // export default OrderManagement;

// // // // import React, { useEffect, useState } from "react";
// // // // import axios from "axios";
// // // // import {
// // // //   Table,
// // // //   TableBody,
// // // //   TableCell,
// // // //   TableContainer,
// // // //   TableHead,
// // // //   TableRow,
// // // //   Paper,
// // // //   IconButton,
// // // //   Typography,
// // // //   Box,
// // // //   Grid,
// // // //   Fab,
// // // //   TextField,
// // // //   Avatar,
// // // //   Modal,
// // // //   tableCellClasses,
// // // // } from "@mui/material";
// // // // import ClearIcon from "@mui/icons-material/Clear";
// // // // import VisibilityIcon from "@mui/icons-material/Visibility";
// // // // import { styled } from "@mui/material/styles";

// // // // const StyledTableCell = styled(TableCell)(({ theme }) => ({
// // // //   [`&.${tableCellClasses.head}`]: {
// // // //     backgroundColor: theme.palette.common.black,
// // // //     color: theme.palette.common.white,
// // // //   },
// // // //   [`&.${tableCellClasses.body}`]: {
// // // //     fontSize: 14,
// // // //   },
// // // // }));

// // // // const StyledTableRow = styled(TableRow)(({ theme }) => ({
// // // //   "&:nth-of-type(odd)": {
// // // //     backgroundColor: theme.palette.action.hover,
// // // //   },
// // // //   // hide last border
// // // //   "&:last-child td, &:last-child th": {
// // // //     border: 0,
// // // //   },
// // // // }));

// // // // const OrderManagement = () => {
// // // //   const [orders, setOrders] = useState([]);
// // // //   const [latestOrders, setLatestOrders] = useState([]);
// // // //   const [selectedUserOrders, setSelectedUserOrders] = useState([]);
// // // //   const [openModal, setOpenModal] = useState(false);

// // // //   const [orderIdFilter, setOrderIdFilter] = useState("");
// // // //   const [userNameFilter, setUserNameFilter] = useState("");
// // // //   const [statusFilter, setStatusFilter] = useState("");

// // // //   useEffect(() => {
// // // //     const fetchOrders = async () => {
// // // //       try {
// // // //         const response = await axios.get("https://app-api.carexpert.org.in/api/orders");
// // // //         const ordersData = response.data;

// // // //         // Group orders by userId and get the latest order for each user
// // // //         const latestOrdersMap = ordersData.reduce((acc, order) => {
// // // //           if (
// // // //             !acc[order.userId] ||
// // // //             new Date(order.date) > new Date(acc[order.userId].date)
// // // //           ) {
// // // //             acc[order.userId] = order;
// // // //           }
// // // //           return acc;
// // // //         }, {});

// // // //         setOrders(ordersData);
// // // //         setLatestOrders(Object.values(latestOrdersMap));
// // // //       } catch (error) {
// // // //         console.error("Error fetching orders:", error);
// // // //       }
// // // //     };

// // // //     fetchOrders();
// // // //   }, []);

// // // //   const handleViewOrders = (userId) => {
// // // //     // Filter orders for the selected user
// // // //     const userOrders = orders.filter((order) => order.userId === userId);
// // // //     setSelectedUserOrders(userOrders);
// // // //     setOpenModal(true);
// // // //   };

// // // //   const handleCloseModal = () => {
// // // //     setOpenModal(false);
// // // //   };

// // // //   const handleOrderIdChange = (e) => {
// // // //     setOrderIdFilter(e.target.value);
// // // //   };

// // // //   const handleUserNameChange = (e) => {
// // // //     setUserNameFilter(e.target.value);
// // // //   };

// // // //   const handleStatusChange = (e) => {
// // // //     setStatusFilter(e.target.value);
// // // //   };

// // // //   const handleResetFilters = () => {
// // // //     setOrderIdFilter("");
// // // //     setUserNameFilter("");
// // // //     setStatusFilter("");
// // // //   };

// // // //   return (
// // // //     <Box
// // // //       sx={{
// // // //         padding: "10px",
// // // //         display: "flex",
// // // //         flexDirection: "column",
// // // //         gap: "20px",
// // // //       }}
// // // //     >
// // // //       <TableContainer component={Paper}>
// // // //         <Typography variant="h6" gutterBottom component="div">
// // // //           Orders
// // // //         </Typography>
// // // //         <Grid container spacing={2} alignItems="center">
// // // //           <Grid item xs={12} sm>
// // // //             <TextField
// // // //               label="Order ID"
// // // //               value={orderIdFilter}
// // // //               onChange={handleOrderIdChange}
// // // //               fullWidth
// // // //             />
// // // //           </Grid>
// // // //           <Grid item xs={12} sm>
// // // //             <TextField
// // // //               label="User Name"
// // // //               value={userNameFilter}
// // // //               onChange={handleUserNameChange}
// // // //               fullWidth
// // // //             />
// // // //           </Grid>
// // // //           <Grid item xs={12} sm>
// // // //             <TextField
// // // //               label="Status"
// // // //               value={statusFilter}
// // // //               onChange={handleStatusChange}
// // // //               fullWidth
// // // //             />
// // // //           </Grid>
// // // //           <Grid item xs={12} sm="auto">
// // // //             <Fab
// // // //               color="primary"
// // // //               aria-label="clear"
// // // //               onClick={handleResetFilters}
// // // //             >
// // // //               <ClearIcon />
// // // //             </Fab>
// // // //           </Grid>
// // // //         </Grid>
// // // //         <Table sx={{ minWidth: 700 }} aria-label="customized table">
// // // //           <TableHead>
// // // //             <TableRow>
// // // //               <StyledTableCell>S.No</StyledTableCell>
// // // //               <StyledTableCell>User Name</StyledTableCell>
// // // //               <StyledTableCell>Car</StyledTableCell>
// // // //               <StyledTableCell>Slot</StyledTableCell>
// // // //               <StyledTableCell>Date</StyledTableCell>
// // // //               <StyledTableCell>Payment Method</StyledTableCell>
// // // //               <StyledTableCell>Mode</StyledTableCell>
// // // //               <StyledTableCell>Address</StyledTableCell>
// // // //               <StyledTableCell>Tracking Status</StyledTableCell>
// // // //               <StyledTableCell>Actions</StyledTableCell>
// // // //             </TableRow>
// // // //           </TableHead>
// // // //           <TableBody>
// // // //             {latestOrders.map((order, index) => (
// // // //               <StyledTableRow key={order._id}>
// // // //                 <StyledTableCell component="th" scope="row">
// // // //                   {index + 1}
// // // //                 </StyledTableCell>
// // // //                 <StyledTableCell>{order.userId}</StyledTableCell>
// // // //                 <StyledTableCell>
// // // //                   <Avatar
// // // //                     src={order.cartId?.products?.[0]?.imageUrl}
// // // //                     alt="Car Image"
// // // //                   />
// // // //                 </StyledTableCell>
// // // //                 <StyledTableCell>{order.slot}</StyledTableCell>
// // // //                 <StyledTableCell>
// // // //                   {new Date(order.date).toLocaleDateString()}
// // // //                 </StyledTableCell>
// // // //                 <StyledTableCell>{order.paymentMethod}</StyledTableCell>
// // // //                 <StyledTableCell>{order.mode}</StyledTableCell>
// // // //                 <StyledTableCell>{order.address}</StyledTableCell>
// // // //                 <StyledTableCell>{order.trackingStatus}</StyledTableCell>
// // // //                 <StyledTableCell>
// // // //                   <IconButton
// // // //                     color="primary"
// // // //                     onClick={() => handleViewOrders(order.userId)}
// // // //                   >
// // // //                     <VisibilityIcon />
// // // //                   </IconButton>
// // // //                 </StyledTableCell>
// // // //               </StyledTableRow>
// // // //             ))}
// // // //           </TableBody>
// // // //         </Table>
// // // //       </TableContainer>

// // // //       {/* Modal to show all orders of a user */}
// // // //       <Modal open={openModal} onClose={handleCloseModal}>
// // // //         <Box
// // // //           sx={{
// // // //             border: "1px solid red",
// // // //             position: "absolute",
// // // //             top: "50%",
// // // //             left: "50%",
// // // //             transform: "translate(-50%, -50%)",
// // // //             width: "90%",
// // // //             height:"80vh",
// // // //             bgcolor: "background.paper",
// // // //             border: "2px solid #000",
// // // //             boxShadow: 24,
// // // //             p: 4,
// // // //             overflow: "auto",
// // // //             // maxHeight: "80vh",
// // // //             paddingRight: "17px",
// // // //             marginRight: "-17px",
// // // //           }}
// // // //         >
// // // //           <Typography variant="h6" gutterBottom component="div">
// // // //             User Orders
// // // //           </Typography>
// // // //           <TableContainer component={Paper}>
// // // //             <Table sx={{ minWidth: 700 }} aria-label="user orders table">
// // // //               <TableHead>
// // // //                 <TableRow>
// // // //                   <StyledTableCell>S.No</StyledTableCell>
// // // //                   <StyledTableCell>Order ID</StyledTableCell>
// // // //                   <StyledTableCell>Car</StyledTableCell>
// // // //                   <StyledTableCell>Slot</StyledTableCell>
// // // //                   <StyledTableCell>Date</StyledTableCell>
// // // //                   <StyledTableCell>Payment Method</StyledTableCell>
// // // //                   <StyledTableCell>Mode</StyledTableCell>
// // // //                   <StyledTableCell>Address</StyledTableCell>
// // // //                   <StyledTableCell>Tracking Status</StyledTableCell>
// // // //                 </TableRow>
// // // //               </TableHead>
// // // //               <TableBody>
// // // //                 {selectedUserOrders.map((order, index) => (
// // // //                   <StyledTableRow key={order._id}>
// // // //                     <StyledTableCell component="th" scope="row">
// // // //                       {index + 1}
// // // //                     </StyledTableCell>
// // // //                     <StyledTableCell>{order._id}</StyledTableCell>
// // // //                     <StyledTableCell>
// // // //                       <Avatar
// // // //                         src={order.cartId?.products?.[0]?.imageUrl}
// // // //                         alt="Car Image"
// // // //                       />
// // // //                     </StyledTableCell>
// // // //                     <StyledTableCell>{order.slot}</StyledTableCell>
// // // //                     <StyledTableCell>
// // // //                       {new Date(order.date).toLocaleDateString()}
// // // //                     </StyledTableCell>
// // // //                     <StyledTableCell>{order.paymentMethod}</StyledTableCell>
// // // //                     <StyledTableCell>{order.mode}</StyledTableCell>
// // // //                     <StyledTableCell>{order.address}</StyledTableCell>
// // // //                     <StyledTableCell>{order.trackingStatus}</StyledTableCell>
// // // //                   </StyledTableRow>
// // // //                 ))}
// // // //               </TableBody>
// // // //             </Table>
// // // //           </TableContainer>
// // // //         </Box>
// // // //       </Modal>
// // // //     </Box>
// // // //   );
// // // // };

// // // // export default OrderManagement;

// // // // // import React, { useEffect, useState } from "react";
// // // // // import axios from "axios";
// // // // // import {
// // // // //  Table,
// // // // //  TableBody,
// // // // //  TableCell,
// // // // //  TableContainer,
// // // // //  TableHead,
// // // // //  TableRow,
// // // // //  Paper,
// // // // //  IconButton,
// // // // //  Typography,
// // // // //  Box,
// // // // //  Grid,
// // // // //  Fab,
// // // // //  TextField,
// // // // //  Avatar,
// // // // //  tableCellClasses,
// // // // // } from "@mui/material";
// // // // // import DeleteIcon from "@mui/icons-material/Delete";
// // // // // import ClearIcon from "@mui/icons-material/Clear";
// // // // // import { styled } from "@mui/material/styles";

// // // // // const StyledTableCell = styled(TableCell)(({ theme }) => ({
// // // // //  [`&.${tableCellClasses.head}`]: {
// // // // //   backgroundColor: theme.palette.common.black,
// // // // //   color: theme.palette.common.white,
// // // // //  },
// // // // //  [`&.${tableCellClasses.body}`]: {
// // // // //   fontSize: 14,
// // // // //  },
// // // // // }));

// // // // // const StyledTableRow = styled(TableRow)(({ theme }) => ({
// // // // //  "&:nth-of-type(odd)": {
// // // // //   backgroundColor: theme.palette.action.hover,
// // // // //  },
// // // // //  // hide last border
// // // // //  "&:last-child td, &:last-child th": {
// // // // //   border: 0,
// // // // //  },
// // // // // }));

// // // // // const OrderManagement = () => {
// // // // //  const [orders, setOrders] = useState([]);
// // // // //  const [orderIdFilter, setOrderIdFilter] = useState("");
// // // // //  const [userNameFilter, setUserNameFilter] = useState("");
// // // // //  const [statusFilter, setStatusFilter] = useState("");

// // // // //  useEffect(() => {
// // // // //   const fetchOrders = async () => {
// // // // //    try {
// // // // //     const response = await axios.get(
// // // // //      "https://app-api.carexpert.org.in/api/orders"
// // // // //     );
// // // // //     setOrders(response.data);
// // // // //    } catch (error) {
// // // // //     console.error("Error fetching orders:", error);
// // // // //    }
// // // // //   };

// // // // //   fetchOrders();
// // // // //  }, []);

// // // // //  const handleDelete = (orderId) => {
// // // // //   // Handle delete order logic
// // // // //   console.log("Delete order with ID:", orderId);
// // // // //  };

// // // // //  const handleOrderIdChange = (e) => {
// // // // //   setOrderIdFilter(e.target.value);
// // // // //  };

// // // // //  const handleUserNameChange = (e) => {
// // // // //   setUserNameFilter(e.target.value);
// // // // //  };

// // // // //  const handleStatusChange = (e) => {
// // // // //   setStatusFilter(e.target.value);
// // // // //  };

// // // // //  const handleResetFilters = () => {
// // // // //   setOrderIdFilter("");
// // // // //   setUserNameFilter("");
// // // // //   setStatusFilter("");
// // // // //  };

// // // // //  return (
// // // // //   <Box
// // // // //    sx={{
// // // // //     padding: "10px",
// // // // //     display: "flex",
// // // // //     flexDirection: "column",
// // // // //     gap: "20px",
// // // // //    }}
// // // // //   >
// // // // //    <TableContainer component={Paper}>
// // // // //     <Typography variant="h6" gutterBottom component="div">
// // // // //      Orders
// // // // //     </Typography>
// // // // //     <Grid container spacing={2} alignItems="center">
// // // // //      <Grid item xs={12} sm>
// // // // //       <TextField
// // // // //        label="Order ID"
// // // // //        value={orderIdFilter}
// // // // //        onChange={handleOrderIdChange}
// // // // //        fullWidth
// // // // //       />
// // // // //      </Grid>
// // // // //      <Grid item xs={12} sm>
// // // // //       <TextField
// // // // //        label="User Name"
// // // // //        value={userNameFilter}
// // // // //        onChange={handleUserNameChange}
// // // // //        fullWidth
// // // // //       />
// // // // //      </Grid>
// // // // //      <Grid item xs={12} sm>
// // // // //       <TextField
// // // // //        label="Status"
// // // // //        value={statusFilter}
// // // // //        onChange={handleStatusChange}
// // // // //        fullWidth
// // // // //       />
// // // // //      </Grid>
// // // // //      <Grid item xs={12} sm="auto">
// // // // //       <Fab color="primary" aria-label="clear" onClick={handleResetFilters}>
// // // // //        <ClearIcon />
// // // // //       </Fab>
// // // // //      </Grid>
// // // // //     </Grid>
// // // // //     <Table sx={{ minWidth: 700 }} aria-label="customized table">
// // // // //      <TableHead>
// // // // //       <TableRow>
// // // // //        <StyledTableCell>S.No</StyledTableCell>
// // // // //        <StyledTableCell>User Name</StyledTableCell>
// // // // //        <StyledTableCell>Car</StyledTableCell>
// // // // //        <StyledTableCell>Slot</StyledTableCell>
// // // // //        <StyledTableCell>Date</StyledTableCell>
// // // // //        <StyledTableCell>Payment Method</StyledTableCell>
// // // // //        <StyledTableCell>Mode</StyledTableCell>
// // // // //        <StyledTableCell>Address</StyledTableCell>
// // // // //        <StyledTableCell>Tracking Status</StyledTableCell>
// // // // //        <StyledTableCell>Actions</StyledTableCell>
// // // // //       </TableRow>
// // // // //      </TableHead>
// // // // //      <TableBody>
// // // // //       {orders.map((order, index) => (
// // // // //        <StyledTableRow key={order._id}>
// // // // //         <StyledTableCell component="th" scope="row">
// // // // //          {index + 1}
// // // // //         </StyledTableCell>
// // // // //         <StyledTableCell>{order.userId}</StyledTableCell>
// // // // //         <StyledTableCell>
// // // // //          <Avatar src={order.cartId?.products?.[0]?.imageUrl} alt="Car Image" />
// // // // //         </StyledTableCell>
// // // // //         <StyledTableCell>{order.slot}</StyledTableCell>
// // // // //         <StyledTableCell>
// // // // //          {new Date(order.date).toLocaleDateString()}
// // // // //         </StyledTableCell>
// // // // //         <StyledTableCell>{order.paymentMethod}</StyledTableCell>
// // // // //         <StyledTableCell>{order.mode}</StyledTableCell>
// // // // //         <StyledTableCell>{order.address}</StyledTableCell>
// // // // //         <StyledTableCell>{order.trackingStatus}</StyledTableCell>
// // // // //         <StyledTableCell>
// // // // //          <IconButton color="secondary" onClick={() => handleDelete(order._id)}>
// // // // //           <DeleteIcon />
// // // // //          </IconButton>
// // // // //         </StyledTableCell>
// // // // //        </StyledTableRow>
// // // // //       ))}
// // // // //      </TableBody>
// // // // //     </Table>
// // // // //    </TableContainer>
// // // // //   </Box>
// // // // //  );
// // // // // };

// // // // // export default OrderManagement;
// // // // /*
// // // //  In this code i have all orders of same user so i want to show the data like if there are multiple order of a user in table show only last order by user
// // // //  and on the place of order delete icon make it view icon if user click on that icon open a model
// // // //  in which show all  orders of that user in tabular form with all details

// // // //  */

import axios from "axios";
import * as types from "./actionTypes";

// Cart Actions
export const fetchCart = () => async (dispatch) => {
 dispatch({ type: types.GET_CART_REQUEST });
 try {
  const res = await axios.get("https://app-api.carexpert.org.in/api/cart");
  dispatch({ type: types.GET_CART_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_CART_FAILURE, payload: err.message });
 }
};

export const addToCart = (product) => async (dispatch) => {
 dispatch({ type: types.ADD_TO_CART_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/cart/add",
   product
  );
  dispatch({ type: types.ADD_TO_CART_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.ADD_TO_CART_FAILURE, payload: err.message });
 }
};

export const removeFromCart = (product) => async (dispatch) => {
 dispatch({ type: types.REMOVE_FROM_CART_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/cart/remove",
   product
  );
  dispatch({ type: types.REMOVE_FROM_CART_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.REMOVE_FROM_CART_FAILURE, payload: err.message });
 }
};

export const applyCoupon = (code) => async (dispatch) => {
 dispatch({ type: types.APPLY_COUPON_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/cart/apply-coupon",
   {
    code,
   }
  );
  dispatch({ type: types.APPLY_COUPON_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.APPLY_COUPON_FAILURE, payload: err.message });
 }
};

// Referral Actions
export const fetchReferrals = () => async (dispatch) => {
 dispatch({ type: types.GET_REFERRALS_REQUEST });
 try {
  const res = await axios.get("https://app-api.carexpert.org.in/api/referrals");
  dispatch({ type: types.GET_REFERRALS_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_REFERRALS_FAILURE, payload: err.message });
 }
};

export const createReferral = (referral) => async (dispatch) => {
 dispatch({ type: types.CREATE_REFERRAL_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/referrals/create",
   referral
  );
  dispatch({ type: types.CREATE_REFERRAL_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.CREATE_REFERRAL_FAILURE, payload: err.message });
 }
};

// Order Actions
export const fetchOrderHistory = () => async (dispatch) => {
 dispatch({ type: types.GET_ORDER_HISTORY_REQUEST });
 try {
  const res = await axios.get(
   "https://app-api.carexpert.org.in/api/orders/history"
  );
  dispatch({ type: types.GET_ORDER_HISTORY_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_ORDER_HISTORY_FAILURE, payload: err.message });
 }
};

export const cancelOrder = (orderId) => async (dispatch) => {
 dispatch({ type: types.CANCEL_ORDER_REQUEST });
 try {
  const res = await axios.post(
   `https://app-api.carexpert.org.in/api/orders/cancel/${orderId}`
  );
  dispatch({ type: types.CANCEL_ORDER_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.CANCEL_ORDER_FAILURE, payload: err.message });
 }
};

export const refundOrder = (orderId) => async (dispatch) => {
 dispatch({ type: types.REFUND_ORDER_REQUEST });
 try {
  const res = await axios.post(
   `https://app-api.carexpert.org.in/api/orders/refund/${orderId}`
  );
  dispatch({ type: types.REFUND_ORDER_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.REFUND_ORDER_FAILURE, payload: err.message });
 }
};

// Slot Actions
export const fetchSlots = () => async (dispatch) => {
 dispatch({ type: types.GET_SLOTS_REQUEST });
 try {
  const res = await axios.get("https://app-api.carexpert.org.in/api/slots");
  dispatch({ type: types.GET_SLOTS_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_SLOTS_FAILURE, payload: err.message });
 }
};

export const createSlot = (slot) => async (dispatch) => {
 dispatch({ type: types.CREATE_SLOT_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/slots",
   slot
  );
  dispatch({ type: types.CREATE_SLOT_SUCCESS, payload: res.data });
  dispatch(fetchSlots());
 } catch (err) {
  dispatch({ type: types.CREATE_SLOT_FAILURE, payload: err.message });
 }
};

export const updateSlot = (id, slot) => async (dispatch) => {
 dispatch({ type: types.UPDATE_SLOT_REQUEST });
 try {
  const res = await axios.put(
   `https://app-api.carexpert.org.in/api/slots/${id}`,
   slot
  );
  dispatch({ type: types.UPDATE_SLOT_SUCCESS, payload: res.data });
  dispatch(fetchSlots());
 } catch (err) {
  dispatch({ type: types.UPDATE_SLOT_FAILURE, payload: err.message });
 }
};
export const deleteSlot = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_SLOT_REQUEST });
 try {
  const res = await axios.delete(
   `https://app-api.carexpert.org.in/api/slots/${id}`
  );
  dispatch({ type: types.DELETE_SLOT_SUCCESS, payload: res.data });
  dispatch(fetchSlots());
 } catch (err) {
  dispatch({ type: types.DELETE_SLOT_FAILURE, payload: err.message });
 }
};

// Coupon Actions
export const fetchCoupons = () => async (dispatch) => {
 dispatch({ type: types.GET_COUPONS_REQUEST });
 try {
  const res = await axios.get("https://app-api.carexpert.org.in/api/coupons");
  dispatch({ type: types.GET_COUPONS_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_COUPONS_FAILURE, payload: err.message });
 }
};

export const createCoupon = (coupon) => async (dispatch) => {
 dispatch({ type: types.CREATE_COUPON_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/coupons",
   coupon
  );
  dispatch({ type: types.CREATE_COUPON_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.CREATE_COUPON_FAILURE, payload: err.message });
 }
};

export const updateCoupon = (id, coupon) => async (dispatch) => {
 dispatch({ type: types.UPDATE_COUPON_REQUEST });
 try {
  const res = await axios.put(
   `https://app-api.carexpert.org.in/api/coupons/${id}`,
   coupon
  );
  dispatch({ type: types.UPDATE_COUPON_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.UPDATE_COUPON_FAILURE, payload: err.message });
 }
};

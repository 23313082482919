import React, { useEffect, useState } from "react";
import {
 Box,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 Fab,
 FormControl,
 TextField,
 Modal,
 Fade,
 Backdrop,
 Button,
 Snackbar,
 Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
 addBrand,
 deleteBrand,
 getBrands,
 updateBrand,
} from "../redux/admin/action";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

const Brands = () => {
 const dispatch = useDispatch();
 const { brands, isError } = useSelector((store) => store.admin);
 const [open, setOpen] = useState(false);
 const [openAdd, setOpenAdd] = useState(false);
 const [searchTerm, setSearchTerm] = useState("");
 const [ID, setID] = useState("");

 // Snackbar state
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [snackbarSeverity, setSnackbarSeverity] = useState("success");

 // React Hook Form setup
 const { register, handleSubmit, reset, setValue } = useForm();
 const [currentBrand, setCurrentBrand] = useState(null);

 useEffect(() => {
  if (brands.length <= 0) {
   dispatch(getBrands());
  }
 }, [brands.length, dispatch]);

 useEffect(() => {
  if (isError) {
   console.log("error:", isError);
   setSnackbarMessage(isError);
   setSnackbarSeverity("isError");
   setSnackbarOpen(true);
  }
 }, [isError]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setOpen(false);
  reset();
 };

 const handleOpenAdd = () => setOpenAdd(true);
 const handleCloseAdd = () => {
  setOpenAdd(false);
  reset();
 };

 const handleSnackbarClose = (event, reason) => {
  if (reason === "clickaway") {
   return;
  }
  setSnackbarOpen(false);
 };

 const handleDelete = async (id) => {
  try {
   await dispatch(deleteBrand(id));
   dispatch(getBrands());
   setSnackbarMessage("Brand deleted successfully!");
   setSnackbarSeverity("success");
   setSnackbarOpen(true);
  } catch (error) {
   console.error("Error deleting brand:", error);
  }
 };

 const handleEdit = (id) => {
  setID(id);
  const brand = brands.find((brand) => brand._id === id);
  setCurrentBrand(brand);
  setValue("title", brand.title);
  setValue("logo", ""); // Handle logo separately
  handleOpen();
 };

 const handleAddBrand = async (data) => {
  try {
   const formData = new FormData();
   formData.append("title", data.title);
   formData.append("logo", data.logo[0]); // Append the file to FormData

   await dispatch(addBrand(formData)); // Pass FormData to the action
   if (!isError) {
    handleCloseAdd();
    setSnackbarMessage("Brand added successfully!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
   } else {
    setSnackbarMessage("Brand with this title already exists");
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
   }
  } catch (error) {
   console.error("Error adding brand:", error);
  }
 };

 const handleUpdateBrand = async (data) => {
  try {
   const formData = new FormData();
   formData.append("title", data.title);
   if (data.logo.length > 0) {
    formData.append("logo", data.logo[0]); // File upload
   }

   await dispatch(updateBrand(ID, formData));
   dispatch(getBrands());
   handleClose();
   setSnackbarMessage("Brand updated successfully!");
   setSnackbarSeverity("success");
   setSnackbarOpen(true);
  } catch (error) {
   console.error("Error updating brand:", error);
  }
 };

 const filteredBrands = brands?.filter((brand) =>
  brand.title.toLowerCase().includes(searchTerm.toLowerCase())
 );

 return (
  <Box sx={{ width: "100%", height: "40vh" }}>
   <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
    Brands
   </Typography>
   <Box
    sx={{
     border: "1px solid #1976D2",
     padding: "10px",
     display: "flex",
     alignItems: "center",
     gap: "50px",
    }}
   >
    <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
     <AddIcon />
    </Fab>
    <FormControl sx={{ width: "50%" }}>
     <TextField
      label="Search Brand"
      variant="outlined"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
     />
    </FormControl>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>Brand Name</TableCell>
       <TableCell sx={{ color: "white" }}>Logo</TableCell>
       <TableCell sx={{ color: "white" }}>Created At</TableCell>
       <TableCell sx={{ color: "white" }}>Updated At</TableCell>
       <TableCell sx={{ color: "white" }}>Action</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {filteredBrands?.map((brand) => (
       <TableRow key={brand._id}>
        <TableCell component="th" scope="row">
         {brand.title}
        </TableCell>
        <TableCell>
         <img
          src={brand.logo}
          alt={brand.title}
          style={{ width: "50px", height: "50px" }}
         />
        </TableCell>
        <TableCell>{new Date(brand.createdAt).toLocaleString()}</TableCell>
        <TableCell>{new Date(brand.updatedAt).toLocaleString()}</TableCell>
        <TableCell
         sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
         }}
        >
         <Fab
          color="secondary"
          aria-label="edit"
          onClick={() => handleEdit(brand._id)}
         >
          <EditIcon />
         </Fab>
         <Fab
          color="secondary"
          aria-label="delete"
          sx={{ marginLeft: "5px" }}
          onClick={() => handleDelete(brand._id)}
         >
          <DeleteIcon />
         </Fab>
        </TableCell>
       </TableRow>
      ))}
     </TableBody>
    </Table>
   </TableContainer>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
   >
    <Fade in={open}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       Edit Brand
      </Typography>
      <FormControl fullWidth>
       <TextField
        {...register("title", { required: "Brand title is required" })}
        label="Brand Title"
        margin="normal"
       />
       <input
        type="file"
        {...register("logo")}
        accept="image/*"
        style={{ marginTop: "16px" }}
       />
      </FormControl>
      <Box mt={2} display="flex" justifyContent="space-between">
       <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit(handleUpdateBrand)}
       >
        Save
       </Button>
       <Button variant="outlined" onClick={handleClose}>
        Cancel
       </Button>
      </Box>
     </Box>
    </Fade>
   </Modal>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={openAdd}
    onClose={handleCloseAdd}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
   >
    <Fade in={openAdd}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       Add Brand
      </Typography>
      <FormControl fullWidth>
       <TextField
        {...register("title", { required: "Brand title is required" })}
        label="Brand Title"
        margin="normal"
       />
       <input
        type="file"
        {...register("logo", { required: "Brand logo is required" })}
        accept="image/*"
        style={{ marginTop: "16px" }}
       />
      </FormControl>
      <Box mt={2} display="flex" justifyContent="space-between">
       <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit(handleAddBrand)}
       >
        Add
       </Button>
       <Button variant="outlined" onClick={handleCloseAdd}>
        Cancel
       </Button>
      </Box>
     </Box>
    </Fade>
   </Modal>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={3000}
    onClose={handleSnackbarClose}
   >
    <Alert
     onClose={handleSnackbarClose}
     severity={snackbarSeverity}
     variant="filled"
     sx={{ width: "100%" }}
    >
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Box>
 );
};

export default Brands;

// import React, { useEffect, useState } from "react";
// import {
//  Box,
//  Table,
//  TableBody,
//  TableCell,
//  TableContainer,
//  TableHead,
//  TableRow,
//  Paper,
//  Typography,
//  Fab,
//  FormControl,
//  TextField,
//  Modal,
//  Fade,
//  Backdrop,
//  Button,
//  Snackbar,
//  Alert,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { useForm } from "react-hook-form";
// import {
//  addBrand,
//  deleteBrand,
//  getBrands,
//  updateBrand,
// } from "../redux/admin/action";
// import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";

// const style = {
//  position: "absolute",
//  top: "50%",
//  left: "50%",
//  transform: "translate(-50%, -50%)",
//  width: 400,
//  bgcolor: "background.paper",
//  border: "2px solid #000",
//  boxShadow: 24,
//  p: 4,
// };

// const Brands = () => {
//  const dispatch = useDispatch();
//  const { brands } = useSelector((store) => store.admin);
//  const [open, setOpen] = useState(false);
//  const [openAdd, setOpenAdd] = useState(false);
//  const [searchTerm, setSearchTerm] = useState("");
//  const [ID, setID] = useState("");

//  // Snackbar state
//  const [snackbarOpen, setSnackbarOpen] = useState(false);
//  const [snackbarMessage, setSnackbarMessage] = useState("");
//  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

//  // React Hook Form setup
//  const { register, handleSubmit, reset, setValue } = useForm();
//  const [currentBrand, setCurrentBrand] = useState(null);

//  useEffect(() => {
//   if (brands.length <= 0) {
//    dispatch(getBrands());
//   }
//  }, [brands.length, dispatch]);

//  const handleOpen = () => setOpen(true);
//  const handleClose = () => {
//   setOpen(false);
//   reset();
//  };

//  const handleOpenAdd = () => setOpenAdd(true);
//  const handleCloseAdd = () => {
//   setOpenAdd(false);
//   reset();
//  };

//  const handleSnackbarClose = (event, reason) => {
//   if (reason === "clickaway") {
//    return;
//   }
//   setSnackbarOpen(false);
//  };

//  const handleDelete = async (id) => {
//   try {
//    await dispatch(deleteBrand(id));
//    dispatch(getBrands());
//    setSnackbarMessage("Brand deleted successfully!");
//    setSnackbarSeverity("success");
//    setSnackbarOpen(true);
//   } catch (error) {
//    console.error("Error deleting brand:", error);
//    setSnackbarMessage("Error deleting brand. Please try again.");
//    setSnackbarSeverity("error");
//    setSnackbarOpen(true);
//   }
//  };

//  const handleEdit = (id) => {
//   setID(id);
//   const brand = brands.find((brand) => brand._id === id);
//   setCurrentBrand(brand);
//   setValue("title", brand.title);
//   setValue("logo", ""); // Handle logo separately
//   handleOpen();
//  };

//  const handleAddBrand = async (data) => {
//   try {
//    const formData = new FormData();
//    formData.append("title", data.title);
//    formData.append("logo", data.logo[0]); // Append the file to FormData

//    await dispatch(addBrand(formData)); // Pass FormData to the action
//    handleCloseAdd();
//    setSnackbarMessage("Brand added successfully!");
//    setSnackbarSeverity("success");
//    setSnackbarOpen(true);
//   } catch (error) {
//    console.error("Error adding brand:", error);
//    setSnackbarMessage(
//     error.response?.data?.message || "Error adding brand. Please try again."
//    );
//    setSnackbarSeverity("error");
//    setSnackbarOpen(true);
//   }
//  };

//  const handleUpdateBrand = async (data) => {
//   try {
//    const formData = new FormData();
//    formData.append("title", data.title);
//    if (data.logo.length > 0) {
//     formData.append("logo", data.logo[0]); // File upload
//    }

//    await dispatch(updateBrand(ID, formData));
//    dispatch(getBrands());
//    handleClose();
//    setSnackbarMessage("Brand updated successfully!");
//    setSnackbarSeverity("success");
//    setSnackbarOpen(true);
//   } catch (error) {
//    console.error("Error updating brand:", error);
//    setSnackbarMessage(
//     error.response?.data?.message || "Error updating brand. Please try again."
//    );
//    setSnackbarSeverity("error");
//    setSnackbarOpen(true);
//   }
//  };

//  const filteredBrands = brands?.filter((brand) =>
//   brand.title.toLowerCase().includes(searchTerm.toLowerCase())
//  );

//  return (
//   <Box sx={{ width: "100%", height: "40vh" }}>
//    <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
//     Brands
//    </Typography>
//    <Box
//     sx={{
//      border: "1px solid #1976D2",
//      padding: "10px",
//      display: "flex",
//      alignItems: "center",
//      gap: "50px",
//     }}
//    >
//     <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
//      <AddIcon />
//     </Fab>
//     <FormControl sx={{ width: "50%" }}>
//      <TextField
//       label="Search Brand"
//       variant="outlined"
//       value={searchTerm}
//       onChange={(e) => setSearchTerm(e.target.value)}
//      />
//     </FormControl>
//    </Box>
//    <TableContainer component={Paper} sx={{ margin: "auto" }}>
//     <Table sx={{ minWidth: 650 }} aria-label="simple table">
//      <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
//       <TableRow>
//        <TableCell sx={{ color: "white" }}>Brand Name</TableCell>
//        <TableCell sx={{ color: "white" }}>Logo</TableCell>
//        <TableCell sx={{ color: "white" }}>Created At</TableCell>
//        <TableCell sx={{ color: "white" }}>Updated At</TableCell>
//        <TableCell sx={{ color: "white" }}>Action</TableCell>
//       </TableRow>
//      </TableHead>
//      <TableBody>
//       {filteredBrands?.map((brand) => (
//        <TableRow key={brand._id}>
//         <TableCell component="th" scope="row">
//          {brand.title}
//         </TableCell>
//         <TableCell>
//          <img
//           src={brand.logo}
//           alt={brand.title}
//           style={{ width: "50px", height: "50px" }}
//          />
//         </TableCell>
//         <TableCell>{new Date(brand.createdAt).toLocaleString()}</TableCell>
//         <TableCell>{new Date(brand.updatedAt).toLocaleString()}</TableCell>
//         <TableCell
//          sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//          }}
//         >
//          <Fab
//           color="secondary"
//           aria-label="edit"
//           onClick={() => handleEdit(brand._id)}
//          >
//           <EditIcon />
//          </Fab>
//          <Fab
//           color="secondary"
//           aria-label="delete"
//           sx={{ marginLeft: "5px" }}
//           onClick={() => handleDelete(brand._id)}
//          >
//           <DeleteIcon />
//          </Fab>
//         </TableCell>
//        </TableRow>
//       ))}
//      </TableBody>
//     </Table>
//    </TableContainer>
//    <Modal
//     aria-labelledby="transition-modal-title"
//     aria-describedby="transition-modal-description"
//     open={open}
//     onClose={handleClose}
//     closeAfterTransition
//     BackdropComponent={Backdrop}
//     BackdropProps={{
//      timeout: 500,
//     }}
//    >
//     <Fade in={open}>
//      <Box sx={style}>
//       <Typography id="transition-modal-title" variant="h6" component="h2">
//        Edit Brand
//       </Typography>
//       <FormControl fullWidth>
//        <TextField
//         {...register("title", { required: "Brand title is required" })}
//         label="Brand Title"
//         margin="normal"
//        />
//        <input
//         type="file"
//         {...register("logo")}
//         accept="image/*"
//         style={{ marginTop: "16px" }}
//        />
//       </FormControl>
//       <Box mt={2} display="flex" justifyContent="space-between">
//        <Button
//         variant="contained"
//         color="primary"
//         onClick={handleSubmit(handleUpdateBrand)}
//        >
//         Save
//        </Button>
//        <Button variant="outlined" onClick={handleClose}>
//         Cancel
//        </Button>
//       </Box>
//      </Box>
//     </Fade>
//    </Modal>
//    <Modal
//     aria-labelledby="transition-modal-title"
//     aria-describedby="transition-modal-description"
//     open={openAdd}
//     onClose={handleCloseAdd}
//     closeAfterTransition
//     BackdropComponent={Backdrop}
//     BackdropProps={{
//      timeout: 500,
//     }}
//    >
//     <Fade in={openAdd}>
//      <Box sx={style}>
//       <Typography id="transition-modal-title" variant="h6" component="h2">
//        Add Brand
//       </Typography>
//       <FormControl fullWidth>
//        <TextField
//         {...register("title", { required: "Brand title is required" })}
//         label="Brand Title"
//         margin="normal"
//        />
//        <input
//         type="file"
//         {...register("logo", { required: "Brand logo is required" })}
//         accept="image/*"
//         style={{ marginTop: "16px" }}
//        />
//       </FormControl>
//       <Box mt={2} display="flex" justifyContent="space-between">
//        <Button
//         variant="contained"
//         color="primary"
//         onClick={handleSubmit(handleAddBrand)}
//        >
//         Add
//        </Button>
//        <Button variant="outlined" onClick={handleCloseAdd}>
//         Cancel
//        </Button>
//       </Box>
//      </Box>
//     </Fade>
//    </Modal>
//    <Snackbar
//     open={snackbarOpen}
//     autoHideDuration={6000}
//     onClose={handleSnackbarClose}
//    >
//     <Alert
//      onClose={handleSnackbarClose}
//      severity={snackbarSeverity}
//      variant="filled"
//      sx={{ width: "100%" }}
//     >
//      {snackbarMessage}
//     </Alert>
//    </Snackbar>
//   </Box>
//  );
// };

// export default Brands;

// // import React, { useEffect, useState } from "react";
// // import {
// //  Box,
// //  Table,
// //  TableBody,
// //  TableCell,
// //  TableContainer,
// //  TableHead,
// //  TableRow,
// //  Paper,
// //  Typography,
// //  Fab,
// //  FormControl,
// //  TextField,
// //  Modal,
// //  Fade,
// //  Backdrop,
// //  Button,
// //  Snackbar,
// //  Alert,
// // } from "@mui/material";
// // import { useDispatch, useSelector } from "react-redux";
// // import { useForm } from "react-hook-form";
// // import {
// //  addBrand,
// //  deleteBrand,
// //  getBrands,
// //  updateBrand,
// // } from "../redux/admin/action";
// // import AddIcon from "@mui/icons-material/Add";
// // import EditIcon from "@mui/icons-material/Edit";
// // import DeleteIcon from "@mui/icons-material/Delete";

// // const style = {
// //  position: "absolute",
// //  top: "50%",
// //  left: "50%",
// //  transform: "translate(-50%, -50%)",
// //  width: 400,
// //  bgcolor: "background.paper",
// //  border: "2px solid #000",
// //  boxShadow: 24,
// //  p: 4,
// // };

// // const Brands = () => {
// //  const dispatch = useDispatch();
// //  const { brands } = useSelector((store) => store.admin);
// //  const [open, setOpen] = useState(false);
// //  const [openAdd, setOpenAdd] = useState(false);
// //  const [searchTerm, setSearchTerm] = useState("");
// //  const [ID, setID] = useState("");

// //  // Snackbar state
// //  const [snackbarOpen, setSnackbarOpen] = useState(false);
// //  const [snackbarMessage, setSnackbarMessage] = useState("");

// //  // React Hook Form setup
// //  const { register, handleSubmit, reset, setValue } = useForm();
// //  const [currentBrand, setCurrentBrand] = useState(null);

// //  useEffect(() => {
// //   if (brands.length <= 0) {
// //    dispatch(getBrands());
// //   }
// //  }, [brands.length, dispatch]);

// //  const handleOpen = () => setOpen(true);
// //  const handleClose = () => {
// //   setOpen(false);
// //   reset();
// //  };

// //  const handleOpenAdd = () => setOpenAdd(true);
// //  const handleCloseAdd = () => {
// //   setOpenAdd(false);
// //   reset();
// //  };

// //  const handleSnackbarClose = (event, reason) => {
// //   if (reason === "clickaway") {
// //    return;
// //   }
// //   setSnackbarOpen(false);
// //  };

// //  const handleDelete = async (id) => {
// //   try {
// //    await dispatch(deleteBrand(id));
// //    dispatch(getBrands());
// //    setSnackbarMessage("Brand deleted successfully!");
// //    setSnackbarOpen(true);
// //   } catch (error) {
// //    console.error("Error:", error);
// //   }
// //  };

// //  const handleEdit = (id) => {
// //   setID(id);
// //   const brand = brands.find((brand) => brand._id === id);
// //   setCurrentBrand(brand);
// //   setValue("title", brand.title);
// //   setValue("logo", ""); // Handle logo separately
// //   handleOpen();
// //  };

// //  const handleAddBrand = async (data) => {
// //   try {
// //    const formData = new FormData();
// //    formData.append("title", data.title);
// //    formData.append("logo", data.logo[0]); // Append the file to FormData

// //    await dispatch(addBrand(formData)); // Pass FormData to the action
// //    handleCloseAdd();
// //    setSnackbarMessage("Brand added successfully!");
// //    setSnackbarOpen(true);
// //   } catch (error) {
// //    console.error("Error:", error);
// //   }
// //  };

// //  const handleUpdateBrand = async (data) => {
// //   try {
// //    const formData = new FormData();
// //    formData.append("title", data.title);
// //    if (data.logo.length > 0) {
// //     formData.append("logo", data.logo[0]); // File upload
// //    }

// //    await dispatch(updateBrand(ID, formData));
// //    dispatch(getBrands());
// //    handleClose();
// //    setSnackbarMessage("Brand updated successfully!");
// //    setSnackbarOpen(true);
// //   } catch (error) {
// //    console.error("Error:", error);
// //   }
// //  };

// //  const filteredBrands = brands?.filter((brand) =>
// //   brand.title.toLowerCase().includes(searchTerm.toLowerCase())
// //  );

// //  return (
// //   <Box sx={{ width: "100%", height: "40vh" }}>
// //    <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
// //     Brands
// //    </Typography>
// //    <Box
// //     sx={{
// //      border: "1px solid #1976D2",
// //      padding: "10px",
// //      display: "flex",
// //      alignItems: "center",
// //      gap: "50px",
// //     }}
// //    >
// //     <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
// //      <AddIcon />
// //     </Fab>
// //     <FormControl sx={{ width: "50%" }}>
// //      <TextField
// //       label="Search Brand"
// //       variant="outlined"
// //       value={searchTerm}
// //       onChange={(e) => setSearchTerm(e.target.value)}
// //      />
// //     </FormControl>
// //    </Box>
// //    <TableContainer component={Paper} sx={{ margin: "auto" }}>
// //     <Table sx={{ minWidth: 650 }} aria-label="simple table">
// //      <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
// //       <TableRow>
// //        <TableCell sx={{ color: "white" }}>Brand Name</TableCell>
// //        <TableCell sx={{ color: "white" }}>Logo</TableCell>
// //        <TableCell sx={{ color: "white" }}>Created At</TableCell>
// //        <TableCell sx={{ color: "white" }}>Updated At</TableCell>
// //        <TableCell sx={{ color: "white" }}>Action</TableCell>
// //       </TableRow>
// //      </TableHead>
// //      <TableBody>
// //       {filteredBrands?.map((brand) => (
// //        <TableRow key={brand._id}>
// //         <TableCell component="th" scope="row">
// //          {brand.title}
// //         </TableCell>
// //         <TableCell>
// //          <img
// //           src={brand.logo}
// //           alt={brand.title}
// //           style={{ width: "50px", height: "50px" }}
// //          />
// //         </TableCell>
// //         <TableCell>{new Date(brand.createdAt).toLocaleString()}</TableCell>
// //         <TableCell>{new Date(brand.updatedAt).toLocaleString()}</TableCell>
// //         <TableCell
// //          sx={{
// //           display: "flex",
// //           alignItems: "center",
// //           justifyContent: "center",
// //          }}
// //         >
// //          <Fab
// //           color="secondary"
// //           aria-label="edit"
// //           onClick={() => handleEdit(brand._id)}
// //          >
// //           <EditIcon />
// //          </Fab>
// //          <Fab
// //           color="secondary"
// //           aria-label="delete"
// //           sx={{ marginLeft: "5px" }}
// //           onClick={() => handleDelete(brand._id)}
// //          >
// //           <DeleteIcon />
// //          </Fab>
// //         </TableCell>
// //        </TableRow>
// //       ))}
// //      </TableBody>
// //     </Table>
// //    </TableContainer>
// //    <Modal
// //     aria-labelledby="transition-modal-title"
// //     aria-describedby="transition-modal-description"
// //     open={open}
// //     onClose={handleClose}
// //     closeAfterTransition
// //     BackdropComponent={Backdrop}
// //     BackdropProps={{
// //      timeout: 500,
// //     }}
// //    >
// //     <Fade in={open}>
// //      <Box sx={style}>
// //       <Typography id="transition-modal-title" variant="h6" component="h2">
// //        Edit Brand
// //       </Typography>
// //       <FormControl fullWidth>
// //        <TextField
// //         {...register("title", { required: "Brand title is required" })}
// //         label="Brand Title"
// //         margin="normal"
// //        />
// //        <input
// //         type="file"
// //         {...register("logo")}
// //         accept="image/*"
// //         style={{ marginTop: "16px" }}
// //        />
// //       </FormControl>
// //       <Box mt={2} display="flex" justifyContent="space-between">
// //        <Button
// //         variant="contained"
// //         color="primary"
// //         onClick={handleSubmit(handleUpdateBrand)}
// //        >
// //         Save
// //        </Button>
// //        <Button variant="outlined" onClick={handleClose}>
// //         Cancel
// //        </Button>
// //       </Box>
// //      </Box>
// //     </Fade>
// //    </Modal>
// //    <Modal
// //     aria-labelledby="transition-modal-title"
// //     aria-describedby="transition-modal-description"
// //     open={openAdd}
// //     onClose={handleCloseAdd}
// //     closeAfterTransition
// //     BackdropComponent={Backdrop}
// //     BackdropProps={{
// //      timeout: 500,
// //     }}
// //    >
// //     <Fade in={openAdd}>
// //      <Box sx={style}>
// //       <Typography id="transition-modal-title" variant="h6" component="h2">
// //        Add Brand
// //       </Typography>
// //       <FormControl fullWidth>
// //        <TextField
// //         {...register("title", { required: "Brand title is required" })}
// //         label="Brand Title"
// //         margin="normal"
// //        />
// //        <input
// //         type="file"
// //         {...register("logo", { required: "Brand logo is required" })}
// //         accept="image/*"
// //         style={{ marginTop: "16px" }}
// //        />
// //       </FormControl>
// //       <Box mt={2} display="flex" justifyContent="space-between">
// //        <Button
// //         variant="contained"
// //         color="primary"
// //         onClick={handleSubmit(handleAddBrand)}
// //        >
// //         Add
// //        </Button>
// //        <Button variant="outlined" onClick={handleCloseAdd}>
// //         Cancel
// //        </Button>
// //       </Box>
// //      </Box>
// //     </Fade>
// //    </Modal>
// //    <Snackbar
// //     open={snackbarOpen}
// //     autoHideDuration={6000}
// //     onClose={handleSnackbarClose}
// //    >
// //     <Alert
// //      onClose={handleSnackbarClose}
// //      severity="success"
// //      variant="filled"
// //      sx={{ width: "100%" }}
// //     >
// //      {snackbarMessage}
// //     </Alert>
// //    </Snackbar>
// //   </Box>
// //  );
// // };

// // export default Brands;

// src/components/RecentOrders.js
import React from "react";
import {
 Paper,
 Typography,
 Box,
 List,
 ListItem,
 ListItemText,
} from "@mui/material";

const orders = [
 { id: 1, service: "Oil Change", customer: "John Doe", date: "2024-07-20" },
 {
  id: 2,
  service: "Brake Replacement",
  customer: "Jane Smith",
  date: "2024-07-19",
 },
 // Add more orders
];

const RecentOrders = () => (
 <Paper>
  <Box p={2}>
   <Typography variant="h6">Recent Orders</Typography>
   <List>
    {orders.map((order) => (
     <ListItem key={order.id}>
      <ListItemText
       primary={`${order.service} - ${order.customer}`}
       secondary={order.date}
      />
     </ListItem>
    ))}
   </List>
  </Box>
 </Paper>
);

export default RecentOrders;

// src/components/TopMechanics.js
import React from "react";
import {
 Paper,
 Typography,
 Box,
 List,
 ListItem,
 ListItemText,
} from "@mui/material";

const mechanics = [
 { id: 1, name: "Mike Johnson", rating: "4.8" },
 { id: 2, name: "Emily Clark", rating: "4.7" },
 // Add more mechanics
];

const TopMechanics = () => (
 <Paper>
  <Box p={2}>
   <Typography variant="h6">Top Performing Mechanics</Typography>
   <List>
    {mechanics.map((mechanic) => (
     <ListItem key={mechanic.id}>
      <ListItemText
       primary={mechanic.name}
       secondary={`Rating: ${mechanic.rating}`}
      />
     </ListItem>
    ))}
   </List>
  </Box>
 </Paper>
);

export default TopMechanics;

import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MyForm from "../Test";

const modalStyle = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: "90vw",
 maxWidth: 1200,
 bgcolor: "background.paper",
 boxShadow: 24,
 p: 4,
 borderRadius: 2,
 //  maxHeight: "90vh",
 overflow: "hidden",
};

const contentStyle = {
 overflow: "auto",
 maxHeight: "80vh",
 paddingRight: "17px",
 marginRight: "-17px",
};

const ProductModal = ({ open, onClose, onSubmit, product }) => {
 return (
  <Modal open={open}>
   <Box sx={modalStyle}>
    <Box
     display="flex"
     justifyContent="space-between"
     alignItems="center"
     mb={2}
    >
     <Typography variant="h6">
      {product ? "Update Product" : "Add New Product"}
     </Typography>
     <IconButton onClick={onClose}>
      <CloseIcon />
     </IconButton>
    </Box>
    <Box sx={contentStyle}>
     <MyForm onSubmit={onSubmit} product={product} />
    </Box>
   </Box>
  </Modal>
 );
};

export default ProductModal;

// import React from "react";
// import { Modal, Box, Typography, IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import MyForm from "../Test";

// const modalStyle = {
//  position: "absolute",
//  top: "50%",
//  left: "50%",
//  transform: "translate(-50%, -50%)",
//  width: "90vw", // Set width to 90% of the viewport width
//  maxWidth: 1200, // Set maxWidth to 1200px for XL size
//  bgcolor: "background.paper",
//  boxShadow: 24,
//  p: 4,
//  borderRadius: 2,
//  maxHeight: "90vh", // Set maxHeight to 90% of the viewport height
//  overflow: "hidden", // Hide scrollbars
// };

// const contentStyle = {
//  overflow: "auto", // Enable scrolling
//  maxHeight: "80vh", // Limit height to 80% of viewport height
//  paddingRight: "17px", // Add padding to account for hidden scrollbar
//  marginRight: "-17px", // Offset margin to keep content aligned
// };

// const ProductModal = ({ open, onClose, onSubmit }) => {
//  return (
//   <Modal open={open} onClose={onClose}>
//    <Box sx={modalStyle}>
//     <Box
//      display="flex"
//      justifyContent="space-between"
//      alignItems="center"
//      mb={2}
//     >
//      <Typography variant="h6">Add New Product</Typography>
//      <IconButton onClick={onClose}>
//       <CloseIcon />
//      </IconButton>
//     </Box>
//     <Box sx={contentStyle}>
//      <MyForm onSubmit={onSubmit} />
//     </Box>
//    </Box>
//   </Modal>
//  );
// };

// export default ProductModal;

// // // ProductModal.js
// // import React from "react";
// // import { Modal, Box, Typography, IconButton } from "@mui/material";
// // import CloseIcon from "@mui/icons-material/Close";
// // import MyForm from "../Test";

// // const modalStyle = {
// //  position: "absolute",
// //  top: "50%",
// //  left: "50%",
// //  transform: "translate(-50%, -50%)",
// //  width: "90vw", // Set width to 90% of the viewport width
// //  maxWidth: 1200, // Set maxWidth to 1200px for XL size
// //  bgcolor: "background.paper",
// //  boxShadow: 24,
// //  p: 4,
// //  borderRadius: 2,
// //  maxHeight: "90vh", // Set maxHeight to 90% of the viewport height
// //  overflow: "hidden", // Hide scrollbars
// // };

// // const contentStyle = {
// //  overflow: "auto", // Enable scrolling
// //  maxHeight: "80vh", // Limit height to 80% of viewport height
// //  paddingRight: "17px", // Add padding to account for hidden scrollbar
// //  marginRight: "-17px", // Offset margin to keep content aligned
// // };

// // const ProductModal = ({ open, onClose, onSubmit }) => {
// //  return (
// //   <Modal open={open} onClose={onClose}>
// //    <Box sx={modalStyle}>
// //     <Box
// //      display="flex"
// //      justifyContent="space-between"
// //      alignItems="center"
// //      mb={2}
// //     >
// //      <Typography variant="h6">Add New Product</Typography>
// //      <IconButton onClick={onClose}>
// //       <CloseIcon />
// //      </IconButton>
// //     </Box>
// //     <Box sx={contentStyle}>
// //      <MyForm onSubmit={onSubmit} />
// //     </Box>
// //    </Box>
// //   </Modal>
// //  );
// // };

// // export default ProductModal;

// src/components/FaqComponent.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
 Box,
 Button,
 TextField,
 Typography,
 Accordion,
 AccordionSummary,
 AccordionDetails,
 IconButton,
 Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
 addFAQ,
 deleteFAQ,
 fetchFAQs,
 updateFAQ,
} from "../../redux/city/action";

const FaqComponent = () => {
 const dispatch = useDispatch();
 const { faqs, isLoading, isError } = useSelector((state) => state.city);
 const [modalOpen, setModalOpen] = useState(false);
 const [editMode, setEditMode] = useState(false);
 const [currentFAQ, setCurrentFAQ] = useState({
  question: "",
  answer: "",
  category: "",
 });

 useEffect(() => {
  dispatch(fetchFAQs());
 }, [dispatch]);

 const handleOpen = () => {
  setEditMode(false);
  setCurrentFAQ({ question: "", answer: "", category: "" });
  setModalOpen(true);
 };

 const handleClose = () => setModalOpen(false);

 const handleChange = (e) => {
  const { name, value } = e.target;
  setCurrentFAQ((prev) => ({ ...prev, [name]: value }));
 };

 const handleSubmit = () => {
  if (editMode) {
   dispatch(updateFAQ(currentFAQ._id, currentFAQ));
  } else {
   dispatch(addFAQ(currentFAQ));
  }
  handleClose();
 };

 const handleEdit = (faq) => {
  setEditMode(true);
  setCurrentFAQ(faq);
  setModalOpen(true);
 };

 const handleDelete = (id) => {
  dispatch(deleteFAQ(id));
 };

 return (
  <Box>
   <Typography variant="h4" gutterBottom>
    FAQ Management
   </Typography>
   <Button variant="contained" color="primary" onClick={handleOpen}>
    Add FAQ
   </Button>
   {isLoading ? (
    <Typography>Loading...</Typography>
   ) : isError ? (
    <Typography>Error loading FAQs</Typography>
   ) : (
    faqs.map((faq) => (
     <Accordion key={faq._id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
       <Typography>{faq.question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
       <Typography>{faq.answer}</Typography>
       <IconButton onClick={() => handleEdit(faq)}>
        <EditIcon />
       </IconButton>
       <IconButton onClick={() => handleDelete(faq._id)}>
        <DeleteIcon />
       </IconButton>
      </AccordionDetails>
     </Accordion>
    ))
   )}
   <Modal open={modalOpen} onClose={handleClose}>
    <Box
     sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
     }}
    >
     <Typography variant="h6" gutterBottom>
      {editMode ? "Edit FAQ" : "Add FAQ"}
     </Typography>
     <TextField
      label="Question"
      name="question"
      value={currentFAQ.question}
      onChange={handleChange}
      fullWidth
      margin="normal"
     />
     <TextField
      label="Answer"
      name="answer"
      value={currentFAQ.answer}
      onChange={handleChange}
      fullWidth
      margin="normal"
     />
     <TextField
      label="Category"
      name="category"
      value={currentFAQ.category}
      onChange={handleChange}
      fullWidth
      margin="normal"
     />
     <Button variant="contained" color="primary" onClick={handleSubmit}>
      {editMode ? "Update" : "Add"}
     </Button>
    </Box>
   </Modal>
  </Box>
 );
};

export default FaqComponent;

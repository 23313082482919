import * as types from "./actionTypes";

const initialState = {
 partnerReviews: [],
 reviews: [],
 isLoading: false,
 isError: false,
};

const reviewReducer = (state = initialState, action) => {
 const { payload, type } = action;
 switch (type) {
  case types.GET_REVIEWS_REQUEST:
  case types.ADD_REVIEW_REQUEST:
  case types.UPDATE_REVIEW_REQUEST:
  case types.DELETE_REVIEW_REQUEST:
  case types.GET_PARTNER_REVIEWS_REQUEST:
  case types.ADD_PARTNER_REVIEW_REQUEST:
  case types.UPDATE_PARTNER_REVIEW_REQUEST:
  case types.DELETE_PARTNER_REVIEW_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };

  case types.GET_REVIEWS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    reviews: payload,
   };

  case types.ADD_REVIEW_SUCCESS:
  case types.UPDATE_REVIEW_SUCCESS:
   return {
    ...state,
    isLoading: false,
    reviews: [...state.reviews, payload],
   };

  case types.DELETE_REVIEW_SUCCESS:
   return {
    ...state,
    isLoading: false,
    reviews: state.reviews.filter((review) => review._id !== payload),
   };
  case types.GET_PARTNER_REVIEWS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    partnerReviews: payload,
   };

  case types.ADD_PARTNER_REVIEW_SUCCESS:
  case types.UPDATE_PARTNER_REVIEW_SUCCESS:
   return {
    ...state,
    isLoading: false,
    partnerReviews: [...state.partnerReviews, payload],
   };

  case types.DELETE_PARTNER_REVIEW_SUCCESS:
   return {
    ...state,
    isLoading: false,
    partnerReviews: state.partnerReviews.filter(
     (review) => review._id !== payload
    ),
   };

  case types.GET_PARTNER_REVIEWS_FAILURE:
  case types.ADD_PARTNER_REVIEW_FAILURE:
  case types.UPDATE_PARTNER_REVIEW_FAILURE:
  case types.DELETE_PARTNER_REVIEW_FAILURE:
  case types.GET_REVIEWS_FAILURE:
  case types.ADD_REVIEW_FAILURE:
  case types.UPDATE_REVIEW_FAILURE:
  case types.DELETE_REVIEW_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  default:
   return state;
 }
};

export { reviewReducer };

import axios from "axios";
import * as types from "./actionTypes";

// API URLs
const API_URL_ABOUT = "https://app-api.carexpert.org.in/api/about";
const API_URL_PRIVACY_POLICY =
 "https://app-api.carexpert.org.in/api/privacy-policy";
const API_URL_TERMS_CONDITIONS =
 "https://app-api.carexpert.org.in/api/terms-conditions";

// About Us Actions
export const fetchAbout = () => async (dispatch) => {
 dispatch({ type: types.GET_ABOUT_REQUEST });
 try {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/about"
  );
  dispatch({ type: types.GET_ABOUT_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_ABOUT_FAILURE, payload: error.message });
 }
};

export const addAbout = (about) => async (dispatch) => {
 dispatch({ type: types.ADD_ABOUT_REQUEST });
 try {
  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/about",
   about
  );
  dispatch({ type: types.ADD_ABOUT_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.ADD_ABOUT_FAILURE, payload: error.message });
 }
};

export const updateAbout = (id, about) => async (dispatch) => {
 dispatch({ type: types.UPDATE_ABOUT_REQUEST });
 try {
  const response = await axios.put(
   `https://app-api.carexpert.org.in/api/about/${id}`,
   about
  );
  dispatch({ type: types.UPDATE_ABOUT_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.UPDATE_ABOUT_FAILURE, payload: error.message });
 }
};

export const deleteAbout = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_ABOUT_REQUEST });
 try {
  await axios.delete(`https://app-api.carexpert.org.in/api/about/${id}`);
  dispatch({ type: types.DELETE_ABOUT_SUCCESS, payload: id });
 } catch (error) {
  dispatch({ type: types.DELETE_ABOUT_FAILURE, payload: error.message });
 }
};

// Privacy Policy Actions
export const fetchPrivacyPolicies = () => async (dispatch) => {
 dispatch({ type: types.GET_PRIVACY_POLICY_REQUEST });
 try {
  const res = await axios.get(API_URL_PRIVACY_POLICY);
  dispatch({ type: types.GET_PRIVACY_POLICY_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_PRIVACY_POLICY_FAILURE, payload: err.message });
 }
};

export const addPrivacyPolicy = (formData) => async (dispatch) => {
 dispatch({ type: types.ADD_PRIVACY_POLICY_REQUEST });
 try {
  const res = await axios.post(API_URL_PRIVACY_POLICY, formData);
  dispatch({ type: types.ADD_PRIVACY_POLICY_SUCCESS, payload: res.data });
  dispatch(fetchPrivacyPolicies());
 } catch (err) {
  dispatch({ type: types.ADD_PRIVACY_POLICY_FAILURE, payload: err.message });
 }
};

export const updatePrivacyPolicy = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_PRIVACY_POLICY_REQUEST });
 try {
  const res = await axios.put(`${API_URL_PRIVACY_POLICY}/${id}`, formData);
  dispatch({ type: types.UPDATE_PRIVACY_POLICY_SUCCESS, payload: res.data });
  dispatch(fetchPrivacyPolicies());
 } catch (err) {
  dispatch({ type: types.UPDATE_PRIVACY_POLICY_FAILURE, payload: err.message });
 }
};

export const deletePrivacyPolicy = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_PRIVACY_POLICY_REQUEST });
 try {
  await axios.delete(`${API_URL_PRIVACY_POLICY}/${id}`);
  dispatch({ type: types.DELETE_PRIVACY_POLICY_SUCCESS, payload: id });
  dispatch(fetchPrivacyPolicies());
 } catch (err) {
  dispatch({ type: types.DELETE_PRIVACY_POLICY_FAILURE, payload: err.message });
 }
};

// Terms & Conditions Actions
export const fetchTermsConditions = () => async (dispatch) => {
 dispatch({ type: types.GET_TERMS_CONDITIONS_REQUEST });
 try {
  const res = await axios.get(API_URL_TERMS_CONDITIONS);
  dispatch({ type: types.GET_TERMS_CONDITIONS_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_TERMS_CONDITIONS_FAILURE, payload: err.message });
 }
};

export const addTermsConditions = (formData) => async (dispatch) => {
 dispatch({ type: types.ADD_TERMS_CONDITIONS_REQUEST });
 try {
  const res = await axios.post(API_URL_TERMS_CONDITIONS, formData);
  dispatch({ type: types.ADD_TERMS_CONDITIONS_SUCCESS, payload: res.data });
  dispatch(fetchTermsConditions());
 } catch (err) {
  dispatch({ type: types.ADD_TERMS_CONDITIONS_FAILURE, payload: err.message });
 }
};

export const updateTermsConditions = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_TERMS_CONDITIONS_REQUEST });
 try {
  const res = await axios.put(`${API_URL_TERMS_CONDITIONS}/${id}`, formData);
  dispatch({ type: types.UPDATE_TERMS_CONDITIONS_SUCCESS, payload: res.data });
  dispatch(fetchTermsConditions());
 } catch (err) {
  dispatch({
   type: types.UPDATE_TERMS_CONDITIONS_FAILURE,
   payload: err.message,
  });
 }
};

export const deleteTermsConditions = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_TERMS_CONDITIONS_REQUEST });
 try {
  await axios.delete(`${API_URL_TERMS_CONDITIONS}/${id}`);
  dispatch({ type: types.DELETE_TERMS_CONDITIONS_SUCCESS, payload: id });
  dispatch(fetchTermsConditions());
 } catch (err) {
  dispatch({
   type: types.DELETE_TERMS_CONDITIONS_FAILURE,
   payload: err.message,
  });
 }
};

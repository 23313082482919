import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
 Box,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 Fab,
 FormControl,
 TextField,
 Modal,
 Fade,
 Backdrop,
 Button,
 MenuItem,
 Select,
 InputLabel,
 Snackbar,
 Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
 addSubCategory,
 getSubCategories,
 updateSubCategory,
 deleteSubCategory,
 getCategories,
} from "../../redux/products/action";

const modalStyle = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

const SubCategory = () => {
 const dispatch = useDispatch();
 const {
  subCategories = [],
  categories = [],
  isError,
 } = useSelector((state) => state.product);

 const [openEditModal, setOpenEditModal] = useState(false);
 const [openAddModal, setOpenAddModal] = useState(false);
 const [searchTerm, setSearchTerm] = useState("");
 const [currentSubCategory, setCurrentSubCategory] = useState({
  categoryId: "",
  subCategoryName: "",
  subCategoryImage: null,
  subCategoryImagePreview: "",
 });
 const [newSubCategory, setNewSubCategory] = useState({
  categoryId: "",
  subCategoryName: "",
  subCategoryImage: null,
  subCategoryImagePreview: "",
 });
 const [snackbar, setSnackbar] = useState({
  open: false,
  message: "",
  severity: "success",
 });

 useEffect(() => {
  dispatch(getSubCategories());
  dispatch(getCategories());
 }, [dispatch]);

 const handleEditClick = (id) => {
  const subCategory = subCategories.find((subcat) => subcat._id === id);
  if (subCategory) {
   setCurrentSubCategory({
    ...subCategory,
    categoryId: subCategory.categoryId._id,
    subCategoryImage: null,
    subCategoryImagePreview: subCategory.subCategoryImage || "",
   });
   setOpenEditModal(true);
  }
 };

 const handleDeleteClick = async (id) => {
  try {
   await dispatch(deleteSubCategory(id));
   setSnackbar({
    open: true,
    message: "Subcategory deleted successfully!",
    severity: "success",
   });
  } catch (error) {
   setSnackbar({
    open: true,
    message: "Error deleting subcategory.",
    severity: "error",
   });
   console.error("Error deleting subcategory:", error);
  }
 };

 const handleEditSubmit = async () => {
  const formData = new FormData();
  formData.append("_id", currentSubCategory._id);
  formData.append("categoryId", currentSubCategory.categoryId);
  formData.append("subCategoryName", currentSubCategory.subCategoryName);
  if (currentSubCategory.subCategoryImage) {
   formData.append("subCategoryImage", currentSubCategory.subCategoryImage);
  }

  try {
   console.log("Submitting edit formData:", formData);
   await dispatch(updateSubCategory(formData));
   if (!isError) {
    setOpenEditModal(false);
    setSnackbar({
     open: true,
     message: "Subcategory updated successfully!",
     severity: "success",
    });
   }
  } catch (error) {
   setSnackbar({
    open: true,
    message: "Error updating subcategory.",
    severity: "error",
   });
   console.error("Error updating subcategory:", error);
  }
 };

 const handleAddSubmit = async () => {
  const formData = new FormData();
  formData.append("categoryId", newSubCategory.categoryId);
  formData.append("subCategoryName", newSubCategory.subCategoryName);
  if (newSubCategory.subCategoryImage) {
   formData.append("subCategoryImage", newSubCategory.subCategoryImage);
  }

  try {
   console.log("Submitting add formData:", formData);
   await dispatch(addSubCategory(formData));
   if (!isError) {
    console.log("isError:", !isError);
    setNewSubCategory({
     categoryId: "",
     subCategoryName: "",
     subCategoryImage: null,
     subCategoryImagePreview: "",
    });
    setOpenAddModal(false);
    setSnackbar({
     open: true,
     message: "Subcategory added successfully!",
     severity: "success",
    });
   } else {
    setSnackbar({
     open: true,
     message: "Subcategory already exists!",
     severity: "error",
    });
   }
  } catch (error) {
   setSnackbar({
    open: true,
    message: "Error adding subcategory.",
    severity: "error",
   });
   console.error("Error adding subcategory:", error);
  }
 };

 const handleSearchChange = (event) => {
  setSearchTerm(event.target.value.toLowerCase());
 };

 const handleFileChange = (e, isEdit) => {
  const file = e.target.files[0];
  if (file) {
   const previewUrl = URL.createObjectURL(file);
   if (isEdit) {
    setCurrentSubCategory({
     ...currentSubCategory,
     subCategoryImage: file,
     subCategoryImagePreview: previewUrl,
    });
   } else {
    setNewSubCategory({
     ...newSubCategory,
     subCategoryImage: file,
     subCategoryImagePreview: previewUrl,
    });
   }
  }
 };

 const handleClose = () => {
  setSnackbar({ ...snackbar, open: false });
 };

 const filteredSubCategories = Array.isArray(subCategories)
  ? subCategories.filter((subcat) =>
     subcat.subCategoryName.toLowerCase().includes(searchTerm)
    )
  : [];
 console.log("isError:", isError);
 return (
  <Box sx={{ width: "100%", height: "40vh" }}>
   <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
    Subcategories
   </Typography>
   <Box
    sx={{
     border: "1px solid #1976D2",
     padding: "10px",
     display: "flex",
     alignItems: "center",
     gap: "50px",
    }}
   >
    <Fab
     color="secondary"
     aria-label="add"
     onClick={() => setOpenAddModal(true)}
    >
     <AddIcon />
    </Fab>
    <FormControl sx={{ width: "50%" }}>
     <TextField
      label="Search Subcategory"
      variant="outlined"
      value={searchTerm}
      onChange={handleSearchChange}
     />
    </FormControl>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>Category Name</TableCell>
       <TableCell sx={{ color: "white" }}>Subcategory Name</TableCell>
       <TableCell sx={{ color: "white" }}>Subcategory Image</TableCell>
       <TableCell sx={{ color: "white" }}>Action</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {filteredSubCategories.length > 0 ? (
       filteredSubCategories.map((subcat) => (
        <TableRow key={subcat._id}>
         <TableCell component="th" scope="row">
          {subcat.categoryId ? subcat.categoryId.categoryName : ""}
         </TableCell>
         <TableCell>{subcat.subCategoryName}</TableCell>
         <TableCell>
          <img
           src={subcat.subCategoryImage}
           alt={subcat.subCategoryName}
           width="50"
           height="50"
          />
         </TableCell>
         <TableCell
          sx={{
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
          }}
         >
          <Fab
           color="secondary"
           aria-label="edit"
           onClick={() => handleEditClick(subcat._id)}
          >
           <EditIcon />
          </Fab>
          <Fab
           color="secondary"
           aria-label="delete"
           sx={{ marginLeft: "5px" }}
           onClick={() => handleDeleteClick(subcat._id)}
          >
           <DeleteIcon />
          </Fab>
         </TableCell>
        </TableRow>
       ))
      ) : (
       <TableRow>
        <TableCell colSpan={4}>No subcategories available.</TableCell>
       </TableRow>
      )}
     </TableBody>
    </Table>
   </TableContainer>

   {/* Edit Subcategory Modal */}
   <Modal
    open={openEditModal}
    onClose={() => setOpenEditModal(false)}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
   >
    <Fade in={openEditModal}>
     <Box sx={modalStyle}>
      <Typography variant="h6" component="h2">
       Edit Subcategory
      </Typography>
      <FormControl fullWidth margin="normal">
       <InputLabel id="edit-category-select-label">Category</InputLabel>
       <Select
        labelId="edit-category-select-label"
        id="edit-category-select"
        value={currentSubCategory.categoryId}
        label="Category"
        onChange={(e) =>
         setCurrentSubCategory({
          ...currentSubCategory,
          categoryId: e.target.value,
         })
        }
       >
        {categories.map((category) => (
         <MenuItem key={category._id} value={category._id}>
          {category.categoryName}
         </MenuItem>
        ))}
       </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
       <TextField
        label="Subcategory Name"
        value={currentSubCategory.subCategoryName}
        onChange={(e) =>
         setCurrentSubCategory({
          ...currentSubCategory,
          subCategoryName: e.target.value,
         })
        }
       />
      </FormControl>
      <FormControl fullWidth margin="normal">
       <Button variant="contained" component="label">
        Upload Subcategory Image
        <input type="file" hidden onChange={(e) => handleFileChange(e, true)} />
       </Button>
       {currentSubCategory.subCategoryImagePreview && (
        <img
         src={currentSubCategory.subCategoryImagePreview}
         alt="Subcategory Preview"
         width="100"
         height="100"
         style={{ marginTop: "10px" }}
        />
       )}
      </FormControl>
      <Box sx={{ mt: 2 }}>
       <Button variant="contained" onClick={handleEditSubmit}>
        Save Changes
       </Button>
       <Button
        variant="outlined"
        onClick={() => setOpenEditModal(false)}
        sx={{ ml: 2 }}
       >
        Cancel
       </Button>
      </Box>
     </Box>
    </Fade>
   </Modal>

   {/* Add Subcategory Modal */}
   <Modal
    open={openAddModal}
    onClose={() => setOpenAddModal(false)}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
   >
    <Fade in={openAddModal}>
     <Box sx={modalStyle}>
      <Typography variant="h6" component="h2">
       Add Subcategory
      </Typography>
      <FormControl fullWidth margin="normal">
       <InputLabel id="add-category-select-label">Category</InputLabel>
       <Select
        labelId="add-category-select-label"
        id="add-category-select"
        value={newSubCategory.categoryId}
        label="Category"
        onChange={(e) =>
         setNewSubCategory({
          ...newSubCategory,
          categoryId: e.target.value,
         })
        }
       >
        {categories.map((category) => (
         <MenuItem key={category._id} value={category._id}>
          {category.categoryName}
         </MenuItem>
        ))}
       </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
       <TextField
        label="Subcategory Name"
        value={newSubCategory.subCategoryName}
        onChange={(e) =>
         setNewSubCategory({
          ...newSubCategory,
          subCategoryName: e.target.value,
         })
        }
       />
      </FormControl>
      <FormControl fullWidth margin="normal">
       <Button variant="contained" component="label">
        Upload Subcategory Image
        <input
         type="file"
         hidden
         onChange={(e) => handleFileChange(e, false)}
        />
       </Button>
       {newSubCategory.subCategoryImagePreview && (
        <img
         src={newSubCategory.subCategoryImagePreview}
         alt="Subcategory Preview"
         width="100"
         height="100"
         style={{ marginTop: "10px" }}
        />
       )}
      </FormControl>
      <Box sx={{ mt: 2 }}>
       <Button variant="contained" onClick={handleAddSubmit}>
        Add Subcategory
       </Button>
       <Button
        variant="outlined"
        onClick={() => setOpenAddModal(false)}
        sx={{ ml: 2 }}
       >
        Cancel
       </Button>
      </Box>
     </Box>
    </Fade>
   </Modal>

   <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity={snackbar.severity}>
     {snackbar.message}
    </Alert>
   </Snackbar>
  </Box>
 );
};

export default SubCategory;

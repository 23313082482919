import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import axios from "axios";

const API_URL = "https://app-api.carexpert.org.in/api/profile";

const getCompanyContact = async () => {
 try {
  const response = await axios.get(`${API_URL}/companycontacts`);
  return response.data[0]; // Assuming you only have one contact record
 } catch (error) {
  console.error("Error fetching company contact:", error);
  throw error;
 }
};

const saveCompanyContact = async (contact) => {
 try {
  const response = await axios.post(`${API_URL}/companycontacts`, contact);
  return response.data;
 } catch (error) {
  console.error("Error saving company contact:", error);
  throw error;
 }
};

const updateCompanyContact = async (id, contact) => {
 try {
  const response = await axios.put(`${API_URL}/companycontact/${id}`, contact);
  return response.data;
 } catch (error) {
  console.error("Error updating company contact:", error);
  throw error;
 }
};

const ContactUs = () => {
 const [email, setEmail] = useState("");
 const [mobile, setMobile] = useState("");
 const [contactId, setContactId] = useState(null); // Store contact ID for updates

 useEffect(() => {
  const fetchCompanyContact = async () => {
   try {
    const contact = await getCompanyContact();
    if (contact) {
     setEmail(contact.email);
     setMobile(contact.mobile);
     setContactId(contact._id);
    }
   } catch (error) {
    console.error("Failed to fetch company contact:", error);
   }
  };

  fetchCompanyContact();
 }, []);

 const handleSave = async () => {
  try {
   const contactData = { email, mobile };

   if (contactId) {
    await updateCompanyContact(contactId, contactData);
    console.log("Company contact updated successfully");
   } else {
    const savedContact = await saveCompanyContact(contactData);
    setContactId(savedContact._id); // Set the new contact ID after saving
    console.log("Company contact saved successfully");
   }
  } catch (error) {
   console.error("Failed to save/update company contact:", error);
  }
 };

 return (
  <Container maxWidth="sm">
   <Box mt={4}>
    <Typography variant="h4" component="h1" gutterBottom>
     Company Contact Us
    </Typography>

    <TextField
     label="Email"
     variant="outlined"
     fullWidth
     margin="normal"
     value={email}
     onChange={(e) => setEmail(e.target.value)}
     placeholder="Enter company email"
     required
     type="email"
    />

    <TextField
     label="Mobile"
     variant="outlined"
     fullWidth
     margin="normal"
     value={mobile}
     onChange={(e) => setMobile(e.target.value)}
     placeholder="Enter company mobile"
     required
    />

    <Button
     variant="contained"
     color="primary"
     onClick={handleSave}
     sx={{ mt: 2 }}
     fullWidth
    >
     {contactId ? "Update" : "Save"}
    </Button>
   </Box>
  </Container>
 );
};

export default ContactUs;

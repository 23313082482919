import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
 Box,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 Fab,
 FormControl,
 TextField,
 Modal,
 Fade,
 Backdrop,
 Button,
 Snackbar,
 Alert,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import {
 addAbout,
 deleteAbout,
 fetchAbout,
 updateAbout,
} from "../../redux/about-termsConditions-privacyPolicy/action";

const style = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

const AdminAbout = () => {
 const dispatch = useDispatch();
 const { about } = useSelector((state) => state.aboutReducer);
 const { register, handleSubmit, reset, setValue } = useForm();
 const [form, setForm] = useState({
  visionAndMission: "",
  story: "",
  services: "",
  socialProof: "",
  image: "",
 });
 const [open, setOpen] = useState(false);
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [currentAbout, setCurrentAbout] = useState(null);

 useEffect(() => {
  dispatch(fetchAbout());
 }, [dispatch]);

 const handleInputChange = (e) => {
  const { name, value } = e.target;
  setForm({ ...form, [name]: value });
 };

 const handleFileChange = (e) => {
  const { name, files } = e.target;
  setForm({ ...form, [name]: files[0] });
 };

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setOpen(false);
  reset();
 };

 const handleSnackbarClose = (event, reason) => {
  if (reason === "clickaway") {
   return;
  }
  setSnackbarOpen(false);
 };

 const handleDelete = (id) => {
  dispatch(deleteAbout(id));
  setSnackbarMessage("About Us content deleted successfully!");
  setSnackbarOpen(true);
 };

 const handleEdit = (about) => {
  setCurrentAbout(about);
  setValue("visionAndMission", about.visionAndMission);
  setValue("story", about.story);
  setValue("services", about.services);
  setValue("socialProof", about.socialProof);
  setValue("image", about.image);
  setForm({
   visionAndMission: about.visionAndMission,
   story: about.story,
   services: about.services,
   socialProof: about.socialProof,
   image: about.image,
  });
  handleOpen();
 };

 const handleAddAbout = (data) => {
  const formData = new FormData();
  formData.append("visionAndMission", data.visionAndMission);
  formData.append("story", data.story);
  formData.append("services", data.services);
  formData.append("socialProof", data.socialProof);
  if (data.image.length > 0) {
   formData.append("image", data.image[0]);
  }
  dispatch(addAbout(formData));
  setSnackbarMessage("About Us content added successfully!");
  setSnackbarOpen(true);
  handleClose();
 };

 const handleUpdateAbout = (data) => {
  const formData = new FormData();
  formData.append("visionAndMission", data.visionAndMission);
  formData.append("story", data.story);
  formData.append("services", data.services);
  formData.append("socialProof", data.socialProof);
  if (data.image.length > 0) {
   formData.append("image", data.image[0]);
  }
  dispatch(updateAbout(currentAbout._id, formData));
  setSnackbarMessage("About Us content updated successfully!");
  setSnackbarOpen(true);
  handleClose();
 };

 const onSubmit = (data) => {
  if (currentAbout) {
   handleUpdateAbout(data);
  } else {
   handleAddAbout(data);
  }
 };

 return (
  <Box sx={{ width: "100%", height: "40vh" }}>
   <Typography fontSize="25px" paddingLeft="5px" fontWeight="600">
    About Us Management
   </Typography>
   <Box
    sx={{
     border: "1px solid #1976D2",
     padding: "10px",
     display: "flex",
     alignItems: "center",
     gap: "50px",
    }}
   >
    <Fab
     color="secondary"
     aria-label="add"
     onClick={() => {
      setCurrentAbout(null);
      handleOpen();
     }}
    >
     <AddIcon />
    </Fab>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>Vision and Mission</TableCell>
       <TableCell sx={{ color: "white" }}>Story</TableCell>
       <TableCell sx={{ color: "white" }}>Services</TableCell>
       <TableCell sx={{ color: "white" }}>Social Proof</TableCell>
       <TableCell sx={{ color: "white" }}>Image</TableCell>
       <TableCell sx={{ color: "white" }}>Actions</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {Array.isArray(about) &&
       about.map((item) => (
        <TableRow key={item._id}>
         <TableCell component="th" scope="row">
          {item.visionAndMission}
         </TableCell>
         <TableCell>{item.story}</TableCell>
         <TableCell>{item.services}</TableCell>
         <TableCell>{item.socialProof}</TableCell>
         <TableCell>
          {item.image && (
           <img
            src={item.image}
            alt="About Us"
            style={{ width: "100px", height: "auto" }}
           />
          )}
         </TableCell>
         <TableCell>
          <Fab
           color="primary"
           aria-label="edit"
           onClick={() => handleEdit(item)}
          >
           <EditIcon />
          </Fab>
          <Fab
           color="secondary"
           aria-label="delete"
           sx={{ marginLeft: "5px" }}
           onClick={() => handleDelete(item._id)}
          >
           <DeleteIcon />
          </Fab>
         </TableCell>
        </TableRow>
       ))}
     </TableBody>
    </Table>
   </TableContainer>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
   >
    <Fade in={open}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       {currentAbout ? "Edit About Us" : "Add About Us"}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <FormControl fullWidth>
        <TextField
         {...register("visionAndMission", {
          required: "Vision and Mission is required",
         })}
         label="Vision and Mission"
         value={form.visionAndMission}
         onChange={handleInputChange}
         name="visionAndMission"
         margin="normal"
         multiline
         rows={4}
        />
        <TextField
         {...register("story", { required: "Story is required" })}
         label="Story"
         value={form.story}
         onChange={handleInputChange}
         name="story"
         margin="normal"
         multiline
         rows={4}
        />
        <TextField
         {...register("services", { required: "Services are required" })}
         label="Services"
         value={form.services}
         onChange={handleInputChange}
         name="services"
         margin="normal"
        />
        <TextField
         {...register("socialProof", { required: "Social Proof is required" })}
         label="Social Proof"
         value={form.socialProof}
         onChange={handleInputChange}
         name="socialProof"
         margin="normal"
         multiline
         rows={4}
        />
        <input
         {...register("image")}
         type="file"
         onChange={handleFileChange}
         name="image"
         accept="image/*"
         margin="normal"
        />
       </FormControl>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         {currentAbout ? "Update" : "Add"}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={handleSnackbarClose}
   >
    <Alert
     onClose={handleSnackbarClose}
     severity="success"
     variant="filled"
     sx={{ width: "100%" }}
    >
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Box>
 );
};

export default AdminAbout;

 
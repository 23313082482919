import React from "react";
import { Container, Box, Typography, Avatar, Grid } from "@mui/material";
import Slider from "react-slick";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { styled } from "@mui/system";

// Import slick carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample testimonial data
const testimonials = [
 {
  id: 1,
  name: "Merjin Jose",
  business: "Owner - Car-Expert Shalom Multi Car Care",
  testimonial:
   "The kind of technical assistance Car-Expert gives to its partner workshops is amazing. They even created an app which made the management of the garage at the ease of the clicks.",
  avatar: "D", // You can use initials for Avatar
 },
 {
  id: 2,
  name: "Ajay Shokeen",
  business: "Owner - Car-Expert Insta Car Care",
  testimonial:
   "My garage was going at a sustainable pace. But there wasn’t much growth in the business, but a partnership with Car-Expert proved to be really beneficial for me.",
  avatar: "", // You can use empty string for avatar fallback
 },
 {
  id: 3,
  name: "Karan Bhutani",
  business: "Owner - Car-Expert Keshav Automobiles",
  testimonial:
   "Good marketing means good business, this is something I learned from Car-Expert. My workshop now gained brand status in the area.",
  avatar: "D",
 },
 {
  id: 4,
  name: "Sohan Kumar",
  business: "Owner - Car-Expert Shine Auto",
  testimonial:
   "Working with Car-Expert has helped my business grow rapidly with their amazing service support and marketing strategies.",
  avatar: "S",
 },
 {
  id: 5,
  name: "Ravi Sharma",
  business: "Owner - Car-Expert Speed Motors",
  testimonial:
   "The tools and customer management provided by Car-Expert have made my business efficient and smooth. Highly recommended!",
  avatar: "R",
 },
];

// Styled components for custom styling
const TestimonialCard = styled(Box)(({ theme }) => ({
 backgroundColor: "#fff",
 padding: theme.spacing(3),
 borderRadius: theme.shape.borderRadius,
 boxShadow: theme.shadows[2],
 position: "relative",
 overflow: "hidden",
 height: "100%",
 minHeight: "350px", // Set a minimum height for cards
 maxHeight: "350px", // Set a maximum height to ensure uniformity
 display: "flex", // Ensure cards take full height
 flexDirection: "column", // Column layout to stack text and author info
 justifyContent: "space-between", // Ensure spacing between text and author
}));

const QuoteIcon = styled(FormatQuoteIcon)(({ theme }) => ({
 position: "absolute",
 top: theme.spacing(2),
 left: theme.spacing(2),
 fontSize: 40,
 color: theme.palette.success.main,
}));

const AuthorInfo = styled(Box)(({ theme }) => ({
 borderTop: `1px solid ${theme.palette.grey[300]}`,
 paddingTop: theme.spacing(2),
 textAlign: "left",
}));

const FormTestimonials = () => {
 // Carousel settings to show 3 cards in a row with auto-scrolling
 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true, // Enable auto-scrolling
  autoplaySpeed: 3000, // Set auto-scrolling speed
  arrows: true,
  responsive: [
   {
    breakpoint: 1024, // Adjusts for tablets and large screens
    settings: {
     slidesToShow: 2,
     slidesToScroll: 1,
     infinite: true,
     dots: true,
    },
   },
   {
    breakpoint: 600, // Adjusts for mobile phones
    settings: {
     slidesToShow: 1,
     slidesToScroll: 1,
    },
   },
  ],
 };

 return (
  <Container sx={{ py: 6 }}>
   {/* Heading Section */}
   <Typography variant="h4" align="center" gutterBottom>
    Car-Expert Happy Partners
   </Typography>
   <Typography variant="subtitle1" align="center" gutterBottom>
    Hear it from the workshop owners themselves!
   </Typography>

   {/* Carousel Component */}
   <Box sx={{ mt: 4 }}>
    <Slider {...settings}>
     {testimonials.map((testimonial) => (
      <Box key={testimonial.id} px={2}>
       <TestimonialCard>
        <QuoteIcon />

        {/* Testimonial Text */}
        <Typography variant="body1" gutterBottom>
         {testimonial.testimonial}
        </Typography>

        {/* Author Info */}
        <AuthorInfo>
         <Grid container spacing={2} alignItems="center">
          <Grid item>
           <Avatar>{testimonial.avatar || "A"}</Avatar>
          </Grid>
          <Grid item>
           <Typography variant="subtitle1" fontWeight="bold">
            {testimonial.name}
           </Typography>
           <Typography variant="body2" color="textSecondary">
            {testimonial.business}
           </Typography>
          </Grid>
         </Grid>
        </AuthorInfo>
       </TestimonialCard>
      </Box>
     ))}
    </Slider>
    <Box
     sx={{ border: "5px solid blue", width: "200px", margin: "50px auto" }}
    />
   </Box>
  </Container>
 );
};

export default FormTestimonials;

import * as types from "./actionTypes";

const initialState = {
 isLoading: false,
 isError: false,
 allCars: [],
 brands: [],
 users: [],
 uniqueCars: [],
};

const reducer = (state = initialState, action) => {
 const { payload, type } = action;
 switch (type) {
  case types.GET_USER_REQUEST:
  case types.ADD_CAR_REQUEST:
  case types.GET_CAR_REQUEST:
  case types.UPDATE_CAR_REQUEST:
  case types.DELETE_CAR_REQUEST:
  case types.DELETE_CARS_REQUEST:
  case types.CREATE_BRAND_REQUEST:
  case types.GET_BRANDS_REQUEST:
  case types.UPDATE_BRAND_REQUEST:
  case types.DELETE_BRAND_REQUEST:
  case types.GET_ALL_VARIENTS_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };

  case types.GET_USER_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    users: payload,
   };

  case types.ADD_CAR_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    allCars: [...state.allCars, payload],
   };
  case types.GET_CAR_SUCCESS:
   console.log("payload:", payload);
   return {
    ...state,
    isLoading: false,
    isError: false,
    allCars: payload,
   };
  case types.GET_ALL_VARIENTS_SUCCESS:
   console.log("payload:", payload);
   return {
    ...state,
    isLoading: false,
    isError: false,
    allCars: payload,
   };

  case types.UPDATE_CAR_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    allCars: state.allCars.map((car) =>
     car._id === payload._id ? payload : car
    ),
   };

  case types.DELETE_CARS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    allCars: state.allCars.filter((car) => car._id !== payload._id),
   };
  case types.DELETE_CAR_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    allCars: state.allCars.filter((car) => car._id !== payload._id),
   };

  case types.CREATE_BRAND_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    brands: [...state.brands, payload],
   };

  case types.GET_BRANDS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    brands: payload,
   };

  case types.UPDATE_BRAND_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    brands: state.brands.map((brand) =>
     brand._id === payload._id ? payload : brand
    ),
   };

  case types.DELETE_BRAND_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    brands: state.brands.filter((brand) => brand._id !== payload._id),
   };

  case types.GET_USER_FAILURE:
  case types.ADD_CAR_FAILURE:
  case types.GET_CAR_FAILURE:
  case types.UPDATE_CAR_FAILURE:
  case types.DELETE_CAR_FAILURE:
  case types.DELETE_CARS_FAILURE:
  case types.DELETE_CAR_FAILURE:
  case types.CREATE_BRAND_FAILURE:
  case types.GET_BRANDS_FAILURE:
  case types.UPDATE_BRAND_FAILURE:
  case types.DELETE_BRAND_FAILURE:
  case types.GET_ALL_VARIENTS_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  default:
   return state;
 }
};

export { reducer };

import React, { useState, useEffect } from "react";
import {
 Container,
 Grid,
 TextField,
 Button,
 Typography,
 Snackbar,
 Box,
} from "@mui/material";
import { Alert } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";

const UploadForm = () => {
 const { userDetails } = useSelector((store) => store.auth);
 const { vendor } = userDetails;
 const [formValues, setFormValues] = useState({
  vendorId: "",
  aadhaar: null,
  panCard: null,
  gstCertificate: null,
  storePhoto: null,
 });

 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [snackbarSeverity, setSnackbarSeverity] = useState("success");

 // useEffect(() => {
 //   const fetchVendorData = async () => {
 //     try {
 //       const response = await axios.get(`http://localhost8900/api/vendor/${vendorId}`);
 //       setVendorData(response.data);
 //     } catch (error) {
 //       console.error('Error fetching vendor data:', error);
 //     }
 //   };

 //   fetchVendorData();
 // }, [vendorId]);

 const handleFileChange = (event) => {
  const { name, files } = event.target;
  setFormValues({ ...formValues, [name]: files[0] });
 };

 const handleChange = (event) => {
  const { name, value } = event.target;
  setFormValues({ ...formValues, [name]: value });
 };

 const handleSubmit = async (event) => {
  event.preventDefault();

  const formData = new FormData();
  formData.append("aadhaar", formValues.aadhaar);
  formData.append("panCard", formValues.panCard);
  formData.append("gstCertificate", formValues.gstCertificate);
  formData.append("storePhoto", formValues.storePhoto);
  formData.append("vendorId", formValues.vendorId);

  try {
   const response = await axios.patch(
    "http://localhost8900/api/upload",
    formData,
    {
     headers: {
      "Content-Type": "multipart/form-data",
     },
    }
   );

   setSnackbarMessage(response.data.message);
   setSnackbarSeverity("success");
   setSnackbarOpen(true);
  } catch (error) {
   console.error("Error uploading documents:", error);
   setSnackbarMessage("Failed to upload documents.");
   setSnackbarSeverity("error");
   setSnackbarOpen(true);
  }
 };

 const handleSnackbarClose = () => {
  setSnackbarOpen(false);
 };

 return (
  <Container>
   <Typography variant="h5" gutterBottom>
    Vendor Information
   </Typography>
   <Box>
    <Typography variant="body1">
     <strong>Name:</strong> {vendor.name}
    </Typography>
    <Typography variant="body1">
     <strong>Email:</strong> {vendor.email}
    </Typography>
    <Typography variant="body1">
     <strong>Mobile:</strong> {vendor.mobile}
    </Typography>
    <Typography variant="body1">
     <strong>Status:</strong> {vendor.status}
    </Typography>
    <Typography variant="body1">
     <strong>Document Status:</strong> {vendor.documentStatus}
    </Typography>
    <Typography variant="body1">
     <strong>Role:</strong> {vendor.role}
    </Typography>
    <Typography variant="body1">
     <strong>Daily Booking Cap:</strong> {vendor.dailyBookingCap}
    </Typography>
    <Typography variant="body1">
     <strong>Monthly Booking Cap:</strong> {vendor.monthlyBookingCap}
    </Typography>
   </Box>
   <Typography variant="h5" gutterBottom>
    Upload Documents
   </Typography>
   <form onSubmit={handleSubmit}>
    <Grid container spacing={2}>
     <Grid item xs={12}>
      <TextField
       name="vendorId"
       label="Vendor ID"
       variant="outlined"
       fullWidth
       value={formValues.vendorId}
       onChange={handleChange}
      />
     </Grid>
     <Grid item xs={12}>
      <Typography variant="subtitle1">Upload Aadhaar Card:</Typography>
      <input
       type="file"
       name="aadhaar"
       onChange={handleFileChange}
       accept=".jpg,.jpeg,.png,.pdf"
      />
     </Grid>
     <Grid item xs={12}>
      <Typography variant="subtitle1">Upload PAN Card:</Typography>
      <input
       type="file"
       name="panCard"
       onChange={handleFileChange}
       accept=".jpg,.jpeg,.png,.pdf"
      />
     </Grid>
     <Grid item xs={12}>
      <Typography variant="subtitle1">Upload GST Certificate:</Typography>
      <input
       type="file"
       name="gstCertificate"
       onChange={handleFileChange}
       accept=".jpg,.jpeg,.png,.pdf"
      />
     </Grid>
     <Grid item xs={12}>
      <Typography variant="subtitle1">Upload Store Photo:</Typography>
      <input
       type="file"
       name="storePhoto"
       onChange={handleFileChange}
       accept=".jpg,.jpeg,.png,.pdf"
      />
     </Grid>
     <Grid item xs={12}>
      <Button variant="contained" color="primary" type="submit">
       Upload Documents
      </Button>
     </Grid>
    </Grid>
   </form>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={handleSnackbarClose}
   >
    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Container>
 );
};

export default UploadForm;

// import React, { useState } from 'react';
// import { Container, Grid, TextField, Button, Typography, Snackbar } from '@mui/material';
// import { Alert } from '@mui/material';
// import axios from 'axios';
// import { useSelector } from 'react-redux';

// const UploadForm = () => {
//   const { userDetails } = useSelector((store) => store.auth);
//   const{vendor}=userDetails
//   console.log('vendor:', vendor)
//   const [formValues, setFormValues] = useState({
//     vendorId: '',
//     aadhaar: null,
//     panCard: null,
//     gstCertificate: null,
//     storePhoto: null,
//   });

//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');

//   const handleFileChange = (event) => {
//     const { name, files } = event.target;
//     setFormValues({ ...formValues, [name]: files[0] });
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormValues({ ...formValues, [name]: value });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const formData = new FormData();
//     formData.append('aadhaar', formValues.aadhaar);
//     formData.append('panCard', formValues.panCard);
//     formData.append('gstCertificate', formValues.gstCertificate);
//     formData.append('storePhoto', formValues.storePhoto);
//     formData.append('vendorId', formValues.vendorId);

//     try {
//       const response = await axios.patch('http://localhost8900/api/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       setSnackbarMessage(response.data.message);
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error uploading documents:', error);
//       setSnackbarMessage('Failed to upload documents.');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Container>
//       <Typography variant="h5" gutterBottom>
//         Upload Documents
//       </Typography>
//       <form onSubmit={handleSubmit}>
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <TextField
//               name="vendorId"
//               label="Vendor ID"
//               variant="outlined"
//               fullWidth
//               value={formValues.vendorId}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="subtitle1">Upload Aadhaar Card:</Typography>
//             <input
//               type="file"
//               name="aadhaar"
//               onChange={handleFileChange}
//               accept=".jpg,.jpeg,.png,.pdf"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="subtitle1">Upload PAN Card:</Typography>
//             <input
//               type="file"
//               name="panCard"
//               onChange={handleFileChange}
//               accept=".jpg,.jpeg,.png,.pdf"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="subtitle1">Upload GST Certificate:</Typography>
//             <input
//               type="file"
//               name="gstCertificate"
//               onChange={handleFileChange}
//               accept=".jpg,.jpeg,.png,.pdf"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="subtitle1">Upload Store Photo:</Typography>
//             <input
//               type="file"
//               name="storePhoto"
//               onChange={handleFileChange}
//               accept=".jpg,.jpeg,.png,.pdf"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button variant="contained" color="primary" type="submit">
//               Upload Documents
//             </Button>
//           </Grid>
//         </Grid>
//       </form>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// };

// export default UploadForm;

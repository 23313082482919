import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
 Box,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 Fab,
 FormControl,
 TextField,
 Modal,
 Fade,
 Backdrop,
 Button,
 Snackbar,
 Alert,
 FormControlLabel,
 Switch,
 MenuItem,
 Select,
 IconButton,
 Chip,
} from "@mui/material";
import {
 addCity,
 deleteCity,
 fetchCities,
 updateCity,
 voteCity,
 toggleServiceStatus,
 removePincode,
} from "../../redux/city/action";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";

const style = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

const states = [
 "Andhra Pradesh",
 "Arunachal Pradesh",
 "Assam",
 "Bihar",
 "Chhattisgarh",
 "Goa",
 "Gujarat",
 "Haryana",
 "Himachal Pradesh",
 "Jharkhand",
 "Karnataka",
 "Kerala",
 "Madhya Pradesh",
 "Maharashtra",
 "Manipur",
 "Meghalaya",
 "Mizoram",
 "Nagaland",
 "Odisha",
 "Punjab",
 "Rajasthan",
 "Sikkim",
 "Tamil Nadu",
 "Telangana",
 "Tripura",
 "Uttar Pradesh",
 "Uttarakhand",
 "West Bengal",
];
const AdminCities = () => {
 const dispatch = useDispatch();
 const { cities } = useSelector((state) => state.city);
 const { register, handleSubmit, reset, setValue } = useForm();
 const [form, setForm] = useState({
  state: "",
  city: "",
  pincodes: "",
  isServiced: false,
 });
 const [open, setOpen] = useState(false);
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [currentCity, setCurrentCity] = useState(null);

 useEffect(() => {
  dispatch(fetchCities());
 }, [dispatch]);

 const handleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
  setForm({ ...form, [name]: type === "checkbox" ? checked : value });
 };

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setOpen(false);
  reset();
 };

 const handleSnackbarClose = (event, reason) => {
  if (reason === "clickaway") {
   return;
  }
  setSnackbarOpen(false);
 };

 const handleDelete = (id) => {
  dispatch(deleteCity(id));
  setSnackbarMessage("City deleted successfully!");
  setSnackbarOpen(true);
 };

 const handleEdit = (city) => {
  setCurrentCity(city);
  setValue("state", city.state);
  setValue("city", city.city);
  setValue("pincodes", city.pincodes ? city.pincodes.join(", ") : "");
  setValue("isServiced", city.isServiced);
  setForm({
   state: city.state,
   city: city.city,
   pincodes: city.pincodes ? city.pincodes.join(", ") : "",
   isServiced: city.isServiced,
  });
  handleOpen();
 };

 const handleAddCity = (data) => {
  const formData = new FormData();
  formData.append("state", data.state);
  formData.append("city", data.city);
  formData.append("pincodes", data.pincodes);
  formData.append("isServiced", data.isServiced);
  formData.append("votes", data.votes);
  if (data.image.length > 0) {
   formData.append("image", data.image[0]);
  }
  dispatch(addCity(formData));
  setSnackbarMessage("City added successfully!");
  setSnackbarOpen(true);
  handleClose();
 };

 const handleUpdateCity = (data) => {
  const formData = new FormData();
  formData.append("state", data.state);
  formData.append("city", data.city);
  formData.append("pincodes", data.pincodes);
  formData.append("isServiced", data.isServiced);
  formData.append("votes", data.votes);
  if (data.image.length > 0) {
   formData.append("image", data.image[0]);
  }
  dispatch(updateCity(currentCity._id, formData));
  setSnackbarMessage("City updated successfully!");
  setSnackbarOpen(true);
  handleClose();
 };

 const handleVote = (id) => {
  dispatch(voteCity(id));
  setSnackbarMessage("Vote recorded!");
  setSnackbarOpen(true);
 };

 const handleToggleServiceStatus = (id, isServiced) => {
  dispatch(toggleServiceStatus(id, !isServiced));
 };

 const handleRemovePincode = (cityId, pincode) => {
  dispatch(removePincode(cityId, pincode));
 };

 const onSubmit = (data) => {
  if (currentCity) {
   handleUpdateCity(data);
  } else {
   handleAddCity(data);
  }
 };

 return (
  <Box sx={{ width: "100%", height: "40vh" }}>
   <Typography fontSize="25px" paddingLeft="5px" fontWeight="600">
    City Management
   </Typography>
   <Box
    sx={{
     border: "1px solid #1976D2",
     padding: "10px",
     display: "flex",
     alignItems: "center",
     gap: "50px",
    }}
   >
    <Fab
     color="secondary"
     aria-label="add"
     onClick={() => {
      setCurrentCity(null);
      handleOpen();
     }}
    >
     <AddIcon />
    </Fab>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>State</TableCell>
       <TableCell sx={{ color: "white" }}>City</TableCell>
       <TableCell sx={{ color: "white" }}>Pincodes</TableCell>
       <TableCell sx={{ color: "white" }}>Is Serviced</TableCell>
       <TableCell sx={{ color: "white" }}>Votes</TableCell>
       <TableCell sx={{ color: "white" }}>Image</TableCell>
       <TableCell sx={{ color: "white" }}>Actions</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {Array.isArray(cities) &&
       cities.map((city) => (
        <TableRow key={city._id}>
         <TableCell component="th" scope="row">
          {city.state}
         </TableCell>
         <TableCell>{city.city}</TableCell>
         <TableCell>
          {city.pincodes &&
           city.pincodes.map((pincode) => (
            <Chip
             key={pincode}
             label={pincode}
             onDelete={() => handleRemovePincode(city._id, pincode)}
             deleteIcon={<CloseIcon />}
             sx={{ margin: "2px" }}
            />
           ))}
         </TableCell>
         <TableCell>
          <FormControlLabel
           control={
            <Switch
             checked={city.isServiced}
             onChange={() =>
              handleToggleServiceStatus(city._id, city.isServiced)
             }
            />
           }
           label={city.isServiced ? "Yes" : "No"}
          />
         </TableCell>
         <TableCell>{city.votes}</TableCell>
         <TableCell>
          {city.image && (
           <img
            src={city.image}
            alt={city.city}
            style={{ width: "100px", height: "auto" }}
           />
          )}
         </TableCell>
         <TableCell>
          <Fab
           color="primary"
           aria-label="edit"
           onClick={() => handleEdit(city)}
          >
           <EditIcon />
          </Fab>
          <Fab
           color="secondary"
           aria-label="delete"
           sx={{ marginLeft: "5px" }}
           onClick={() => handleDelete(city._id)}
          >
           <DeleteIcon />
          </Fab>
          <Fab
           color="default"
           aria-label="vote"
           sx={{ marginLeft: "5px" }}
           onClick={() => handleVote(city._id)}
          >
           <ThumbUpIcon />
          </Fab>
         </TableCell>
        </TableRow>
       ))}
     </TableBody>
    </Table>
   </TableContainer>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
   >
    <Fade in={open}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       {currentCity ? "Edit City" : "Add City"}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <FormControl fullWidth>
        <Select
         {...register("state", { required: "State is required" })}
         value={form.state}
         onChange={handleInputChange}
         name="state"
         margin="normal"
        >
         {states.map((state) => (
          <MenuItem key={state} value={state}>
           {state}
          </MenuItem>
         ))}
        </Select>
        <TextField
         {...register("city", { required: "City is required" })}
         label="City"
         value={form.city}
         onChange={handleInputChange}
         name="city"
         margin="normal"
        />
        <TextField
         {...register("pincodes", { required: "Pincodes are required" })}
         label="Pincodes (separated by commas)"
         value={form.pincodes}
         onChange={handleInputChange}
         name="pincodes"
         margin="normal"
        />
        <FormControlLabel
         control={
          <Switch
           {...register("isServiced")}
           checked={form.isServiced}
           onChange={handleInputChange}
           name="isServiced"
          />
         }
         label="Is Serviced"
        />
        <TextField
         {...register("votes")}
         label="Votes"
         type="number"
         value={form.votes}
         onChange={handleInputChange}
         name="votes"
         margin="normal"
        />
        <input
         {...register("image")}
         type="file"
         accept="image/*"
         name="image"
        />
       </FormControl>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         {currentCity ? "Update" : "Add"}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={handleSnackbarClose}
   >
    <Alert
     onClose={handleSnackbarClose}
     severity="success"
     variant="filled"
     sx={{ width: "100%" }}
    >
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Box>
 );
};

export default AdminCities;
 
// src/components/HomePage.js
import React from "react";
import { Container, Grid, Box } from "@mui/material";
import StatsCard from "../components/homepage/stats/StatsCard";
import RevenueChart from "../components/homepage/Charts/RevenueCharts";
import OrdersChart from "../components/homepage/Charts/OrdersChart";
import RecentOrders from "../components/homepage/stats/RecentOrders";
import TopMechanics from "../components/homepage/stats/TopMechanics";

const HomePage = () => (
 <Container maxWidth="xl">
  <Grid container spacing={3}>
   <Grid item xs={12} md={4}>
    <StatsCard title="Total Revenue" value="$120K" subtitle="This Month" />
   </Grid>
   <Grid item xs={12} md={4}>
    <StatsCard title="Total Orders" value="480" subtitle="This Month" />
   </Grid>
   <Grid item xs={12} md={4}>
    <StatsCard title="Active Mechanics" value="35" subtitle="" />
   </Grid>
   <Grid item xs={12}>
    <RevenueChart />
   </Grid>
   <Grid item xs={12}>
    <OrdersChart />
   </Grid>
   <Grid item xs={12} md={6}>
    <RecentOrders />
   </Grid>
   <Grid item xs={12} md={6}>
    <TopMechanics />
   </Grid>
  </Grid>
 </Container>
);

export default HomePage;

import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Fab,
  FormControl,
  TextField,
  Modal,
  Fade,
  Backdrop,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
 
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { addVideoTestimonial, deleteVideoTestimonial, getVideoTestimonials, updateVideoTestimonial } from "../../redux/advertisement/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VideoTestimonials = () => {
  const dispatch = useDispatch();
  const { videoTestimonials } = useSelector((store) => store.advertisement);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [ID, setID] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { register, handleSubmit, reset, setValue } = useForm();
  const [currentVideoTestimonial, setCurrentVideoTestimonial] = useState(null);

  useEffect(() => {
    dispatch(getVideoTestimonials());
  }, [dispatch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => {
    setOpenAdd(false);
    reset();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteVideoTestimonial(id));
      setSnackbarMessage("Video Testimonial deleted successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = (id) => {
    setID(id);
    const videoTestimonial = videoTestimonials.find((video) => video._id === id);
    setCurrentVideoTestimonial(videoTestimonial);
    setValue("title", videoTestimonial.title);
    setValue("videoUrl", ""); 
    handleOpen();
  };

  const handleAddVideoTestimonial = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("videoUrl", data.videoUrl[0]);

      await dispatch(addVideoTestimonial(formData)); 
      handleCloseAdd();
      setSnackbarMessage("Video Testimonial added successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateVideoTestimonial = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      if (data.videoUrl.length > 0) {
        formData.append("videoUrl", data.videoUrl[0]); 
      }

      await dispatch(updateVideoTestimonial(ID, formData));
      handleClose();
      setSnackbarMessage("Video Testimonial updated successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filteredVideoTestimonials = videoTestimonials?.filter((video) =>
    video?.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ width: "100%", height: "40vh" }}>
      <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
        Video Testimonials
      </Typography>
      <Box
        sx={{
          border: "1px solid #1976D2",
          padding: "10px",
          display: "flex",
          alignItems: "center",
          gap: "50px",
        }}
      >
        <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
          <AddIcon />
        </Fab>
        <FormControl sx={{ width: "50%" }}>
          <TextField
            label="Search Video Testimonial"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </FormControl>
      </Box>
      <TableContainer component={Paper} sx={{ margin: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Video Title</TableCell>
              <TableCell sx={{ color: "white" }}>Video</TableCell>
              <TableCell sx={{ color: "white" }}>Created At</TableCell>
              <TableCell sx={{ color: "white" }}>Updated At</TableCell>
              <TableCell sx={{ color: "white" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVideoTestimonials?.map((video) => (
              <TableRow key={video._id}>
                <TableCell component="th" scope="row">
                  {video.title}
                </TableCell>
                <TableCell>
                  <video
                    src={video.videoUrl}
                    alt={video.title}
                    style={{ width: "100px", height: "100px" }}
                    controls
                  />
                </TableCell>
                <TableCell>{new Date(video.createdAt).toLocaleString()}</TableCell>
                <TableCell>{new Date(video.updatedAt).toLocaleString()}</TableCell>
                <TableCell
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Fab
                    color="secondary"
                    aria-label="edit"
                    onClick={() => handleEdit(video._id)}
                  >
                    <EditIcon />
                  </Fab>
                  <Fab
                    color="secondary"
                    aria-label="delete"
                    sx={{ marginLeft: "5px" }}
                    onClick={() => handleDelete(video._id)}
                  >
                    <DeleteIcon />
                  </Fab>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Edit Video Testimonial
            </Typography>
            <FormControl fullWidth>
              <TextField
                {...register("title", { required: "Video title is required" })}
                label="Video Title"
                margin="normal"
              />
              <input
                type="file"
                {...register("videoUrl")}
                accept="video/*"
                style={{ marginTop: "16px" }}
              />
            </FormControl>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(handleUpdateVideoTestimonial)}
              >
                Save
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAdd}
        onClose={handleCloseAdd}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdd}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Video Testimonial
            </Typography>
            <FormControl fullWidth>
              <TextField
                {...register("title", { required: "Video title is required" })}
                label="Video Title"
                margin="normal"
              />
              <input
                type="file"
                {...register("videoUrl", { required: "Video URL is required" })}
                accept="video/*"
                style={{ marginTop: "16px" }}
              />
            </FormControl>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(handleAddVideoTestimonial)}
              >
                Add
              </Button>
              <Button variant="outlined" onClick={handleCloseAdd}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VideoTestimonials;

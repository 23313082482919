import { combineReducers } from "redux";
import * as types from "./actionTypes";

const initialState = {
 referAndEarn: [],
 couponCodes: [],
 wallets: [],
 isLoading: false,
 isError: false,
};

const referAndEarnReducer = (state = initialState, action) => {
 const { payload, type } = action;
 switch (type) {
  case types.GET_REFER_AND_EARN_REQUEST:
  case types.ADD_REFER_AND_EARN_REQUEST:
  case types.UPDATE_REFER_AND_EARN_REQUEST:
  case types.DELETE_REFER_AND_EARN_REQUEST:
  case types.GET_COUPON_CODES_REQUEST:
  case types.ADD_COUPON_CODE_REQUEST:
  case types.UPDATE_COUPON_CODE_REQUEST:
  case types.DELETE_COUPON_CODE_REQUEST:
  case types.GET_WALLETS_REQUEST:
  case types.ADD_WALLET_REQUEST:
  case types.UPDATE_WALLET_REQUEST:
  case types.DELETE_WALLET_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_REFER_AND_EARN_SUCCESS:
   return {
    ...state,
    isLoading: false,
    referAndEarn: payload,
   };
  case types.ADD_REFER_AND_EARN_SUCCESS:
  case types.UPDATE_REFER_AND_EARN_SUCCESS:
   return {
    ...state,
    isLoading: false,
    referAndEarn: [...state.referAndEarn, payload],
   };
  case types.DELETE_REFER_AND_EARN_SUCCESS:
   return {
    ...state,
    isLoading: false,
    referAndEarn: state.referAndEarn.filter((item) => item._id !== payload),
   };
  case types.GET_COUPON_CODES_SUCCESS:
   return {
    ...state,
    isLoading: false,
    couponCodes: payload,
   };
  case types.ADD_COUPON_CODE_SUCCESS:
  case types.UPDATE_COUPON_CODE_SUCCESS:
   return {
    ...state,
    isLoading: false,
    couponCodes: [...state.couponCodes, payload],
   };
  case types.DELETE_COUPON_CODE_SUCCESS:
   return {
    ...state,
    isLoading: false,
    couponCodes: state.couponCodes.filter((item) => item._id !== payload),
   };
  case types.GET_WALLETS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    wallets: payload,
   };
  case types.ADD_WALLET_SUCCESS:
  case types.UPDATE_WALLET_SUCCESS:
   return {
    ...state,
    isLoading: false,
    wallets: [...state.wallets, payload],
   };
  case types.DELETE_WALLET_SUCCESS:
   return {
    ...state,
    isLoading: false,
    wallets: state.wallets.filter((item) => item._id !== payload),
   };
  case types.GET_REFER_AND_EARN_FAILURE:
  case types.ADD_REFER_AND_EARN_FAILURE:
  case types.UPDATE_REFER_AND_EARN_FAILURE:
  case types.DELETE_REFER_AND_EARN_FAILURE:
  case types.GET_COUPON_CODES_FAILURE:
  case types.ADD_COUPON_CODE_FAILURE:
  case types.UPDATE_COUPON_CODE_FAILURE:
  case types.DELETE_COUPON_CODE_FAILURE:
  case types.GET_WALLETS_FAILURE:
  case types.ADD_WALLET_FAILURE:
  case types.UPDATE_WALLET_FAILURE:
  case types.DELETE_WALLET_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };
  default:
   return state;
 }
};

export { referAndEarnReducer };

// About Us
export const GET_ABOUT_REQUEST = "GET_ABOUT_REQUEST";
export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS";
export const GET_ABOUT_FAILURE = "GET_ABOUT_FAILURE";

export const ADD_ABOUT_REQUEST = "ADD_ABOUT_REQUEST";
export const ADD_ABOUT_SUCCESS = "ADD_ABOUT_SUCCESS";
export const ADD_ABOUT_FAILURE = "ADD_ABOUT_FAILURE";

export const UPDATE_ABOUT_REQUEST = "UPDATE_ABOUT_REQUEST";
export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS";
export const UPDATE_ABOUT_FAILURE = "UPDATE_ABOUT_FAILURE";

export const DELETE_ABOUT_REQUEST = "DELETE_ABOUT_REQUEST";
export const DELETE_ABOUT_SUCCESS = "DELETE_ABOUT_SUCCESS";
export const DELETE_ABOUT_FAILURE = "DELETE_ABOUT_FAILURE";

// Privacy Policy
export const GET_PRIVACY_POLICY_REQUEST = "GET_PRIVACY_POLICY_REQUEST";
export const GET_PRIVACY_POLICY_SUCCESS = "GET_PRIVACY_POLICY_SUCCESS";
export const GET_PRIVACY_POLICY_FAILURE = "GET_PRIVACY_POLICY_FAILURE";

export const ADD_PRIVACY_POLICY_REQUEST = "ADD_PRIVACY_POLICY_REQUEST";
export const ADD_PRIVACY_POLICY_SUCCESS = "ADD_PRIVACY_POLICY_SUCCESS";
export const ADD_PRIVACY_POLICY_FAILURE = "ADD_PRIVACY_POLICY_FAILURE";

export const UPDATE_PRIVACY_POLICY_REQUEST = "UPDATE_PRIVACY_POLICY_REQUEST";
export const UPDATE_PRIVACY_POLICY_SUCCESS = "UPDATE_PRIVACY_POLICY_SUCCESS";
export const UPDATE_PRIVACY_POLICY_FAILURE = "UPDATE_PRIVACY_POLICY_FAILURE";

export const DELETE_PRIVACY_POLICY_REQUEST = "DELETE_PRIVACY_POLICY_REQUEST";
export const DELETE_PRIVACY_POLICY_SUCCESS = "DELETE_PRIVACY_POLICY_SUCCESS";
export const DELETE_PRIVACY_POLICY_FAILURE = "DELETE_PRIVACY_POLICY_FAILURE";

// Terms & Conditions
export const GET_TERMS_CONDITIONS_REQUEST = "GET_TERMS_CONDITIONS_REQUEST";
export const GET_TERMS_CONDITIONS_SUCCESS = "GET_TERMS_CONDITIONS_SUCCESS";
export const GET_TERMS_CONDITIONS_FAILURE = "GET_TERMS_CONDITIONS_FAILURE";

export const ADD_TERMS_CONDITIONS_REQUEST = "ADD_TERMS_CONDITIONS_REQUEST";
export const ADD_TERMS_CONDITIONS_SUCCESS = "ADD_TERMS_CONDITIONS_SUCCESS";
export const ADD_TERMS_CONDITIONS_FAILURE = "ADD_TERMS_CONDITIONS_FAILURE";

export const UPDATE_TERMS_CONDITIONS_REQUEST =
 "UPDATE_TERMS_CONDITIONS_REQUEST";
export const UPDATE_TERMS_CONDITIONS_SUCCESS =
 "UPDATE_TERMS_CONDITIONS_SUCCESS";
export const UPDATE_TERMS_CONDITIONS_FAILURE =
 "UPDATE_TERMS_CONDITIONS_FAILURE";

export const DELETE_TERMS_CONDITIONS_REQUEST =
 "DELETE_TERMS_CONDITIONS_REQUEST";
export const DELETE_TERMS_CONDITIONS_SUCCESS =
 "DELETE_TERMS_CONDITIONS_SUCCESS";
export const DELETE_TERMS_CONDITIONS_FAILURE =
 "DELETE_TERMS_CONDITIONS_FAILURE";

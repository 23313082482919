// src/components/StatsCard.js
import React from "react";
import { Paper, Typography, Box } from "@mui/material";

const StatsCard = ({ title, value, subtitle }) => (
 <Paper>
  <Box p={2}>
   <Typography variant="h6">{title}</Typography>
   <Typography variant="h4">{value}</Typography>
   {subtitle && <Typography color="textSecondary">{subtitle}</Typography>}
  </Box>
 </Paper>
);

export default StatsCard;

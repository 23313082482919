import React, { useState, useEffect } from "react";
import {
 Box,
 TextField,
 MenuItem,
 FormControl,
 InputLabel,
 Select,
 Button,
 Checkbox,
 FormControlLabel,
 FormGroup,
 DialogActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../redux/admin/action";

const CarForm = ({ car, editMode, onSubmit, onClose }) => {
 const dispatch = useDispatch();
 const { brands } = useSelector((store) => store.admin);
 const [imagePreview, setImagePreview] = useState("");
 const [title, setTitle] = useState("");
 const [brand, setBrand] = useState("");
 const [fuelType, setFuelType] = useState([]);
 const [transmissionType, setTransmissionType] = useState([]);
 const [image, setImage] = useState("");

 useEffect(() => {
  dispatch(getBrands()); // Fetch brands when the component mounts
 }, [dispatch]);

 useEffect(() => {
  if (editMode && car) {
   setTitle(car.title || "");
   setBrand(car.brand?._id || "");
   setFuelType(Array.isArray(car.fuelType) ? car.fuelType : [car.fuelType]);
   setTransmissionType(
    Array.isArray(car.transmissionType)
     ? car.transmissionType
     : [car.transmissionType]
   );
   setImage(car.images || "");
  }
 }, [editMode, car]);

 const handleSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData();
  formData.append("title", title);
  formData.append("brand", brand);
  if (image) {
   formData.append("image", image);
  }
  formData.append("transmissionTypes", transmissionType.join(","));
  formData.append("fuelTypes", fuelType.join(","));

  // Logging form data to ensure it's being appended correctly
  for (let pair of formData.entries()) {
   console.log(pair[0] + ": " + pair[1]);
  }

  onSubmit(formData);
 };

 const handleTransmissionChange = (event) => {
  const { value } = event.target;
  setTransmissionType((prev) =>
   prev.includes(value)
    ? prev.filter((type) => type !== value)
    : [...prev, value]
  );
 };

 const handleFuelTypeChange = (event) => {
  const { value } = event.target;
  setFuelType((prev) =>
   prev.includes(value)
    ? prev.filter((type) => type !== value)
    : [...prev, value]
  );
 };

 const handleImageChange = (event) => {
  const file = event.target.files[0];
  if (file) {
   const fileUrl = URL.createObjectURL(file);
   setImage(file);
   setImagePreview(fileUrl);
  }
 };

 const handleBrandChange = (event) => {
  setBrand(event.target.value);
 };

 return (
  <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
   <TextField
    label="Car Title"
    value={title}
    onChange={(e) => setTitle(e.target.value)}
    fullWidth
    margin="normal"
   />
   <FormControl fullWidth variant="outlined" margin="normal">
    <InputLabel id="brand-label">Brand</InputLabel>
    <Select
     labelId="brand-label"
     id="brand-select"
     value={brand}
     onChange={handleBrandChange}
     label="Brand"
    >
     {brands.map((brand) => (
      <MenuItem key={brand._id} value={brand._id}>
       {brand.title}
      </MenuItem>
     ))}
    </Select>
   </FormControl>

   {!editMode && (
    <>
     <FormControl component="fieldset" fullWidth margin="normal">
      <FormGroup row>
       <FormControlLabel
        control={
         <Checkbox
          checked={transmissionType.includes("automatic")}
          onChange={handleTransmissionChange}
          value="automatic"
         />
        }
        label="Automatic"
       />
       <FormControlLabel
        control={
         <Checkbox
          checked={transmissionType.includes("manual")}
          onChange={handleTransmissionChange}
          value="manual"
         />
        }
        label="Manual"
       />
      </FormGroup>
     </FormControl>
     <FormControl component="fieldset" fullWidth margin="normal">
      <FormGroup row>
       <FormControlLabel
        control={
         <Checkbox
          checked={fuelType.includes("petrol")}
          onChange={handleFuelTypeChange}
          value="petrol"
         />
        }
        label="Petrol"
       />
       <FormControlLabel
        control={
         <Checkbox
          checked={fuelType.includes("cng")}
          onChange={handleFuelTypeChange}
          value="cng"
         />
        }
        label="CNG"
       />
       <FormControlLabel
        control={
         <Checkbox
          checked={fuelType.includes("diesel")}
          onChange={handleFuelTypeChange}
          value="diesel"
         />
        }
        label="Diesel"
       />
       <FormControlLabel
        control={
         <Checkbox
          checked={fuelType.includes("electric")}
          onChange={handleFuelTypeChange}
          value="electric"
         />
        }
        label="Electric"
       />
      </FormGroup>
     </FormControl>
    </>
   )}

   <Button variant="contained" component="label" fullWidth margin="normal">
    Upload Image
    <input type="file" hidden onChange={handleImageChange} />
   </Button>
   {imagePreview && (
    <Box sx={{ marginTop: 2 }}>
     <Box sx={{ display: "inline-block", marginRight: 2 }}>
      <img
       src={imagePreview}
       alt="Car"
       style={{ width: "100px", height: "100px" }}
      />
     </Box>
    </Box>
   )}
   <Box sx={{ marginTop: 2, textAlign: "right" }}>
    <DialogActions>
     <Button type="submit" variant="contained" color="primary">
      {editMode ? "Update Car" : "Add Car"}
     </Button>
     <Button variant="outlined" onClick={onClose}>
      Cancel
     </Button>
    </DialogActions>
   </Box>
  </Box>
 );
};

export default CarForm;

 
import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Slider from "react-slick";
import { styled } from "@mui/system";

// Import slick carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample partner workshop data
const workshops = [
 {
  id: 1,
  name: "Motoring 65, Gurgaon",
  image: "/workshop1.jpg", // Replace with actual image path
 },
 {
  id: 2,
  name: "Car-Expert - Nerul, Mumbai",
  image: "/workshop2.jpg", // Replace with actual image path
 },
 {
  id: 3,
  name: "Car-Expert - Diya Motors, Chennai",
  image: "/workshop3.jpg", // Replace with actual image path
 },
 {
  id: 4,
  name: "3M Automobiles, Gurgaon",
  image: "/workshop4.jpg", // Replace with actual image path
 },
 {
  id: 5,
  name: "AutoFix Workshop, Pune",
  image: "/workshop5.jpg", // Replace with actual image path
 },
];

// Styled component for workshop image and caption
const WorkshopImage = styled(Box)(({ theme }) => ({
 borderRadius: theme.shape.borderRadius,
 overflow: "hidden",
 boxShadow: theme.shadows[3],
 img: {
  width: "100%",
  height: "200px",
  objectFit: "cover",
 },
 textAlign: "center",
 marginTop: theme.spacing(2),
}));

const WorkshopSlider = () => {
 // Slick carousel settings
 const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 2,
     slidesToScroll: 1,
    },
   },
   {
    breakpoint: 600,
    settings: {
     slidesToShow: 1,
     slidesToScroll: 1,
    },
   },
  ],
 };

 return (
  <Container sx={{ py: 6 }}>
   {/* Title Section */}
   <Typography variant="h4" align="center" gutterBottom>
    Our Partner Workshops
   </Typography>
   <Typography variant="subtitle1" align="center" gutterBottom>
    Here’s a sneak peek into the Car-Expert Partner Workshops
   </Typography>

   {/* Carousel Component */}
   <Box sx={{ mt: 4 }}>
    <Slider {...settings}>
     {workshops.map((workshop) => (
      <Box key={workshop.id} px={2}>
       <WorkshopImage>
        <img src={workshop.image} alt={workshop.name} />
        <Typography variant="h6" component="p" mt={2}>
         {workshop.name}
        </Typography>
       </WorkshopImage>
      </Box>
     ))}
    </Slider>
   </Box>
  </Container>
 );
};

export default WorkshopSlider;

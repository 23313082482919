import axios from "axios";
import * as types from "./actionTypes";
import {
 GET_VIDEO_TESTIMONIALS_REQUEST,
 GET_VIDEO_TESTIMONIALS_SUCCESS,
 GET_VIDEO_TESTIMONIALS_FAILURE,
 ADD_VIDEO_TESTIMONIAL_REQUEST,
 ADD_VIDEO_TESTIMONIAL_SUCCESS,
 ADD_VIDEO_TESTIMONIAL_FAILURE,
 UPDATE_VIDEO_TESTIMONIAL_REQUEST,
 UPDATE_VIDEO_TESTIMONIAL_SUCCESS,
 UPDATE_VIDEO_TESTIMONIAL_FAILURE,
 DELETE_VIDEO_TESTIMONIAL_REQUEST,
 DELETE_VIDEO_TESTIMONIAL_SUCCESS,
 DELETE_VIDEO_TESTIMONIAL_FAILURE,
} from "./actionTypes";

// Get Video Testimonials
export const getVideoTestimonials = () => async (dispatch) => {
 dispatch({ type: GET_VIDEO_TESTIMONIALS_REQUEST });
 try {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/testimonial-videos"
  );
  dispatch({ type: GET_VIDEO_TESTIMONIALS_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: GET_VIDEO_TESTIMONIALS_FAILURE, payload: error.message });
  console.error("Error fetching video testimonials:", error);
 }
};

// Add Video Testimonial
export const addVideoTestimonial = (formData) => async (dispatch) => {
 dispatch({ type: ADD_VIDEO_TESTIMONIAL_REQUEST });
 try {
  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/testimonial-videos",
   formData,
   {
    headers: { "Content-Type": "multipart/form-data" },
   }
  );
  dispatch({ type: ADD_VIDEO_TESTIMONIAL_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: ADD_VIDEO_TESTIMONIAL_FAILURE, payload: error.message });
  console.error("Error adding video testimonial:", error);
 }
};

// Update Video Testimonial
export const updateVideoTestimonial = (id, formData) => async (dispatch) => {
 dispatch({ type: UPDATE_VIDEO_TESTIMONIAL_REQUEST });
 try {
  const response = await axios.patch(
   `https://app-api.carexpert.org.in/api/testimonial-videos/update/${id}`,
   formData,
   {
    headers: { "Content-Type": "multipart/form-data" },
   }
  );
  dispatch({ type: UPDATE_VIDEO_TESTIMONIAL_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: UPDATE_VIDEO_TESTIMONIAL_FAILURE, payload: error.message });
  console.error("Error updating video testimonial:", error);
 }
};

// Delete Video Testimonial
export const deleteVideoTestimonial = (id) => async (dispatch) => {
 dispatch({ type: DELETE_VIDEO_TESTIMONIAL_REQUEST });
 try {
  await axios.delete(
   `https://app-api.carexpert.org.in/api/testimonial-videos/delete/${id}`
  );
  dispatch({ type: DELETE_VIDEO_TESTIMONIAL_SUCCESS, payload: id });
 } catch (error) {
  dispatch({ type: DELETE_VIDEO_TESTIMONIAL_FAILURE, payload: error.message });
  console.error("Error deleting video testimonial:", error);
 }
};

// const API_URL = "https://app-api.carexpert.org.in/api/offers";

// export const fetchOffers = () => async (dispatch) => {
//  dispatch({ type: types.GET_OFFERS_REQUEST });
//  try {
//   const res = await axios.get(API_URL);
//   dispatch({ type: types.GET_OFFERS_SUCCESS, payload: res.data.offers });
//  } catch (err) {
//   dispatch({ type: types.GET_OFFERS_FAILURE, payload: err.message });
//  }
// };

// export const addOffer = (offer) => async (dispatch) => {
//  dispatch({ type: types.ADD_OFFER_REQUEST });
//  try {
//   const res = await axios.post(API_URL, offer);
//   dispatch({ type: types.ADD_OFFER_SUCCESS, payload: res.data });
//   dispatch(fetchOffers());
//  } catch (err) {
//   dispatch({ type: types.ADD_OFFER_FAILURE, payload: err.message });
//  }
// };

// export const updateOffer = (id, offer) => async (dispatch) => {
//  dispatch({ type: types.UPDATE_OFFER_REQUEST });
//  try {
//   const res = await axios.put(`${API_URL}/${id}`, offer);
//   dispatch({ type: types.UPDATE_OFFER_SUCCESS, payload: res.data });
//   dispatch(fetchOffers());
//  } catch (err) {
//   dispatch({ type: types.UPDATE_OFFER_FAILURE, payload: err.message });
//  }
// };

// export const deleteOffer = (id) => async (dispatch) => {
//  dispatch({ type: types.DELETE_OFFER_REQUEST });
//  try {
//   await axios.delete(`${API_URL}/${id}`);
//   dispatch({ type: types.DELETE_OFFER_SUCCESS, payload: id });
//   dispatch(fetchOffers());
//  } catch (err) {
//   dispatch({ type: types.DELETE_OFFER_FAILURE, payload: err.message });
//  }
// };

const API_URL = "https://app-api.carexpert.org.in/api/offers";

export const fetchOffers = () => async (dispatch) => {
 dispatch({ type: types.GET_OFFERS_REQUEST });
 try {
  const res = await axios.get(API_URL);
  dispatch({ type: types.GET_OFFERS_SUCCESS, payload: res.data.offers });
 } catch (err) {
  dispatch({ type: types.GET_OFFERS_FAILURE, payload: err.message });
 }
};

export const addOffer = (offer) => async (dispatch) => {
 dispatch({ type: types.ADD_OFFER_REQUEST });
 try {
  const res = await axios.post(API_URL, offer, {
   headers: {
    "Content-Type": "application/json",
   },
  });
  dispatch({ type: types.ADD_OFFER_SUCCESS, payload: res.data });
  dispatch(fetchOffers());
 } catch (err) {
  dispatch({ type: types.ADD_OFFER_FAILURE, payload: err.message });
 }
};

export const updateOffer = (id, offer) => async (dispatch) => {
 dispatch({ type: types.UPDATE_OFFER_REQUEST });
 try {
  const res = await axios.put(`${API_URL}/${id}`, offer, {
   headers: {
    "Content-Type": "application/json",
   },
  });
  dispatch({ type: types.UPDATE_OFFER_SUCCESS, payload: res.data });
  dispatch(fetchOffers());
 } catch (err) {
  dispatch({ type: types.UPDATE_OFFER_FAILURE, payload: err.message });
 }
};

export const deleteOffer = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_OFFER_REQUEST });
 try {
  await axios.delete(`${API_URL}/${id}`);
  dispatch({ type: types.DELETE_OFFER_SUCCESS, payload: id });
  dispatch(fetchOffers());
 } catch (err) {
  dispatch({ type: types.DELETE_OFFER_FAILURE, payload: err.message });
 }
};

import { useDispatch, useSelector } from "react-redux";
import {
 Box,
 Button,
 TextField,
 Typography,
 IconButton,
 Modal,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 CircularProgress,
 Select,
 MenuItem,
 Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
 addOffer,
 deleteOffer,
 fetchOffers,
 updateOffer,
} from "../../redux/advertisement/action";
import { getSubCategories } from "../../redux/products/action";
import AWS from "aws-sdk";
import { useEffect, useState } from "react";

const useFileUpload = () => {
 const [isUploading, setIsUploading] = useState(false);
 const [error, setError] = useState(null);
 const [uploadedFileUrl, setUploadedFileUrl] = useState("");

 const uploadFile = async (file, callback) => {
  setIsUploading(true);
  setError(null);

  const S3 = new AWS.S3({
   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
   region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
   Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
   Key: `${Date.now()}-${file.name}`,
   Body: file,
   ContentType: file.type,
  };

  try {
   const uploadResult = await S3.upload(params).promise();
   setUploadedFileUrl(uploadResult.Location);
   if (callback) callback(uploadResult.Location);
  } catch (err) {
   setError(err.message);
  } finally {
   setIsUploading(false);
  }
 };

 return {
  uploadFile,
  isUploading,
  error,
  uploadedFileUrl,
  setUploadedFileUrl,
 };
};

const OfferBanners = () => {
 const dispatch = useDispatch();
 const { offers, isLoading, isError } = useSelector(
  (state) => state.advertisement
 );
 const { subCategories } = useSelector((state) => state.product);
 const [modalOpen, setModalOpen] = useState(false);
 const [editMode, setEditMode] = useState(false);
 const [currentOffer, setCurrentOffer] = useState({
  title: "",
  description: "",
  imageUrl: "",
  startDate: "",
  endDate: "",
  subCategory: "",
  discountPercent: 0,
 });
 const [selectedSubCategory, setSelectedSubCategory] = useState("");

 const { uploadFile, isUploading, error, uploadedFileUrl, setUploadedFileUrl } =
  useFileUpload();

 useEffect(() => {
  dispatch(fetchOffers());
  dispatch(getSubCategories());
 }, [dispatch]);

 useEffect(() => {
  if (uploadedFileUrl) {
   setCurrentOffer((prev) => ({ ...prev, imageUrl: uploadedFileUrl }));
  }
 }, [uploadedFileUrl]);

 const handleOpen = () => {
  setEditMode(false);
  setCurrentOffer({
   title: "",
   description: "",
   imageUrl: "",
   startDate: "",
   endDate: "",
   subCategory: "",
   discountPercent: 0,
  });
  setSelectedSubCategory("");
  setUploadedFileUrl("");
  setModalOpen(true);
 };

 const handleClose = () => setModalOpen(false);

 const handleChange = (e) => {
  const { name, value } = e.target;
  setCurrentOffer((prev) => ({ ...prev, [name]: value }));
 };

 const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   uploadFile(file);
  }
 };

 const handleSubmit = () => {
  const offerData = { ...currentOffer, subCategory: selectedSubCategory };
  console.log("Submitting offer:", offerData); // Add console log here
  if (editMode) {
   dispatch(updateOffer(currentOffer._id, offerData));
  } else {
   dispatch(addOffer(offerData));
  }
  handleClose();
 };

 const handleEdit = (offer) => {
  setEditMode(true);
  setCurrentOffer(offer);
  setSelectedSubCategory(offer.subCategory);
  setUploadedFileUrl(offer.imageUrl);
  setModalOpen(true);
 };

 const handleDelete = (id) => {
  dispatch(deleteOffer(id));
 };

 const handleSubCategoryChange = (e) => {
  setSelectedSubCategory(e.target.value);
 };

 console.log("subcategories:", subCategories);
 return (
  <Box>
   <Typography variant="h4" gutterBottom>
    Homepage Banner
   </Typography>
   <Button variant="contained" color="primary" onClick={handleOpen}>
    Add Homepage Banner
   </Button>
   {isLoading ? (
    <Typography>Loading...</Typography>
   ) : isError ? (
    <Typography>Error loading offers</Typography>
   ) : (
    <TableContainer component={Paper}>
     <Table>
      <TableHead>
       <TableRow>
        <TableCell>Title</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Image</TableCell>
        <TableCell>Start Date</TableCell>
        <TableCell>End Date</TableCell>
        <TableCell>Sub Category</TableCell>
        <TableCell>Discount Percent</TableCell>
        <TableCell>Actions</TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {offers.map((offer) => (
        <TableRow key={offer._id}>
         <TableCell>{offer.title}</TableCell>
         <TableCell>{offer.description}</TableCell>
         <TableCell>
          <img
           src={offer.imageUrl}
           alt={offer.title}
           style={{ width: "100px", height: "auto" }}
          />
         </TableCell>
         <TableCell>{new Date(offer.startDate).toLocaleDateString()}</TableCell>
         <TableCell>{new Date(offer.endDate).toLocaleDateString()}</TableCell>
         <TableCell>{offer?.subCategory?.subCategoryName}</TableCell>
         <TableCell>{offer.discountPercent}%</TableCell>
         <TableCell>
          <IconButton onClick={() => handleEdit(offer)}>
           <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(offer._id)}>
           <DeleteIcon />
          </IconButton>
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
   )}
   <Modal open={modalOpen} onClose={handleClose}>
    <Box
     sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", xl: "40%" },
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
     }}
    >
     <Typography variant="h6" gutterBottom>
      {editMode ? "Edit Offer" : "Add Offer"}
     </Typography>
     <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
       <TextField
        label="Title"
        name="title"
        value={currentOffer.title}
        onChange={handleChange}
        fullWidth
        margin="normal"
       />
      </Grid>
      <Grid item xs={12} sm={6}>
       <TextField
        label="Description"
        name="description"
        value={currentOffer.description}
        onChange={handleChange}
        fullWidth
        margin="normal"
       />
      </Grid>
      <Grid item xs={12} sm={6}>
       <TextField
        label="Start Date"
        name="startDate"
        type="date"
        value={currentOffer.startDate}
        onChange={handleChange}
        fullWidth
        margin="normal"
        InputLabelProps={{
         shrink: true,
        }}
       />
      </Grid>
      <Grid item xs={12} sm={6}>
       <TextField
        label="End Date"
        name="endDate"
        type="date"
        value={currentOffer.endDate}
        onChange={handleChange}
        fullWidth
        margin="normal"
        InputLabelProps={{
         shrink: true,
        }}
       />
      </Grid>
      <Grid item xs={12} sm={6}>
       <TextField
        label="Discount Percent"
        name="discountPercent"
        type="number"
        value={currentOffer.discountPercent}
        onChange={handleChange}
        fullWidth
        margin="normal"
        InputProps={{
         inputProps: { min: 0, max: 100 },
        }}
       />
      </Grid>
      <Grid item xs={12} sm={6}>
       <Select
        value={selectedSubCategory}
        onChange={handleSubCategoryChange}
        fullWidth
        margin="normal"
       >
        {subCategories?.map((subcategory) => (
         <MenuItem key={subcategory._id} value={subcategory._id}>
          {subcategory.subCategoryName}
         </MenuItem>
        ))}
       </Select>
      </Grid>
      <Grid item xs={12}>
       <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ margin: "20px 0" }}
       />
       {isUploading ? (
        <CircularProgress />
       ) : (
        uploadedFileUrl && (
         <img
          src={uploadedFileUrl}
          alt="Uploaded file"
          style={{ width: "100%", height: "auto", marginBottom: "20px" }}
         />
        )
       )}
       {error && <Typography color="error">{error}</Typography>}
      </Grid>
      <Grid item xs={12}>
       <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isUploading}
        fullWidth
       >
        {editMode ? "Update" : "Add"}
       </Button>
      </Grid>
     </Grid>
    </Box>
   </Modal>
  </Box>
 );
};

export default OfferBanners;

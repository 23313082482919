import axios from "axios";
import * as types from "./actionTypes";

const API_URL = "https://app-api.carexpert.org.in/api/reviews"; // Adjust the URL as per your backend API endpoint

export const fetchReviews = () => async (dispatch) => {
 dispatch({ type: types.GET_REVIEWS_REQUEST });
 try {
  const res = await axios.get(API_URL);
  dispatch({ type: types.GET_REVIEWS_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_REVIEWS_FAILURE, payload: err.message });
 }
};

export const addReview = (formData) => async (dispatch) => {
 dispatch({ type: types.ADD_REVIEW_REQUEST });
 try {
  const res = await axios.post(`${API_URL}`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.ADD_REVIEW_SUCCESS, payload: res.data });
  dispatch(fetchReviews());
 } catch (err) {
  dispatch({ type: types.ADD_REVIEW_FAILURE, payload: err.message });
 }
};

export const updateReview = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_REVIEW_REQUEST });
 try {
  const res = await axios.put(`${API_URL}/${id}`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.UPDATE_REVIEW_SUCCESS, payload: res.data });
  dispatch(fetchReviews());
 } catch (err) {
  dispatch({ type: types.UPDATE_REVIEW_FAILURE, payload: err.message });
 }
};

export const deleteReview = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_REVIEW_REQUEST });
 try {
  await axios.delete(`${API_URL}/${id}`);
  dispatch({ type: types.DELETE_REVIEW_SUCCESS, payload: id });
  dispatch(fetchReviews());
 } catch (err) {
  dispatch({ type: types.DELETE_REVIEW_FAILURE, payload: err.message });
 }
};

const API_URL_PATNER = "https://app-api.carexpert.org.in/api/partner-reviews"; // Adjust the URL as per your backend API endpoint

export const fetchPartnerReviews = () => async (dispatch) => {
 dispatch({ type: types.GET_PARTNER_REVIEWS_REQUEST });
 try {
  const res = await axios.get(API_URL_PATNER);
  dispatch({ type: types.GET_PARTNER_REVIEWS_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_PARTNER_REVIEWS_FAILURE, payload: err.message });
 }
};

export const addPartnerReview = (formData) => async (dispatch) => {
 dispatch({ type: types.ADD_PARTNER_REVIEW_REQUEST });
 try {
  const res = await axios.post(`${API_URL_PATNER}`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.ADD_PARTNER_REVIEW_SUCCESS, payload: res.data });
  dispatch(fetchPartnerReviews());
 } catch (err) {
  dispatch({ type: types.ADD_PARTNER_REVIEW_FAILURE, payload: err.message });
 }
};

export const updatePartnerReview = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_PARTNER_REVIEW_REQUEST });
 try {
  const res = await axios.put(`${API_URL_PATNER}/${id}`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.UPDATE_PARTNER_REVIEW_SUCCESS, payload: res.data });
  dispatch(fetchPartnerReviews());
 } catch (err) {
  dispatch({ type: types.UPDATE_PARTNER_REVIEW_FAILURE, payload: err.message });
 }
};

export const deletePartnerReview = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_PARTNER_REVIEW_REQUEST });
 try {
  await axios.delete(`${API_URL_PATNER}/${id}`);
  dispatch({ type: types.DELETE_PARTNER_REVIEW_SUCCESS, payload: id });
  dispatch(fetchPartnerReviews());
 } catch (err) {
  dispatch({ type: types.DELETE_PARTNER_REVIEW_FAILURE, payload: err.message });
 }
};

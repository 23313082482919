import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 Button,
 TextField,
 Select,
 MenuItem,
 Checkbox,
 FormControl,
 InputLabel,
 FormControlLabel,
 Typography,
 Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../redux/admin/action";
import {
 fetchCarByBrand,
 fetchCarsByBrandAndTitle,
 getProducts,
} from "../../redux/products/action";

const CarPriceForm = ({ open, onClose, onSubmit }) => {
 const dispatch = useDispatch();
 const { brands } = useSelector((store) => store.admin);
 const { carByBrand, carByTitleAndBrand, products } = useSelector(
  (store) => store.product
 );
 const { handleSubmit, control, reset, watch } = useForm({
  defaultValues: {
   brandId: "",
   carIds: [],
   productId: "",
   mrp: "",
   givenPrice: "",
   prices: [],
  },
 });

 const brandId = watch("brandId");
 const productId = watch("productId");
 const mrp = watch("mrp");
 const givenPrice = watch("givenPrice");
 const [selectedCar, setSelectedCar] = useState("");
 const [selectedVariants, setSelectedVariants] = useState([]);

 useEffect(() => {
  if (brandId) {
   dispatch(fetchCarByBrand(brandId));
  }
 }, [dispatch, brandId]);

 useEffect(() => {
  if (brands.length <= 0) {
   dispatch(getBrands());
  }
 }, [dispatch, brands.length]);

 useEffect(() => {
  if (products?.length <= 0) {
   dispatch(getProducts());
  }
 }, [dispatch, products.length]);

 useEffect(() => {
  if (brandId && selectedCar) {
   dispatch(fetchCarsByBrandAndTitle(brandId, selectedCar));
  }
 }, [brandId, selectedCar, dispatch]);

 const handleVariantSelect = (event, variant) => {
  if (event.target.checked) {
   setSelectedVariants((prev) => [...prev, variant]);
  } else {
   setSelectedVariants((prev) => prev.filter((v) => v._id !== variant._id));
  }
 };

 const submitHandler = (data) => {
  const prices = selectedVariants.map((variant) => ({
   carId: variant._id,
   variant: variant.fuelType,
   transmission: variant.transmissionType,
   mrp: data.mrp,
   givenPrice: data.givenPrice,
  }));

  const formattedData = {
   carIds: selectedVariants.map((variant) => variant._id),
   productId: data.productId,
   prices: prices,
  };

  console.log("Submitted data:", formattedData);
  onSubmit(formattedData);
 };

 return (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
   <DialogTitle>Add Car Price</DialogTitle>
   <DialogContent>
    <form onSubmit={handleSubmit(submitHandler)}>
     <FormControl fullWidth margin="normal">
      <InputLabel>Brand</InputLabel>
      <Controller
       name="brandId"
       control={control}
       render={({ field }) => (
        <Select {...field} fullWidth>
         {brands.map((brand) => (
          <MenuItem key={brand._id} value={brand._id}>
           {brand.title}
          </MenuItem>
         ))}
        </Select>
       )}
      />
     </FormControl>
     {brandId && (
      <FormControl fullWidth margin="normal">
       <InputLabel>Car Model</InputLabel>
       <Select
        value={selectedCar}
        onChange={(e) => setSelectedCar(e.target.value)}
       >
        {carByBrand?.data?.map((car) => (
         <MenuItem key={car._id} value={car.title}>
          {car.title}
         </MenuItem>
        ))}
       </Select>
      </FormControl>
     )}
     {carByTitleAndBrand && (
      <Box>
       <Typography variant="h6">Select Variants</Typography>
       {carByTitleAndBrand.data
        ?.filter((car) => car.title === selectedCar)
        .map((variant) => (
         <FormControlLabel
          key={variant._id}
          control={
           <Checkbox
            checked={selectedVariants.some((v) => v._id === variant._id)}
            onChange={(e) => handleVariantSelect(e, variant)}
           />
          }
          label={`${variant.transmissionType} - ${variant.fuelType}`}
         />
        ))}
      </Box>
     )}
     <FormControl fullWidth margin="normal">
      <InputLabel>Product</InputLabel>
      <Controller
       name="productId"
       control={control}
       render={({ field }) => (
        <Select {...field} fullWidth>
         {products.map((product) => (
          <MenuItem key={product._id} value={product._id}>
           {product.productName}
          </MenuItem>
         ))}
        </Select>
       )}
      />
     </FormControl>
     <Controller
      name="mrp"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        label="MRP"
        type="number"
        fullWidth
        margin="normal"
       />
      )}
     />
     <Controller
      name="givenPrice"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        label="Given Price"
        type="number"
        fullWidth
        margin="normal"
       />
      )}
     />
     <DialogActions>
      <Button onClick={onClose} color="secondary">
       Cancel
      </Button>
      <Button type="submit" color="primary">
       Submit
      </Button>
     </DialogActions>
    </form>
   </DialogContent>
  </Dialog>
 );
};

export default CarPriceForm;
 
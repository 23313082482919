import React, { useEffect, useState } from "react";
import axios from "axios";

const CODVisibilityCard = () => {
 const [showCOD, setShowCOD] = useState(null);
 const [isEditing, setIsEditing] = useState(false);

 useEffect(() => {
  // Fetch the current COD visibility setting when the component mounts
  axios
   .get("https://app-api.carexpert.org.in/api/admin-settings/cod-visibility")
   .then((response) => {
    if (response.data) {
     setShowCOD(response.data.showCOD);
    }
   })
   .catch((error) => {
    console.error("Error fetching COD visibility:", error);
   });
 }, []);

 const handleUpdateCODVisibility = () => {
  axios
   .post("https://app-api.carexpert.org.in/api/admin-settings/cod-visibility", {
    showCOD,
   })
   .then((response) => {
    setShowCOD(response.data.showCOD);
    setIsEditing(false);
    alert("COD visibility updated successfully");
   })
   .catch((error) => {
    console.error("Error updating COD visibility:", error);
    alert("Failed to update COD visibility");
   });
 };

 return (
  <div className="card">
   <h3>COD Visibility</h3>
   {isEditing ? (
    <>
     <label>
      <input
       type="checkbox"
       checked={showCOD}
       onChange={(e) => setShowCOD(e.target.checked)}
      />
      Show COD
     </label>
     <button onClick={handleUpdateCODVisibility}>Save</button>
     <button onClick={() => setIsEditing(false)}>Cancel</button>
    </>
   ) : (
    <>
     <p>Current COD Visibility: {showCOD ? "Enabled" : "Disabled"}</p>
     <button onClick={() => setIsEditing(true)}>Edit</button>
    </>
   )}
  </div>
 );
};

export default CODVisibilityCard;

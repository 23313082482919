import axios from "axios";
import * as types from "./actionTypes";

// Refer and Earn Actions
export const fetchReferAndEarns = () => async (dispatch) => {
 dispatch({ type: types.GET_REFER_AND_EARN_REQUEST });
 try {
  const res = await axios.get(
   "https://app-api.carexpert.org.in/api/refer-and-earn"
  );
  dispatch({ type: types.GET_REFER_AND_EARN_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_REFER_AND_EARN_FAILURE, payload: err.message });
 }
};

export const addReferAndEarn = (formData) => async (dispatch) => {
 dispatch({ type: types.ADD_REFER_AND_EARN_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/refer-and-earn",
   formData
  );
  dispatch({ type: types.ADD_REFER_AND_EARN_SUCCESS, payload: res.data });
  dispatch(fetchReferAndEarns());
 } catch (err) {
  dispatch({ type: types.ADD_REFER_AND_EARN_FAILURE, payload: err.message });
 }
};

export const updateReferAndEarn = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_REFER_AND_EARN_REQUEST });
 try {
  const res = await axios.put(
   `https://app-api.carexpert.org.in/api/refer-and-earn/${id}`,
   formData
  );
  dispatch({ type: types.UPDATE_REFER_AND_EARN_SUCCESS, payload: res.data });
  dispatch(fetchReferAndEarns());
 } catch (err) {
  dispatch({ type: types.UPDATE_REFER_AND_EARN_FAILURE, payload: err.message });
 }
};

export const deleteReferAndEarn = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_REFER_AND_EARN_REQUEST });
 try {
  await axios.delete(
   `https://app-api.carexpert.org.in/api/refer-and-earn/${id}`
  );
  dispatch({ type: types.DELETE_REFER_AND_EARN_SUCCESS, payload: id });
  dispatch(fetchReferAndEarns());
 } catch (err) {
  dispatch({ type: types.DELETE_REFER_AND_EARN_FAILURE, payload: err.message });
 }
};

// Coupon Code Actions
export const fetchCouponCodes = () => async (dispatch) => {
 dispatch({ type: types.GET_COUPON_CODES_REQUEST });
 try {
  const res = await axios.get(
   "https://app-api.carexpert.org.in/api/coupon-codes"
  );
  dispatch({ type: types.GET_COUPON_CODES_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_COUPON_CODES_FAILURE, payload: err.message });
 }
};

export const addCouponCode = (formData) => async (dispatch) => {
 dispatch({ type: types.ADD_COUPON_CODE_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/coupon-codes",
   formData
  );
  dispatch({ type: types.ADD_COUPON_CODE_SUCCESS, payload: res.data });
  dispatch(fetchCouponCodes());
 } catch (err) {
  dispatch({ type: types.ADD_COUPON_CODE_FAILURE, payload: err.message });
 }
};

export const updateCouponCode = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_COUPON_CODE_REQUEST });
 try {
  const res = await axios.put(
   `https://app-api.carexpert.org.in/api/coupon-codes/${id}`,
   formData
  );
  dispatch({ type: types.UPDATE_COUPON_CODE_SUCCESS, payload: res.data });
  dispatch(fetchCouponCodes());
 } catch (err) {
  dispatch({ type: types.UPDATE_COUPON_CODE_FAILURE, payload: err.message });
 }
};

export const deleteCouponCode = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_COUPON_CODE_REQUEST });
 try {
  await axios.delete(`https://app-api.carexpert.org.in/api/coupon-codes/${id}`);
  dispatch({ type: types.DELETE_COUPON_CODE_SUCCESS, payload: id });
  dispatch(fetchCouponCodes());
 } catch (err) {
  dispatch({ type: types.DELETE_COUPON_CODE_FAILURE, payload: err.message });
 }
};

// Wallet Actions
export const fetchWallets = () => async (dispatch) => {
 dispatch({ type: types.GET_WALLETS_REQUEST });
 try {
  const res = await axios.get("https://app-api.carexpert.org.in/api/wallets");
  dispatch({ type: types.GET_WALLETS_SUCCESS, payload: res.data });
 } catch (err) {
  dispatch({ type: types.GET_WALLETS_FAILURE, payload: err.message });
 }
};

export const addWallet = (formData) => async (dispatch) => {
 dispatch({ type: types.ADD_WALLET_REQUEST });
 try {
  const res = await axios.post(
   "https://app-api.carexpert.org.in/api/wallets",
   formData
  );
  dispatch({ type: types.ADD_WALLET_SUCCESS, payload: res.data });
  dispatch(fetchWallets());
 } catch (err) {
  dispatch({ type: types.ADD_WALLET_FAILURE, payload: err.message });
 }
};

export const updateWallet = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_WALLET_REQUEST });
 try {
  const res = await axios.put(
   `https://app-api.carexpert.org.in/api/wallets/${id}`,
   formData
  );
  dispatch({ type: types.UPDATE_WALLET_SUCCESS, payload: res.data });
  dispatch(fetchWallets());
 } catch (err) {
  dispatch({ type: types.UPDATE_WALLET_FAILURE, payload: err.message });
 }
};

export const deleteWallet = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_WALLET_REQUEST });
 try {
  await axios.delete(`https://app-api.carexpert.org.in/api/wallets/${id}`);
  dispatch({ type: types.DELETE_WALLET_SUCCESS, payload: id });
  dispatch(fetchWallets());
 } catch (err) {
  dispatch({ type: types.DELETE_WALLET_FAILURE, payload: err.message });
 }
};

// components/MyForm.js

import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
 TextField,
 IconButton,
 Button,
 Box,
 MenuItem,
 Select,
 FormControl,
 InputLabel,
 Typography,
 Grid,
 Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import AWS from "aws-sdk";
import { getCategories, fetchSubcategories } from "../redux/products/action";

const useFileUpload = () => {
 const [isUploading, setIsUploading] = useState(false);
 const [error, setError] = useState(null);
 const [uploadedFileUrl, setUploadedFileUrl] = useState("");

 const uploadFile = async (file, callback) => {
  setIsUploading(true);
  setError(null);

  const S3 = new AWS.S3({
   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
   region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
   Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
   Key: `${Date.now()}-${file.name}`,
   Body: file,
   ContentType: file.type,
  };

  try {
   const uploadResult = await S3.upload(params).promise();
   setUploadedFileUrl(uploadResult.Location);
   if (callback) callback(uploadResult.Location);
  } catch (err) {
   setError(err.message);
  } finally {
   setIsUploading(false);
  }
 };

 return { uploadFile, isUploading, error, uploadedFileUrl, setUploadedFileUrl };
};

const MyForm = ({ onSubmit, product }) => {
 const dispatch = useDispatch();
 const { categories = [], subCatById = [] } = useSelector(
  (state) => state.product
 );

 useEffect(() => {
  dispatch(getCategories());
 }, [dispatch]);

 const { control, handleSubmit, setValue, reset, watch, getValues } = useForm({
  defaultValues: {
   categoryId: "",
   subCategoryId: "",
   productName: "",
   productImage: "",
   productBannerImages: [], // Changed to an array of strings
   highlights: [{ text: "", icon: "" }],
   tags: [{ name: "", value: "" }],
   offerTag: [{ name: "", value: "" }],
   includedService: [
    {
     name: "",
     services: [{ title: "", image: "" }],
    },
   ],
   additionalServices: [""],
   stepsAfterBooking: [""],
   ratings: "",
   dummyPriceMrp: "",
   dummyPriceActual: "",
  },
 });

 const watchCategoryId = watch("categoryId");

 useEffect(() => {
  if (watchCategoryId) {
   dispatch(fetchSubcategories(watchCategoryId));
  }
 }, [watchCategoryId, dispatch]);

 useEffect(() => {
  if (product) {
   reset(product);
  }
 }, [product, reset]);

 const {
  fields: includedServiceFields,
  append: appendIncludedService,
  remove: removeIncludedService,
 } = useFieldArray({
  control,
  name: "includedService",
 });

 const {
  fields: highlights,
  append: appendHighlightsField,
  remove: removeHighlightsField,
 } = useFieldArray({
  control,
  name: "highlights",
 });

 const {
  fields: additionalServices,
  append: appendAdditionalService,
  remove: removeAdditionalService,
 } = useFieldArray({
  control,
  name: "additionalServices",
 });

 const {
  fields: stepsAfterBooking,
  append: appendStepsAfterBooking,
  remove: removeStepsAfterBooking,
 } = useFieldArray({
  control,
  name: "stepsAfterBooking",
 });

 const {
  fields: tags,
  append: appendTag,
  remove: removeTag,
 } = useFieldArray({
  control,
  name: "tags",
 });

 const {
  fields: offerTag,
  append: appendOfferTag,
  remove: removeOfferTag,
 } = useFieldArray({
  control,
  name: "offerTag",
 });

 const {
  fields: productBannerImages,
  append: appendProductBannerImage,
  remove: removeProductBannerImage,
 } = useFieldArray({
  control,
  name: "productBannerImages",
 });

 const { uploadFile, isUploading, error } = useFileUpload();

 const handleFileChange = async (name, e) => {
  const file = e.target.files[0];
  if (file) {
   uploadFile(file, (url) => setValue(name, url));
  }
 };

 const handleMultipleFileChange = async (index, e) => {
  const file = e.target.files[0];
  if (file) {
   uploadFile(file, (url) => {
    const values = getValues("productBannerImages");
    values[index] = url; // Store string directly
    setValue("productBannerImages", values);
   });
  }
 };

 const validateForm = () => {
  let isValid = true;
  const checkFields = [
   "productImage",
   "ratings",
   "dummyPriceMrp",
   "dummyPriceActual",
  ];
  checkFields.forEach((field) => {
   if (!watch(field)) {
    isValid = false;
    console.error(`${field} is not filled`);
   }
  });
  return isValid;
 };

 const onSubmitForm = (data) => {
  if (!validateForm()) {
   console.error("Form validation failed");
   return;
  }
  onSubmit(data);
 };

 return (
  <form onSubmit={handleSubmit(onSubmitForm)}>
   <Grid container spacing={3}>
    {/* Product Details Section */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Product Details
     </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
     <Controller
      name="categoryId"
      control={control}
      render={({ field }) => (
       <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        <Select {...field}>
         {categories.map((category) => (
          <MenuItem key={category._id} value={category._id}>
           {category.categoryName}
          </MenuItem>
         ))}
        </Select>
       </FormControl>
      )}
     />
    </Grid>
    <Grid item xs={12} md={6}>
     <Controller
      name="subCategoryId"
      control={control}
      render={({ field }) => (
       <FormControl fullWidth margin="normal">
        <InputLabel>SubCategory</InputLabel>
        <Select {...field}>
         {subCatById.map((subCategory) => (
          <MenuItem key={subCategory._id} value={subCategory._id}>
           {subCategory.subCategoryName}
          </MenuItem>
         ))}
        </Select>
       </FormControl>
      )}
     />
    </Grid>
    <Grid item xs={12} md={6}>
     <Controller
      name="productName"
      control={control}
      render={({ field }) => (
       <TextField label="Product Name" {...field} fullWidth margin="normal" />
      )}
     />
    </Grid>
    <Grid item xs={12} md={6}>
     <Controller
      name="productImage"
      control={control}
      render={({ field }) => (
       <Box mb={2}>
        <TextField
         label="Product Image URL"
         {...field}
         fullWidth
         margin="normal"
         disabled
        />
        <input
         type="file"
         onChange={(e) => handleFileChange("productImage", e)}
        />
        {isUploading && <p>Uploading...</p>}
        {error && <p>Error: {error}</p>}
       </Box>
      )}
     />
    </Grid>

    {/* Dynamic Product Banner Images */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Product Banner Images
     </Typography>
    </Grid>
    {productBannerImages.map((field, index) => (
     <Grid item xs={12} key={field.id}>
      <Box mb={2}>
       <Controller
        name={`productBannerImages[${index}]`}
        control={control}
        render={({ field }) => (
         <TextField
          label={`Product Banner Image URL ${index + 1}`}
          {...field}
          fullWidth
          margin="normal"
          disabled
         />
        )}
       />
       <input
        type="file"
        onChange={(e) => handleMultipleFileChange(index, e)}
       />
       {isUploading && <p>Uploading...</p>}
       {error && <p>Error: {error}</p>}
       <IconButton onClick={() => removeProductBannerImage(index)}>
        <RemoveIcon />
       </IconButton>
      </Box>
     </Grid>
    ))}
    <Grid item xs={12}>
     <Button
      type="button"
      onClick={() => appendProductBannerImage("")}
      startIcon={<AddIcon />}
     >
      Add Product Banner Image
     </Button>
    </Grid>

    {/* Pricing and Ratings Section */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Pricing and Ratings
     </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
     <Controller
      name="dummyPriceMrp"
      control={control}
      render={({ field }) => (
       <TextField
        label="Dummy Price MRP"
        {...field}
        fullWidth
        margin="normal"
       />
      )}
     />
    </Grid>
    <Grid item xs={12} md={6}>
     <Controller
      name="dummyPriceActual"
      control={control}
      render={({ field }) => (
       <TextField
        label="Dummy Price Actual"
        {...field}
        fullWidth
        margin="normal"
       />
      )}
     />
    </Grid>
    <Grid item xs={12} md={6}>
     <Controller
      name="ratings"
      control={control}
      render={({ field }) => (
       <TextField label="Ratings" {...field} fullWidth margin="normal" />
      )}
     />
    </Grid>

    {/* Highlights Section */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Highlights
     </Typography>
    </Grid>
    {highlights.map((field, index) => (
     <Grid item xs={12} key={field.id}>
      <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
       <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
         <Controller
          name={`highlights[${index}].text`}
          control={control}
          render={({ field }) => (
           <TextField label="Highlight Text" {...field} fullWidth />
          )}
         />
        </Grid>
        <Grid item xs={12} md={4}>
         <Controller
          name={`highlights[${index}].icon`}
          control={control}
          render={({ field }) => (
           <Box>
            <TextField
             label="Highlight Icon URL"
             {...field}
             fullWidth
             disabled
            />
            <input
             type="file"
             onChange={(e) => handleFileChange(`highlights[${index}].icon`, e)}
            />
            {isUploading && <p>Uploading...</p>}
            {error && <p>Error: {error}</p>}
           </Box>
          )}
         />
        </Grid>
        <Grid item xs={12} md={2}>
         <IconButton onClick={() => removeHighlightsField(index)}>
          <RemoveIcon />
         </IconButton>
        </Grid>
       </Grid>
      </Paper>
     </Grid>
    ))}
    <Grid item xs={12}>
     <Button
      type="button"
      onClick={() => appendHighlightsField({ text: "", icon: "" })}
      startIcon={<AddIcon />}
     >
      Add Highlight
     </Button>
    </Grid>

    {/* Included Services Section */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Included Services
     </Typography>
    </Grid>
    {includedServiceFields.map((includedService, includedServiceIndex) => (
     <Grid item xs={12} key={includedService.id}>
      <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
       <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
         <Controller
          name={`includedService[${includedServiceIndex}].name`}
          control={control}
          render={({ field }) => (
           <TextField
            label="Service Name"
            {...field}
            fullWidth
            margin="normal"
           />
          )}
         />
        </Grid>
        <Grid item xs={12}>
         <ServiceFields
          control={control}
          includedServiceIndex={includedServiceIndex}
          setValue={setValue}
          getValues={getValues}
         />
        </Grid>
        <Grid item xs={12}>
         <IconButton
          type="button"
          onClick={() => removeIncludedService(includedServiceIndex)}
         >
          <RemoveIcon />
         </IconButton>
        </Grid>
       </Grid>
      </Paper>
     </Grid>
    ))}
    <Grid item xs={12}>
     <Button
      type="button"
      onClick={() =>
       appendIncludedService({
        name: "",
        services: [{ title: "", image: "" }],
       })
      }
      startIcon={<AddIcon />}
     >
      Add Included Service
     </Button>
    </Grid>

    {/* Additional Services Section */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Additional Services
     </Typography>
    </Grid>
    {additionalServices.map((field, index) => (
     <Grid item xs={12} key={field.id}>
      <Grid container spacing={2} alignItems="center">
       <Grid item xs={10}>
        <Controller
         name={`additionalServices[${index}]`}
         control={control}
         render={({ field }) => (
          <TextField
           label={`Additional Service ${index + 1}`}
           {...field}
           fullWidth
           margin="normal"
          />
         )}
        />
       </Grid>
       <Grid item xs={2}>
        <IconButton onClick={() => removeAdditionalService(index)}>
         <RemoveIcon />
        </IconButton>
       </Grid>
      </Grid>
     </Grid>
    ))}
    <Grid item xs={12}>
     <Button
      type="button"
      onClick={() => appendAdditionalService("")}
      startIcon={<AddIcon />}
     >
      Add Additional Service
     </Button>
    </Grid>

    {/* Steps After Booking Section */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Steps After Booking
     </Typography>
    </Grid>
    {stepsAfterBooking.map((field, index) => (
     <Grid item xs={12} key={field.id}>
      <Grid container spacing={2} alignItems="center">
       <Grid item xs={10}>
        <Controller
         name={`stepsAfterBooking[${index}]`}
         control={control}
         render={({ field }) => (
          <TextField
           label={`Step After Booking ${index + 1}`}
           {...field}
           fullWidth
           margin="normal"
          />
         )}
        />
       </Grid>
       <Grid item xs={2}>
        <IconButton onClick={() => removeStepsAfterBooking(index)}>
         <RemoveIcon />
        </IconButton>
       </Grid>
      </Grid>
     </Grid>
    ))}
    <Grid item xs={12}>
     <Button
      type="button"
      onClick={() => appendStepsAfterBooking("")}
      startIcon={<AddIcon />}
     >
      Add Step After Booking
     </Button>
    </Grid>

    {/* Tags Section */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Tags
     </Typography>
    </Grid>
    {tags.map((field, index) => (
     <Grid item xs={12} key={field.id}>
      <Grid container spacing={2} alignItems="center">
       <Grid item xs={5}>
        <Controller
         name={`tags[${index}].name`}
         control={control}
         render={({ field }) => (
          <TextField
           label={`Tag Name ${index + 1}`}
           {...field}
           fullWidth
           margin="normal"
          />
         )}
        />
       </Grid>
       <Grid item xs={5}>
        <Controller
         name={`tags[${index}].value`}
         control={control}
         render={({ field }) => (
          <TextField
           label={`Tag Value ${index + 1}`}
           {...field}
           fullWidth
           margin="normal"
          />
         )}
        />
       </Grid>
       <Grid item xs={2}>
        <IconButton onClick={() => removeTag(index)}>
         <RemoveIcon />
        </IconButton>
       </Grid>
      </Grid>
     </Grid>
    ))}
    <Grid item xs={12}>
     <Button
      type="button"
      onClick={() => appendTag({ name: "", value: "" })}
      startIcon={<AddIcon />}
     >
      Add Tag
     </Button>
    </Grid>

    {/* Offer Tags Section */}
    <Grid item xs={12}>
     <Typography variant="h6" gutterBottom>
      Offer Tags
     </Typography>
    </Grid>
    {offerTag.map((field, index) => (
     <Grid item xs={12} key={field.id}>
      <Grid container spacing={2} alignItems="center">
       <Grid item xs={5}>
        <Controller
         name={`offerTag[${index}].name`}
         control={control}
         render={({ field }) => (
          <TextField
           label={`Offer Tag Name ${index + 1}`}
           {...field}
           fullWidth
           margin="normal"
          />
         )}
        />
       </Grid>
       <Grid item xs={5}>
        <Controller
         name={`offerTag[${index}].value`}
         control={control}
         render={({ field }) => (
          <TextField
           label={`Offer Tag Value ${index + 1}`}
           {...field}
           fullWidth
           margin="normal"
          />
         )}
        />
       </Grid>
       <Grid item xs={2}>
        <IconButton onClick={() => removeOfferTag(index)}>
         <RemoveIcon />
        </IconButton>
       </Grid>
      </Grid>
     </Grid>
    ))}
    <Grid item xs={12}>
     <Button
      type="button"
      onClick={() => appendOfferTag({ name: "", value: "" })}
      startIcon={<AddIcon />}
     >
      Add Offer Tag
     </Button>
    </Grid>

    {/* Submit Button */}
    <Grid item xs={12}>
     <Box mt={3} textAlign="center">
      <Button type="submit" variant="contained" color="primary">
       Submit
      </Button>
     </Box>
    </Grid>
   </Grid>
  </form>
 );
};

const ServiceFields = ({
 control,
 includedServiceIndex,
 setValue,
 getValues,
}) => {
 const {
  fields: serviceFields,
  append: appendService,
  remove: removeService,
 } = useFieldArray({
  control,
  name: `includedService[${includedServiceIndex}].services`,
 });

 const { uploadFile, isUploading, error, uploadedFileUrl, setUploadedFileUrl } =
  useFileUpload();

 useEffect(() => {
  if (uploadedFileUrl) {
   setUploadedFileUrl("");
  }
 }, [uploadedFileUrl, setUploadedFileUrl]);

 const handleFileChange = async (serviceIndex, e) => {
  const file = e.target.files[0];
  if (file) {
   uploadFile(file, (url) =>
    setValue(
     `includedService[${includedServiceIndex}].services[${serviceIndex}].image`,
     url
    )
   );
  }
 };

 return (
  <>
   {serviceFields.map((service, serviceIndex) => (
    <Grid container spacing={2} key={service.id} alignItems="center">
     <Grid item xs={5}>
      <Controller
       name={`includedService[${includedServiceIndex}].services[${serviceIndex}].title`}
       control={control}
       render={({ field }) => (
        <TextField label="Service Title" {...field} fullWidth margin="normal" />
       )}
      />
     </Grid>
     <Grid item xs={5}>
      <Controller
       name={`includedService[${includedServiceIndex}].services[${serviceIndex}].image`}
       control={control}
       render={({ field }) => (
        <>
         <TextField
          label="Service Image URL"
          {...field}
          fullWidth
          margin="normal"
          disabled
         />
         <input
          type="file"
          onChange={(e) => handleFileChange(serviceIndex, e)}
         />
         {isUploading && <p>Uploading...</p>}
         {error && <p>Error: {error}</p>}
        </>
       )}
      />
     </Grid>
     <Grid item xs={2}>
      <IconButton type="button" onClick={() => removeService(serviceIndex)}>
       <RemoveIcon />
      </IconButton>
     </Grid>
    </Grid>
   ))}
   <Button
    type="button"
    onClick={() => appendService({ title: "", image: "" })}
    startIcon={<AddIcon />}
   >
    Add Sub Service
   </Button>
  </>
 );
};

export default MyForm;

// import React, { useEffect, useState } from "react";
// import { useForm, Controller, useFieldArray } from "react-hook-form";
// import {
//  TextField,
//  IconButton,
//  Button,
//  Box,
//  MenuItem,
//  Select,
//  FormControl,
//  InputLabel,
//  Typography,
//  Grid,
//  Paper,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
// import { useDispatch, useSelector } from "react-redux";
// import AWS from "aws-sdk";
// import { getCategories, fetchSubcategories } from "../redux/products/action";

// const useFileUpload = () => {
//  const [isUploading, setIsUploading] = useState(false);
//  const [error, setError] = useState(null);
//  const [uploadedFileUrl, setUploadedFileUrl] = useState("");

//  const uploadFile = async (file, callback) => {
//   setIsUploading(true);
//   setError(null);

//   const S3 = new AWS.S3({
//    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
//    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
//    region: process.env.REACT_APP_AWS_REGION,
//   });

//   const params = {
//    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
//    Key: `${Date.now()}-${file.name}`,
//    Body: file,
//    ContentType: file.type,
//   };

//   try {
//    const uploadResult = await S3.upload(params).promise();
//    setUploadedFileUrl(uploadResult.Location);
//    if (callback) callback(uploadResult.Location);
//   } catch (err) {
//    setError(err.message);
//   } finally {
//    setIsUploading(false);
//   }
//  };

//  return { uploadFile, isUploading, error, uploadedFileUrl, setUploadedFileUrl };
// };

// const MyForm = ({ onSubmit, product }) => {
//  const dispatch = useDispatch();
//  const { categories = [], subCatById = [] } = useSelector(
//   (state) => state.product
//  );

//  useEffect(() => {
//   dispatch(getCategories());
//  }, [dispatch]);

//  const { control, handleSubmit, setValue, reset, watch, getValues } = useForm({
//   defaultValues: {
//    categoryId: "",
//    subCategoryId: "",
//    productName: "",
//    productImage: "",
//    productBannerImage: "",
//    highlights: [{ text: "", icon: "" }],
//    tags: [{ name: "", value: "" }],
//    offerTag: [{ name: "", value: "" }],
//    //    discountPercent: "",
//    includedService: [
//     {
//      name: "",
//      services: [{ title: "", image: "" }],
//     },
//    ],
//    additionalServices: [""],
//    stepsAfterBooking: [""],
//    ratings: "",
//    dummyPriceMrp: "",
//    dummyPriceActual: "",
//   },
//  });

//  const watchCategoryId = watch("categoryId");

//  useEffect(() => {
//   if (watchCategoryId) {
//    dispatch(fetchSubcategories(watchCategoryId));
//   }
//  }, [watchCategoryId, dispatch]);

//  useEffect(() => {
//   if (product) {
//    reset(product);
//   }
//  }, [product, reset]);

//  const {
//   fields: includedServiceFields,
//   append: appendIncludedService,
//   remove: removeIncludedService,
//  } = useFieldArray({
//   control,
//   name: "includedService",
//  });

//  const {
//   fields: highlights,
//   append: appendHighlightsField,
//   remove: removeHighlightsField,
//  } = useFieldArray({
//   control,
//   name: "highlights",
//  });

//  const {
//   fields: additionalServices,
//   append: appendAdditionalService,
//   remove: removeAdditionalService,
//  } = useFieldArray({
//   control,
//   name: "additionalServices",
//  });

//  const {
//   fields: stepsAfterBooking,
//   append: appendStepsAfterBooking,
//   remove: removeStepsAfterBooking,
//  } = useFieldArray({
//   control,
//   name: "stepsAfterBooking",
//  });

//  const {
//   fields: tags,
//   append: appendTag,
//   remove: removeTag,
//  } = useFieldArray({
//   control,
//   name: "tags",
//  });

//  const {
//   fields: offerTag,
//   append: appendOfferTag,
//   remove: removeOfferTag,
//  } = useFieldArray({
//   control,
//   name: "offerTag",
//  });

//  const { uploadFile, isUploading, error } = useFileUpload();

//  const handleFileChange = async (name, e) => {
//   const file = e.target.files[0];
//   if (file) {
//    uploadFile(file, (url) => setValue(name, url));
//   }
//  };

//  const validateForm = () => {
//   let isValid = true;
//   const checkFields = [
//    "productImage",
//    "productBannerImage",
//    //    "discountPercent",
//    "ratings",
//    "dummyPriceMrp",
//    "dummyPriceActual",
//   ];
//   checkFields.forEach((field) => {
//    if (!watch(field)) {
//     isValid = false;
//     console.error(`${field} is not filled`);
//    }
//   });
//   return isValid;
//  };

//  const onSubmitForm = (data) => {
//   if (!validateForm()) {
//    console.error("Form validation failed");
//    return;
//   }
//   onSubmit(data);
//  };

//  return (
//   <form onSubmit={handleSubmit(onSubmitForm)}>
//    <Grid container spacing={3}>
//     {/* Product Details Section */}
//     <Grid item xs={12}>
//      <Typography variant="h6" gutterBottom>
//       Product Details
//      </Typography>
//     </Grid>
//     <Grid item xs={12} md={6}>
//      <Controller
//       name="categoryId"
//       control={control}
//       render={({ field }) => (
//        <FormControl fullWidth margin="normal">
//         <InputLabel>Category</InputLabel>
//         <Select {...field}>
//          {categories.map((category) => (
//           <MenuItem key={category._id} value={category._id}>
//            {category.categoryName}
//           </MenuItem>
//          ))}
//         </Select>
//        </FormControl>
//       )}
//      />
//     </Grid>
//     <Grid item xs={12} md={6}>
//      <Controller
//       name="subCategoryId"
//       control={control}
//       render={({ field }) => (
//        <FormControl fullWidth margin="normal">
//         <InputLabel>SubCategory</InputLabel>
//         <Select {...field}>
//          {subCatById.map((subCategory) => (
//           <MenuItem key={subCategory._id} value={subCategory._id}>
//            {subCategory.subCategoryName}
//           </MenuItem>
//          ))}
//         </Select>
//        </FormControl>
//       )}
//      />
//     </Grid>
//     <Grid item xs={12} md={6}>
//      <Controller
//       name="productName"
//       control={control}
//       render={({ field }) => (
//        <TextField label="Product Name" {...field} fullWidth margin="normal" />
//       )}
//      />
//     </Grid>
//     <Grid item xs={12} md={6}>
//      <Controller
//       name="productImage"
//       control={control}
//       render={({ field }) => (
//        <Box mb={2}>
//         <TextField
//          label="Product Image URL"
//          {...field}
//          fullWidth
//          margin="normal"
//          disabled
//         />
//         <input
//          type="file"
//          onChange={(e) => handleFileChange("productImage", e)}
//         />
//         {isUploading && <p>Uploading...</p>}
//         {error && <p>Error: {error}</p>}
//        </Box>
//       )}
//      />
//     </Grid>
//     <Grid item xs={12} md={6}>
//      <Controller
//       name="productBannerImage"
//       control={control}
//       render={({ field }) => (
//        <Box mb={2}>
//         <TextField
//          label="Product Banner Image URL"
//          {...field}
//          fullWidth
//          margin="normal"
//          disabled
//         />
//         <input
//          type="file"
//          onChange={(e) => handleFileChange("productBannerImage", e)}
//         />
//         {isUploading && <p>Uploading...</p>}
//         {error && <p>Error: {error}</p>}
//        </Box>
//       )}
//      />
//     </Grid>

//     {/* Pricing and Ratings Section */}
//     <Grid item xs={12}>
//      <Typography variant="h6" gutterBottom>
//       Pricing and Ratings
//      </Typography>
//     </Grid>
//     {/* <Grid item xs={12} md={6}>
//      <Controller
//       name="discountPercent"
//       control={control}
//       render={({ field }) => (
//        <TextField
//         label="Discount Percent"
//         {...field}
//         fullWidth
//         margin="normal"
//        />
//       )}
//      />
//     </Grid> */}
//     <Grid item xs={12} md={6}>
//      <Controller
//       name="dummyPriceMrp"
//       control={control}
//       render={({ field }) => (
//        <TextField
//         label="Dummy Price MRP"
//         {...field}
//         fullWidth
//         margin="normal"
//        />
//       )}
//      />
//     </Grid>
//     <Grid item xs={12} md={6}>
//      <Controller
//       name="dummyPriceActual"
//       control={control}
//       render={({ field }) => (
//        <TextField
//         label="Dummy Price Actual"
//         {...field}
//         fullWidth
//         margin="normal"
//        />
//       )}
//      />
//     </Grid>
//     <Grid item xs={12} md={6}>
//      <Controller
//       name="ratings"
//       control={control}
//       render={({ field }) => (
//        <TextField label="Ratings" {...field} fullWidth margin="normal" />
//       )}
//      />
//     </Grid>

//     {/* Highlights Section */}
//     <Grid item xs={12}>
//      <Typography variant="h6" gutterBottom>
//       Highlights
//      </Typography>
//     </Grid>
//     {highlights.map((field, index) => (
//      <Grid item xs={12} key={field.id}>
//       <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
//        <Grid container spacing={2} alignItems="center">
//         <Grid item xs={12} md={6}>
//          <Controller
//           name={`highlights[${index}].text`}
//           control={control}
//           render={({ field }) => (
//            <TextField label="Highlight Text" {...field} fullWidth />
//           )}
//          />
//         </Grid>
//         <Grid item xs={12} md={4}>
//          <Controller
//           name={`highlights[${index}].icon`}
//           control={control}
//           render={({ field }) => (
//            <Box>
//             <TextField
//              label="Highlight Icon URL"
//              {...field}
//              fullWidth
//              disabled
//             />
//             <input
//              type="file"
//              onChange={(e) => handleFileChange(`highlights[${index}].icon`, e)}
//             />
//             {isUploading && <p>Uploading...</p>}
//             {error && <p>Error: {error}</p>}
//            </Box>
//           )}
//          />
//         </Grid>
//         <Grid item xs={12} md={2}>
//          <IconButton onClick={() => removeHighlightsField(index)}>
//           <RemoveIcon />
//          </IconButton>
//         </Grid>
//        </Grid>
//       </Paper>
//      </Grid>
//     ))}
//     <Grid item xs={12}>
//      <Button
//       type="button"
//       onClick={() => appendHighlightsField({ text: "", icon: "" })}
//       startIcon={<AddIcon />}
//      >
//       Add Highlight
//      </Button>
//     </Grid>

//     {/* Included Services Section */}
//     <Grid item xs={12}>
//      <Typography variant="h6" gutterBottom>
//       Included Services
//      </Typography>
//     </Grid>
//     {includedServiceFields.map((includedService, includedServiceIndex) => (
//      <Grid item xs={12} key={includedService.id}>
//       <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
//        <Grid container spacing={2} alignItems="center">
//         <Grid item xs={12}>
//          <Controller
//           name={`includedService[${includedServiceIndex}].name`}
//           control={control}
//           render={({ field }) => (
//            <TextField
//             label="Service Name"
//             {...field}
//             fullWidth
//             margin="normal"
//            />
//           )}
//          />
//         </Grid>
//         <Grid item xs={12}>
//          <ServiceFields
//           control={control}
//           includedServiceIndex={includedServiceIndex}
//           setValue={setValue}
//           getValues={getValues}
//          />
//         </Grid>
//         <Grid item xs={12}>
//          <IconButton
//           type="button"
//           onClick={() => removeIncludedService(includedServiceIndex)}
//          >
//           <RemoveIcon />
//          </IconButton>
//         </Grid>
//        </Grid>
//       </Paper>
//      </Grid>
//     ))}
//     <Grid item xs={12}>
//      <Button
//       type="button"
//       onClick={() =>
//        appendIncludedService({ name: "", services: [{ title: "", image: "" }] })
//       }
//       startIcon={<AddIcon />}
//      >
//       Add Included Service
//      </Button>
//     </Grid>

//     {/* Additional Services Section */}
//     <Grid item xs={12}>
//      <Typography variant="h6" gutterBottom>
//       Additional Services
//      </Typography>
//     </Grid>
//     {additionalServices.map((field, index) => (
//      <Grid item xs={12} key={field.id}>
//       <Grid container spacing={2} alignItems="center">
//        <Grid item xs={10}>
//         <Controller
//          name={`additionalServices[${index}]`}
//          control={control}
//          render={({ field }) => (
//           <TextField
//            label={`Additional Service ${index + 1}`}
//            {...field}
//            fullWidth
//            margin="normal"
//           />
//          )}
//         />
//        </Grid>
//        <Grid item xs={2}>
//         <IconButton onClick={() => removeAdditionalService(index)}>
//          <RemoveIcon />
//         </IconButton>
//        </Grid>
//       </Grid>
//      </Grid>
//     ))}
//     <Grid item xs={12}>
//      <Button
//       type="button"
//       onClick={() => appendAdditionalService("")}
//       startIcon={<AddIcon />}
//      >
//       Add Additional Service
//      </Button>
//     </Grid>

//     {/* Steps After Booking Section */}
//     <Grid item xs={12}>
//      <Typography variant="h6" gutterBottom>
//       Steps After Booking
//      </Typography>
//     </Grid>
//     {stepsAfterBooking.map((field, index) => (
//      <Grid item xs={12} key={field.id}>
//       <Grid container spacing={2} alignItems="center">
//        <Grid item xs={10}>
//         <Controller
//          name={`stepsAfterBooking[${index}]`}
//          control={control}
//          render={({ field }) => (
//           <TextField
//            label={`Step After Booking ${index + 1}`}
//            {...field}
//            fullWidth
//            margin="normal"
//           />
//          )}
//         />
//        </Grid>
//        <Grid item xs={2}>
//         <IconButton onClick={() => removeStepsAfterBooking(index)}>
//          <RemoveIcon />
//         </IconButton>
//        </Grid>
//       </Grid>
//      </Grid>
//     ))}
//     <Grid item xs={12}>
//      <Button
//       type="button"
//       onClick={() => appendStepsAfterBooking("")}
//       startIcon={<AddIcon />}
//      >
//       Add Step After Booking
//      </Button>
//     </Grid>

//     {/* Tags Section */}
//     <Grid item xs={12}>
//      <Typography variant="h6" gutterBottom>
//       Tags
//      </Typography>
//     </Grid>
//     {tags.map((field, index) => (
//      <Grid item xs={12} key={field.id}>
//       <Grid container spacing={2} alignItems="center">
//        <Grid item xs={5}>
//         <Controller
//          name={`tags[${index}].name`}
//          control={control}
//          render={({ field }) => (
//           <TextField
//            label={`Tag Name ${index + 1}`}
//            {...field}
//            fullWidth
//            margin="normal"
//           />
//          )}
//         />
//        </Grid>
//        <Grid item xs={5}>
//         <Controller
//          name={`tags[${index}].value`}
//          control={control}
//          render={({ field }) => (
//           <TextField
//            label={`Tag Value ${index + 1}`}
//            {...field}
//            fullWidth
//            margin="normal"
//           />
//          )}
//         />
//        </Grid>
//        <Grid item xs={2}>
//         <IconButton onClick={() => removeTag(index)}>
//          <RemoveIcon />
//         </IconButton>
//        </Grid>
//       </Grid>
//      </Grid>
//     ))}
//     <Grid item xs={12}>
//      <Button
//       type="button"
//       onClick={() => appendTag({ name: "", value: "" })}
//       startIcon={<AddIcon />}
//      >
//       Add Tag
//      </Button>
//     </Grid>

//     {/* Offer Tags Section */}
//     <Grid item xs={12}>
//      <Typography variant="h6" gutterBottom>
//       Offer Tags
//      </Typography>
//     </Grid>
//     {offerTag.map((field, index) => (
//      <Grid item xs={12} key={field.id}>
//       <Grid container spacing={2} alignItems="center">
//        <Grid item xs={5}>
//         <Controller
//          name={`offerTag[${index}].name`}
//          control={control}
//          render={({ field }) => (
//           <TextField
//            label={`Offer Tag Name ${index + 1}`}
//            {...field}
//            fullWidth
//            margin="normal"
//           />
//          )}
//         />
//        </Grid>
//        <Grid item xs={5}>
//         <Controller
//          name={`offerTag[${index}].value`}
//          control={control}
//          render={({ field }) => (
//           <TextField
//            label={`Offer Tag Value ${index + 1}`}
//            {...field}
//            fullWidth
//            margin="normal"
//           />
//          )}
//         />
//        </Grid>
//        <Grid item xs={2}>
//         <IconButton onClick={() => removeOfferTag(index)}>
//          <RemoveIcon />
//         </IconButton>
//        </Grid>
//       </Grid>
//      </Grid>
//     ))}
//     <Grid item xs={12}>
//      <Button
//       type="button"
//       onClick={() => appendOfferTag({ name: "", value: "" })}
//       startIcon={<AddIcon />}
//      >
//       Add Offer Tag
//      </Button>
//     </Grid>

//     {/* Submit Button */}
//     <Grid item xs={12}>
//      <Box mt={3} textAlign="center">
//       <Button type="submit" variant="contained" color="primary">
//        Submit
//       </Button>
//      </Box>
//     </Grid>
//    </Grid>
//   </form>
//  );
// };

// const ServiceFields = ({
//  control,
//  includedServiceIndex,
//  setValue,
//  getValues,
// }) => {
//  const {
//   fields: serviceFields,
//   append: appendService,
//   remove: removeService,
//  } = useFieldArray({
//   control,
//   name: `includedService[${includedServiceIndex}].services`,
//  });

//  const { uploadFile, isUploading, error, uploadedFileUrl, setUploadedFileUrl } =
//   useFileUpload();

//  useEffect(() => {
//   if (uploadedFileUrl) {
//    setUploadedFileUrl("");
//   }
//  }, [uploadedFileUrl, setUploadedFileUrl]);

//  const handleFileChange = async (serviceIndex, e) => {
//   const file = e.target.files[0];
//   if (file) {
//    uploadFile(file, (url) =>
//     setValue(
//      `includedService[${includedServiceIndex}].services[${serviceIndex}].image`,
//      url
//     )
//    );
//   }
//  };

//  return (
//   <>
//    {serviceFields.map((service, serviceIndex) => (
//     <Grid container spacing={2} key={service.id} alignItems="center">
//      <Grid item xs={5}>
//       <Controller
//        name={`includedService[${includedServiceIndex}].services[${serviceIndex}].title`}
//        control={control}
//        render={({ field }) => (
//         <TextField label="Service Title" {...field} fullWidth margin="normal" />
//        )}
//       />
//      </Grid>
//      <Grid item xs={5}>
//       <Controller
//        name={`includedService[${includedServiceIndex}].services[${serviceIndex}].image`}
//        control={control}
//        render={({ field }) => (
//         <>
//          <TextField
//           label="Service Image URL"
//           {...field}
//           fullWidth
//           margin="normal"
//           disabled
//          />
//          <input
//           type="file"
//           onChange={(e) => handleFileChange(serviceIndex, e)}
//          />
//          {isUploading && <p>Uploading...</p>}
//          {error && <p>Error: {error}</p>}
//         </>
//        )}
//       />
//      </Grid>
//      <Grid item xs={2}>
//       <IconButton type="button" onClick={() => removeService(serviceIndex)}>
//        <RemoveIcon />
//       </IconButton>
//      </Grid>
//     </Grid>
//    ))}
//    <Button
//     type="button"
//     onClick={() => appendService({ title: "", image: "" })}
//     startIcon={<AddIcon />}
//    >
//     Add Sub Service
//    </Button>
//   </>
//  );
// };

// export default MyForm;

// src/redux/city/actionTypes.js
export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_FAILURE = "ADD_CITY_FAILURE";

export const UPDATE_CITY_REQUEST = "UPDATE_CITY_REQUEST";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAILURE = "UPDATE_CITY_FAILURE";

export const DELETE_CITY_REQUEST = "DELETE_CITY_REQUEST";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_FAILURE = "DELETE_CITY_FAILURE";

export const VOTE_CITY_REQUEST = "VOTE_CITY_REQUEST";
export const VOTE_CITY_SUCCESS = "VOTE_CITY_SUCCESS";
export const VOTE_CITY_FAILURE = "VOTE_CITY_FAILURE";

// FAQ Action Types
export const GET_FAQS_REQUEST = "GET_FAQS_REQUEST";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAILURE = "GET_FAQS_FAILURE";

export const ADD_FAQ_REQUEST = "ADD_FAQ_REQUEST";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_FAILURE = "ADD_FAQ_FAILURE";

export const UPDATE_FAQ_REQUEST = "UPDATE_FAQ_REQUEST";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAILURE = "UPDATE_FAQ_FAILURE";

export const DELETE_FAQ_REQUEST = "DELETE_FAQ_REQUEST";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAILURE = "DELETE_FAQ_FAILURE";

export const TOGGLE_SERVICE_STATUS_REQUEST = "TOGGLE_SERVICE_STATUS_REQUEST";
export const TOGGLE_SERVICE_STATUS_SUCCESS = "TOGGLE_SERVICE_STATUS_SUCCESS";
export const TOGGLE_SERVICE_STATUS_FAILURE = "TOGGLE_SERVICE_STATUS_FAILURE";

export const REMOVE_PINCODE_REQUEST = "REMOVE_PINCODE_REQUEST";
export const REMOVE_PINCODE_SUCCESS = "REMOVE_PINCODE_SUCCESS";
export const REMOVE_PINCODE_FAILURE = "REMOVE_PINCODE_FAILURE";

import * as types from "./actionTypes";

const initialState = {
 cities: [],
 faqs: [],
 isLoading: false,
 isError: false,
};

const reducer = (state = initialState, action) => {
 const { payload, type } = action;
 switch (type) {
  case types.GET_CITIES_REQUEST:
  case types.ADD_CITY_REQUEST:
  case types.UPDATE_CITY_REQUEST:
  case types.DELETE_CITY_REQUEST:
  case types.VOTE_CITY_REQUEST:
  case types.GET_FAQS_REQUEST:
  case types.ADD_FAQ_REQUEST:
  case types.UPDATE_FAQ_REQUEST:
  case types.DELETE_FAQ_REQUEST:
  case types.REMOVE_PINCODE_REQUEST:
  case types.TOGGLE_SERVICE_STATUS_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };

  case types.GET_CITIES_SUCCESS:
   return {
    ...state,
    isLoading: false,
    cities: action.payload,
   };

  case types.ADD_CITY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    cities: [...state.cities, action.payload],
   };

  case types.UPDATE_CITY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    cities: state.cities.map((city) =>
     city._id === payload._id ? payload : city
    ),
   };

  case types.DELETE_CITY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    cities: state.cities.filter((city) => city._id !== action.payload),
   };

  case types.VOTE_CITY_SUCCESS:
   return {
    ...state,
    isLoading: false,
   };

  case types.TOGGLE_SERVICE_STATUS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    cities: state.cities.map((city) =>
     city._id === payload._id
      ? { ...city, isServiced: payload.isServiced }
      : city
    ),
   };

  case types.REMOVE_PINCODE_SUCCESS:
   return {
    ...state,
    isLoading: false,
    cities: state.cities.map((city) =>
     city._id === payload._id ? { ...city, pincodes: payload.pincodes } : city
    ),
   };

  case types.GET_FAQS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    faqs: payload,
   };

  case types.ADD_FAQ_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    faqs: [...state.faqs, payload],
   };

  case types.UPDATE_FAQ_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    faqs: state.faqs.map((faq) => (faq._id === payload._id ? payload : faq)),
   };

  case types.DELETE_FAQ_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    faqs: state.faqs.filter((faq) => faq._id !== payload),
   };

  case types.TOGGLE_SERVICE_STATUS_FAILURE:
  case types.REMOVE_PINCODE_FAILURE:
  case types.GET_CITIES_FAILURE:
  case types.ADD_CITY_FAILURE:
  case types.UPDATE_CITY_FAILURE:
  case types.DELETE_CITY_FAILURE:
  case types.VOTE_CITY_FAILURE:
  case types.GET_FAQS_FAILURE:
  case types.ADD_FAQ_FAILURE:
  case types.UPDATE_FAQ_FAILURE:
  case types.DELETE_FAQ_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  default:
   return state;
 }
};

export { reducer };

// import * as types from "./actionTypes";

// const initialState = {
//  cities: [],
//  faqs: [],
//  isLoading: false,
//  isError: false,
// };

// const reducer = (state = initialState, action) => {
//  const { payload, type } = action;
//  switch (type) {
//   case types.GET_CITIES_REQUEST:
//   case types.ADD_CITY_REQUEST:
//   case types.UPDATE_CITY_REQUEST:
//   case types.DELETE_CITY_REQUEST:
//   case types.VOTE_CITY_REQUEST:
//   case types.GET_FAQS_REQUEST:
//   case types.ADD_FAQ_REQUEST:
//   case types.UPDATE_FAQ_REQUEST:
//   case types.DELETE_FAQ_REQUEST:
//   case types.REMOVE_PINCODE_REQUEST:
//   case types.TOGGLE_SERVICE_STATUS_REQUEST:
//    return {
//     ...state,
//     isLoading: true,
//     isError: false,
//    };

//   case types.GET_CITIES_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     cities: action.payload,
//    };

//   case types.ADD_CITY_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     cities: [...state.cities, action.payload],
//    };

//   case types.UPDATE_CITY_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     cities: state.cities.map((city) =>
//      city._id === payload._id ? payload : city
//     ),
//    };

//   case types.DELETE_CITY_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     cities: state.cities.filter((city) => city._id !== action.payload),
//    };

//   case types.VOTE_CITY_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//    };

//   case types.TOGGLE_SERVICE_STATUS_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     cities: state.cities.map((city) =>
//      city._id === payload._id
//       ? { ...city, isServiced: payload.isServiced }
//       : city
//     ),
//    };

//   case types.REMOVE_PINCODE_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     cities: state.cities.map((city) =>
//      city._id === payload.cityId
//       ? {
//          ...city,
//          pincodes: city.pincodes.filter(
//           (pincode) => pincode !== payload.pincode
//          ),
//         }
//       : city
//     ),
//    };

//   case types.GET_FAQS_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     isError: false,
//     faqs: payload,
//    };

//   case types.ADD_FAQ_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     isError: false,
//     faqs: [...state.faqs, payload],
//    };

//   case types.UPDATE_FAQ_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     isError: false,
//     faqs: state.faqs.map((faq) => (faq._id === payload._id ? payload : faq)),
//    };

//   case types.DELETE_FAQ_SUCCESS:
//    return {
//     ...state,
//     isLoading: false,
//     isError: false,
//     faqs: state.faqs.filter((faq) => faq._id !== payload),
//    };

//   case types.TOGGLE_SERVICE_STATUS_FAILURE:
//   case types.REMOVE_PINCODE_FAILURE:
//   case types.GET_CITIES_FAILURE:
//   case types.ADD_CITY_FAILURE:
//   case types.UPDATE_CITY_FAILURE:
//   case types.DELETE_CITY_FAILURE:
//   case types.VOTE_CITY_FAILURE:
//   case types.GET_FAQS_FAILURE:
//   case types.ADD_FAQ_FAILURE:
//   case types.UPDATE_FAQ_FAILURE:
//   case types.DELETE_FAQ_FAILURE:
//    return {
//     ...state,
//     isLoading: false,
//     isError: true,
//    };

//   default:
//    return state;
//  }
// };

// export { reducer };

// // import * as types from "./actionTypes";

// // const initialState = {
// //  cities: [],
// //  faqs: [],
// //  isLoading: false,
// //  isError: false,
// // };

// // const reducer = (state = initialState, action) => {
// //  const { payload, type } = action;
// //  switch (type) {
// //   case types.GET_CITIES_REQUEST:
// //   case types.ADD_CITY_REQUEST:
// //   case types.UPDATE_CITY_REQUEST:
// //   case types.DELETE_CITY_REQUEST:
// //   case types.VOTE_CITY_REQUEST:
// //   case types.GET_FAQS_REQUEST:
// //   case types.ADD_FAQ_REQUEST:
// //   case types.UPDATE_FAQ_REQUEST:
// //   case types.DELETE_FAQ_REQUEST:
// //   case types.REMOVE_PINCODE_REQUEST:
// //   case types.TOGGLE_SERVICE_STATUS_REQUEST:
// //    return {
// //     ...state,
// //     isLoading: true,
// //     isError: false,
// //    };
// //   case types.GET_CITIES_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     cities: action.payload,
// //    };

// //   case types.ADD_CITY_SUCCESS:
// //   case types.UPDATE_CITY_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     cities: [...state.cities, action.payload],
// //    };

// //   case types.DELETE_CITY_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     cities: state.cities.filter((city) => city._id !== action.payload),
// //    };

// //   case types.VOTE_CITY_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //    };

// //   case types.GET_FAQS_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     isError: false,
// //     faqs: payload,
// //    };

// //   case types.ADD_FAQ_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     isError: false,
// //     faqs: [...state.faqs, payload],
// //    };

// //   case types.UPDATE_FAQ_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     isError: false,
// //     faqs: state.faqs.map((faq) => (faq._id === payload._id ? payload : faq)),
// //    };

// //   case types.DELETE_FAQ_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     isError: false,
// //     faqs: state.faqs.filter((faq) => faq._id !== payload),
// //    };
// //   case types.TOGGLE_SERVICE_STATUS_SUCCESS:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     isError: false,
// //    };
// //   case types.REMOVE_PINCODE_REQUEST:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     isError: false,
// //    };

// //   case types.TOGGLE_SERVICE_STATUS_FAILURE:
// //   case types.REMOVE_PINCODE_FAILURE:
// //   case types.GET_CITIES_FAILURE:
// //   case types.ADD_CITY_FAILURE:
// //   case types.UPDATE_CITY_FAILURE:
// //   case types.DELETE_CITY_FAILURE:
// //   case types.VOTE_CITY_FAILURE:
// //   case types.GET_FAQS_FAILURE:
// //   case types.ADD_FAQ_FAILURE:
// //   case types.UPDATE_FAQ_FAILURE:
// //   case types.DELETE_FAQ_FAILURE:
// //    return {
// //     ...state,
// //     isLoading: false,
// //     isError: true,
// //    };

// //   default:
// //    return state;
// //  }
// // };

// // export { reducer };

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

// Brand Actions
export const CREATE_BRAND_REQUEST = "CREATE_BRAND_REQUEST";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAILURE = "CREATE_BRAND_FAILURE";

export const GET_BRANDS_REQUEST = "GET_BRANDS_REQUEST";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAILURE = "GET_BRANDS_FAILURE";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILURE = "UPDATE_BRAND_FAILURE";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";

// Car Actions
export const ADD_CAR_REQUEST = "ADD_CAR_REQUEST";
export const ADD_CAR_SUCCESS = "ADD_CAR_SUCCESS";
export const ADD_CAR_FAILURE = "ADD_CAR_FAILURE";

export const GET_CAR_REQUEST = "GET_CAR_REQUEST";
export const GET_CAR_SUCCESS = "GET_CAR_SUCCESS";
export const GET_CAR_FAILURE = "GET_CAR_FAILURE";

export const UPDATE_CAR_REQUEST = "UPDATE_CAR_REQUEST";
export const UPDATE_CAR_SUCCESS = "UPDATE_CAR_SUCCESS";
export const UPDATE_CAR_FAILURE = "UPDATE_CAR_FAILURE";

export const DELETE_CAR_REQUEST = "UPDATE_CAR_REQUEST";
export const DELETE_CAR_SUCCESS = "UPDATE_CAR_SUCCESS";
export const DELETE_CAR_FAILURE = "UPDATE_CAR_FAILURE";

export const DELETE_CARS_REQUEST = "DELETE_CARS_REQUEST";
export const DELETE_CARS_SUCCESS = "DELETE_CARS_SUCCESS";
export const DELETE_CARS_FAILURE = "DELETE_CARS_FAILURE";

export const GET_ALL_VARIENTS_REQUEST = "GET_ALL_VARIENTS_REQUEST";
export const GET_ALL_VARIENTS_SUCCESS = "GET_ALL_VARIENTS_SUCCESS";
export const GET_ALL_VARIENTS_FAILURE = "GET_ALL_VARIENTS_FAILURE";

import React, { useEffect, useState } from "react";
import {
 Box,
 Button,
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 FormControl,
 InputLabel,
 MenuItem,
 Select,
 TextField,
 Typography,
 Checkbox,
 FormControlLabel,
 IconButton,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
 fetchCarByBrand,
 fetchCarsByBrandAndTitle,
 fetchSpareParts,
 createSparePartPrice,
 getSparePartPrices,
 deleteSparePartPrice,
} from "../../../redux/products/action";
import { getBrands } from "../../../redux/admin/action";

const SparePartPriceForm = () => {
 const dispatch = useDispatch();
 const { carByBrand, carByTitleAndBrand, spareParts, sparePartPrices } =
  useSelector((store) => store.product);
 const { brands } = useSelector((store) => store.admin);
 const { handleSubmit, control, reset, watch, setValue } = useForm({
  defaultValues: {
   brandId: "",
   carIds: [],
   sparePartId: "",
   mrp: "",
   price: "",
   prices: [],
  },
 });

 const [open, setOpen] = useState(false);
 const [selectedCar, setSelectedCar] = useState("");
 const [selectedVariants, setSelectedVariants] = useState([]);
 const [editingPrice, setEditingPrice] = useState(null);

 const brandId = watch("brandId");
 const sparePartId = watch("sparePartId");

 useEffect(() => {
  dispatch(getBrands());
  dispatch(fetchSpareParts());
  dispatch(getSparePartPrices());
 }, [dispatch]);

 useEffect(() => {
  if (brandId) {
   dispatch(fetchCarByBrand(brandId));
  }
 }, [dispatch, brandId]);

 useEffect(() => {
  if (brandId && selectedCar) {
   dispatch(fetchCarsByBrandAndTitle(brandId, selectedCar));
  }
 }, [brandId, selectedCar, dispatch]);

 const handleVariantSelect = (event, variant) => {
  if (event.target.checked) {
   setSelectedVariants((prev) => [...prev, variant]);
  } else {
   setSelectedVariants((prev) => prev.filter((v) => v._id !== variant._id));
  }
 };

 const submitHandler = (data) => {
  const prices = selectedVariants.map((variant) => ({
   carId: variant._id,
   variant: variant.fuelType,
   transmission: variant.transmissionType,
   mrp: data.mrp,
   price: data.price,
  }));

  const formattedData = {
   carIds: selectedVariants.map((variant) => variant._id),
   sparePartId: data.sparePartId,
   prices: prices,
  };

  if (editingPrice) {
   formattedData.id = editingPrice._id;
   // dispatch update action here if required
  } else {
   dispatch(createSparePartPrice(formattedData));
  }

  setOpen(false);
  reset(); // reset the form after submission
  setEditingPrice(null); // clear editing state
 };

 const handleEdit = (priceData) => {
  setEditingPrice(priceData);
  setValue("brandId", priceData.carId?.brandId); // Assuming brandId is part of the carId object
  setValue("sparePartId", priceData.sparePartId._id);
  setValue("mrp", priceData.mrp);
  setValue("price", priceData.price);
  setSelectedCar(priceData.carId?.title);
  setSelectedVariants([
   {
    _id: priceData.carId?._id,
    fuelType: priceData.variant,
    transmissionType: priceData.transmission,
   },
  ]);
  setOpen(true);
 };

 const handleDelete = (id) => {
  if (window.confirm("Are you sure you want to delete this price?")) {
   dispatch(deleteSparePartPrice(id));
  }
 };

 return (
  <Box>
   <Typography variant="h4" gutterBottom>
    Spare Part Prices
   </Typography>
   <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
    ADD SPARE PART PRICE
   </Button>
   <Box mt={2}>
    <Typography variant="h6">
     Existing Prices for this Spare Part/ {sparePartPrices?.length}
    </Typography>
    <TableContainer component={Paper}>
     <Table>
      <TableHead>
       <TableRow>
        <TableCell>Car Model</TableCell>
        <TableCell>Spare-Part</TableCell>
        <TableCell>Variant</TableCell>
        <TableCell>Transmission</TableCell>
        <TableCell>MRP</TableCell>
        <TableCell>Price</TableCell>
        <TableCell>Actions</TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {sparePartPrices?.map((price) => (
        <TableRow key={price._id}>
         <TableCell>{price.carId?.title}</TableCell>
         <TableCell>{price.sparePartId?.spareName}</TableCell>
         <TableCell>{price.variant}</TableCell>
         <TableCell>{price.transmission}</TableCell>
         <TableCell>{price.mrp}</TableCell>
         <TableCell>{price.price}</TableCell>
         <TableCell>
          <IconButton onClick={() => handleEdit(price)}>
           <EditIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => handleDelete(price._id)}>
           <DeleteIcon color="secondary" />
          </IconButton>
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
   </Box>
   <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
    <DialogTitle>
     {editingPrice ? "Edit Spare Part Price" : "Add Spare Part Price"}
    </DialogTitle>
    <DialogContent>
     <form onSubmit={handleSubmit(submitHandler)}>
      <FormControl fullWidth margin="normal">
       <InputLabel>Brand</InputLabel>
       <Controller
        name="brandId"
        control={control}
        render={({ field }) => (
         <Select {...field} fullWidth>
          {brands.map((brand) => (
           <MenuItem key={brand._id} value={brand._id}>
            {brand.title}
           </MenuItem>
          ))}
         </Select>
        )}
       />
      </FormControl>
      {brandId && (
       <FormControl fullWidth margin="normal">
        <InputLabel>Car Model</InputLabel>
        <Select
         value={selectedCar}
         onChange={(e) => setSelectedCar(e.target.value)}
        >
         {carByBrand?.data?.map((car) => (
          <MenuItem key={car._id} value={car.title}>
           {car.title}
          </MenuItem>
         ))}
        </Select>
       </FormControl>
      )}
      {carByTitleAndBrand && (
       <Box>
        <Typography variant="h6">Select Variants</Typography>
        {carByTitleAndBrand.data
         ?.filter((car) => car.title === selectedCar)
         .map((variant) => (
          <FormControlLabel
           key={variant._id}
           control={
            <Checkbox
             checked={selectedVariants.some((v) => v._id === variant._id)}
             onChange={(e) => handleVariantSelect(e, variant)}
            />
           }
           label={`${variant.transmissionType} - ${variant.fuelType}`}
          />
         ))}
       </Box>
      )}
      <FormControl fullWidth margin="normal">
       <InputLabel>Spare Part</InputLabel>
       <Controller
        name="sparePartId"
        control={control}
        render={({ field }) => (
         <Select {...field} fullWidth>
          {spareParts?.map((product) => (
           <MenuItem key={product._id} value={product._id}>
            {product.spareName}
           </MenuItem>
          ))}
         </Select>
        )}
       />
      </FormControl>
      <Controller
       name="mrp"
       control={control}
       render={({ field }) => (
        <TextField
         {...field}
         label="MRP"
         type="number"
         fullWidth
         margin="normal"
        />
       )}
      />
      <Controller
       name="price"
       control={control}
       render={({ field }) => (
        <TextField
         {...field}
         label="Price"
         type="number"
         fullWidth
         margin="normal"
        />
       )}
      />
      <DialogActions>
       <Button onClick={() => setOpen(false)} color="secondary">
        Cancel
       </Button>
       <Button type="submit" color="primary">
        Submit
       </Button>
      </DialogActions>
     </form>
    </DialogContent>
   </Dialog>
  </Box>
 );
};

export default SparePartPriceForm;

// import React, { useEffect, useState } from "react";
// import {
//  Box,
//  Button,
//  Dialog,
//  DialogActions,
//  DialogContent,
//  DialogTitle,
//  FormControl,
//  InputLabel,
//  MenuItem,
//  Select,
//  Table,
//  TableBody,
//  TableCell,
//  TableContainer,
//  TableHead,
//  TableRow,
//  Paper,
//  TextField,
//  Typography,
//  Checkbox,
//  FormControlLabel,
// } from "@mui/material";
// import { useForm, Controller } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";
// import {
//  fetchCarByBrand,
//  fetchCarsByBrandAndTitle,
//  fetchSpareParts,
//  createSparePartPrice,
//  getSparePartPrices,
// } from "../../../redux/products/action";
// import { getBrands } from "../../../redux/admin/action";

// const SparePartPriceForm = () => {
//  const dispatch = useDispatch();
//  const { carByBrand, carByTitleAndBrand, spareParts, sparePartPrices } =
//   useSelector((store) => store.product);
//  const { brands } = useSelector((store) => store.admin);
//  const { handleSubmit, control, reset, watch } = useForm({
//   defaultValues: {
//    brandId: "",
//    carIds: [],
//    sparePartId: "",
//    mrp: "",
//    price: "",
//    prices: [],
//   },
//  });

//  const [open, setOpen] = useState(false);
//  const [selectedCar, setSelectedCar] = useState("");
//  const [selectedVariants, setSelectedVariants] = useState([]);

//  const brandId = watch("brandId");
//  const sparePartId = watch("sparePartId");

//  useEffect(() => {
//   dispatch(getBrands());
//   dispatch(fetchSpareParts());
//    dispatch(getSparePartPrices());
//  }, [dispatch]);

//  useEffect(() => {
//   if (brandId) {
//    dispatch(fetchCarByBrand(brandId));
//   }
//  }, [dispatch, brandId]);

//  useEffect(() => {
//   if (brandId && selectedCar) {
//    dispatch(fetchCarsByBrandAndTitle(brandId, selectedCar));
//   }
//  }, [brandId, selectedCar, dispatch]);

//  useEffect(() => {
//   if (sparePartId) {
//   //  dispatch(getSparePartPrices(sparePartId));
//   }
//  }, [dispatch, sparePartId]);

//  const handleVariantSelect = (event, variant) => {
//   if (event.target.checked) {
//    setSelectedVariants((prev) => [...prev, variant]);
//   } else {
//    setSelectedVariants((prev) => prev.filter((v) => v._id !== variant._id));
//   }
//  };

//  const submitHandler = (data) => {
//   const prices = selectedVariants.map((variant) => ({
//    carId: variant._id,
//    variant: variant.fuelType,
//    transmission: variant.transmissionType,
//    mrp: data.mrp,
//    price: data.price,
//   }));

//   const formattedData = {
//    carIds: selectedVariants.map((variant) => variant._id),
//    sparePartId: data.sparePartId,
//    prices: prices,
//   };

//   dispatch(createSparePartPrice(formattedData));
//   setOpen(false);
//  };
//  console.log("sparePartPrices:", sparePartPrices);
//  return (
//   <Box>
//    <Typography variant="h4" gutterBottom>
//     Spare Part Prices
//    </Typography>
//    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
//     ADD SPARE PART PRICE
//    </Button>
//    {/* {sparePartId && (

//    )} */}
//    <Box mt={2}>
//     <Typography variant="h6">Existing Prices for this Spare Part</Typography>
//     <TableContainer component={Paper}>
//      <Table>
//       {/* <TableContainer aria-label="spare part prices table"> */}
//       <TableHead>
//        <TableRow>
//         <TableCell>Car Model</TableCell>
//         <TableCell>Variant</TableCell>
//         <TableCell>Transmission</TableCell>
//         <TableCell>MRP</TableCell>
//         <TableCell>Price</TableCell>
//         <TableCell>Actions</TableCell>
//        </TableRow>
//       </TableHead>
//       <TableBody>
//        {sparePartPrices?.map((price) => (
//         <TableRow key={price._id}>
//          <TableCell>{price.carModel}</TableCell>
//          <TableCell>{price.variant}</TableCell>
//          <TableCell>{price.transmission}</TableCell>
//          <TableCell>{price.mrp}</TableCell>
//          <TableCell>{price.price}</TableCell>
//          <TableCell>
//           <Button color="primary">EDIT</Button>
//           <Button color="secondary">DELETE</Button>
//          </TableCell>
//         </TableRow>
//        ))}
//       </TableBody>
//      </Table>
//     </TableContainer>
//    </Box>
//    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
//     <DialogTitle>Add Spare Part Price</DialogTitle>
//     <DialogContent>
//      <form onSubmit={handleSubmit(submitHandler)}>
//       <FormControl fullWidth margin="normal">
//        <InputLabel>Brand</InputLabel>
//        <Controller
//         name="brandId"
//         control={control}
//         render={({ field }) => (
//          <Select {...field} fullWidth>
//           {brands.map((brand) => (
//            <MenuItem key={brand._id} value={brand._id}>
//             {brand.title}
//            </MenuItem>
//           ))}
//          </Select>
//         )}
//        />
//       </FormControl>
//       {brandId && (
//        <FormControl fullWidth margin="normal">
//         <InputLabel>Car Model</InputLabel>
//         <Select
//          value={selectedCar}
//          onChange={(e) => setSelectedCar(e.target.value)}
//         >
//          {carByBrand?.data?.map((car) => (
//           <MenuItem key={car._id} value={car.title}>
//            {car.title}
//           </MenuItem>
//          ))}
//         </Select>
//        </FormControl>
//       )}
//       {carByTitleAndBrand && (
//        <Box>
//         <Typography variant="h6">Select Variants</Typography>
//         {carByTitleAndBrand.data
//          ?.filter((car) => car.title === selectedCar)
//          .map((variant) => (
//           <FormControlLabel
//            key={variant._id}
//            control={
//             <Checkbox
//              checked={selectedVariants.some((v) => v._id === variant._id)}
//              onChange={(e) => handleVariantSelect(e, variant)}
//             />
//            }
//            label={`${variant.transmissionType} - ${variant.fuelType}`}
//           />
//          ))}
//        </Box>
//       )}
//       <FormControl fullWidth margin="normal">
//        <InputLabel>Spare Part</InputLabel>
//        <Controller
//         name="sparePartId"
//         control={control}
//         render={({ field }) => (
//          <Select {...field} fullWidth>
//           {spareParts?.map((product) => (
//            <MenuItem key={product._id} value={product._id}>
//             {product.spareName}
//            </MenuItem>
//           ))}
//          </Select>
//         )}
//        />
//       </FormControl>
//       <Controller
//        name="mrp"
//        control={control}
//        render={({ field }) => (
//         <TextField
//          {...field}
//          label="MRP"
//          type="number"
//          fullWidth
//          margin="normal"
//         />
//        )}
//       />
//       <Controller
//        name="price"
//        control={control}
//        render={({ field }) => (
//         <TextField
//          {...field}
//          label="Price"
//          type="number"
//          fullWidth
//          margin="normal"
//         />
//        )}
//       />
//       <DialogActions>
//        <Button onClick={() => setOpen(false)} color="secondary">
//         Cancel
//        </Button>
//        <Button type="submit" color="primary">
//         Submit
//        </Button>
//       </DialogActions>
//      </form>
//     </DialogContent>
//    </Dialog>
//   </Box>
//  );
// };

// export default SparePartPriceForm;

// // import React, { useEffect, useState } from "react";
// // import { useForm, Controller } from "react-hook-form";
// // import {
// //  Dialog,
// //  DialogActions,
// //  DialogContent,
// //  DialogTitle,
// //  Button,
// //  TextField,
// //  Select,
// //  MenuItem,
// //  Checkbox,
// //  FormControl,
// //  InputLabel,
// //  FormControlLabel,
// //  Typography,
// //  Box,
// //  Table,
// //  TableBody,
// //  TableCell,
// //  TableContainer,
// //  TableHead,
// //  TableRow,
// //  Paper,
// // } from "@mui/material";
// // import { useDispatch, useSelector } from "react-redux";
// // import { getBrands } from "../../../redux/admin/action";
// // import {
// //  fetchCarByBrand,
// //  fetchCarsByBrandAndTitle,
// //  fetchSpareParts,
// //  createSparePartPrice,
// //  updateSparePartPrice,
// //  deleteSparePartPrice,
// //  getSparePartPrices, // Fetch existing spare part prices
// // } from "../../../redux/products/action";

// // const SparePartPriceForm = ({ open, onClose }) => {
// //  const dispatch = useDispatch();
// //  const { brands } = useSelector((store) => store.admin);
// //  const { carByBrand, carByTitleAndBrand, spareParts, sparePartPrices } =
// //   useSelector((store) => store.product);
// //  const { handleSubmit, control, reset, watch } = useForm({
// //   defaultValues: {
// //    _id: "",
// //    brandId: "",
// //    carIds: [],
// //    sparePartId: "",
// //    mrp: "",
// //    price: "",
// //    prices: [],
// //   },
// //  });

// //  const brandId = watch("brandId");
// //  const sparePartId = watch("sparePartId");
// //  const mrp = watch("mrp");
// //  const price = watch("price");
// //  const [selectedCar, setSelectedCar] = useState("");
// //  const [selectedVariants, setSelectedVariants] = useState([]);

// //  useEffect(() => {
// //   if (brandId) {
// //    dispatch(fetchCarByBrand(brandId));
// //   }
// //  }, [dispatch, brandId]);

// //  useEffect(() => {
// //   if (brands.length <= 0) {
// //    dispatch(getBrands());
// //   }
// //  }, [dispatch, brands.length]);

// //  useEffect(() => {
// //   if (spareParts?.length <= 0) {
// //    dispatch(fetchSpareParts());
// //   }
// //  }, [dispatch, spareParts.length]);

// //  useEffect(() => {
// //   if (brandId && selectedCar) {
// //    dispatch(fetchCarsByBrandAndTitle(brandId, selectedCar));
// //   }
// //  }, [brandId, selectedCar, dispatch]);

// //  useEffect(() => {
// //   if (sparePartId) {
// //    dispatch(getSparePartPrices(sparePartId));
// //   }
// //  }, [dispatch, sparePartId]);

// //  const handleVariantSelect = (event, variant) => {
// //   if (event.target.checked) {
// //    setSelectedVariants((prev) => [...prev, variant]);
// //   } else {
// //    setSelectedVariants((prev) => prev.filter((v) => v._id !== variant._id));
// //   }
// //  };

// //  const handleDeletePrice = (priceId) => {
// //   dispatch(deleteSparePartPrice(priceId));
// //  };

// //  const handleEditPrice = (price) => {
// //   setSelectedVariants([
// //    {
// //     _id: price.carId,
// //     fuelType: price.variant,
// //     transmissionType: price.transmission,
// //    },
// //   ]);
// //   reset({
// //    _id: price._id,
// //    sparePartId: price.sparePartId,
// //    mrp: price.mrp,
// //    price: price.price,
// //   });
// //  };

// //  const submitHandler = (data) => {
// //   const prices = selectedVariants.map((variant) => ({
// //    carId: variant._id,
// //    variant: variant.fuelType,
// //    transmission: variant.transmissionType,
// //    mrp: data.mrp,
// //    price: data.price,
// //   }));

// //   const formattedData = {
// //    carIds: selectedVariants.map((variant) => variant._id),
// //    sparePartId: data.sparePartId,
// //    prices: prices,
// //   };

// //   if (data._id) {
// //    dispatch(updateSparePartPrice(data._id, formattedData));
// //   } else {
// //    dispatch(createSparePartPrice(formattedData));
// //   }

// //   onClose();
// //  };

// //  return (
// //   <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
// //    <DialogTitle>Add or Edit Spare Part Price</DialogTitle>
// //    <DialogContent>
// //     {sparePartId && (
// //      <Box mb={2}>
// //       <Typography variant="h6">Existing Prices for this Spare Part</Typography>
// //       <TableContainer component={Paper}>
// //        <Table aria-label="spare part prices table">
// //         <TableHead>
// //          <TableRow>
// //           <TableCell>Car Model</TableCell>
// //           <TableCell>Variant</TableCell>
// //           <TableCell>Transmission</TableCell>
// //           <TableCell>MRP</TableCell>
// //           <TableCell>Price</TableCell>
// //           <TableCell>Actions</TableCell>
// //          </TableRow>
// //         </TableHead>
// //         <TableBody>
// //          {sparePartPrices?.map((price) => (
// //           <TableRow key={price._id}>
// //            <TableCell>{price.carModel}</TableCell>
// //            <TableCell>{price.variant}</TableCell>
// //            <TableCell>{price.transmission}</TableCell>
// //            <TableCell>{price.mrp}</TableCell>
// //            <TableCell>{price.price}</TableCell>
// //            <TableCell>
// //             <Button
// //              variant="outlined"
// //              color="primary"
// //              onClick={() => handleEditPrice(price)}
// //             >
// //              Edit
// //             </Button>
// //             <Button
// //              variant="outlined"
// //              color="secondary"
// //              onClick={() => handleDeletePrice(price._id)}
// //             >
// //              Delete
// //             </Button>
// //            </TableCell>
// //           </TableRow>
// //          ))}
// //         </TableBody>
// //        </Table>
// //       </TableContainer>
// //      </Box>
// //     )}
// //     <form onSubmit={handleSubmit(submitHandler)}>
// //      <FormControl fullWidth margin="normal">
// //       <InputLabel>Brand</InputLabel>
// //       <Controller
// //        name="brandId"
// //        control={control}
// //        render={({ field }) => (
// //         <Select {...field} fullWidth>
// //          {brands.map((brand) => (
// //           <MenuItem key={brand._id} value={brand._id}>
// //            {brand.title}
// //           </MenuItem>
// //          ))}
// //         </Select>
// //        )}
// //       />
// //      </FormControl>
// //      {brandId && (
// //       <FormControl fullWidth margin="normal">
// //        <InputLabel>Car Model</InputLabel>
// //        <Select
// //         value={selectedCar}
// //         onChange={(e) => setSelectedCar(e.target.value)}
// //        >
// //         {carByBrand?.data?.map((car) => (
// //          <MenuItem key={car._id} value={car.title}>
// //           {car.title}
// //          </MenuItem>
// //         ))}
// //        </Select>
// //       </FormControl>
// //      )}
// //      {carByTitleAndBrand && (
// //       <Box>
// //        <Typography variant="h6">Select Variants</Typography>
// //        {carByTitleAndBrand.data
// //         ?.filter((car) => car.title === selectedCar)
// //         .map((variant) => (
// //          <FormControlLabel
// //           key={variant._id}
// //           control={
// //            <Checkbox
// //             checked={selectedVariants.some((v) => v._id === variant._id)}
// //             onChange={(e) => handleVariantSelect(e, variant)}
// //            />
// //           }
// //           label={`${variant.transmissionType} - ${variant.fuelType}`}
// //          />
// //         ))}
// //       </Box>
// //      )}
// //      <FormControl fullWidth margin="normal">
// //       <InputLabel>Spare Part</InputLabel>
// //       <Controller
// //        name="sparePartId"
// //        control={control}
// //        render={({ field }) => (
// //         <Select {...field} fullWidth>
// //          {spareParts?.map((product) => (
// //           <MenuItem key={product._id} value={product._id}>
// //            {product.spareName}
// //           </MenuItem>
// //          ))}
// //         </Select>
// //        )}
// //       />
// //      </FormControl>
// //      <Controller
// //       name="mrp"
// //       control={control}
// //       render={({ field }) => (
// //        <TextField
// //         {...field}
// //         label="MRP"
// //         type="number"
// //         fullWidth
// //         margin="normal"
// //        />
// //       )}
// //      />
// //      <Controller
// //       name="price"
// //       control={control}
// //       render={({ field }) => (
// //        <TextField
// //         {...field}
// //         label="Price"
// //         type="number"
// //         fullWidth
// //         margin="normal"
// //        />
// //       )}
// //      />
// //      <DialogActions>
// //       <Button onClick={onClose} color="secondary">
// //        Cancel
// //       </Button>
// //       <Button type="submit" color="primary">
// //        Submit
// //       </Button>
// //      </DialogActions>
// //     </form>
// //    </DialogContent>
// //   </Dialog>
// //  );
// // };

// // export default SparePartPriceForm;

// // // import React, { useEffect, useState } from "react";
// // // import { useForm, Controller } from "react-hook-form";
// // // import {
// // //  Dialog,
// // //  DialogActions,
// // //  DialogContent,
// // //  DialogTitle,
// // //  Button,
// // //  TextField,
// // //  Select,
// // //  MenuItem,
// // //  Checkbox,
// // //  FormControl,
// // //  InputLabel,
// // //  FormControlLabel,
// // //  Typography,
// // //  Box,
// // //  Table,
// // //  TableBody,
// // //  TableCell,
// // //  TableContainer,
// // //  TableHead,
// // //  TableRow,
// // //  Paper,
// // // } from "@mui/material";
// // // import { useDispatch, useSelector } from "react-redux";
// // // import { getBrands } from "../../../redux/admin/action";
// // // import {
// // //  fetchCarByBrand,
// // //  fetchCarsByBrandAndTitle,
// // //  fetchSpareParts,
// // //  createSparePartPrice, // Import the action to create spare part prices
// // //  getSparePartPrices, // Fetch existing spare part prices
// // // } from "../../../redux/products/action";

// // // const SparePartPriceForm = ({ open, onClose }) => {
// // //  const dispatch = useDispatch();
// // //  const { brands } = useSelector((store) => store.admin);
// // //  const { carByBrand, carByTitleAndBrand, spareParts, sparePartPrices } =
// // //   useSelector((store) => store.product);
// // //  const { handleSubmit, control, reset, watch } = useForm({
// // //   defaultValues: {
// // //    brandId: "",
// // //    carIds: [],
// // //    sparePartId: "",
// // //    mrp: "",
// // //    price: "",
// // //    prices: [],
// // //   },
// // //  });

// // //  const brandId = watch("brandId");
// // //  const sparePartId = watch("sparePartId");
// // //  const mrp = watch("mrp");
// // //  const price = watch("price");
// // //  const [selectedCar, setSelectedCar] = useState("");
// // //  const [selectedVariants, setSelectedVariants] = useState([]);

// // //  useEffect(() => {
// // //   if (brandId) {
// // //    dispatch(fetchCarByBrand(brandId));
// // //   }
// // //  }, [dispatch, brandId]);

// // //  useEffect(() => {
// // //   if (brands.length <= 0) {
// // //    dispatch(getBrands());
// // //   }
// // //  }, [dispatch, brands.length]);

// // //  useEffect(() => {
// // //   if (spareParts?.length <= 0) {
// // //    dispatch(fetchSpareParts());
// // //   }
// // //  }, [dispatch, spareParts.length]);

// // //  useEffect(() => {
// // //   if (brandId && selectedCar) {
// // //    dispatch(fetchCarsByBrandAndTitle(brandId, selectedCar));
// // //   }
// // //  }, [brandId, selectedCar, dispatch]);

// // //  useEffect(() => {
// // //   if (sparePartId) {
// // //    dispatch(getSparePartPrices(sparePartId));
// // //   }
// // //  }, [dispatch, sparePartId]);

// // //  const handleVariantSelect = (event, variant) => {
// // //   if (event.target.checked) {
// // //    setSelectedVariants((prev) => [...prev, variant]);
// // //   } else {
// // //    setSelectedVariants((prev) => prev.filter((v) => v._id !== variant._id));
// // //   }
// // //  };

// // //  const submitHandler = (data) => {
// // //   const prices = selectedVariants.map((variant) => ({
// // //    carId: variant._id,
// // //    variant: variant.fuelType,
// // //    transmission: variant.transmissionType,
// // //    mrp: data.mrp,
// // //    price: data.price,
// // //   }));

// // //   const formattedData = {
// // //    carIds: selectedVariants.map((variant) => variant._id),
// // //    sparePartId: data.sparePartId,
// // //    prices: prices,
// // //   };

// // //   // Dispatch the createSparePartPrice action with the formatted data
// // //   dispatch(createSparePartPrice(formattedData));

// // //   // Close the modal after submission
// // //   onClose();
// // //  };
// // //  console.log("sparePartPrices:", sparePartPrices);
// // //  return (
// // //   <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
// // //    <DialogTitle>Add Spare Part Price</DialogTitle>
// // //    <DialogContent>
// // //     {sparePartId && (
// // //      <Box mb={2}>
// // //       <Typography variant="h6">Existing Prices for this Spare Part</Typography>
// // //       <TableContainer component={Paper}>
// // //        <Table aria-label="spare part prices table">
// // //         <TableHead>
// // //          <TableRow>
// // //           <TableCell>Car Model</TableCell>
// // //           <TableCell>Variant</TableCell>
// // //           <TableCell>Transmission</TableCell>
// // //           <TableCell>MRP</TableCell>
// // //           <TableCell>Price</TableCell>
// // //          </TableRow>
// // //         </TableHead>
// // //         <TableBody>
// // //          {sparePartPrices?.map((price) => (
// // //           <TableRow key={price._id}>
// // //            <TableCell>{price.carModel}</TableCell>
// // //            <TableCell>{price.variant}</TableCell>
// // //            <TableCell>{price.transmission}</TableCell>
// // //            <TableCell>{price.mrp}</TableCell>
// // //            <TableCell>{price.price}</TableCell>
// // //           </TableRow>
// // //          ))}
// // //         </TableBody>
// // //        </Table>
// // //       </TableContainer>
// // //      </Box>
// // //     )}
// // //     <form onSubmit={handleSubmit(submitHandler)}>
// // //      <FormControl fullWidth margin="normal">
// // //       <InputLabel>Brand</InputLabel>
// // //       <Controller
// // //        name="brandId"
// // //        control={control}
// // //        render={({ field }) => (
// // //         <Select {...field} fullWidth>
// // //          {brands.map((brand) => (
// // //           <MenuItem key={brand._id} value={brand._id}>
// // //            {brand.title}
// // //           </MenuItem>
// // //          ))}
// // //         </Select>
// // //        )}
// // //       />
// // //      </FormControl>
// // //      {brandId && (
// // //       <FormControl fullWidth margin="normal">
// // //        <InputLabel>Car Model</InputLabel>
// // //        <Select
// // //         value={selectedCar}
// // //         onChange={(e) => setSelectedCar(e.target.value)}
// // //        >
// // //         {carByBrand?.data?.map((car) => (
// // //          <MenuItem key={car._id} value={car.title}>
// // //           {car.title}
// // //          </MenuItem>
// // //         ))}
// // //        </Select>
// // //       </FormControl>
// // //      )}
// // //      {carByTitleAndBrand && (
// // //       <Box>
// // //        <Typography variant="h6">Select Variants</Typography>
// // //        {carByTitleAndBrand.data
// // //         ?.filter((car) => car.title === selectedCar)
// // //         .map((variant) => (
// // //          <FormControlLabel
// // //           key={variant._id}
// // //           control={
// // //            <Checkbox
// // //             checked={selectedVariants.some((v) => v._id === variant._id)}
// // //             onChange={(e) => handleVariantSelect(e, variant)}
// // //            />
// // //           }
// // //           label={`${variant.transmissionType} - ${variant.fuelType}`}
// // //          />
// // //         ))}
// // //       </Box>
// // //      )}
// // //      <FormControl fullWidth margin="normal">
// // //       <InputLabel>Spare Part</InputLabel>
// // //       <Controller
// // //        name="sparePartId"
// // //        control={control}
// // //        render={({ field }) => (
// // //         <Select {...field} fullWidth>
// // //          {spareParts?.map((product) => (
// // //           <MenuItem key={product._id} value={product._id}>
// // //            {product.spareName}
// // //           </MenuItem>
// // //          ))}
// // //         </Select>
// // //        )}
// // //       />
// // //      </FormControl>
// // //      <Controller
// // //       name="mrp"
// // //       control={control}
// // //       render={({ field }) => (
// // //        <TextField
// // //         {...field}
// // //         label="MRP"
// // //         type="number"
// // //         fullWidth
// // //         margin="normal"
// // //        />
// // //       )}
// // //      />
// // //      <Controller
// // //       name="price"
// // //       control={control}
// // //       render={({ field }) => (
// // //        <TextField
// // //         {...field}
// // //         label="Price"
// // //         type="number"
// // //         fullWidth
// // //         margin="normal"
// // //        />
// // //       )}
// // //      />
// // //      <DialogActions>
// // //       <Button onClick={onClose} color="secondary">
// // //        Cancel
// // //       </Button>
// // //       <Button type="submit" color="primary">
// // //        Submit
// // //       </Button>
// // //      </DialogActions>
// // //     </form>
// // //    </DialogContent>
// // //   </Dialog>
// // //  );
// // // };

// // // export default SparePartPriceForm;

// // // // import React, { useEffect, useState } from "react";
// // // // import { useForm, Controller } from "react-hook-form";
// // // // import {
// // // //  Dialog,
// // // //  DialogActions,
// // // //  DialogContent,
// // // //  DialogTitle,
// // // //  Button,
// // // //  TextField,
// // // //  Select,
// // // //  MenuItem,
// // // //  Checkbox,
// // // //  FormControl,
// // // //  InputLabel,
// // // //  FormControlLabel,
// // // //  Typography,
// // // //  Box,
// // // // } from "@mui/material";
// // // // import { useDispatch, useSelector } from "react-redux";
// // // // import { getBrands } from "../../../redux/admin/action";
// // // // import {
// // // //  fetchCarByBrand,
// // // //  fetchCarsByBrandAndTitle,
// // // //  fetchSpareParts,
// // // //  createSparePartPrice, // Import the action to create spare part prices
// // // // } from "../../../redux/products/action";

// // // // const SparePartPriceForm = ({ open, onClose }) => {
// // // //  const dispatch = useDispatch();
// // // //  const { brands } = useSelector((store) => store.admin);
// // // //  const { carByBrand, carByTitleAndBrand, spareParts } = useSelector(
// // // //   (store) => store.product
// // // //  );
// // // //  const { handleSubmit, control, reset, watch } = useForm({
// // // //   defaultValues: {
// // // //    brandId: "",
// // // //    carIds: [],
// // // //    sparePartId: "",
// // // //    mrp: "",
// // // //    price: "",
// // // //    prices: [],
// // // //   },
// // // //  });

// // // //  const brandId = watch("brandId");
// // // //  const sparePartId = watch("sparePartId");
// // // //  const mrp = watch("mrp");
// // // //  const price = watch("price");
// // // //  const [selectedCar, setSelectedCar] = useState("");
// // // //  const [selectedVariants, setSelectedVariants] = useState([]);

// // // //  useEffect(() => {
// // // //   if (brandId) {
// // // //    dispatch(fetchCarByBrand(brandId));
// // // //   }
// // // //  }, [dispatch, brandId]);

// // // //  useEffect(() => {
// // // //   if (brands.length <= 0) {
// // // //    dispatch(getBrands());
// // // //   }
// // // //  }, [dispatch, brands.length]);

// // // //  useEffect(() => {
// // // //   if (spareParts?.length <= 0) {
// // // //    dispatch(fetchSpareParts());
// // // //   }
// // // //  }, [dispatch, spareParts.length]);

// // // //  useEffect(() => {
// // // //   if (brandId && selectedCar) {
// // // //    dispatch(fetchCarsByBrandAndTitle(brandId, selectedCar));
// // // //   }
// // // //  }, [brandId, selectedCar, dispatch]);

// // // //  const handleVariantSelect = (event, variant) => {
// // // //   if (event.target.checked) {
// // // //    setSelectedVariants((prev) => [...prev, variant]);
// // // //   } else {
// // // //    setSelectedVariants((prev) => prev.filter((v) => v._id !== variant._id));
// // // //   }
// // // //  };

// // // //  const submitHandler = (data) => {
// // // //   const prices = selectedVariants.map((variant) => ({
// // // //    carId: variant._id,
// // // //    variant: variant.fuelType,
// // // //    transmission: variant.transmissionType,
// // // //    mrp: data.mrp,
// // // //    price: data.price,
// // // //   }));

// // // //   const formattedData = {
// // // //    carIds: selectedVariants.map((variant) => variant._id),
// // // //    sparePartId: data.sparePartId,
// // // //    prices: prices,
// // // //   };

// // // //   // Dispatch the createSparePartPrice action with the formatted data
// // // //   dispatch(createSparePartPrice(formattedData));

// // // //   // Close the modal after submission
// // // //   onClose();
// // // //  };

// // // //  return (
// // // //   <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
// // // //    <DialogTitle>Add Spare Part Price</DialogTitle>
// // // //    <DialogContent>
// // // //     <form onSubmit={handleSubmit(submitHandler)}>
// // // //      <FormControl fullWidth margin="normal">
// // // //       <InputLabel>Brand</InputLabel>
// // // //       <Controller
// // // //        name="brandId"
// // // //        control={control}
// // // //        render={({ field }) => (
// // // //         <Select {...field} fullWidth>
// // // //          {brands.map((brand) => (
// // // //           <MenuItem key={brand._id} value={brand._id}>
// // // //            {brand.title}
// // // //           </MenuItem>
// // // //          ))}
// // // //         </Select>
// // // //        )}
// // // //       />
// // // //      </FormControl>
// // // //      {brandId && (
// // // //       <FormControl fullWidth margin="normal">
// // // //        <InputLabel>Car Model</InputLabel>
// // // //        <Select
// // // //         value={selectedCar}
// // // //         onChange={(e) => setSelectedCar(e.target.value)}
// // // //        >
// // // //         {carByBrand?.data?.map((car) => (
// // // //          <MenuItem key={car._id} value={car.title}>
// // // //           {car.title}
// // // //          </MenuItem>
// // // //         ))}
// // // //        </Select>
// // // //       </FormControl>
// // // //      )}
// // // //      {carByTitleAndBrand && (
// // // //       <Box>
// // // //        <Typography variant="h6">Select Variants</Typography>
// // // //        {carByTitleAndBrand.data
// // // //         ?.filter((car) => car.title === selectedCar)
// // // //         .map((variant) => (
// // // //          <FormControlLabel
// // // //           key={variant._id}
// // // //           control={
// // // //            <Checkbox
// // // //             checked={selectedVariants.some((v) => v._id === variant._id)}
// // // //             onChange={(e) => handleVariantSelect(e, variant)}
// // // //            />
// // // //           }
// // // //           label={`${variant.transmissionType} - ${variant.fuelType}`}
// // // //          />
// // // //         ))}
// // // //       </Box>
// // // //      )}
// // // //      <FormControl fullWidth margin="normal">
// // // //       <InputLabel>Spare Part</InputLabel>
// // // //       <Controller
// // // //        name="sparePartId"
// // // //        control={control}
// // // //        render={({ field }) => (
// // // //         <Select {...field} fullWidth>
// // // //          {spareParts?.map((product) => (
// // // //           <MenuItem key={product._id} value={product._id}>
// // // //            {product.spareName}
// // // //           </MenuItem>
// // // //          ))}
// // // //         </Select>
// // // //        )}
// // // //       />
// // // //      </FormControl>
// // // //      <Controller
// // // //       name="mrp"
// // // //       control={control}
// // // //       render={({ field }) => (
// // // //        <TextField
// // // //         {...field}
// // // //         label="MRP"
// // // //         type="number"
// // // //         fullWidth
// // // //         margin="normal"
// // // //        />
// // // //       )}
// // // //      />
// // // //      <Controller
// // // //       name="price"
// // // //       control={control}
// // // //       render={({ field }) => (
// // // //        <TextField
// // // //         {...field}
// // // //         label="Price"
// // // //         type="number"
// // // //         fullWidth
// // // //         margin="normal"
// // // //        />
// // // //       )}
// // // //      />
// // // //      <DialogActions>
// // // //       <Button onClick={onClose} color="secondary">
// // // //        Cancel
// // // //       </Button>
// // // //       <Button type="submit" color="primary">
// // // //        Submit
// // // //       </Button>
// // // //      </DialogActions>
// // // //     </form>
// // // //    </DialogContent>
// // // //   </Dialog>
// // // //  );
// // // // };

// // // // export default SparePartPriceForm;

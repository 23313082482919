import React from "react";
import { Modal, Box, Typography, IconButton, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  //  maxHeight: "90vh", // Ensures the modal doesn't exceed 90% of the viewport height
  overflow: "hidden",
};

const contentStyle = {
  overflowY: "auto", // Enables vertical scrolling
  maxHeight: "80vh", // Ensures the content doesn't exceed 80% of the viewport height
};

const ProductPreviewModal = ({ open, onClose, product }) => {
  if (!product) return null;

  return (
    <Modal open={open}>
      <Box sx={modalStyle}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Product Details</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={contentStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                <strong>Name:</strong> {product.productName}
              </Typography>
              <Typography variant="body1">
                <strong>MRP:</strong> {product.dummyPriceMrp}
              </Typography>
              <Typography variant="body1">
                <strong>Selling Price:</strong> {product.dummyPriceActual}
              </Typography>
              <Typography variant="body1">
                <strong>Category:</strong> {product.categoryId?.categoryName}
              </Typography>
              <Typography variant="body1">
                <strong>Sub-Category:</strong>{" "}
                {product.subCategoryId?.subCategoryName}
              </Typography>
              <Typography variant="body1">
                <strong>Ratings:</strong> {product.ratings}
              </Typography>
              <Typography variant="body1">
                <strong>Discount Percent:</strong> {product.discountPercent}%
              </Typography>
              <Typography variant="body1">
                <strong>Tags:</strong>
              </Typography>
              {product.tags.map((tag, index) => (
                <Typography key={index} variant="body2">
                  {tag.name}: {tag.value}
                </Typography>
              ))}
              <Typography variant="body1">
                <strong>Offer Tags:</strong>
              </Typography>
              {product.offerTag.map((tag, index) => (
                <Typography key={index} variant="body2">
                  {tag.name}: {tag.value}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src={product.productImage}
                alt="Product"
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Highlights:</strong>
              </Typography>
              {product.highlights.map((highlight, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <img
                    src={highlight.icon}
                    alt="icon"
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  />
                  <Typography variant="body2">{highlight.text}</Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Included Services:</strong>
              </Typography>
              {product.includedService.map((service, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2">
                    <strong>{service.name}</strong>
                  </Typography>
                  {service.services.map((s, idx) => (
                    <Box key={idx} display="flex" alignItems="center" ml={2}>
                      <img
                        src={s.image}
                        alt="service"
                        style={{ width: 24, height: 24, marginRight: 8 }}
                      />
                      <Typography variant="body2">{s.title}</Typography>
                    </Box>
                  ))}
                </Box>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Additional Services:</strong>
              </Typography>
              {product.additionalServices.map((service, index) => (
                <Typography key={index} variant="body2">
                  {service}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Steps After Booking:</strong>
              </Typography>
              {product.stepsAfterBooking.map((step, index) => (
                <Typography key={index} variant="body2">
                  {step}
                </Typography>
              ))}
              <Typography variant="body1">
                <strong>Product Banner Images</strong>
              </Typography>
              {product.productBannerImages.map((productBannerImage, index) => (
                <img
                  key={index}
                  src={productBannerImage}
                  alt="icon"
                  style={{ width: 200, height: 200, marginRight: 8 }}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductPreviewModal;

import * as types from "./actionTypes";

const initialState = {
 isLoading: false,
 isError: false,
 categories: [],
 subCategories: [],
 products: [],
 subCatById: [],
 singleProduct: [],
 productPriceBaseOnCar: [],
 carByBrand: [],
 carByTitleAndBrand: [],
 productByCarPrice: [],
 spareParts: [],
 sparePartPrices: [],
};

const reducer = (state = initialState, action) => {
 const { payload, type } = action;
 switch (type) {
  case types.CREATE_CATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.CREATE_CATEGORY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    // users: payload,
   };
  case types.CREATE_CATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.GET_CATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_CATEGORY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    categories: payload,
   };
  case types.GET_CATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.UPDATE_CATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.UPDATE_CATEGORY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    categories: payload,
   };
  case types.UPDATE_CATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.DELETE_CATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.DELETE_CATEGORY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    categories: payload,
   };
  case types.DELETE_CATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.GET_SUBCATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_SUBCATEGORY_SUCCESS:
   console.log("payload:", payload);
   //  console.log("subCategories:", subCategories);
   return {
    ...state,
    isLoading: false,
    isError: false,
    subCategories: payload,
   };
  case types.GET_SUBCATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.CREATE_SUBCATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.CREATE_SUBCATEGORY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    subCategories: payload,
   };
  case types.CREATE_SUBCATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.UPDATE_SUBCATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.UPDATE_SUBCATEGORY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    subCategories: payload,
   };
  case types.UPDATE_SUBCATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.DELETE_SUBCATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.DELETE_SUBCATEGORY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    subCategories: payload,
   };
  case types.DELETE_SUBCATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.GET_PRODUCT_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_PRODUCT_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    products: payload,
   };
  case types.GET_PRODUCT_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.CREATE_PRODUCT_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.CREATE_PRODUCT_SUCCESS:
   console.log("payload:", payload);
   return {
    ...state,
    isLoading: false,
    isError: false,
   };
  case types.CREATE_PRODUCT_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.GET_SUB_CATEGORY_BASED_ON_CATEGORY_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_SUB_CATEGORY_BASED_ON_CATEGORY_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    subCatById: payload,
   };
  case types.GET_SUB_CATEGORY_BASED_ON_CATEGORY_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.GET_PRODUCT_BY_ID_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_PRODUCT_BY_ID_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    singleProduct: payload,
   };
  case types.GET_PRODUCT_BY_ID_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.GET_PRICE_BY_CAR_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_PRICE_BY_CAR_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    productPriceBaseOnCar: payload,
   };
  case types.GET_PRICE_BY_CAR_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.GET_CAR_BY_BRAND_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.GET_CAR_BY_BRAND_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    carByBrand: payload,
   };
  case types.GET_CAR_BY_BRAND_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.GET_CARS_BY_BRAND_AND_TITLE_REQUEST:
   return {
    ...state,
    loading: true,
   };
  case types.GET_CARS_BY_BRAND_AND_TITLE_SUCCESS:
   return {
    ...state,
    loading: false,
    carByTitleAndBrand: payload,
   };
  case types.GET_CARS_BY_BRAND_AND_TITLE_FAILURE:
   return {
    ...state,
    loading: false,
    error: action.payload,
   };

  case types.GET_ALL_PRICES_REQUEST:
   return {
    ...state,
    loading: true,
   };
  case types.GET_ALL_PRICES_SUCCESS:
   return {
    ...state,
    loading: false,
    productByCarPrice: payload,
   };
  case types.GET_ALL_PRICES_FAILURE:
   return {
    ...state,
    loading: false,
    error: action.payload,
   };
  // sparePart / reducer;

  case types.GET_SPARE_PARTS_REQUEST:
  case types.GET_SPARE_PART_REQUEST:
  case types.ADD_SPARE_PART_REQUEST:
  case types.UPDATE_SPARE_PART_REQUEST:
  case types.DELETE_SPARE_PART_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };

  case types.GET_SPARE_PARTS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    spareParts: payload,
   };

  case types.GET_SPARE_PART_SUCCESS:
   return {
    ...state,
    isLoading: false,
    spareParts: state.spareParts.map((sparePart) =>
     sparePart._id === payload._id ? payload : sparePart
    ),
   };

  case types.ADD_SPARE_PART_SUCCESS:
   return {
    ...state,
    isLoading: false,
    spareParts: [...state.spareParts, payload],
   };

  case types.UPDATE_SPARE_PART_SUCCESS:
   return {
    ...state,
    isLoading: false,
    spareParts: state.spareParts.map((sparePart) =>
     sparePart._id === payload._id ? payload : sparePart
    ),
   };

  case types.DELETE_SPARE_PART_SUCCESS:
   return {
    ...state,
    isLoading: false,
    spareParts: state.spareParts.filter(
     (sparePart) => sparePart._id !== payload
    ),
   };

  case types.GET_SPARE_PARTS_FAILURE:
  case types.GET_SPARE_PART_FAILURE:
  case types.ADD_SPARE_PART_FAILURE:
  case types.UPDATE_SPARE_PART_FAILURE:
  case types.DELETE_SPARE_PART_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };
  case types.CREATE_SPARE_PART_PRICE_REQUEST:
   return {
    ...state,
    isLoading: true,
    error: null,
   };
  case types.CREATE_SPARE_PART_PRICE_SUCCESS:
   return {
    ...state,
    isLoading: false,
    sparePartPrices: payload,
   };
  case types.CREATE_SPARE_PART_PRICE_FAILURE:
   return {
    ...state,
    isLoading: false,
    error: action.payload,
   };
  // case types.CREATE_SPARE_PART_PRICE_REQUEST:
  case types.GET_SPARE_PART_PRICES_REQUEST:
  case types.UPDATE_SPARE_PART_PRICE_REQUEST:
  case types.DELETE_SPARE_PART_PRICE_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: "",
   };

  // case types.CREATE_SPARE_PART_PRICE_SUCCESS:
  //  return {
  //   ...state,
  //   isLoading: false,
  //   sparePartPrices: [...state.sparePartPrices, action.payload],
  //  };

  case types.GET_SPARE_PART_PRICES_SUCCESS:
   return {
    ...state,
    isLoading: false,
    sparePartPrices: action.payload,
   };

  case types.UPDATE_SPARE_PART_PRICE_SUCCESS:
   return {
    ...state,
    isLoading: false,
    sparePartPrices: state.sparePartPrices.map((price) =>
     price._id === action.payload._id ? action.payload : price
    ),
   };

  case types.DELETE_SPARE_PART_PRICE_SUCCESS:
   return {
    ...state,
    isLoading: false,
    sparePartPrices: state.sparePartPrices.filter(
     (price) => price._id !== action.payload
    ),
   };

  // case types.CREATE_SPARE_PART_PRICE_FAILURE:
  case types.GET_SPARE_PART_PRICES_FAILURE:
  case types.UPDATE_SPARE_PART_PRICE_FAILURE:
  case types.DELETE_SPARE_PART_PRICE_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.payload,
   };

  default:
   return state;
 }
};

export { reducer };

import React, { useEffect, useState } from "react";
import {
 Box,
 Typography,
 Fab,
 TextField,
 Dialog,
 DialogTitle,
 DialogContent,
 DialogActions,
 Snackbar,
 Alert,
 Button,
 FormControl,
 InputLabel,
 Select,
 MenuItem,
 IconButton,
 Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
 addCar,
 getCars,
 getAllCars,
 updateCars,
 deleteCarById,
 deleteCars,
 getBrands,
} from "../redux/admin/action";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CarForm from "../components/car/AddCarForm";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

const Cars = () => {
 const dispatch = useDispatch();
 const {
  allCars = [],
  loading,
  error,
  brands = [],
 } = useSelector((store) => store.admin);

 const [carTitle, setCarTitle] = useState("");
 const [transmissionFilter, setTransmissionFilter] = useState("");
 const [fuelFilter, setFuelFilter] = useState("");
 const [brandFilter, setBrandFilter] = useState("");
 const [modalOpen, setModalOpen] = useState(false);
 const [editMode, setEditMode] = useState(false);
 const [updateModalOpen, setUpdateModalOpen] = useState(false);
 const [selectedCar, setSelectedCar] = useState(null);
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [snackbarSeverity, setSnackbarSeverity] = useState("success");
 const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
 const [showAllVariants, setShowAllVariants] = useState(false);

 useEffect(() => {
  if (allCars.length === 0) {
   dispatch(getCars());
  }
 }, [dispatch, allCars.length]);

 const handleCarTitleChange = (event) => setCarTitle(event.target.value);
 const handleTransmissionFilterChange = (event) =>
  setTransmissionFilter(event.target.value);
 const handleFuelFilterChange = (event) => setFuelFilter(event.target.value);
 const handleBrandFilterChange = (event) => setBrandFilter(event.target.value);

 const handleEdit = (car) => {
  setSelectedCar(car);
  setEditMode(true);
  setModalOpen(true);
 };

 const handleUpdate = (car) => {
  setSelectedCar(car);
  setUpdateModalOpen(true);
 };

 const handleAdd = () => {
  setSelectedCar(null);
  setEditMode(false);
  setModalOpen(true);
 };

 const handleClose = () => {
  setModalOpen(false);
  setUpdateModalOpen(false);
  setDeleteDialogOpen(false);
 };

 const handleResetFilters = () => {
  setCarTitle("");
  setTransmissionFilter("");
  setFuelFilter("");
  setBrandFilter("");
  dispatch(getCars());
 };

 const handleDelete = async () => {
  try {
   if (selectedCar) {
    if (showAllVariants) {
     await dispatch(deleteCarById(selectedCar._id));
    } else {
     await dispatch(deleteCars(selectedCar.title));
    }
    setSnackbarMessage("Car deleted successfully!");
    setSnackbarSeverity("success");
    dispatch(getCars());
   } else {
    setSnackbarMessage("No car selected for deletion.");
    setSnackbarSeverity("warning");
   }
  } catch (error) {
   console.error("Error deleting car:", error);
   setSnackbarMessage("Error deleting car. Please try again.");
   setSnackbarSeverity("error");
  }
  setSnackbarOpen(true);
  handleClose();
 };

 const handleDeleteConfirmation = (car) => {
  setSelectedCar(car);
  setDeleteDialogOpen(true);
 };

 const handleSubmit = async (formData) => {
  try {
   if (editMode && selectedCar) {
    await dispatch(updateCars(selectedCar._id, formData));
    setSnackbarMessage("Car updated successfully!");
    setSnackbarSeverity("success");
   } else {
    await dispatch(addCar(formData));
    setSnackbarMessage("Car added successfully!");
    setSnackbarSeverity("success");
   }
  } catch (error) {
   console.error("Error uploading car:", error);
   setSnackbarMessage(
    error.response?.data?.message || "Error uploading car. Please try again."
   );
   setSnackbarSeverity("error");
  } finally {
   setSnackbarOpen(true);
   handleClose();
   dispatch(getCars());
  }
 };

 const handleUpdateSubmit = async (formData) => {
  try {
   if (selectedCar && selectedCar._id) {
    await dispatch(updateCars(selectedCar._id, formData));
    setSnackbarMessage("Car updated successfully!");
    setSnackbarSeverity("success");
   } else {
    setSnackbarMessage("No car selected for update.");
    setSnackbarSeverity("warning");
   }
  } catch (error) {
   console.error("Error updating car:", error);
   setSnackbarMessage(
    error.response?.data?.message || "Error updating car. Please try again."
   );
   setSnackbarSeverity("error");
  } finally {
   setSnackbarOpen(true);
   handleClose();
   dispatch(getCars());
  }
 };

 const groupCarsByTitle = (cars) => {
  const groupedCars = {};

  cars.forEach((car) => {
   if (!car) return;
   const { title, transmissionType, fuelType } = car;

   if (!groupedCars[title]) {
    groupedCars[title] = {
     ...car,
     transmissionTypes: new Set(),
     fuelTypes: new Set(),
    };
   }

   groupedCars[title].transmissionTypes.add(transmissionType);
   groupedCars[title].fuelTypes.add(fuelType);
  });

  return Object.values(groupedCars).map((car) => ({
   ...car,
   transmissionTypes: Array.from(car.transmissionTypes).join(", "),
   fuelTypes: Array.from(car.fuelTypes).join(", "),
  }));
 };

 const handleToggleVariants = () => {
  setShowAllVariants((prev) => !prev);
  if (!showAllVariants) {
   dispatch(
    getAllCars({
     title: carTitle,
     transmissionType: transmissionFilter,
     fuelType: fuelFilter,
     brand: brandFilter,
    })
   );
  } else {
   dispatch(getCars());
  }
 };

 const filteredCars = (
  showAllVariants ? allCars : groupCarsByTitle(allCars)
 ).filter((car) => {
  if (!car) return false;
  const matchesTitle = car?.title
   ? car.title.toLowerCase().includes(carTitle.toLowerCase())
   : false;
  const matchesTransmission = transmissionFilter
   ? showAllVariants
     ? car.transmissionType
        .toLowerCase()
        .includes(transmissionFilter.toLowerCase())
     : car.transmissionTypes.includes(transmissionFilter)
   : true;
  const matchesFuel = fuelFilter
   ? showAllVariants
     ? car.fuelType.toLowerCase().includes(fuelFilter.toLowerCase())
     : car.fuelTypes.includes(fuelFilter)
   : true;
  const matchesBrand = brandFilter
   ? car.brand?.title.toLowerCase().includes(brandFilter.toLowerCase())
   : true;
  return matchesTitle && matchesTransmission && matchesFuel && matchesBrand;
 });

 const rows = filteredCars.map((car, index) => ({
  ...car,
  serialNumber: index + 1,
  brandTitle: car.brand?.title || "N/A",
  fuelTypes: showAllVariants ? car.fuelType : car.fuelTypes,
  transmissionTypes: showAllVariants
   ? car.transmissionType
   : car.transmissionTypes,
 }));

 const columns = [
  { field: "serialNumber", headerName: "S.No.", width: 100 },
  { field: "title", headerName: "Car Title", width: 250 },
  { field: "brandTitle", headerName: "Brand Title", width: 200 },
  { field: "fuelTypes", headerName: "Fuel Types", width: 200 },
  {
   field: "transmissionTypes",
   headerName: "Transmission Types",
   width: 200,
  },
  {
   field: "images",
   headerName: "Images",
   width: 100,
   renderCell: (params) => (
    <img
     src={params.row.images}
     alt={`Car image ${params.row.images}`}
     style={{ width: "50px", height: "50px" }}
    />
   ),
  },
  {
   field: "actions",
   headerName: "Actions",
   width: 150,
   renderCell: (params) => (
    <>
     {showAllVariants && (
      <Fab
       color="secondary"
       aria-label="edit"
       size="small"
       onClick={() => handleUpdate(params.row)}
      >
       <EditIcon />
      </Fab>
     )}
     <Fab
      color="secondary"
      aria-label="delete"
      size="small"
      onClick={() => handleDeleteConfirmation(params.row)}
     >
      <DeleteIcon />
     </Fab>
    </>
   ),
  },
 ];

 return (
  <Box sx={{ width: "100%", padding: "20px", boxShadow: 3 }}>
   <Typography fontSize={"25px"} padding="5px" fontWeight={"600"}>
    {`Cars/${allCars.length}`}
   </Typography>
   <Box
    sx={{
     padding: "10px",
     display: "flex",
     flexDirection: "column",
     gap: "20px",
    }}
   >
    <Grid container spacing={2} alignItems="center">
     <Grid item xs={12} sm="auto">
      <Fab color="secondary" aria-label="add" onClick={handleAdd}>
       <AddIcon />
      </Fab>
     </Grid>
     <Grid item xs={12} sm>
      <TextField
       label="Car Title"
       value={carTitle}
       onChange={handleCarTitleChange}
       fullWidth
      />
     </Grid>
     <Grid item xs={12} sm>
      <TextField
       label="Transmission Type"
       value={transmissionFilter}
       onChange={handleTransmissionFilterChange}
       fullWidth
      />
     </Grid>
     <Grid item xs={12} sm>
      <TextField
       label="Fuel Type"
       value={fuelFilter}
       onChange={handleFuelFilterChange}
       fullWidth
      />
     </Grid>
     <Grid item xs={12} sm>
      <TextField
       label="Brand"
       value={brandFilter}
       onChange={handleBrandFilterChange}
       fullWidth
      />
     </Grid>
     <Grid item xs={12} sm="auto">
      <Fab color="primary" aria-label="clear" onClick={handleResetFilters}>
       <ClearIcon />
      </Fab>
     </Grid>
     <Grid item xs={12} sm="auto">
      <IconButton
       color="primary"
       aria-label="toggle"
       onClick={handleToggleVariants}
      >
       {showAllVariants ? <ToggleOnIcon /> : <ToggleOffIcon />}
      </IconButton>
     </Grid>
    </Grid>
   </Box>

   <Box sx={{ height: 600, width: "100%", marginTop: "10px" }}>
    <DataGrid
     rows={rows}
     columns={columns}
     pageSize={10}
     rowsPerPageOptions={[10]}
     getRowId={(row) => row._id}
    />
   </Box>

   <Dialog open={modalOpen} onClose={handleClose}>
    <DialogTitle>{editMode ? "Edit Car" : "Add Car"}</DialogTitle>
    <DialogContent>
     <CarForm
      car={selectedCar}
      editMode={editMode}
      onSubmit={handleSubmit}
      onClose={handleClose}
     />
    </DialogContent>
   </Dialog>

   <Dialog open={updateModalOpen} onClose={handleClose}>
    <DialogTitle>Edit Car</DialogTitle>
    <DialogContent>
     <UpdateCarForm
      car={selectedCar}
      onSubmit={handleUpdateSubmit}
      onClose={handleClose}
     />
    </DialogContent>
   </Dialog>

   <Dialog open={deleteDialogOpen} onClose={handleClose}>
    <DialogTitle>Confirm Deletion</DialogTitle>
    <DialogContent>
     <Typography>
      Are you sure you want to delete this car model and all its combinations?
     </Typography>
    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose} color="primary">
      Cancel
     </Button>
     <Button onClick={handleDelete} color="secondary">
      Delete
     </Button>
    </DialogActions>
   </Dialog>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={() => setSnackbarOpen(false)}
   >
    <Alert
     onClose={() => setSnackbarOpen(false)}
     severity={snackbarSeverity}
     variant="filled"
     sx={{ width: "100%" }}
    >
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Box>
 );
};

const UpdateCarForm = ({ car, onSubmit, onClose }) => {
 const dispatch = useDispatch();

 const [title, setTitle] = useState(car?.title || "");
 const [brand, setBrand] = useState(car?.brand?._id || "");
 const [image, setImage] = useState(null);
 const [imagePreview, setImagePreview] = useState(car?.images || "");
 const { brands = [] } = useSelector((store) => store.admin);

 useEffect(() => {
  if (brands.length === 0) {
   dispatch(getBrands());
  }
 }, [dispatch, brands.length]);

 const handleSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData();
  formData.append("title", title);
  formData.append("brand", brand);
  if (image) {
   formData.append("image", image);
  }
  onSubmit(formData);
 };

 const handleImageChange = (event) => {
  const file = event.target.files[0];
  if (file) {
   const fileUrl = URL.createObjectURL(file);
   setImage(file);
   setImagePreview(fileUrl);
  }
 };

 const handleBrandChange = (event) => {
  setBrand(event.target.value);
 };

 return (
  <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
   <TextField
    label="Car Title"
    value={title}
    onChange={(e) => setTitle(e.target.value)}
    fullWidth
    margin="normal"
   />
   <FormControl fullWidth variant="outlined" margin="normal">
    <InputLabel id="brand-label">Brand</InputLabel>
    <Select
     labelId="brand-label"
     id="brand-select"
     value={brand}
     onChange={handleBrandChange}
     label="Brand"
    >
     {brands?.map((brand) => (
      <MenuItem key={brand._id} value={brand._id}>
       {brand?.title}
      </MenuItem>
     ))}
    </Select>
   </FormControl>

   <Button variant="contained" component="label" fullWidth margin="normal">
    Upload Image
    <input type="file" hidden onChange={handleImageChange} />
   </Button>
   {imagePreview && (
    <Box sx={{ marginTop: 2 }}>
     <Box sx={{ display: "inline-block", marginRight: 2 }}>
      <img
       src={imagePreview}
       alt="Car"
       style={{ width: "100px", height: "100px" }}
      />
     </Box>
    </Box>
   )}
   <Box sx={{ marginTop: 2, textAlign: "right" }}>
    <DialogActions>
     <Button type="submit" variant="contained" color="primary">
      Update Car
     </Button>
     <Button variant="outlined" onClick={onClose}>
      Cancel
     </Button>
    </DialogActions>
   </Box>
  </Box>
 );
};

export default Cars;

// // File: Cars.js
// import React, { useEffect, useState } from "react";
// import {
//  Box,
//  Typography,
//  Fab,
//  TextField,
//  Dialog,
//  DialogTitle,
//  DialogContent,
//  DialogActions,
//  Snackbar,
//  Alert,
//  Button,
//  FormControl,
//  InputLabel,
//  Select,
//  MenuItem,
//  IconButton,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import { useDispatch, useSelector } from "react-redux";
// import {
//  addCar,
//  getCars,
//  getAllCars,
//  updateCars,
//  deleteCars,
//  getBrands,
// } from "../redux/admin/action";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddIcon from "@mui/icons-material/Add";
// import ClearIcon from "@mui/icons-material/Clear";
// import CarForm from "../components/car/AddCarForm";
// import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import ToggleOnIcon from "@mui/icons-material/ToggleOn";

// const Cars = () => {
//  const dispatch = useDispatch();
//  const {
//   allCars = [],
//   loading,
//   error,
//   brands = [],
//  } = useSelector((store) => store.admin);

//  const [carTitle, setCarTitle] = useState("");
//  const [transmissionFilter, setTransmissionFilter] = useState("");
//  const [fuelFilter, setFuelFilter] = useState("");
//  const [brandFilter, setBrandFilter] = useState("");
//  const [modalOpen, setModalOpen] = useState(false);
//  const [editMode, setEditMode] = useState(false);
//  const [updateModalOpen, setUpdateModalOpen] = useState(false);
//  const [selectedCar, setSelectedCar] = useState(null);
//  const [snackbarOpen, setSnackbarOpen] = useState(false);
//  const [snackbarMessage, setSnackbarMessage] = useState("");
//  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
//  const [showAllVariants, setShowAllVariants] = useState(false);

//  useEffect(() => {
//   if (allCars.length === 0) {
//    dispatch(getCars());
//   }
//  }, [dispatch, allCars.length]);

//  const handleCarTitleChange = (event) => setCarTitle(event.target.value);
//  const handleTransmissionFilterChange = (event) =>
//   setTransmissionFilter(event.target.value);
//  const handleFuelFilterChange = (event) => setFuelFilter(event.target.value);
//  const handleBrandFilterChange = (event) => setBrandFilter(event.target.value);

//  const handleEdit = (car) => {
//   setSelectedCar(car);
//   setEditMode(true);
//   setModalOpen(true);
//  };

//  const handleUpdate = (car) => {
//   setSelectedCar(car);
//   setUpdateModalOpen(true);
//  };

//  const handleAdd = () => {
//   setSelectedCar(null);
//   setEditMode(false);
//   setModalOpen(true);
//  };

//  const handleClose = () => {
//   setModalOpen(false);
//   setUpdateModalOpen(false);
//   setDeleteDialogOpen(false);
//  };

//  const handleResetFilters = () => {
//   setCarTitle("");
//   setTransmissionFilter("");
//   setFuelFilter("");
//   setBrandFilter("");
//   dispatch(getCars());
//  };

//  const handleDelete = async () => {
//   try {
//    if (selectedCar && selectedCar.title) {
//     await dispatch(deleteCars(selectedCar.title));
//     setSnackbarMessage("Car deleted successfully!");
//     setSnackbarSeverity("success");
//     dispatch(getCars());
//    } else {
//     setSnackbarMessage("No car selected for deletion.");
//     setSnackbarSeverity("warning");
//    }
//   } catch (error) {
//    console.error("Error deleting car:", error);
//    setSnackbarMessage("Error deleting car. Please try again.");
//    setSnackbarSeverity("error");
//   }
//   setSnackbarOpen(true);
//   handleClose();
//  };

//  const handleDeleteConfirmation = (car) => {
//   setSelectedCar(car);
//   setDeleteDialogOpen(true);
//  };

//  const handleSubmit = async (formData) => {
//   try {
//    if (editMode && selectedCar) {
//     await dispatch(updateCars(selectedCar._id, formData));
//     setSnackbarMessage("Car updated successfully!");
//     setSnackbarSeverity("success");
//    } else {
//     await dispatch(addCar(formData));
//     setSnackbarMessage("Car added successfully!");
//     setSnackbarSeverity("success");
//    }
//   } catch (error) {
//    console.error("Error uploading car:", error);
//    setSnackbarMessage(
//     error.response?.data?.message || "Error uploading car. Please try again."
//    );
//    setSnackbarSeverity("error");
//   } finally {
//    setSnackbarOpen(true);
//    handleClose();
//    dispatch(getCars());
//   }
//  };

//  const handleUpdateSubmit = async (formData) => {
//   try {
//    if (selectedCar && selectedCar._id) {
//     await dispatch(updateCars(selectedCar._id, formData));
//     setSnackbarMessage("Car updated successfully!");
//     setSnackbarSeverity("success");
//    } else {
//     setSnackbarMessage("No car selected for update.");
//     setSnackbarSeverity("warning");
//    }
//   } catch (error) {
//    console.error("Error updating car:", error);
//    setSnackbarMessage(
//     error.response?.data?.message || "Error updating car. Please try again."
//    );
//    setSnackbarSeverity("error");
//   } finally {
//    setSnackbarOpen(true);
//    handleClose();
//    dispatch(getCars());
//   }
//  };

//  const groupCarsByTitle = (cars) => {
//   const groupedCars = {};

//   cars.forEach((car) => {
//    if (!car) return;
//    const { title, transmissionType, fuelType } = car;

//    if (!groupedCars[title]) {
//     groupedCars[title] = {
//      ...car,
//      transmissionTypes: new Set(),
//      fuelTypes: new Set(),
//     };
//    }

//    groupedCars[title].transmissionTypes.add(transmissionType);
//    groupedCars[title].fuelTypes.add(fuelType);
//   });

//   return Object.values(groupedCars).map((car) => ({
//    ...car,
//    transmissionTypes: Array.from(car.transmissionTypes).join(", "),
//    fuelTypes: Array.from(car.fuelTypes).join(", "),
//   }));
//  };

//  const handleToggleVariants = () => {
//   setShowAllVariants((prev) => !prev);
//   if (!showAllVariants) {
//    dispatch(
//     getAllCars({
//      title: carTitle,
//      transmissionType: transmissionFilter,
//      fuelType: fuelFilter,
//      brand: brandFilter,
//     })
//    );
//   } else {
//    dispatch(getCars());
//   }
//  };

//  const filteredCars = (
//   showAllVariants ? allCars : groupCarsByTitle(allCars)
//  ).filter((car) => {
//   if (!car) return false;
//   const matchesTitle = car?.title
//    ? car.title.toLowerCase().includes(carTitle.toLowerCase())
//    : false;
//   const matchesTransmission = transmissionFilter
//    ? showAllVariants
//      ? car.transmissionType
//         .toLowerCase()
//         .includes(transmissionFilter.toLowerCase())
//      : car.transmissionTypes.includes(transmissionFilter)
//    : true;
//   const matchesFuel = fuelFilter
//    ? showAllVariants
//      ? car.fuelType.toLowerCase().includes(fuelFilter.toLowerCase())
//      : car.fuelTypes.includes(fuelFilter)
//    : true;
//   const matchesBrand = brandFilter
//    ? car.brand?.title.toLowerCase().includes(brandFilter.toLowerCase())
//    : true;
//   return matchesTitle && matchesTransmission && matchesFuel && matchesBrand;
//  });

//  const rows = filteredCars.map((car, index) => ({
//   ...car,
//   serialNumber: index + 1,
//   brandTitle: car.brand?.title || "N/A",
//   fuelTypes: showAllVariants ? car.fuelType : car.fuelTypes,
//   transmissionTypes: showAllVariants
//    ? car.transmissionType
//    : car.transmissionTypes,
//  }));

//  const columns = [
//   { field: "serialNumber", headerName: "S.No.", width: 100 },
//   { field: "title", headerName: "Car Title", width: 250 },
//   { field: "brandTitle", headerName: "Brand Title", width: 200 },
//   { field: "fuelTypes", headerName: "Fuel Types", width: 200 },
//   { field: "transmissionTypes", headerName: "Transmission Types", width: 200 },
//   {
//    field: "images",
//    headerName: "Images",
//    width: 100,
//    renderCell: (params) => (
//     <img
//      src={params.row.images}
//      alt={`Car image ${params.row.images}`}
//      style={{ width: "50px", height: "50px" }}
//     />
//    ),
//   },
//   {
//    field: "actions",
//    headerName: "Actions",
//    width: 150,
//    renderCell: (params) => (
//     <>
//      {showAllVariants && (
//       <Fab
//        color="secondary"
//        aria-label="edit"
//        size="small"
//        onClick={() => handleUpdate(params.row)}
//       >
//        <EditIcon />
//       </Fab>
//      )}
//      <Fab
//       color="secondary"
//       aria-label="delete"
//       size="small"
//       onClick={() => handleDeleteConfirmation(params.row)}
//      >
//       <DeleteIcon />
//      </Fab>
//     </>
//    ),
//   },
//  ];

//  return (
//   <Box sx={{ width: "100%", padding: "20px", boxShadow: 3 }}>
//    <Typography fontSize={"25px"} padding="5px" fontWeight={"600"}>
//     {`Cars/${allCars.length}`}
//    </Typography>

//    <Box
//     sx={{
//      padding: "10px",
//      display: "flex",
//      alignItems: "center",
//      gap: "50px",
//     }}
//    >
//     <Fab color="secondary" aria-label="add" onClick={handleAdd}>
//      <AddIcon />
//     </Fab>
//     <TextField
//      label="Car Title"
//      value={carTitle}
//      onChange={handleCarTitleChange}
//     />
//     <TextField
//      label="Transmission Type"
//      value={transmissionFilter}
//      onChange={handleTransmissionFilterChange}
//     />
//     <TextField
//      label="Fuel Type"
//      value={fuelFilter}
//      onChange={handleFuelFilterChange}
//     />
//     <TextField
//      label="Brand"
//      value={brandFilter}
//      onChange={handleBrandFilterChange}
//     />
//     <Fab color="primary" aria-label="clear" onClick={handleResetFilters}>
//      <ClearIcon />
//     </Fab>
//     <IconButton
//      color="primary"
//      aria-label="toggle"
//      onClick={handleToggleVariants}
//     >
//      {showAllVariants ? <ToggleOnIcon /> : <ToggleOffIcon />}
//     </IconButton>
//    </Box>

//    <Box sx={{ height: 600, width: "100%", marginTop: "10px" }}>
//     <DataGrid
//      rows={rows}
//      columns={columns}
//      pageSize={10}
//      rowsPerPageOptions={[10]}
//      getRowId={(row) => row._id}
//     />
//    </Box>

//    <Dialog open={modalOpen} onClose={handleClose}>
//     <DialogTitle>{editMode ? "Edit Car" : "Add Car"}</DialogTitle>
//     <DialogContent>
//      <CarForm
//       car={selectedCar}
//       editMode={editMode}
//       onSubmit={handleSubmit}
//       onClose={handleClose}
//      />
//     </DialogContent>
//    </Dialog>

//    <Dialog open={updateModalOpen} onClose={handleClose}>
//     <DialogTitle>Edit Car</DialogTitle>
//     <DialogContent>
//      <UpdateCarForm
//       car={selectedCar}
//       onSubmit={handleUpdateSubmit}
//       onClose={handleClose}
//      />
//     </DialogContent>
//    </Dialog>

//    <Dialog open={deleteDialogOpen} onClose={handleClose}>
//     <DialogTitle>Confirm Deletion</DialogTitle>
//     <DialogContent>
//      <Typography>
//       Are you sure you want to delete this car model and all its combinations?
//      </Typography>
//     </DialogContent>
//     <DialogActions>
//      <Button onClick={handleClose} color="primary">
//       Cancel
//      </Button>
//      <Button onClick={handleDelete} color="secondary">
//       Delete
//      </Button>
//     </DialogActions>
//    </Dialog>
//    <Snackbar
//     open={snackbarOpen}
//     autoHideDuration={6000}
//     onClose={() => setSnackbarOpen(false)}
//    >
//     <Alert
//      onClose={() => setSnackbarOpen(false)}
//      severity={snackbarSeverity}
//      variant="filled"
//      sx={{ width: "100%" }}
//     >
//      {snackbarMessage}
//     </Alert>
//    </Snackbar>
//   </Box>
//  );
// };

// const UpdateCarForm = ({ car, onSubmit, onClose }) => {
//  const dispatch = useDispatch();

//  const [title, setTitle] = useState(car?.title || "");
//  const [brand, setBrand] = useState(car?.brand?._id || "");
//  const [image, setImage] = useState(null);
//  const [imagePreview, setImagePreview] = useState(car?.images || "");
//  const { brands = [] } = useSelector((store) => store.admin);

//  useEffect(() => {
//   if (brands.length === 0) {
//    dispatch(getBrands());
//   }
//  }, [dispatch, brands.length]);

//  const handleSubmit = (event) => {
//   event.preventDefault();
//   const formData = new FormData();
//   formData.append("title", title);
//   formData.append("brand", brand);
//   if (image) {
//    formData.append("image", image);
//   }
//   onSubmit(formData);
//  };

//  const handleImageChange = (event) => {
//   const file = event.target.files[0];
//   if (file) {
//    const fileUrl = URL.createObjectURL(file);
//    setImage(file);
//    setImagePreview(fileUrl);
//   }
//  };

//  const handleBrandChange = (event) => {
//   setBrand(event.target.value);
//  };

//  return (
//   <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
//    <TextField
//     label="Car Title"
//     value={title}
//     onChange={(e) => setTitle(e.target.value)}
//     fullWidth
//     margin="normal"
//    />
//    <FormControl fullWidth variant="outlined" margin="normal">
//     <InputLabel id="brand-label">Brand</InputLabel>
//     <Select
//      labelId="brand-label"
//      id="brand-select"
//      value={brand}
//      onChange={handleBrandChange}
//      label="Brand"
//     >
//      {brands?.map((brand) => (
//       <MenuItem key={brand._id} value={brand._id}>
//        {brand?.title}
//       </MenuItem>
//      ))}
//     </Select>
//    </FormControl>

//    <Button variant="contained" component="label" fullWidth margin="normal">
//     Upload Image
//     <input type="file" hidden onChange={handleImageChange} />
//    </Button>
//    {imagePreview && (
//     <Box sx={{ marginTop: 2 }}>
//      <Box sx={{ display: "inline-block", marginRight: 2 }}>
//       <img
//        src={imagePreview}
//        alt="Car"
//        style={{ width: "100px", height: "100px" }}
//       />
//      </Box>
//     </Box>
//    )}
//    <Box sx={{ marginTop: 2, textAlign: "right" }}>
//     <DialogActions>
//      <Button type="submit" variant="contained" color="primary">
//       Update Car
//      </Button>
//      <Button variant="outlined" onClick={onClose}>
//       Cancel
//      </Button>
//     </DialogActions>
//    </Box>
//   </Box>
//  );
// };

// export default Cars;

// // import React, { useEffect, useState } from "react";
// // import {
// //  Box,
// //  Typography,
// //  Fab,
// //  TextField,
// //  Dialog,
// //  DialogTitle,
// //  DialogContent,
// //  DialogActions,
// //  Snackbar,
// //  Alert,
// //  Button,
// //  FormControl,
// //  InputLabel,
// //  Select,
// //  MenuItem,
// // } from "@mui/material";
// // import { DataGrid } from "@mui/x-data-grid";
// // import { useDispatch, useSelector } from "react-redux";
// // import {
// //  addCar,
// //  getCars,
// //  updateCars,
// //  deleteCars,
// //  getBrands,
// // } from "../redux/admin/action";
// // import EditIcon from "@mui/icons-material/Edit";
// // import DeleteIcon from "@mui/icons-material/Delete";
// // import AddIcon from "@mui/icons-material/Add";
// // import ClearIcon from "@mui/icons-material/Clear";
// // import CarForm from "../components/car/AddCarForm";

// // const Cars = () => {
// //  const dispatch = useDispatch();
// //  const {
// //   allCars = [],
// //   loading,
// //   error,
// //   brands = [],
// //  } = useSelector((store) => store.admin);

// //  const [carTitle, setCarTitle] = useState("");
// //  const [transmissionFilter, setTransmissionFilter] = useState("");
// //  const [fuelFilter, setFuelFilter] = useState("");
// //  const [brandFilter, setBrandFilter] = useState("");
// //  const [modalOpen, setModalOpen] = useState(false);
// //  const [editMode, setEditMode] = useState(false);
// //  const [updateModalOpen, setUpdateModalOpen] = useState(false);
// //  const [selectedCar, setSelectedCar] = useState(null);
// //  const [snackbarOpen, setSnackbarOpen] = useState(false);
// //  const [snackbarMessage, setSnackbarMessage] = useState("");
// //  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
// //  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

// //  useEffect(() => {
// //   if (allCars.length === 0) {
// //    dispatch(getCars());
// //   }
// //  }, [dispatch, allCars.length]);

// //  const handleCarTitleChange = (event) => setCarTitle(event.target.value);
// //  const handleTransmissionFilterChange = (event) =>
// //   setTransmissionFilter(event.target.value);
// //  const handleFuelFilterChange = (event) => setFuelFilter(event.target.value);
// //  const handleBrandFilterChange = (event) => setBrandFilter(event.target.value);

// //  const handleEdit = (car) => {
// //   setSelectedCar(car);
// //   setEditMode(true);
// //   setModalOpen(true);
// //  };

// //  const handleUpdate = (car) => {
// //   setSelectedCar(car);
// //   setUpdateModalOpen(true);
// //  };

// //  const handleAdd = () => {
// //   setSelectedCar(null);
// //   setEditMode(false);
// //   setModalOpen(true);
// //  };

// //  const handleClose = () => {
// //   setModalOpen(false);
// //   setUpdateModalOpen(false);
// //   setDeleteDialogOpen(false);
// //  };

// //  const handleResetFilters = () => {
// //   setCarTitle("");
// //   setTransmissionFilter("");
// //   setFuelFilter("");
// //   setBrandFilter("");
// //   dispatch(getCars());
// //  };

// //  const handleDelete = async () => {
// //   try {
// //    if (selectedCar && selectedCar.title) {
// //     await dispatch(deleteCars(selectedCar.title));
// //     setSnackbarMessage("Car deleted successfully!");
// //     setSnackbarSeverity("success");
// //     dispatch(getCars());
// //    } else {
// //     setSnackbarMessage("No car selected for deletion.");
// //     setSnackbarSeverity("warning");
// //    }
// //   } catch (error) {
// //    console.error("Error deleting car:", error);
// //    setSnackbarMessage("Error deleting car. Please try again.");
// //    setSnackbarSeverity("error");
// //   }
// //   setSnackbarOpen(true);
// //   handleClose();
// //  };

// //  const handleDeleteConfirmation = (car) => {
// //   setSelectedCar(car);
// //   setDeleteDialogOpen(true);
// //  };

// //  const handleSubmit = async (formData) => {
// //   try {
// //    if (editMode && selectedCar) {
// //     await dispatch(updateCars(selectedCar._id, formData));
// //     setSnackbarMessage("Car updated successfully!");
// //     setSnackbarSeverity("success");
// //    } else {
// //     await dispatch(addCar(formData));
// //     setSnackbarMessage("Car added successfully!");
// //     setSnackbarSeverity("success");
// //    }
// //   } catch (error) {
// //    console.error("Error uploading car:", error);
// //    setSnackbarMessage(
// //     error.response?.data?.message || "Error uploading car. Please try again."
// //    );
// //    setSnackbarSeverity("error");
// //   } finally {
// //    setSnackbarOpen(true);
// //    handleClose();
// //    dispatch(getCars());
// //   }
// //  };

// //  const handleUpdateSubmit = async (formData) => {
// //   try {
// //    if (selectedCar && selectedCar._id) {
// //     await dispatch(updateCars(selectedCar._id, formData));
// //     setSnackbarMessage("Car updated successfully!");
// //     setSnackbarSeverity("success");
// //    } else {
// //     setSnackbarMessage("No car selected for update.");
// //     setSnackbarSeverity("warning");
// //    }
// //   } catch (error) {
// //    console.error("Error updating car:", error);
// //    setSnackbarMessage(
// //     error.response?.data?.message || "Error updating car. Please try again."
// //    );
// //    setSnackbarSeverity("error");
// //   } finally {
// //    setSnackbarOpen(true);
// //    handleClose();
// //    dispatch(getCars());
// //   }
// //  };

// //  const groupCarsByTitle = (cars) => {
// //   const groupedCars = {};

// //   cars.forEach((car) => {
// //    if (!car) return;
// //    const { title, transmissionType, fuelType } = car;

// //    if (!groupedCars[title]) {
// //     groupedCars[title] = {
// //      ...car,
// //      transmissionTypes: new Set(),
// //      fuelTypes: new Set(),
// //     };
// //    }

// //    groupedCars[title].transmissionTypes.add(transmissionType);
// //    groupedCars[title].fuelTypes.add(fuelType);
// //   });

// //   return Object.values(groupedCars).map((car) => ({
// //    ...car,
// //    transmissionTypes: Array.from(car.transmissionTypes).join(", "),
// //    fuelTypes: Array.from(car.fuelTypes).join(", "),
// //   }));
// //  };

// //  const filteredCars = groupCarsByTitle(allCars).filter((car) => {
// //   if (!car) return false;
// //   const matchesTitle = car?.title
// //    ? car.title.toLowerCase().includes(carTitle.toLowerCase())
// //    : false;
// //   const matchesTransmission = transmissionFilter
// //    ? car.transmissionTypes.includes(transmissionFilter)
// //    : true;
// //   const matchesFuel = fuelFilter ? car.fuelTypes.includes(fuelFilter) : true;
// //   const matchesBrand = brandFilter
// //    ? car.brand?.title.toLowerCase().includes(brandFilter.toLowerCase())
// //    : true;
// //   return matchesTitle && matchesTransmission && matchesFuel && matchesBrand;
// //  });

// //  const rows = filteredCars.map((car, index) => ({
// //   ...car,
// //   serialNumber: index + 1,
// //   brandTitle: car.brand?.title || "N/A",
// //  }));

// //  const columns = [
// //   { field: "serialNumber", headerName: "S.No.", width: 100 },
// //   { field: "title", headerName: "Car Title", width: 250 },
// //   { field: "brandTitle", headerName: "Brand Title", width: 200 },
// //   { field: "fuelTypes", headerName: "Fuel Types", width: 200 },
// //   { field: "transmissionTypes", headerName: "Transmission Types", width: 200 },
// //   {
// //    field: "images",
// //    headerName: "Images",
// //    width: 100,
// //    renderCell: (params) => (
// //     <img
// //      src={params.row.images}
// //      alt={`Car image ${params.row.images}`}
// //      style={{ width: "50px", height: "50px" }}
// //     />
// //    ),
// //   },
// //   {
// //    field: "brandLogo",
// //    headerName: "Brand Logo",
// //    width: 100,
// //    renderCell: (params) => (
// //     <img
// //      src={params.row.brand?.logo}
// //      alt={params.row.brandTitle ? `${params.row.brandTitle} logo` : "N/A"}
// //      style={{ width: "50px", height: "50px" }}
// //     />
// //    ),
// //   },
// //   {
// //    field: "actions",
// //    headerName: "Actions",
// //    width: 150,
// //    renderCell: (params) => (
// //     <>
// //      <Fab
// //       color="secondary"
// //       aria-label="edit"
// //       size="small"
// //       onClick={() => handleUpdate(params.row)}
// //      >
// //       <EditIcon />
// //      </Fab>
// //      <Fab
// //       color="secondary"
// //       aria-label="delete"
// //       size="small"
// //       onClick={() => handleDeleteConfirmation(params.row)}
// //      >
// //       <DeleteIcon />
// //      </Fab>
// //     </>
// //    ),
// //   },
// //  ];

// //  return (
// //   <Box sx={{ width: "100%", padding: "20px", boxShadow: 3 }}>
// //    <Typography fontSize={"25px"} padding="5px" fontWeight={"600"}>
// //     {`Cars/${allCars.length}`}
// //    </Typography>

// //    <Box
// //     sx={{
// //      padding: "10px",
// //      display: "flex",
// //      alignItems: "center",
// //      gap: "50px",
// //     }}
// //    >
// //     <Fab color="secondary" aria-label="add" onClick={handleAdd}>
// //      <AddIcon />
// //     </Fab>
// //     <TextField
// //      label="Car Title"
// //      value={carTitle}
// //      onChange={handleCarTitleChange}
// //     />
// //     <TextField
// //      label="Transmission Type"
// //      value={transmissionFilter}
// //      onChange={handleTransmissionFilterChange}
// //     />
// //     <TextField
// //      label="Fuel Type"
// //      value={fuelFilter}
// //      onChange={handleFuelFilterChange}
// //     />
// //     <TextField
// //      label="Brand"
// //      value={brandFilter}
// //      onChange={handleBrandFilterChange}
// //     />
// //     <Fab color="primary" aria-label="clear" onClick={handleResetFilters}>
// //      <ClearIcon />
// //     </Fab>
// //    </Box>

// //    <Box sx={{ height: 600, width: "100%", marginTop: "10px" }}>
// //     <DataGrid
// //      rows={rows}
// //      columns={columns}
// //      pageSize={10}
// //      rowsPerPageOptions={[10]}
// //      getRowId={(row) => row._id}
// //     />
// //    </Box>

// //    <Dialog open={modalOpen} onClose={handleClose}>
// //     <DialogTitle>{editMode ? "Edit Car" : "Add Car"}</DialogTitle>
// //     <DialogContent>
// //      <CarForm
// //       car={selectedCar}
// //       editMode={editMode}
// //       onSubmit={handleSubmit}
// //       onClose={handleClose}
// //      />
// //     </DialogContent>
// //    </Dialog>

// //    <Dialog open={updateModalOpen} onClose={handleClose}>
// //     <DialogTitle>Edit Car</DialogTitle>
// //     <DialogContent>
// //      <UpdateCarForm
// //       car={selectedCar}
// //       onSubmit={handleUpdateSubmit}
// //       onClose={handleClose}
// //      />
// //     </DialogContent>
// //    </Dialog>

// //    <Dialog open={deleteDialogOpen} onClose={handleClose}>
// //     <DialogTitle>Confirm Deletion</DialogTitle>
// //     <DialogContent>
// //      <Typography>
// //       Are you sure you want to delete this car model and all its combinations?
// //      </Typography>
// //     </DialogContent>
// //     <DialogActions>
// //      <Button onClick={handleClose} color="primary">
// //       Cancel
// //      </Button>
// //      <Button onClick={handleDelete} color="secondary">
// //       Delete
// //      </Button>
// //     </DialogActions>
// //    </Dialog>
// //    <Snackbar
// //     open={snackbarOpen}
// //     autoHideDuration={6000}
// //     onClose={() => setSnackbarOpen(false)}
// //    >
// //     <Alert
// //      onClose={() => setSnackbarOpen(false)}
// //      severity={snackbarSeverity}
// //      variant="filled"
// //      sx={{ width: "100%" }}
// //     >
// //      {snackbarMessage}
// //     </Alert>
// //    </Snackbar>
// //   </Box>
// //  );
// // };

// // const UpdateCarForm = ({ car, onSubmit, onClose }) => {
// //  const dispatch = useDispatch();

// //  const [title, setTitle] = useState(car?.title || "");
// //  const [brand, setBrand] = useState(car?.brand?._id || "");
// //  const [image, setImage] = useState(null);
// //  const [imagePreview, setImagePreview] = useState(car?.images || "");
// //  const { brands = [] } = useSelector((store) => store.admin);

// //  useEffect(() => {
// //   if (brands.length === 0) {
// //    dispatch(getBrands());
// //   }
// //  }, [dispatch, brands.length]);

// //  const handleSubmit = (event) => {
// //   event.preventDefault();
// //   const formData = new FormData();
// //   formData.append("title", title);
// //   formData.append("brand", brand);
// //   if (image) {
// //    formData.append("image", image);
// //   }
// //   onSubmit(formData);
// //  };

// //  const handleImageChange = (event) => {
// //   const file = event.target.files[0];
// //   if (file) {
// //    const fileUrl = URL.createObjectURL(file);
// //    setImage(file);
// //    setImagePreview(fileUrl);
// //   }
// //  };

// //  const handleBrandChange = (event) => {
// //   setBrand(event.target.value);
// //  };

// //  return (
// //   <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
// //    <TextField
// //     label="Car Title"
// //     value={title}
// //     onChange={(e) => setTitle(e.target.value)}
// //     fullWidth
// //     margin="normal"
// //    />
// //    <FormControl fullWidth variant="outlined" margin="normal">
// //     <InputLabel id="brand-label">Brand</InputLabel>
// //     <Select
// //      labelId="brand-label"
// //      id="brand-select"
// //      value={brand}
// //      onChange={handleBrandChange}
// //      label="Brand"
// //     >
// //      {brands?.map((brand) => (
// //       <MenuItem key={brand._id} value={brand._id}>
// //        {brand?.title}
// //       </MenuItem>
// //      ))}
// //     </Select>
// //    </FormControl>

// //    <Button variant="contained" component="label" fullWidth margin="normal">
// //     Upload Image
// //     <input type="file" hidden onChange={handleImageChange} />
// //    </Button>
// //    {imagePreview && (
// //     <Box sx={{ marginTop: 2 }}>
// //      <Box sx={{ display: "inline-block", marginRight: 2 }}>
// //       <img
// //        src={imagePreview}
// //        alt="Car"
// //        style={{ width: "100px", height: "100px" }}
// //       />
// //      </Box>
// //     </Box>
// //    )}
// //    <Box sx={{ marginTop: 2, textAlign: "right" }}>
// //     <DialogActions>
// //      <Button type="submit" variant="contained" color="primary">
// //       Update Car
// //      </Button>
// //      <Button variant="outlined" onClick={onClose}>
// //       Cancel
// //      </Button>
// //     </DialogActions>
// //    </Box>
// //   </Box>
// //  );
// // };

// // export default Cars;

// // // import React, { useEffect, useState } from "react";
// // // import {
// // //  Box,
// // //  Typography,
// // //  Fab,
// // //  TextField,
// // //  Dialog,
// // //  DialogTitle,
// // //  DialogContent,
// // //  DialogActions,
// // //  Snackbar,
// // //  Alert,
// // //  Button,
// // //  FormControl,
// // //  InputLabel,
// // //  Select,
// // //  MenuItem,
// // // } from "@mui/material";
// // // import { DataGrid } from "@mui/x-data-grid";
// // // import { useDispatch, useSelector } from "react-redux";
// // // import {
// // //  addCar,
// // //  getCars,
// // //  updateCars,
// // //  deleteCars,
// // //  getBrands,
// // // } from "../redux/admin/action";
// // // import EditIcon from "@mui/icons-material/Edit";
// // // import DeleteIcon from "@mui/icons-material/Delete";
// // // import AddIcon from "@mui/icons-material/Add";
// // // import ClearIcon from "@mui/icons-material/Clear";
// // // import CarForm from "../components/car/AddCarForm";

// // // const Cars = () => {
// // //  const dispatch = useDispatch();
// // //  const { allCars, loading, error, brands } = useSelector(
// // //   (store) => store.admin
// // //  );

// // //  const [carTitle, setCarTitle] = useState("");
// // //  const [transmissionFilter, setTransmissionFilter] = useState("");
// // //  const [fuelFilter, setFuelFilter] = useState("");
// // //  const [brandFilter, setBrandFilter] = useState("");
// // //  const [modalOpen, setModalOpen] = useState(false);
// // //  const [editMode, setEditMode] = useState(false);
// // //  const [updateModalOpen, setUpdateModalOpen] = useState(false);
// // //  const [selectedCar, setSelectedCar] = useState(null);
// // //  const [snackbarOpen, setSnackbarOpen] = useState(false);
// // //  const [snackbarMessage, setSnackbarMessage] = useState("");
// // //  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
// // //  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

// // //  useEffect(() => {
// // //   if (allCars?.length <= 0) {
// // //    dispatch(getCars());
// // //   }
// // //  }, [dispatch, allCars?.length]);

// // //  const handleCarTitleChange = (event) => setCarTitle(event.target.value);
// // //  const handleTransmissionFilterChange = (event) =>
// // //   setTransmissionFilter(event.target.value);
// // //  const handleFuelFilterChange = (event) => setFuelFilter(event.target.value);
// // //  const handleBrandFilterChange = (event) => setBrandFilter(event.target.value);

// // //  const handleEdit = (car) => {
// // //   setSelectedCar(car);
// // //   setEditMode(true);
// // //   setModalOpen(true);
// // //  };

// // //  const handleUpdate = (car) => {
// // //   setSelectedCar(car);
// // //   setUpdateModalOpen(true);
// // //  };

// // //  const handleAdd = () => {
// // //   setSelectedCar(null);
// // //   setEditMode(false);
// // //   setModalOpen(true);
// // //  };

// // //  const handleClose = () => {
// // //   setModalOpen(false);
// // //   setUpdateModalOpen(false);
// // //   setDeleteDialogOpen(false);
// // //  };

// // //  const handleResetFilters = () => {
// // //   setCarTitle("");
// // //   setTransmissionFilter("");
// // //   setFuelFilter("");
// // //   setBrandFilter("");
// // //   dispatch(getCars());
// // //  };

// // //  const handleDelete = async () => {
// // //   try {
// // //    await dispatch(deleteCars(selectedCar.title));
// // //    setSnackbarMessage("Cars deleted successfully!");
// // //    setSnackbarSeverity("success");
// // //    dispatch(getCars());
// // //   } catch (error) {
// // //    console.error("Error deleting cars:", error);
// // //    setSnackbarMessage("Error deleting cars. Please try again.");
// // //    setSnackbarSeverity("error");
// // //   }
// // //   setSnackbarOpen(true);
// // //   handleClose();
// // //  };

// // //  const handleDeleteConfirmation = (car) => {
// // //   setSelectedCar(car);
// // //   setDeleteDialogOpen(true);
// // //  };

// // //  const handleSubmit = async (formData) => {
// // //   try {
// // //    if (editMode && selectedCar) {
// // //     await dispatch(updateCars(selectedCar._id, formData));
// // //     setSnackbarMessage("Car updated successfully!");
// // //     setSnackbarSeverity("success");
// // //    } else {
// // //     await dispatch(addCar(formData));
// // //     setSnackbarMessage("Car added successfully!");
// // //     setSnackbarSeverity("success");
// // //    }
// // //   } catch (error) {
// // //    console.error("Error uploading car:", error);
// // //    setSnackbarMessage(
// // //     error.response?.data?.message || "Error uploading car. Please try again."
// // //    );
// // //    setSnackbarSeverity("error");
// // //   } finally {
// // //    setSnackbarOpen(true);
// // //    handleClose();
// // //    dispatch(getCars());
// // //   }
// // //  };

// // //  const handleUpdateSubmit = async (formData) => {
// // //   try {
// // //    await dispatch(updateCars(selectedCar.title, formData));
// // //    setSnackbarMessage("Car updated successfully!");
// // //    setSnackbarSeverity("success");
// // //   } catch (error) {
// // //    console.error("Error updating car:", error);
// // //    setSnackbarMessage(
// // //     error.response?.data?.message || "Error updating car. Please try again."
// // //    );
// // //    setSnackbarSeverity("error");
// // //   } finally {
// // //    setSnackbarOpen(true);
// // //    handleClose();
// // //    dispatch(getCars());
// // //   }
// // //  };

// // //  const groupCarsByTitle = (cars) => {
// // //   const groupedCars = {};

// // //   cars.forEach((car) => {
// // //    const { title, transmissionType, fuelType } = car;

// // //    if (!groupedCars[title]) {
// // //     groupedCars[title] = {
// // //      ...car,
// // //      transmissionTypes: new Set(),
// // //      fuelTypes: new Set(),
// // //     };
// // //    }

// // //    groupedCars[title].transmissionTypes.add(transmissionType);
// // //    groupedCars[title].fuelTypes.add(fuelType);
// // //   });

// // //   return Object.values(groupedCars).map((car) => ({
// // //    ...car,
// // //    transmissionTypes: Array.from(car.transmissionTypes).join(", "),
// // //    fuelTypes: Array.from(car.fuelTypes).join(", "),
// // //   }));
// // //  };

// // //  const filteredCars = groupCarsByTitle(allCars).filter((car) => {
// // //   const matchesTitle = car?.title
// // //    ? car.title.toLowerCase().includes(carTitle.toLowerCase())
// // //    : false;
// // //   const matchesTransmission = transmissionFilter
// // //    ? car.transmissionTypes.includes(transmissionFilter)
// // //    : true;
// // //   const matchesFuel = fuelFilter ? car.fuelTypes.includes(fuelFilter) : true;
// // //   const matchesBrand = brandFilter
// // //    ? car.brand.title.toLowerCase().includes(brandFilter.toLowerCase())
// // //    : true;
// // //   return matchesTitle && matchesTransmission && matchesFuel && matchesBrand;
// // //  });

// // //  const rows = filteredCars.map((car, index) => ({
// // //   ...car,
// // //   serialNumber: index + 1,
// // //   brandTitle: car.brand.title,
// // //  }));

// // //  const columns = [
// // //   { field: "serialNumber", headerName: "S.No.", width: 100 },
// // //   { field: "title", headerName: "Car Title", width: 250 },
// // //   { field: "brandTitle", headerName: "Brand Title", width: 200 },
// // //   { field: "fuelTypes", headerName: "Fuel Types", width: 200 },
// // //   { field: "transmissionTypes", headerName: "Transmission Types", width: 200 },
// // //   {
// // //    field: "images",
// // //    headerName: "Images",
// // //    width: 100,
// // //    renderCell: (params) => (
// // //     <img
// // //      src={params.row.images}
// // //      alt={`Car image ${params.row.images}`}
// // //      style={{ width: "50px", height: "50px" }}
// // //     />
// // //    ),
// // //   },
// // //   {
// // //    field: "brandLogo",
// // //    headerName: "Brand Logo",
// // //    width: 100,
// // //    renderCell: (params) => (
// // //     <img
// // //      src={params.row.brand.logo}
// // //      alt={params.row.brandTitle ? `${params.row.brandTitle} logo` : "N/A"}
// // //      style={{ width: "50px", height: "50px" }}
// // //     />
// // //    ),
// // //   },
// // //   {
// // //    field: "actions",
// // //    headerName: "Actions",
// // //    width: 150,
// // //    renderCell: (params) => (
// // //     <>
// // //      <Fab
// // //       color="secondary"
// // //       aria-label="edit"
// // //       size="small"
// // //       onClick={() => handleUpdate(params.row)}
// // //      >
// // //       <EditIcon />
// // //      </Fab>
// // //      <Fab
// // //       color="secondary"
// // //       aria-label="delete"
// // //       size="small"
// // //       onClick={() => handleDeleteConfirmation(params.row)}
// // //      >
// // //       <DeleteIcon />
// // //      </Fab>
// // //     </>
// // //    ),
// // //   },
// // //  ];

// // //  return (
// // //   <Box sx={{ width: "100%", padding: "20px", boxShadow: 3 }}>
// // //    <Typography fontSize={"25px"} padding="5px" fontWeight={"600"}>
// // //     {`Cars/${allCars?.length}`}
// // //    </Typography>

// // //    <Box
// // //     sx={{
// // //      padding: "10px",
// // //      display: "flex",
// // //      alignItems: "center",
// // //      gap: "50px",
// // //     }}
// // //    >
// // //     <Fab color="secondary" aria-label="add" onClick={handleAdd}>
// // //      <AddIcon />
// // //     </Fab>
// // //     <TextField
// // //      label="Car Title"
// // //      value={carTitle}
// // //      onChange={handleCarTitleChange}
// // //     />
// // //     <TextField
// // //      label="Transmission Type"
// // //      value={transmissionFilter}
// // //      onChange={handleTransmissionFilterChange}
// // //     />
// // //     <TextField
// // //      label="Fuel Type"
// // //      value={fuelFilter}
// // //      onChange={handleFuelFilterChange}
// // //     />
// // //     <TextField
// // //      label="Brand"
// // //      value={brandFilter}
// // //      onChange={handleBrandFilterChange}
// // //     />
// // //     <Fab color="primary" aria-label="clear" onClick={handleResetFilters}>
// // //      <ClearIcon />
// // //     </Fab>
// // //    </Box>

// // //    <Box sx={{ height: 600, width: "100%", marginTop: "10px" }}>
// // //     <DataGrid
// // //      rows={rows}
// // //      columns={columns}
// // //      pageSize={10}
// // //      rowsPerPageOptions={[10]}
// // //      getRowId={(row) => row._id}
// // //     />
// // //    </Box>

// // //    <Dialog open={modalOpen} onClose={handleClose}>
// // //     <DialogTitle>{editMode ? "Edit Car" : "Add Car"}</DialogTitle>
// // //     <DialogContent>
// // //      <CarForm
// // //       car={selectedCar}
// // //       editMode={editMode}
// // //       onSubmit={handleSubmit}
// // //       onClose={handleClose}
// // //      />
// // //     </DialogContent>
// // //    </Dialog>

// // //    <Dialog open={updateModalOpen} onClose={handleClose}>
// // //     <DialogTitle>Edit Car</DialogTitle>
// // //     <DialogContent>
// // //      <UpdateCarForm
// // //       car={selectedCar}
// // //       onSubmit={handleUpdateSubmit}
// // //       onClose={handleClose}
// // //      />
// // //     </DialogContent>
// // //    </Dialog>

// // //    <Dialog open={deleteDialogOpen} onClose={handleClose}>
// // //     <DialogTitle>Confirm Deletion</DialogTitle>
// // //     <DialogContent>
// // //      <Typography>
// // //       Are you sure you want to delete this car model and all its combinations?
// // //      </Typography>
// // //     </DialogContent>
// // //     <DialogActions>
// // //      <Button onClick={handleClose} color="primary">
// // //       Cancel
// // //      </Button>
// // //      <Button onClick={handleDelete} color="secondary">
// // //       Delete
// // //      </Button>
// // //     </DialogActions>
// // //    </Dialog>
// // //    <Snackbar
// // //     open={snackbarOpen}
// // //     autoHideDuration={6000}
// // //     onClose={() => setSnackbarOpen(false)}
// // //    >
// // //     <Alert
// // //      onClose={() => setSnackbarOpen(false)}
// // //      severity={snackbarSeverity}
// // //      variant="filled"
// // //      sx={{ width: "100%" }}
// // //     >
// // //      {snackbarMessage}
// // //     </Alert>
// // //    </Snackbar>
// // //   </Box>
// // //  );
// // // };

// // // const UpdateCarForm = ({ car, onSubmit, onClose }) => {
// // //  const dispatch = useDispatch();

// // //  const [title, setTitle] = useState(car?.title || "");
// // //  const [brand, setBrand] = useState(car?.brand?._id || "");
// // //  const [image, setImage] = useState(null);
// // //  const [imagePreview, setImagePreview] = useState(car?.images || "");
// // //  const { allCars, loading, error, brands } = useSelector(
// // //   (store) => store.admin
// // //  );

// // //  useEffect(() => {
// // //   if (brands.length <= 0) {
// // //    dispatch(getBrands());
// // //   }
// // //  }, [dispatch, brands.length]);

// // //  const handleSubmit = (event) => {
// // //   event.preventDefault();
// // //   const formData = new FormData();
// // //   formData.append("title", title);
// // //   formData.append("brand", brand);
// // //   if (image) {
// // //    formData.append("image", image);
// // //   }
// // //   onSubmit(formData);
// // //  };

// // //  const handleImageChange = (event) => {
// // //   const file = event.target.files[0];
// // //   if (file) {
// // //    const fileUrl = URL.createObjectURL(file);
// // //    setImage(file);
// // //    setImagePreview(fileUrl);
// // //   }
// // //  };

// // //  const handleBrandChange = (event) => {
// // //   setBrand(event.target.value);
// // //  };

// // //  return (
// // //   <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
// // //    <TextField
// // //     label="Car Title"
// // //     value={title}
// // //     onChange={(e) => setTitle(e.target.value)}
// // //     fullWidth
// // //     margin="normal"
// // //    />
// // //    <FormControl fullWidth variant="outlined" margin="normal">
// // //     <InputLabel id="brand-label">Brand</InputLabel>
// // //     <Select
// // //      labelId="brand-label"
// // //      id="brand-select"
// // //      value={brand}
// // //      onChange={handleBrandChange}
// // //      label="Brand"
// // //     >
// // //      {brands?.map((brand) => (
// // //       <MenuItem key={brand._id} value={brand._id}>
// // //        {brand?.title}
// // //       </MenuItem>
// // //      ))}
// // //     </Select>
// // //    </FormControl>

// // //    <Button variant="contained" component="label" fullWidth margin="normal">
// // //     Upload Image
// // //     <input type="file" hidden onChange={handleImageChange} />
// // //    </Button>
// // //    {imagePreview && (
// // //     <Box sx={{ marginTop: 2 }}>
// // //      <Box sx={{ display: "inline-block", marginRight: 2 }}>
// // //       <img
// // //        src={imagePreview}
// // //        alt="Car"
// // //        style={{ width: "100px", height: "100px" }}
// // //       />
// // //      </Box>
// // //     </Box>
// // //    )}
// // //    <Box sx={{ marginTop: 2, textAlign: "right" }}>
// // //     <DialogActions>
// // //      <Button type="submit" variant="contained" color="primary">
// // //       Update Car
// // //      </Button>
// // //      <Button variant="outlined" onClick={onClose}>
// // //       Cancel
// // //      </Button>
// // //     </DialogActions>
// // //    </Box>
// // //   </Box>
// // //  );
// // // };

// // // export default Cars;

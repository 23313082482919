import React from "react";
import Mainroutes from "./pages/Mainroutes";
import { Box } from "@mui/material";
import FranchiseForm from "./FormComponent";
import FormBanner from "./FormBanner";
import DynamicUI from "./DynamicUI";
import FormTestimonials from "./FormTestimonials";
import WorkshopSlider from "./HappyPartners";

const App = () => {
 return (
  <Box>
   {/* <FormBanner />
   <FranchiseForm />

   <DynamicUI />
   <FormTestimonials />
   <WorkshopSlider /> */}
   <Mainroutes />
  </Box>
 );
};

export default App;

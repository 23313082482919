import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../redux/admin/action";

const Users = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((store) => store.admin);
  useEffect(() => {
    if (users.length <= 0) {
      dispatch(getUsers());
    }
  }, []);
  console.log('users:', users)
  return (
    <Box sx={{ width: "100%", height: "40vh" }}>
      <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
        Users
      </Typography>
      <TableContainer component={Paper} sx={{ margin: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "#1976d2" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Name</TableCell>
              <TableCell sx={{ color: "white" }}>Mobile</TableCell>
              <TableCell sx={{ color: "white" }}>Verify</TableCell>
              <TableCell sx={{ color: "white" }}>Referral Code</TableCell>
              <TableCell sx={{ color: "white" }}>Selected Brand</TableCell>
              <TableCell sx={{ color: "white" }}>Selected Car</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell component="th" scope="row">
                  {user.name}
                </TableCell>
                <TableCell>{user.mobile}</TableCell>
                <TableCell>{user.verify ? "Yes" : "No"}</TableCell>
                <TableCell>{user.referral_code}</TableCell>
                <TableCell>
                  {user.selectedBrand ? user.selectedBrand.name : "N/A"}
                </TableCell>
                <TableCell>
                  {user.selectedCar ? user.selectedCar.name : "N/A"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Users;

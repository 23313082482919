// actionTypes.js

// OTP actions
export const REQUEST_OTP_REQUEST = "REQUEST_OTP_REQUEST";
export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS";
export const REQUEST_OTP_FAILURE = "REQUEST_OTP_FAILURE";

// Verify OTP actions
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

// Update vendor details actions
export const UPDATE_VENDOR_DETAILS_REQUEST = "UPDATE_VENDOR_DETAILS_REQUEST";
export const UPDATE_VENDOR_DETAILS_SUCCESS = "UPDATE_VENDOR_DETAILS_SUCCESS";
export const UPDATE_VENDOR_DETAILS_FAILURE = "UPDATE_VENDOR_DETAILS_FAILURE";

// lOGIN USER
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

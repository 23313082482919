import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
 Box,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 Fab,
 FormControl,
 TextField,
 Modal,
 Fade,
 Backdrop,
 Button,
 Snackbar,
 Alert,
 MenuItem,
 Select,
 InputLabel,
 IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GenerateIcon from "@mui/icons-material/Autorenew"; // Import an icon for generating code
import {
 addCouponCode,
 deleteCouponCode,
 fetchCouponCodes,
 updateCouponCode,
} from "../../redux/account/action";
import { getSubCategories } from "../../redux/products/action";

const style = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: "90%",
 maxWidth: 400,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

const CouponCode = () => {
 const { couponCodes } = useSelector((state) => state.referAndEarnReducer);
 const { userDetails } = useSelector((store) => store.auth);
 const dispatch = useDispatch();

 const [open, setOpen] = useState(false);
 const [openAdd, setOpenAdd] = useState(false);
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [snackbarSeverity, setSnackbarSeverity] = useState("success");
 const [editId, setEditId] = useState(null);
 const { subCategories } = useSelector((state) => state.product);
 const { register, handleSubmit, reset, setValue, watch } = useForm();

 useEffect(() => {
  dispatch(fetchCouponCodes());
  dispatch(getSubCategories());
 }, [dispatch]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setOpen(false);
  reset();
 };

 const handleOpenAdd = () => setOpenAdd(true);
 const handleCloseAdd = () => {
  setOpenAdd(false);
  reset();
 };

 const handleSnackbarClose = (event, reason) => {
  if (reason === "clickaway") {
   return;
  }
  setSnackbarOpen(false);
 };

 const generateCode = () => {
  const code = uuidv4();
  setValue("code", `${"car-expert"}-${code}`);
 };

 const onSubmit = (data) => {
  if (editId) {
   dispatch(updateCouponCode(editId, data));
   setEditId(null);
  } else {
   dispatch(addCouponCode(data));
  }

  setSnackbarMessage("Form submitted successfully!");
  setSnackbarSeverity("success");
  setSnackbarOpen(true);
  handleCloseAdd();
  handleClose();
 };

 const handleOpenEdit = (id) => {
  setEditId(id);
  const item = couponCodes.find((item) => item._id === id);
  setValue("title", item.title);
  setValue("code", item.code);
  setValue("discountPercent", item.discountPercent);
  setValue("description", item.description);
  setValue("subCategory", item.subCategory);
  setValue("type", item.type);
  setValue("expiryDate", item.expiryDate.split("T")[0]); // to handle date format
  setOpen(true);
 };

 const handleDelete = (id) => {
  dispatch(deleteCouponCode(id));
  setSnackbarMessage("Deleted successfully!");
  setSnackbarSeverity("success");
  setSnackbarOpen(true);
 };

 return (
  <Box sx={{ width: "100%", height: "40vh" }}>
   <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
    Coupon Codes
   </Typography>
   <Box
    sx={{
     border: "1px solid #1976D2",
     padding: "10px",
     display: "flex",
     alignItems: "center",
     gap: "50px",
    }}
   >
    <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
     <AddIcon />
    </Fab>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>Title</TableCell>
       <TableCell sx={{ color: "white" }}>Code</TableCell>
       <TableCell sx={{ color: "white" }}>Discount Percent</TableCell>
       <TableCell sx={{ color: "white" }}>Description</TableCell>
       <TableCell sx={{ color: "white" }}>SubCategory</TableCell>
       <TableCell sx={{ color: "white" }}>Type</TableCell>
       <TableCell sx={{ color: "white" }}>Expiry Date</TableCell>
       <TableCell sx={{ color: "white" }}>Action</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {couponCodes?.map((item) => (
       <TableRow key={item?._id}>
        <TableCell>{item?.title}</TableCell>
        <TableCell>{item?.code}</TableCell>
        <TableCell>{item?.discountPercent}</TableCell>
        <TableCell>{item?.description}</TableCell>
        <TableCell>{item?.subCategory?.subCategoryName}</TableCell>
        <TableCell>{item.type}</TableCell>
        <TableCell>{new Date(item.expiryDate).toLocaleDateString()}</TableCell>
        <TableCell
         sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
         }}
        >
         <Fab
          color="secondary"
          aria-label="edit"
          onClick={() => handleOpenEdit(item._id)}
         >
          <EditIcon />
         </Fab>
         <Fab
          color="secondary"
          aria-label="delete"
          sx={{ marginLeft: "5px" }}
          onClick={() => handleDelete(item._id)}
         >
          <DeleteIcon />
         </Fab>
        </TableCell>
       </TableRow>
      ))}
     </TableBody>
    </Table>
   </TableContainer>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
   >
    <Fade in={open}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       Edit Coupon Code
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <FormControl fullWidth>
        <TextField
         {...register("title", { required: "Title is required" })}
         label="Title"
         margin="normal"
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
         <TextField
          {...register("code", { required: "Code is required" })}
          // label="Code"
          margin="normal"
         />
         <IconButton onClick={generateCode} color="primary">
          <GenerateIcon />
         </IconButton>
        </Box>
        <TextField
         {...register("discountPercent", {
          required: "Discount Percent is required",
         })}
         label="Discount Percent"
         type="number"
         margin="normal"
        />
        <TextField
         {...register("description", {
          required: "Description is required",
         })}
         label="Description"
         margin="normal"
        />
        <FormControl fullWidth margin="normal">
         <InputLabel>SubCategory</InputLabel>
         <Select
          {...register("subCategory", {
           required: "SubCategory is required",
          })}
         >
          {/* Assuming subCategories are fetched and stored in state */}
          {subCategories.map((subcategory) => (
           <MenuItem key={subcategory._id} value={subcategory._id}>
            {subcategory.subCategoryName}
           </MenuItem>
          ))}
         </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
         <InputLabel>Type</InputLabel>
         <Select {...register("type", { required: "Type is required" })}>
          <MenuItem value="discount">Discount</MenuItem>
          <MenuItem value="cashback">Cashback</MenuItem>
         </Select>
        </FormControl>
        <label>Expiry Date</label>

        <TextField
         {...register("expiryDate", {
          required: "Expiry Date is required",
         })}
         //  label="Expiry Date"
         type="date"
         margin="normal"
        />
       </FormControl>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={openAdd}
    onClose={handleCloseAdd}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
   >
    <Fade in={openAdd}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       Add Coupon Code
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <FormControl fullWidth>
        <TextField
         {...register("title", { required: "Title is required" })}
         label="Title"
         margin="normal"
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
         <label htmlFor="">Generate Code</label>
         <TextField
          {...register("code", { required: "Code is required" })}
          // label="Code"
          margin="normal"
         />
         <IconButton onClick={generateCode} color="primary">
          <GenerateIcon />
         </IconButton>
        </Box>
        <TextField
         {...register("discountPercent", {
          required: "Discount Percent is required",
         })}
         label="Discount Percent"
         type="number"
         margin="normal"
        />
        <TextField
         {...register("description", {
          required: "Description is required",
         })}
         label="Description"
         margin="normal"
        />
        <FormControl fullWidth margin="normal">
         <InputLabel>SubCategory</InputLabel>
         <Select
          {...register("subCategory", {
           required: "SubCategory is required",
          })}
         >
          {/* Assuming subCategories are fetched and stored in state */}
          {subCategories.map((subcategory) => (
           <MenuItem key={subcategory._id} value={subcategory._id}>
            {subcategory.subCategoryName}
           </MenuItem>
          ))}
         </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
         <InputLabel>Type</InputLabel>
         <Select {...register("type", { required: "Type is required" })}>
          <MenuItem value="discount">Discount</MenuItem>
          <MenuItem value="cashback">Cashback</MenuItem>
         </Select>
        </FormControl>
        <label>Expiry Date</label>

        <TextField
         {...register("expiryDate", {
          required: "Expiry Date is required",
         })}
         //  label="Expiry Date"
         type="date"
         margin="normal"
        />
       </FormControl>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         Add
        </Button>
        <Button variant="outlined" onClick={handleCloseAdd}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={3000}
    onClose={handleSnackbarClose}
   >
    <Alert
     onClose={handleSnackbarClose}
     severity={snackbarSeverity}
     variant="filled"
     sx={{ width: "100%" }}
    >
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Box>
 );
};

export default CouponCode;

// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import {
//  Box,
//  Table,
//  TableBody,
//  TableCell,
//  TableContainer,
//  TableHead,
//  TableRow,
//  Paper,
//  Typography,
//  Fab,
//  FormControl,
//  TextField,
//  Modal,
//  Fade,
//  Backdrop,
//  Button,
//  Snackbar,
//  Alert,
//  MenuItem,
//  Select,
//  InputLabel,
// } from "@mui/material";
// import { useForm } from "react-hook-form";
// import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import {
//  addCouponCode,
//  deleteCouponCode,
//  fetchCouponCodes,
//  updateCouponCode,
// } from "../../redux/account/action";
// import { getSubCategories } from "../../redux/products/action";

// const style = {
//  position: "absolute",
//  top: "50%",
//  left: "50%",
//  transform: "translate(-50%, -50%)",
//  width: 400,
//  bgcolor: "background.paper",
//  border: "2px solid #000",
//  boxShadow: 24,
//  p: 4,
// };

// const CouponCode = () => {
//  const { couponCodes } = useSelector((state) => state.referAndEarnReducer);
//  const dispatch = useDispatch();

//  const [open, setOpen] = useState(false);
//  const [openAdd, setOpenAdd] = useState(false);
//  const [snackbarOpen, setSnackbarOpen] = useState(false);
//  const [snackbarMessage, setSnackbarMessage] = useState("");
//  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//  const [editId, setEditId] = useState(null);
//  const { subCategories } = useSelector((state) => state.product);
//  const { register, handleSubmit, reset, setValue } = useForm();

//  useEffect(() => {
//   dispatch(fetchCouponCodes());
//   dispatch(getSubCategories());
//  }, [dispatch]);

//  const handleOpen = () => setOpen(true);
//  const handleClose = () => {
//   setOpen(false);
//   reset();
//  };

//  const handleOpenAdd = () => {
//   setOpenAdd(true);
//  };

//  const handleCloseAdd = () => {
//   setOpenAdd(false);
//   reset();
//  };

//  const handleSnackbarClose = (event, reason) => {
//   if (reason === "clickaway") {
//    return;
//   }
//   setSnackbarOpen(false);
//  };

//  const onSubmit = (data) => {
//   if (editId) {
//    dispatch(updateCouponCode(editId, data));
//    setEditId(null);
//   } else {
//    dispatch(addCouponCode(data));
//   }

//   setSnackbarMessage("Form submitted successfully!");
//   setSnackbarSeverity("success");
//   setSnackbarOpen(true);
//   handleCloseAdd();
//   handleClose();
//  };

//  const handleOpenEdit = (id) => {
//   setEditId(id);
//   const item = couponCodes.find((item) => item._id === id);
//   setValue("title", item.title);
//   setValue("code", item.code);
//   setValue("discountPercent", item.discountPercent);
//   setValue("description", item.description);
//   setValue("subCategory", item.subCategory);
//   setValue("type", item.type);
//   setValue("expiryDate", item.expiryDate.split("T")[0]); // to handle date format
//   setOpen(true);
//  };

//  const handleDelete = (id) => {
//   dispatch(deleteCouponCode(id));
//   setSnackbarMessage("Deleted successfully!");
//   setSnackbarSeverity("success");
//   setSnackbarOpen(true);
//  };

//  return (
//   <Box sx={{ width: "100%", height: "40vh" }}>
//    <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
//     Coupon Codes
//    </Typography>
//    <Box
//     sx={{
//      border: "1px solid #1976D2",
//      padding: "10px",
//      display: "flex",
//      alignItems: "center",
//      gap: "50px",
//     }}
//    >
//     <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
//      <AddIcon />
//     </Fab>
//    </Box>
//    <TableContainer component={Paper} sx={{ margin: "auto" }}>
//     <Table sx={{ minWidth: 650 }} aria-label="simple table">
//      <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
//       <TableRow>
//        <TableCell sx={{ color: "white" }}>Title</TableCell>
//        <TableCell sx={{ color: "white" }}>Code</TableCell>
//        <TableCell sx={{ color: "white" }}>Discount Percent</TableCell>
//        <TableCell sx={{ color: "white" }}>Description</TableCell>
//        <TableCell sx={{ color: "white" }}>SubCategory</TableCell>
//        <TableCell sx={{ color: "white" }}>Type</TableCell>
//        <TableCell sx={{ color: "white" }}>Expiry Date</TableCell>
//        <TableCell sx={{ color: "white" }}>Action</TableCell>
//       </TableRow>
//      </TableHead>
//      <TableBody>
//       {couponCodes?.map((item) => (
//        <TableRow key={item?._id}>
//         <TableCell>{item?.title}</TableCell>
//         <TableCell>{item?.code}</TableCell>
//         <TableCell>{item?.discountPercent}</TableCell>
//         <TableCell>{item?.description}</TableCell>
//         <TableCell>{item?.subCategory?.subCategoryName}</TableCell>
//         <TableCell>{item.type}</TableCell>
//         <TableCell>{new Date(item.expiryDate).toLocaleDateString()}</TableCell>
//         <TableCell
//          sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//          }}
//         >
//          <Fab
//           color="secondary"
//           aria-label="edit"
//           onClick={() => handleOpenEdit(item._id)}
//          >
//           <EditIcon />
//          </Fab>
//          <Fab
//           color="secondary"
//           aria-label="delete"
//           sx={{ marginLeft: "5px" }}
//           onClick={() => handleDelete(item._id)}
//          >
//           <DeleteIcon />
//          </Fab>
//         </TableCell>
//        </TableRow>
//       ))}
//      </TableBody>
//     </Table>
//    </TableContainer>
//    <Modal
//     aria-labelledby="transition-modal-title"
//     aria-describedby="transition-modal-description"
//     open={open}
//     onClose={handleClose}
//     closeAfterTransition
//     BackdropComponent={Backdrop}
//     BackdropProps={{
//      timeout: 500,
//     }}
//    >
//     <Fade in={open}>
//      <Box sx={style}>
//       <Typography id="transition-modal-title" variant="h6" component="h2">
//        Edit Coupon Code
//       </Typography>
//       <form onSubmit={handleSubmit(onSubmit)}>
//        <FormControl fullWidth>
//         <TextField
//          {...register("title", { required: "Title is required" })}
//          label="Title"
//          margin="normal"
//         />
//         <TextField
//          {...register("code", { required: "Code is required" })}
//          label="Code"
//          margin="normal"
//         />
//         <TextField
//          {...register("discountPercent", {
//           required: "Discount Percent is required",
//          })}
//          label="Discount Percent"
//          type="number"
//          margin="normal"
//         />
//         <TextField
//          {...register("description", {
//           required: "Description is required",
//          })}
//          label="Description"
//          margin="normal"
//         />
//         <FormControl fullWidth margin="normal">
//          <InputLabel>SubCategory</InputLabel>
//          <Select
//           {...register("subCategory", {
//            required: "SubCategory is required",
//           })}
//          >
//           {/* Assuming subCategories are fetched and stored in state */}
//           {subCategories.map((subcategory) => (
//            <MenuItem key={subcategory._id} value={subcategory._id}>
//             {subcategory.subCategoryName}
//            </MenuItem>
//           ))}
//          </Select>
//         </FormControl>
//         <FormControl fullWidth margin="normal">
//          <InputLabel>Type</InputLabel>
//          <Select {...register("type", { required: "Type is required" })}>
//           <MenuItem value="discount">Discount</MenuItem>
//           <MenuItem value="cashback">Cashback</MenuItem>
//          </Select>
//         </FormControl>
//         <TextField
//          {...register("expiryDate", {
//           required: "Expiry Date is required",
//          })}
//          label="Expiry Date"
//          type="date"
//          margin="normal"
//         />
//        </FormControl>
//        <Box mt={2} display="flex" justifyContent="space-between">
//         <Button variant="contained" color="primary" type="submit">
//          Save
//         </Button>
//         <Button variant="outlined" onClick={handleClose}>
//          Cancel
//         </Button>
//        </Box>
//       </form>
//      </Box>
//     </Fade>
//    </Modal>
//    <Modal
//     aria-labelledby="transition-modal-title"
//     aria-describedby="transition-modal-description"
//     open={openAdd}
//     onClose={handleCloseAdd}
//     closeAfterTransition
//     BackdropComponent={Backdrop}
//     BackdropProps={{
//      timeout: 500,
//     }}
//    >
//     <Fade in={openAdd}>
//      <Box sx={style}>
//       <Typography id="transition-modal-title" variant="h6" component="h2">
//        Add Coupon Code
//       </Typography>
//       <form onSubmit={handleSubmit(onSubmit)}>
//        <FormControl fullWidth>
//         <TextField
//          {...register("title", { required: "Title is required" })}
//          label="Title"
//          margin="normal"
//         />
//         <TextField
//          {...register("code", { required: "Code is required" })}
//          label="Code"
//          margin="normal"
//         />
//         <TextField
//          {...register("discountPercent", {
//           required: "Discount Percent is required",
//          })}
//          label="Discount Percent"
//          type="number"
//          margin="normal"
//         />
//         <TextField
//          {...register("description", {
//           required: "Description is required",
//          })}
//          label="Description"
//          margin="normal"
//         />
//         <FormControl fullWidth margin="normal">
//          <InputLabel>SubCategory</InputLabel>
//          <Select
//           {...register("subCategory", {
//            required: "SubCategory is required",
//           })}
//          >
//           {/* Assuming subCategories are fetched and stored in state */}
//           {subCategories.map((subcategory) => (
//            <MenuItem key={subcategory._id} value={subcategory._id}>
//             {subcategory.subCategoryName}
//            </MenuItem>
//           ))}
//          </Select>
//         </FormControl>
//         <FormControl fullWidth margin="normal">
//          <InputLabel>Type</InputLabel>
//          <Select {...register("type", { required: "Type is required" })}>
//           <MenuItem value="discount">Discount</MenuItem>
//           <MenuItem value="cashback">Cashback</MenuItem>
//          </Select>
//         </FormControl>
//         <TextField
//          {...register("expiryDate", {
//           required: "Expiry Date is required",
//          })}
//          label="Expiry Date"
//          type="date"
//          margin="normal"
//         />
//        </FormControl>
//        <Box mt={2} display="flex" justifyContent="space-between">
//         <Button variant="contained" color="primary" type="submit">
//          Add
//         </Button>
//         <Button variant="outlined" onClick={handleCloseAdd}>
//          Cancel
//         </Button>
//        </Box>
//       </form>
//      </Box>
//     </Fade>
//    </Modal>
//    <Snackbar
//     open={snackbarOpen}
//     autoHideDuration={3000}
//     onClose={handleSnackbarClose}
//    >
//     <Alert
//      onClose={handleSnackbarClose}
//      severity={snackbarSeverity}
//      variant="filled"
//      sx={{ width: "100%" }}
//     >
//      {snackbarMessage}
//     </Alert>
//    </Snackbar>
//   </Box>
//  );
// };

// export default CouponCode;

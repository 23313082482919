// Refer and Earn
export const GET_REFER_AND_EARN_REQUEST = "GET_REFER_AND_EARN_REQUEST";
export const GET_REFER_AND_EARN_SUCCESS = "GET_REFER_AND_EARN_SUCCESS";
export const GET_REFER_AND_EARN_FAILURE = "GET_REFER_AND_EARN_FAILURE";

export const ADD_REFER_AND_EARN_REQUEST = "ADD_REFER_AND_EARN_REQUEST";
export const ADD_REFER_AND_EARN_SUCCESS = "ADD_REFER_AND_EARN_SUCCESS";
export const ADD_REFER_AND_EARN_FAILURE = "ADD_REFER_AND_EARN_FAILURE";

export const UPDATE_REFER_AND_EARN_REQUEST = "UPDATE_REFER_AND_EARN_REQUEST";
export const UPDATE_REFER_AND_EARN_SUCCESS = "UPDATE_REFER_AND_EARN_SUCCESS";
export const UPDATE_REFER_AND_EARN_FAILURE = "UPDATE_REFER_AND_EARN_FAILURE";

export const DELETE_REFER_AND_EARN_REQUEST = "DELETE_REFER_AND_EARN_REQUEST";
export const DELETE_REFER_AND_EARN_SUCCESS = "DELETE_REFER_AND_EARN_SUCCESS";
export const DELETE_REFER_AND_EARN_FAILURE = "DELETE_REFER_AND_EARN_FAILURE";

// Coupon Code
export const GET_COUPON_CODES_REQUEST = "GET_COUPON_CODES_REQUEST";
export const GET_COUPON_CODES_SUCCESS = "GET_COUPON_CODES_SUCCESS";
export const GET_COUPON_CODES_FAILURE = "GET_COUPON_CODES_FAILURE";

export const ADD_COUPON_CODE_REQUEST = "ADD_COUPON_CODE_REQUEST";
export const ADD_COUPON_CODE_SUCCESS = "ADD_COUPON_CODE_SUCCESS";
export const ADD_COUPON_CODE_FAILURE = "ADD_COUPON_CODE_FAILURE";

export const UPDATE_COUPON_CODE_REQUEST = "UPDATE_COUPON_CODE_REQUEST";
export const UPDATE_COUPON_CODE_SUCCESS = "UPDATE_COUPON_CODE_SUCCESS";
export const UPDATE_COUPON_CODE_FAILURE = "UPDATE_COUPON_CODE_FAILURE";

export const DELETE_COUPON_CODE_REQUEST = "DELETE_COUPON_CODE_REQUEST";
export const DELETE_COUPON_CODE_SUCCESS = "DELETE_COUPON_CODE_SUCCESS";
export const DELETE_COUPON_CODE_FAILURE = "DELETE_COUPON_CODE_FAILURE";

// Wallet
export const GET_WALLETS_REQUEST = "GET_WALLETS_REQUEST";
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";
export const GET_WALLETS_FAILURE = "GET_WALLETS_FAILURE";

export const ADD_WALLET_REQUEST = "ADD_WALLET_REQUEST";
export const ADD_WALLET_SUCCESS = "ADD_WALLET_SUCCESS";
export const ADD_WALLET_FAILURE = "ADD_WALLET_FAILURE";

export const UPDATE_WALLET_REQUEST = "UPDATE_WALLET_REQUEST";
export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS";
export const UPDATE_WALLET_FAILURE = "UPDATE_WALLET_FAILURE";

export const DELETE_WALLET_REQUEST = "DELETE_WALLET_REQUEST";
export const DELETE_WALLET_SUCCESS = "DELETE_WALLET_SUCCESS";
export const DELETE_WALLET_FAILURE = "DELETE_WALLET_FAILURE";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
 Button,
 Box,
 Typography,
 CircularProgress,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
} from "@mui/material";
import CarPriceForm from "./CarPriceForm";
import CarPriceUpdateForm from "./CarPriceUpdateForm";
import {
 deletePriceByCar,
 getAllCarPrices,
 getProducts,
} from "../../redux/products/action";
import { getBrands } from "../../redux/admin/action";
import axios from "axios";

const PricesByCar = () => {
 const dispatch = useDispatch();
 const { productByCarPrice } = useSelector((store) => store.product);

 const [selectedCar, setSelectedCar] = useState(null);
 const [selectedVariants, setSelectedVariants] = useState([]);
 const [open, setOpen] = useState(false);
 const [updateOpen, setUpdateOpen] = useState(false);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
  const fetchData = async () => {
   try {
    await dispatch(getAllCarPrices());
    await dispatch(getBrands());
    await dispatch(getProducts());
   } catch (error) {
    console.error("Error fetching data:", error);
   } finally {
    setLoading(false);
   }
  };

  fetchData();
 }, [dispatch]);

 useEffect(() => {
  console.log("Product by Car Price:", productByCarPrice);
 }, [productByCarPrice]);

 const handleVariantSelect = (event, variant) => {
  if (event.target.checked) {
   setSelectedVariants([...selectedVariants, variant]);
  } else {
   setSelectedVariants(selectedVariants.filter((v) => v._id !== variant._id));
  }
 };

 const handleSubmit = async (data) => {
  try {
   await axios.post(`https://app-api.carexpert.org.in/api/price/create`, data);
   setOpen(false);
   setSelectedVariants([]);
   console.log("hello:");
   dispatch(getAllCarPrices());
  } catch (error) {
   console.log("error:", error);
  }
 };

 const handleUpdateSubmit = async (data) => {
  try {
   await axios.put(
    `https://app-api.carexpert.org.in/api/price/${selectedCar._id}`,
    data
   );
   setUpdateOpen(false);
   setSelectedCar(null);
   await dispatch(getAllCarPrices()); // Refresh the list
  } catch (error) {
   console.log("error:", error);
  }
 };

 const handleEdit = (carPrice) => {
  setSelectedCar(carPrice);
  setUpdateOpen(true);
 };

 const handleDelete = (id) => {
  dispatch(deletePriceByCar(id));
 };

 if (loading) {
  return <CircularProgress />;
 }

 if (!productByCarPrice || !Array.isArray(productByCarPrice)) {
  console.error(
   "Product by Car Price is not an array or is undefined:",
   productByCarPrice
  );
  return (
   <Typography variant="h6" color="error">
    No data available or data format is incorrect.
   </Typography>
  );
 }

 productByCarPrice.forEach((row, index) => {
  //   console.log(`Row ${index}:`, row);
 });

 return (
  <Box>
   <Typography variant="h4" gutterBottom>
    Car Prices
   </Typography>
   <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
    Add Car Price
   </Button>
   <TableContainer component={Paper} style={{ marginTop: "16px" }}>
    <Table>
     <TableHead>
      <TableRow>
       <TableCell>Product ID</TableCell>
       <TableCell>Car ID</TableCell>
       <TableCell>Variant</TableCell>
       <TableCell>Transmission</TableCell>
       <TableCell>MRP</TableCell>
       <TableCell>Given Price</TableCell>
       <TableCell>Actions</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {productByCarPrice.map((item) => (
       <TableRow key={item._id}>
        <TableCell>{item.productId?.productName || "N/A"}</TableCell>
        <TableCell>{item.carId?.title || "N/A"}</TableCell>
        <TableCell>{item.variant || "N/A"}</TableCell>
        <TableCell>{item.transmission || "N/A"}</TableCell>
        <TableCell>{item.mrp || "N/A"}</TableCell>
        <TableCell>{item.givenPrice || "N/A"}</TableCell>
        <TableCell>
         <Button onClick={() => handleEdit(item)}>Edit</Button>
         <Button onClick={() => handleDelete(item._id)}>Delete</Button>
        </TableCell>
       </TableRow>
      ))}
     </TableBody>
    </Table>
   </TableContainer>
   <CarPriceForm
    open={open}
    onClose={() => setOpen(false)}
    onSubmit={handleSubmit}
    selectedCar={selectedCar}
    setSelectedCar={setSelectedCar}
    selectedVariants={selectedVariants}
    handleVariantSelect={handleVariantSelect}
   />
   <CarPriceUpdateForm
    open={updateOpen}
    onClose={() => setUpdateOpen(false)}
    onSubmit={handleUpdateSubmit}
    selectedCar={selectedCar}
   />
  </Box>
 );
};

export default PricesByCar;

// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//  Button,
//  Box,
//  Typography,
//  CircularProgress,
//  Table,
//  TableBody,
//  TableCell,
//  TableContainer,
//  TableHead,
//  TableRow,
//  Paper,
// } from "@mui/material";
// import CarPriceForm from "./CarPriceForm";
// import {
//  deletePriceByCar,
//  getAllCarPrices,
//  getProducts,
// } from "../../redux/products/action";
// import { getBrands } from "../../redux/admin/action";
// import axios from "axios";

// const PricesByCar = () => {
//  const dispatch = useDispatch();
//  const { productByCarPrice } = useSelector((store) => store.product);

//  const [selectedCar, setSelectedCar] = useState("");
//  const [selectedVariants, setSelectedVariants] = useState([]);
//  const [open, setOpen] = useState(false);
//  const [loading, setLoading] = useState(true);

//  useEffect(() => {
//   const fetchData = async () => {
//    try {
//     await dispatch(getAllCarPrices());
//     await dispatch(getBrands());
//     await dispatch(getProducts());
//    } catch (error) {
//     console.error("Error fetching data:", error);
//    } finally {
//     setLoading(false);
//    }
//   };

//   fetchData();
//  }, [dispatch]);

//  useEffect(() => {
//   console.log("Product by Car Price:", productByCarPrice);
//  }, [productByCarPrice]);

//  const handleVariantSelect = (event, variant) => {
//   if (event.target.checked) {
//    setSelectedVariants([...selectedVariants, variant]);
//   } else {
//    setSelectedVariants(selectedVariants.filter((v) => v._id !== variant._id));
//   }
//  };

//  const handleSubmit = async (data) => {
//   try {
//    await axios.post(`https://app-api.carexpert.org.in/api/price/create`, data);
//    setOpen(false);
//    setSelectedVariants([]);
//   } catch (error) {
//    console.log("error:", error);
//   }
//  };

//  const handleEdit = (carPrice) => {
//   setSelectedCar(carPrice);
//   setOpen(true);
//  };

//  const handleDelete = (id) => {
//   dispatch(deletePriceByCar(id));
//  };

//  if (loading) {
//   return <CircularProgress />;
//  }

//  if (!productByCarPrice || !Array.isArray(productByCarPrice)) {
//   console.error(
//    "Product by Car Price is not an array or is undefined:",
//    productByCarPrice
//   );
//   return (
//    <Typography variant="h6" color="error">
//     No data available or data format is incorrect.
//    </Typography>
//   );
//  }

//  productByCarPrice.forEach((row, index) => {
//   console.log(`Row ${index}:`, row);
//  });

//  return (
//   <Box>
//    <Typography variant="h4" gutterBottom>
//     Car Prices
//    </Typography>
//    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
//     Add Car Price
//    </Button>
//    <TableContainer component={Paper} style={{ marginTop: "16px" }}>
//     <Table>
//      <TableHead>
//       <TableRow>
//        <TableCell>Product ID</TableCell>
//        <TableCell>Car ID</TableCell>
//        <TableCell>Variant</TableCell>
//        <TableCell>Transmission</TableCell>
//        <TableCell>MRP</TableCell>
//        <TableCell>Given Price</TableCell>
//        <TableCell>Actions</TableCell>
//       </TableRow>
//      </TableHead>
//      <TableBody>
//       {productByCarPrice.map((item) => (
//        <TableRow key={item._id}>
//         <TableCell>{item.productId?.productName || "N/A"}</TableCell>
//         <TableCell>{item.carId?.title || "N/A"}</TableCell>
//         <TableCell>{item.variant || "N/A"}</TableCell>
//         <TableCell>{item.transmission || "N/A"}</TableCell>
//         <TableCell>{item.mrp || "N/A"}</TableCell>
//         <TableCell>{item.givenPrice || "N/A"}</TableCell>
//         <TableCell>
//          <Button onClick={() => handleEdit(item)}>Edit</Button>
//          <Button onClick={() => handleDelete(item._id)}>Delete</Button>
//         </TableCell>
//        </TableRow>
//       ))}
//      </TableBody>
//     </Table>
//    </TableContainer>
//    <CarPriceForm
//     open={open}
//     onClose={() => setOpen(false)}
//     onSubmit={handleSubmit}
//     selectedCar={selectedCar}
//     setSelectedCar={setSelectedCar}
//     selectedVariants={selectedVariants}
//     handleVariantSelect={handleVariantSelect}
//    />
//   </Box>
//  );
// };

// export default PricesByCar;

// Video Testimonial Action Types
export const GET_VIDEO_TESTIMONIALS_REQUEST = "GET_VIDEO_TESTIMONIALS_REQUEST";
export const GET_VIDEO_TESTIMONIALS_SUCCESS = "GET_VIDEO_TESTIMONIALS_SUCCESS";
export const GET_VIDEO_TESTIMONIALS_FAILURE = "GET_VIDEO_TESTIMONIALS_FAILURE";

export const ADD_VIDEO_TESTIMONIAL_REQUEST = "ADD_VIDEO_TESTIMONIAL_REQUEST";
export const ADD_VIDEO_TESTIMONIAL_SUCCESS = "ADD_VIDEO_TESTIMONIAL_SUCCESS";
export const ADD_VIDEO_TESTIMONIAL_FAILURE = "ADD_VIDEO_TESTIMONIAL_FAILURE";

export const UPDATE_VIDEO_TESTIMONIAL_REQUEST =
 "UPDATE_VIDEO_TESTIMONIAL_REQUEST";
export const UPDATE_VIDEO_TESTIMONIAL_SUCCESS =
 "UPDATE_VIDEO_TESTIMONIAL_SUCCESS";
export const UPDATE_VIDEO_TESTIMONIAL_FAILURE =
 "UPDATE_VIDEO_TESTIMONIAL_FAILURE";

export const DELETE_VIDEO_TESTIMONIAL_REQUEST =
 "DELETE_VIDEO_TESTIMONIAL_REQUEST";
export const DELETE_VIDEO_TESTIMONIAL_SUCCESS =
 "DELETE_VIDEO_TESTIMONIAL_SUCCESS";
export const DELETE_VIDEO_TESTIMONIAL_FAILURE =
 "DELETE_VIDEO_TESTIMONIAL_FAILURE";

// src/actions/offerActionTypes.js

export const GET_OFFERS_REQUEST = "GET_OFFERS_REQUEST";
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS";
export const GET_OFFERS_FAILURE = "GET_OFFERS_FAILURE";

export const ADD_OFFER_REQUEST = "ADD_OFFER_REQUEST";
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS";
export const ADD_OFFER_FAILURE = "ADD_OFFER_FAILURE";

export const UPDATE_OFFER_REQUEST = "UPDATE_OFFER_REQUEST";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAILURE = "UPDATE_OFFER_FAILURE";

export const DELETE_OFFER_REQUEST = "DELETE_OFFER_REQUEST";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILURE = "DELETE_OFFER_FAILURE";

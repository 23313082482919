// actions.js

import axios from "axios";
import * as types from "./actionTypes";

export const requestOtp = (phoneNumber) => async (dispatch) => {
 dispatch({ type: types.REQUEST_OTP_REQUEST });
 try {
  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/vendor/request-otp",
   { phoneNumber }
  );
  if (response.data.success) {
   dispatch({ type: "REQUEST_OTP_SUCCESS", payload: response.data });
   return { success: true, message: response.data.message };
  } else {
   dispatch({ type: "REQUEST_OTP_FAILURE", payload: response.data });
   return { success: false, message: response.data.message };
  }
 } catch (error) {
  dispatch({ type: "REQUEST_OTP_FAILURE", payload: error.response.data });
  return { success: false, message: error.response.data.message };
 }
};

export const verifyOtp = (phoneNumber, otpCode) => async (dispatch) => {
 dispatch({ type: types.VERIFY_OTP_REQUEST });
 try {
  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/vendor/verify-otp",
   { phoneNumber, otpCode }
  );
  if (response.data.success) {
   dispatch({ type: "VERIFY_OTP_SUCCESS", payload: response.data });
   return { success: true, message: response.data.message };
  } else {
   dispatch({ type: "VERIFY_OTP_FAILURE", payload: response.data });
   return { success: false, message: response.data.message };
  }
 } catch (error) {
  dispatch({ type: "VERIFY_OTP_FAILURE", payload: error.response.data });
  return { success: false, message: error.response.data.message };
 }
};

export const updateVendorDetails =
 (name, email, password, phoneNumber) => async (dispatch) => {
  dispatch({ type: types.UPDATE_VENDOR_DETAILS_REQUEST });
  try {
   const response = await axios.patch(
    "https://app-api.carexpert.org.in/api/vendor/updateVendorDetails",
    {
     name,
     email,
     password,
     phoneNumber,
    }
   );
   if (response.data.success) {
    dispatch({ type: "UPDATE_VENDOR_SUCCESS", payload: response.data });
    return { success: true, message: response.data.message };
   } else {
    dispatch({ type: "UPDATE_VENDOR_FAILURE", payload: response.data });
    return { success: false, message: response.data.message };
   }
  } catch (error) {
   dispatch({ type: "UPDATE_VENDOR_FAILURE", payload: error.response.data });
   return { success: false, message: error.response.data.message };
  }
 };
export const loginUser = (email, password) => async (dispatch) => {
 dispatch({ type: types.LOGIN_USER_REQUEST });
 try {
  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/vendor/login",
   {
    email,
    password,
   }
  );
  dispatch({ type: types.LOGIN_USER_SUCCESS, payload: response.data });
  return response.data; // Ensure the caller gets the response data
 } catch (error) {
  dispatch({ type: types.LOGIN_USER_FAILURE, payload: error.message });
  return null; // Return null or an error object for the caller to handle
 }
};
export const logoutUser = () => (dispatch) => {
 // Clear session storage
 sessionStorage.removeItem("userDetails");
 sessionStorage.removeItem("isAuth");

 // Dispatch logout action to clear state
 dispatch({ type: types.LOGOUT_USER });
};

import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Container, Alert } from "@mui/material";

function ResetPassword() {
 const { token } = useParams();
 const navigate = useNavigate();
 const [formData, setFormData] = useState({
  password: "",
  confirmPassword: "",
 });
 const [alert, setAlert] = useState(null);

 const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
 };

 const handleSubmit = async (e) => {
  e.preventDefault();

  if (formData.password !== formData.confirmPassword) {
   setAlert({ severity: "error", message: "Passwords do not match" });
   return;
  }

  try {
   const response = await fetch(
    `https://app-api.carexpert.org.in/api/reset-password/${token}`,
    {
     method: "POST",
     headers: {
      "Content-Type": "application/json",
     },
     body: JSON.stringify({ password: formData.password }),
    }
   );

   const data = await response.json();

   if (response.ok) {
    setAlert({ severity: "success", message: "Password reset successfully" });
    navigate("/"); // Redirect to login page after success
   } else {
    setAlert({
     severity: "error",
     message: data.message || "Error resetting password",
    });
   }
  } catch (error) {
   setAlert({
    severity: "error",
    message: "An error occurred. Please try again.",
   });
  }
 };

 return (
  <Container maxWidth="sm">
   <Typography variant="h4" align="center" gutterBottom>
    Reset Password
   </Typography>

   {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}

   <form onSubmit={handleSubmit}>
    <TextField
     fullWidth
     label="New Password"
     name="password"
     type="password"
     value={formData.password}
     onChange={handleInputChange}
     margin="normal"
     required
    />
    <TextField
     fullWidth
     label="Confirm Password"
     name="confirmPassword"
     type="password"
     value={formData.confirmPassword}
     onChange={handleInputChange}
     margin="normal"
     required
    />
    <Button
     type="submit"
     fullWidth
     variant="contained"
     color="primary"
     sx={{ mt: 2 }}
    >
     Reset Password
    </Button>
   </form>
  </Container>
 );
}

export default ResetPassword;

// import React, { useState } from "react";
// import {
//  Box,
//  Button,
//  TextField,
//  Typography,
//  Alert,
//  AlertTitle,
//  Paper,
// } from "@mui/material";
// import { styled } from "@mui/system";

// // Import the image
// const imageUrl =
//  "https://ideogram.ai/assets/progressive-image/balanced/response/rKj6De-PSH2y1qRYaK5_zA"; // Replace with the correct path

// const Container = styled(Box)({
//  display: "flex",
//  justifyContent: "center",
//  alignItems: "center",
//  minHeight: "100vh",
//  backgroundColor: "#f0f4fa",
// });

// const FormWrapper = styled(Paper)({
//  padding: "30px",
//  maxWidth: "500px",
//  width: "100%",
//  borderRadius: "10px",
//  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
// });

// const ImageWrapper = styled(Box)({
//  width: "50%",
//  display: "flex",
//  justifyContent: "center",
//  alignItems: "center",
//  paddingRight: "20px",
// });

// const Image = styled("img")({
//  maxWidth: "100%",
//  height: "auto",
//  borderRadius: "10px",
// });

// const SendButton = styled(Button)({
//  marginTop: "20px",
//  backgroundColor: "#FF6F61",
//  color: "white",
//  "&:hover": {
//   backgroundColor: "#FF4B3A",
//  },
// });

// function ResetPassword() {
//  const [formData, setFormData] = useState({
//   newPassword: "",
//   confirmPassword: "",
//  });

//  const [alert, setAlert] = useState({
//   visible: false,
//   message: "",
//   severity: "",
//  });

//  const handleInputChange = (e) => {
//   const { name, value } = e.target;
//   setFormData({ ...formData, [name]: value });
//  };

//  const handleResetPassword = async () => {
//   const { newPassword, confirmPassword } = formData;

//   if (!newPassword || !confirmPassword) {
//    setAlert({
//     visible: true,
//     message: "Please fill out both password fields.",
//     severity: "warning",
//    });
//    return;
//   }

//   if (newPassword !== confirmPassword) {
//    setAlert({
//     visible: true,
//     message: "Passwords do not match.",
//     severity: "error",
//    });
//    return;
//   }

//   // Simulate API call to reset password
//   try {
//    const response = await fetch("https://app-api.carexpert.org.in/api/reset-password", {
//     method: "POST",
//     headers: {
//      "Content-Type": "application/json",
//     },
//     body: JSON.stringify({ password: newPassword }),
//    });

//    const data = await response.json();

//    if (response.ok) {
//     setAlert({
//      visible: true,
//      message: "Password reset successful.",
//      severity: "success",
//     });
//    } else {
//     setAlert({
//      visible: true,
//      message: data.message || "Failed to reset password.",
//      severity: "error",
//     });
//    }
//   } catch (error) {
//    setAlert({
//     visible: true,
//     message: "Error occurred while resetting password. Please try again.",
//     severity: "error",
//    });
//   }
//  };

//  return (
//   <Container>
//    <ImageWrapper>
//     {/* Displaying the uploaded image */}
//     <Image src={imageUrl} alt="Reset Password Illustration" />
//    </ImageWrapper>

//    <FormWrapper>
//     <Typography variant="h4" align="center" gutterBottom>
//      Reset Your Password
//     </Typography>
//     <Typography
//      variant="body1"
//      align="center"
//      color="textSecondary"
//      gutterBottom
//     >
//      Please enter and confirm your new password below.
//     </Typography>

//     {alert.visible && (
//      <Alert
//       severity={alert.severity}
//       onClose={() => setAlert({ visible: false, message: "", severity: "" })}
//      >
//       <AlertTitle>
//        {alert.severity === "success" ? "Success" : "Error"}
//       </AlertTitle>
//       {alert.message}
//      </Alert>
//     )}

//     <TextField
//      fullWidth
//      label="New Password"
//      margin="normal"
//      type="password"
//      name="newPassword"
//      value={formData.newPassword}
//      onChange={handleInputChange}
//      variant="outlined"
//     />
//     <TextField
//      fullWidth
//      label="Confirm Password"
//      margin="normal"
//      type="password"
//      name="confirmPassword"
//      value={formData.confirmPassword}
//      onChange={handleInputChange}
//      variant="outlined"
//     />

//     <SendButton fullWidth variant="contained" onClick={handleResetPassword}>
//      Reset Password
//     </SendButton>
//    </FormWrapper>
//   </Container>
//  );
// }

// export default ResetPassword;

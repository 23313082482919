import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

// Styled Components for uniform styling
const Section = styled(Box)(({ theme }) => ({
 padding: theme.spacing(6, 2),
 textAlign: "center",
}));

const StatNumber = styled(Typography)(({ theme }) => ({
 color: theme.palette.success.main,
 fontSize: "2rem",
 fontWeight: "bold",
}));

const StatLabel = styled(Typography)(({ theme }) => ({
 marginTop: theme.spacing(1),
}));

// Data for dynamic rendering
const stats = [
 { number: "500+", label: "Partners already on board" },
 { number: "₹200Cr", label: "Paid out to partners in 2019" },
 { number: "10000+", label: "Services delivered each month" },
];

const benefits = [
 {
  icon: "👥", // Replace with actual icons or images
  title: "Get Customers",
  description: "Get more customers at your workshop.",
 },
 {
  icon: "🔧", // Replace with actual icons or images
  title: "Get Support",
  description: "Get spares, consumables, and operations support.",
 },
 {
  icon: "💵", // Replace with actual icons or images
  title: "Earn More",
  description: "Earn greater revenue and profits.",
 },
];

const perks = [
 {
  icon: "💰", // Replace with actual icons or images
  title: "Competitive Pricing",
  description: "Get nationwide best pricing & discount on bulk procurement",
 },
 {
  icon: "🔩", // Replace with actual icons or images
  title: "Genuine Spare Parts",
  description: "We use only OEM/OES spare parts.",
 },
 {
  icon: "🚚", // Replace with actual icons or images
  title: "Easy Shipping",
  description: "Get last-mile shipping solutions.",
 },
];

const steps = [
 {
  step: 1,
  title: "Step 1",
  description:
   "Enter your details and we will get back to you to discuss your business needs.",
 },
 {
  step: 2,
  title: "Step 2",
  description:
   "Signup with Car-Expert using the link received after the conversation.",
 },
 {
  step: 3,
  title: "Step 3",
  description:
   "Onboard Car-Expert technology suite for hassle-free operations.",
 },
];

// Main Component
const DynamicUI = () => {
 return (
  <Container sx={{}}>
   {/* Statistics Section */}
   <Section sx={{ height: "70vh" }}>
    <Typography variant="h4" gutterBottom>
     Join Car-Expert & Grow Your Business
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
     Boost your car repair and car spares/accessories business by opting for the
     Car-Expert Partnership program.
    </Typography>
    <Grid container spacing={4} mt={10}>
     {stats.map((stat, index) => (
      <Grid item xs={12} md={4} key={index}>
       <StatNumber>{stat.number}</StatNumber>
       <StatLabel>{stat.label}</StatLabel>
      </Grid>
     ))}
    </Grid>
    <Box
     sx={{ border: "5px solid blue", width: "200px", margin: "50px auto" }}
    />
   </Section>

   {/* Benefits Section */}
   <Section sx={{ height: "70vh" }}>
    <Typography variant="h4" gutterBottom>
     Are You a Workshop Owner?
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
     Join us and get customers, get support and earn more!
    </Typography>
    <Grid container spacing={4} mt={10}>
     {benefits.map((benefit, index) => (
      <Grid item xs={12} md={4} key={index}>
       <Box>
        <Typography variant="h3">{benefit.icon}</Typography>
        <Typography variant="h6">{benefit.title}</Typography>
        <Typography variant="body2">{benefit.description}</Typography>
       </Box>
      </Grid>
     ))}
    </Grid>
    <Box
     sx={{ border: "5px solid blue", width: "200px", margin: "50px auto" }}
    />
   </Section>

   {/* Perks Section */}
   <Section sx={{ height: "70vh" }}>
    <Typography variant="h4" gutterBottom>
     Exclusive Perks for Car-Expert Accessories/Spares Partners
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
     Signup today and enjoy these exclusive benefits.
    </Typography>
    <Grid container spacing={4} mt={10}>
     {perks.map((perk, index) => (
      <Grid item xs={12} md={4} key={index}>
       <Box>
        <Typography variant="h3">{perk.icon}</Typography>
        <Typography variant="h6">{perk.title}</Typography>
        <Typography variant="body2">{perk.description}</Typography>
       </Box>
      </Grid>
     ))}
    </Grid>
    <Box
     sx={{ border: "5px solid blue", width: "200px", margin: "50px auto" }}
    />
   </Section>

   {/* Steps Section */}
   <Section sx={{ height: "70vh" }}>
    <Typography variant="h4" gutterBottom>
     Steps To Partner with Car-Expert
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
     Onboarding with Car-Expert is a simple 3-step process.
    </Typography>
    <Grid container spacing={4} mt={10}>
     {steps.map((step, index) => (
      <Grid item xs={12} md={4} key={index}>
       <Box>
        <Typography variant="h5">{step.title}</Typography>
        <Typography variant="body2">{step.description}</Typography>
       </Box>
      </Grid>
     ))}
    </Grid>
    <Box
     sx={{ border: "5px solid blue", width: "200px", margin: "50px auto" }}
    />
   </Section>
  </Container>
 );
};

export default DynamicUI;

import * as types from "./actionTypes";
import axios from "axios";

const API_URL = "https://app-api.carexpert.org.in/api"; // Define base API URL

export const addCar = (formData) => async (dispatch) => {
 try {
  dispatch({ type: types.ADD_CAR_REQUEST });
  const response = await axios.post(`${API_URL}/cars/add-car`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.ADD_CAR_SUCCESS, payload: response.data });
  dispatch(getCars());
 } catch (error) {
  dispatch({ type: types.ADD_CAR_FAILURE, payload: error.response.data });
 }
};

export const getCars =
 (filters = {}) =>
 async (dispatch) => {
  try {
   dispatch({ type: types.GET_CAR_REQUEST });
   const { data } = await axios.get(
    "https://app-api.carexpert.org.in/api/cars/all-car",
    {
     params: filters,
    }
   );
   console.log("data:", data);
   dispatch({ type: types.GET_CAR_SUCCESS, payload: data });
  } catch (error) {
   dispatch({ type: types.GET_CAR_FAILURE, error });
  }
 };

export const getAllCars =
 (filters = {}) =>
 async (dispatch) => {
  try {
   dispatch({ type: types.GET_ALL_VARIENTS_REQUEST });
   const query = new URLSearchParams(filters).toString();
   const response = await axios.get(
    `https://app-api.carexpert.org.in/api/cars/all-varients?${query}`
   );
   dispatch({
    type: types.GET_ALL_VARIENTS_SUCCESS,
    payload: response.data,
   });
  } catch (error) {
   dispatch({ type: types.GET_ALL_VARIENTS_FAILURE, payload: error });
  }
 };

export const updateCars = (id, car) => async (dispatch) => {
 try {
  dispatch({ type: types.UPDATE_CAR_REQUEST });
  const response = await axios.patch(`${API_URL}/update-car/${id}`, car);
  dispatch({ type: types.UPDATE_CAR_SUCCESS, payload: response.data.data });
  dispatch(getCars());
 } catch (error) {
  dispatch({ type: types.UPDATE_CAR_FAILURE, payload: error });
 }
};

export const deleteCars = (id) => async (dispatch) => {
 try {
  dispatch({ type: types.DELETE_CARS_REQUEST });
  const response = await axios.delete(`${API_URL}/cars/${id}`);
  dispatch({ type: types.DELETE_CARS_SUCCESS, payload: response.data.data });
  dispatch(getCars());
 } catch (error) {
  dispatch({ type: types.DELETE_CARS_FAILURE, payload: error });
 }
};
export const deleteCarById = (id) => async (dispatch) => {
 try {
  dispatch({ type: types.DELETE_CAR_REQUEST });
  await axios.delete(`https://app-api.carexpert.org.in/api/cars/del/var/${id}`);
  dispatch({ type: types.DELETE_CAR_SUCCESS, payload: id });
 } catch (error) {
  dispatch({ type: types.DELETE_CAR_FAILURE, payload: error });
 }
};

export const getBrands = () => async (dispatch) => {
 try {
  dispatch({ type: types.GET_BRANDS_REQUEST });
  const response = await axios.get(`${API_URL}/brands`);
  dispatch({ type: types.GET_BRANDS_SUCCESS, payload: response.data.data });
 } catch (error) {
  dispatch({ type: types.GET_BRANDS_FAILURE, payload: error.message });
 }
};

export const addBrand = (formData) => async (dispatch) => {
 dispatch({ type: types.CREATE_BRAND_REQUEST });
 try {
  const response = await axios.post(`${API_URL}/brands`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.CREATE_BRAND_SUCCESS, payload: response.data.data });
  dispatch(getBrands());
 } catch (error) {
  dispatch({ type: types.CREATE_BRAND_FAILURE, payload: error.message });
 }
};

export const updateBrand = (id, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_BRAND_REQUEST });
 try {
  const response = await axios.patch(`${API_URL}/brands/${id}`, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });
  dispatch({ type: types.UPDATE_BRAND_SUCCESS, payload: response.data.data });
  dispatch(getBrands());
 } catch (error) {
  dispatch({ type: types.UPDATE_BRAND_FAILURE, payload: error.message });
 }
};

export const deleteBrand = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_BRAND_REQUEST });
 try {
  const response = await axios.delete(`${API_URL}/brands/${id}`);
  dispatch({ type: types.DELETE_BRAND_SUCCESS, payload: response.data.data });
  dispatch(getBrands());
 } catch (error) {
  dispatch({ type: types.DELETE_BRAND_FAILURE, payload: error.message });
 }
};

export const getUsers = () => async (dispatch) => {
 try {
  dispatch({ type: types.GET_USER_REQUEST });
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/users"
  );
  dispatch({ type: types.GET_USER_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_USER_FAILURE, payload: error });
 }
};

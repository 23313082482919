export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAILURE = "REMOVE_FROM_CART_FAILURE";

export const APPLY_COUPON_REQUEST = "APPLY_COUPON_REQUEST";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAILURE = "APPLY_COUPON_FAILURE";

export const GET_REFERRALS_REQUEST = "GET_REFERRALS_REQUEST";
export const GET_REFERRALS_SUCCESS = "GET_REFERRALS_SUCCESS";
export const GET_REFERRALS_FAILURE = "GET_REFERRALS_FAILURE";

export const CREATE_REFERRAL_REQUEST = "CREATE_REFERRAL_REQUEST";
export const CREATE_REFERRAL_SUCCESS = "CREATE_REFERRAL_SUCCESS";
export const CREATE_REFERRAL_FAILURE = "CREATE_REFERRAL_FAILURE";

export const GET_ORDER_HISTORY_REQUEST = "GET_ORDER_HISTORY_REQUEST";
export const GET_ORDER_HISTORY_SUCCESS = "GET_ORDER_HISTORY_SUCCESS";
export const GET_ORDER_HISTORY_FAILURE = "GET_ORDER_HISTORY_FAILURE";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const REFUND_ORDER_REQUEST = "REFUND_ORDER_REQUEST";
export const REFUND_ORDER_SUCCESS = "REFUND_ORDER_SUCCESS";
export const REFUND_ORDER_FAILURE = "REFUND_ORDER_FAILURE";

export const GET_SLOTS_REQUEST = "GET_SLOTS_REQUEST";
export const GET_SLOTS_SUCCESS = "GET_SLOTS_SUCCESS";
export const GET_SLOTS_FAILURE = "GET_SLOTS_FAILURE";

export const CREATE_SLOT_REQUEST = "CREATE_SLOT_REQUEST";
export const CREATE_SLOT_SUCCESS = "CREATE_SLOT_SUCCESS";
export const CREATE_SLOT_FAILURE = "CREATE_SLOT_FAILURE";

export const UPDATE_SLOT_REQUEST = "UPDATE_SLOT_REQUEST";
export const UPDATE_SLOT_SUCCESS = "UPDATE_SLOT_SUCCESS";
export const UPDATE_SLOT_FAILURE = "UPDATE_SLOT_FAILURE";
export const DELETE_SLOT_REQUEST = "DELETE_SLOT_REQUEST";
export const DELETE_SLOT_SUCCESS = "DELETE_SLOT_SUCCESS";
export const DELETE_SLOT_FAILURE = "DELETE_SLOT_FAILURE";

export const GET_COUPONS_REQUEST = "GET_COUPONS_REQUEST";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAILURE = "GET_COUPONS_FAILURE";

export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAILURE = "CREATE_COUPON_FAILURE";

export const UPDATE_COUPON_REQUEST = "UPDATE_COUPON_REQUEST";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILURE = "UPDATE_COUPON_FAILURE";

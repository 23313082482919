import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Slider from "react-slick";

// Import slick carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Styled component for the background section
const BackgroundSection = styled(Box)(({ theme }) => ({
 backgroundSize: "cover",
 backgroundPosition: "center",
 height: "65vh",
 position: "relative",
 display: "flex",
 alignItems: "center",
 justifyContent: "center",
 color: "#fff",
 [theme.breakpoints.down("sm")]: {
  flexDirection: "column",
 },
}));

const ParentContainer = styled(Box)({
 backgroundColor: "#091018",

 display: "flex",
 justifyContent: "center",
 alignItems: "center",
});

// Sample data for the carousel slides
const slides = [
 {
  id: 1,
  title: "Are You a Workshop Owner?",
  description:
   "Join India’s fastest growing car service network and get more customers with seamless customer support and inventory management.",
  image: "/car.jpg", // Replace with actual image path
 },
 {
  id: 2,
  title: "Need Spares Retailer?",
  description:
   "Grow your business by joining our platform and offering a wide range of spares to our growing customer base.",
  image: "/about_banner.jpg", // Replace with actual image path
 },
 {
  id: 3,
  title: "Expand Your Workshop?",
  description:
   "Become a partner and take advantage of our advanced tools and services to expand your workshop's reach.",
  image: "/img4.jpeg", // Replace with actual image path
 },
 {
  id: 4,
  title: "Expand Your Workshop?",
  description:
   "Become a partner and take advantage of our advanced tools and services to expand your workshop's reach.",
  image: "/franchisee_banner.png", // Replace with actual image path
 },
 {
  id: 5,
  title: "Expand Your Workshop?",
  description:
   "Become a partner and take advantage of our advanced tools and services to expand your workshop's reach.",
  image: "/carservice.jpg", // Replace with actual image path
 },
 {
  id: 6,
  title: "Expand Your Workshop?",
  description:
   "Become a partner and take advantage of our advanced tools and services to expand your workshop's reach.",
  image: "/carservicing.jpg", // Replace with actual image path
 },
];

const FormBanner = () => {
 // Settings for the slick carousel
 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
 };

 return (
  <ParentContainer>
   <Box sx={{ width: "100%" }}>
    <Slider {...settings}>
     {slides.map((slide) => (
      <BackgroundSection key={slide.id}>
       <Grid container sx={{ height: "100%" }}>
        {/* Image Section (70%) */}
        <Grid
         item
         xs={12}
         sm={7}
         sx={{
          backgroundImage: `url(${slide.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
         }}
        ></Grid>
        {/* Text Section (30%) */}
        <Grid
         item
         xs={12}
         sm={5}
         sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 3,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
         }}
        >
         <Box>
          <Typography variant="h4" component="h1" gutterBottom>
           {slide.title}
          </Typography>
          <Typography variant="body1">{slide.description}</Typography>
         </Box>
        </Grid>
       </Grid>
      </BackgroundSection>
     ))}
    </Slider>
   </Box>
  </ParentContainer>
 );
};

export default FormBanner;

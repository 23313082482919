import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
 Box,
 Grid,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 Fab,
 FormControl,
 TextField,
 Modal,
 Fade,
 Backdrop,
 Button,
 Snackbar,
 Alert,
 MenuItem,
 Select,
 InputLabel,
 IconButton,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import { useForm, useFieldArray } from "react-hook-form";
import {
 addSparePart,
 deleteSparePart,
 fetchSpareParts,
 updateSparePart,
 getSubCategories,
} from "../../redux/products/action";
import AWS from "aws-sdk";

const style = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: "90%",
 maxWidth: 500,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

const useFileUpload = () => {
 const [isUploading, setIsUploading] = useState(false);
 const [error, setError] = useState(null);
 const [uploadedFileUrl, setUploadedFileUrl] = useState("");

 const uploadFile = async (file, callback) => {
  setIsUploading(true);
  setError(null);

  const S3 = new AWS.S3({
   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
   region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
   Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
   Key: `${Date.now()}-${file.name}`,
   Body: file,
   ContentType: file.type,
  };

  try {
   const uploadResult = await S3.upload(params).promise();
   setUploadedFileUrl(uploadResult.Location);
   if (callback) callback(uploadResult.Location);
  } catch (err) {
   setError(err.message);
  } finally {
   setIsUploading(false);
  }
 };

 return { uploadFile, isUploading, error, uploadedFileUrl, setUploadedFileUrl };
};

const AdminSpareParts = () => {
 const dispatch = useDispatch();
 const { spareParts, subCategories } = useSelector((state) => state.product);
 const { register, handleSubmit, reset, setValue, control } = useForm();
 const [form, setForm] = useState({
  spareName: "",
  price: "",
  mrp: "",
  subCategoryId: "",
  image: "",
  highlights: [{ text: "", icon: "" }],
 });
 const [open, setOpen] = useState(false);
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [currentSparePart, setCurrentSparePart] = useState(null);

 const {
  fields: highlightFields,
  append: appendHighlight,
  remove: removeHighlight,
 } = useFieldArray({
  control,
  name: "highlights",
 });

 const { uploadFile, isUploading, error, uploadedFileUrl, setUploadedFileUrl } =
  useFileUpload();

 useEffect(() => {
  dispatch(fetchSpareParts());
  dispatch(getSubCategories());
 }, [dispatch]);

 useEffect(() => {
  if (uploadedFileUrl) {
   setForm((prevForm) => ({
    ...prevForm,
    image: uploadedFileUrl,
   }));
   setUploadedFileUrl("");
  }
 }, [uploadedFileUrl, setUploadedFileUrl]);

 const handleInputChange = (e) => {
  const { name, value } = e.target;
  setForm({ ...form, [name]: value });
 };

 const handleFileChange = async (name, index, e) => {
  const file = e.target.files[0];
  if (file) {
   uploadFile(file, (url) => {
    if (name.startsWith("highlights")) {
     const newHighlights = [...form.highlights];
     newHighlights[index] = { ...newHighlights[index], icon: url };
     setForm({ ...form, highlights: newHighlights });
    } else {
     setForm({ ...form, [name]: url });
    }
   });
  }
 };

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setOpen(false);
  reset();
  setForm({
   spareName: "",
   price: "",
   mrp: "",
   subCategoryId: "",
   image: "",
   highlights: [{ text: "", icon: "" }],
  });
 };

 const handleSnackbarClose = (event, reason) => {
  if (reason === "clickaway") {
   return;
  }
  setSnackbarOpen(false);
 };

 const handleDelete = (id) => {
  dispatch(deleteSparePart(id));
  setSnackbarMessage("Spare part deleted successfully!");
  setSnackbarOpen(true);
 };

 const handleEdit = (sparePart) => {
  setCurrentSparePart(sparePart);
  setValue("spareName", sparePart.spareName);
  setValue("price", sparePart.price);
  setValue("mrp", sparePart.mrp);
  setValue("subCategoryId", sparePart.subCategoryId);
  setValue("highlights", sparePart.highlights || [{ text: "", icon: "" }]);
  setForm({
   spareName: sparePart.spareName,
   price: sparePart.price,
   mrp: sparePart.mrp,
   subCategoryId: sparePart.subCategoryId,
   image: sparePart.image,
   highlights: sparePart.highlights || [{ text: "", icon: "" }],
  });
  handleOpen();
 };

 const handleAddSparePart = (data) => {
  const formData = { ...data, image: form.image, highlights: form.highlights };
  dispatch(addSparePart(formData));
  setSnackbarMessage("Spare part added successfully!");
  setSnackbarOpen(true);
  handleClose();
 };

 const handleUpdateSparePart = (data) => {
  const formData = { ...data, image: form.image, highlights: form.highlights };
  dispatch(updateSparePart(currentSparePart._id, formData));
  setSnackbarMessage("Spare part updated successfully!");
  setSnackbarOpen(true);
  handleClose();
 };

 const onSubmit = (data) => {
  if (currentSparePart) {
   handleUpdateSparePart(data);
  } else {
   handleAddSparePart(data);
  }
 };

 return (
  <Box sx={{ width: "100%", height: "auto" }}>
   <Typography
    fontSize={{ xs: "18px", md: "25px" }}
    paddingLeft="5px"
    fontWeight="600"
   >
    Spare Parts Management
   </Typography>
   <Box
    sx={{
     border: "1px solid #1976D2",
     padding: "10px",
     display: "flex",
     alignItems: "center",
     justifyContent: "space-between",
     flexDirection: { xs: "column", sm: "row" },
     gap: { xs: "10px", sm: "50px" },
    }}
   >
    <Fab
     color="secondary"
     aria-label="add"
     onClick={() => {
      setCurrentSparePart(null);
      handleOpen();
     }}
    >
     <AddIcon />
    </Fab>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto", overflowX: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>Spare Name</TableCell>
       <TableCell sx={{ color: "white" }}>Image</TableCell>
       <TableCell sx={{ color: "white" }}>Price</TableCell>
       <TableCell sx={{ color: "white" }}>MRP</TableCell>
       <TableCell sx={{ color: "white" }}>SubCategory</TableCell>
       <TableCell sx={{ color: "white" }}>Actions</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {Array.isArray(spareParts) &&
       spareParts?.map((sparePart) => (
        <TableRow key={sparePart._id}>
         <TableCell component="th" scope="row">
          {sparePart.spareName}
         </TableCell>
         <TableCell>
          {sparePart.image && (
           <img
            src={sparePart.image}
            alt={sparePart.spareName}
            style={{ width: "50px", height: "50px" }}
           />
          )}
         </TableCell>
         <TableCell>{sparePart.price}</TableCell>
         <TableCell>{sparePart.mrp}</TableCell>
         <TableCell>{sparePart.SubCategory}</TableCell>
         <TableCell>
          <Fab
           color="primary"
           aria-label="edit"
           onClick={() => handleEdit(sparePart)}
          >
           <EditIcon />
          </Fab>
          <Fab
           color="secondary"
           aria-label="delete"
           sx={{ marginLeft: "5px" }}
           onClick={() => handleDelete(sparePart._id)}
          >
           <DeleteIcon />
          </Fab>
         </TableCell>
        </TableRow>
       ))}
     </TableBody>
    </Table>
   </TableContainer>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
   >
    <Fade in={open}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       {currentSparePart ? "Edit Spare Part" : "Add Spare Part"}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <Grid container spacing={2}>
        <Grid item xs={12}>
         <FormControl fullWidth>
          <TextField
           {...register("spareName", {
            required: "Spare Name is required",
           })}
           label="Spare Name"
           value={form.spareName}
           onChange={handleInputChange}
           name="spareName"
           margin="normal"
          />
         </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
         <FormControl fullWidth>
          <TextField
           {...register("price", { required: "Price is required" })}
           label="Price"
           type="number"
           value={form.price}
           onChange={handleInputChange}
           name="price"
           margin="normal"
          />
         </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
         <FormControl fullWidth>
          <TextField
           {...register("mrp", { required: "MRP is required" })}
           label="MRP"
           type="number"
           value={form.mrp}
           onChange={handleInputChange}
           name="mrp"
           margin="normal"
          />
         </FormControl>
        </Grid>
        <Grid item xs={12}>
         <FormControl fullWidth margin="normal">
          <InputLabel>SubCategory</InputLabel>
          <Select
           {...register("subCategoryId", {
            required: "SubCategory is required",
           })}
           value={form.subCategoryId}
           onChange={handleInputChange}
           name="subCategoryId"
          >
           {subCategories?.map((subcategory) => (
            <MenuItem key={subcategory?._id} value={subcategory?._id}>
             {subcategory?.subCategoryName}
            </MenuItem>
           ))}
          </Select>
         </FormControl>
        </Grid>
        <Grid item xs={12}>
         <Box mb={2}>
          <TextField
           label="Image URL"
           value={form.image}
           fullWidth
           margin="normal"
           disabled
          />
          <input
           type="file"
           onChange={(e) => handleFileChange("image", null, e)}
          />
          {isUploading && <p>Uploading...</p>}
          {error && <p>Error: {error}</p>}
         </Box>
        </Grid>
       </Grid>
       <Typography variant="h6" gutterBottom mt={2}>
        Highlights
       </Typography>
       {highlightFields?.map((field, index) => (
        <Box key={field.id} mb={2}>
         <TextField
          {...register(`highlights[${index}].text`, {
           required: "Text is required",
          })}
          label="Highlight Text"
          fullWidth
          margin="normal"
          value={form.highlights[index]?.text || ""}
          onChange={(e) => {
           const newHighlights = [...form.highlights];
           newHighlights[index] = {
            ...newHighlights[index],
            text: e.target.value,
           };
           setForm({ ...form, highlights: newHighlights });
          }}
         />
         <Box mb={2}>
          <TextField
           label="Highlight Icon URL"
           value={form.highlights[index]?.icon || ""}
           fullWidth
           margin="normal"
           disabled
          />
          <input
           type="file"
           onChange={(e) =>
            handleFileChange(`highlights[${index}].icon`, index, e)
           }
          />
          {isUploading && <p>Uploading...</p>}
          {error && <p>Error: {error}</p>}
         </Box>
         <IconButton onClick={() => removeHighlight(index)}>
          <RemoveIcon />
         </IconButton>
        </Box>
       ))}
       <Button
        type="button"
        onClick={() => appendHighlight({ text: "", icon: "" })}
        startIcon={<AddIcon />}
       >
        Add Highlight
       </Button>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         {currentSparePart ? "Update" : "Add"}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={handleSnackbarClose}
   >
    <Alert
     onClose={handleSnackbarClose}
     severity="success"
     variant="filled"
     sx={{ width: "100%" }}
    >
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Box>
 );
};

export default AdminSpareParts;
 
import * as types from "./actionTypes";

import {
 GET_VIDEO_TESTIMONIALS_REQUEST,
 GET_VIDEO_TESTIMONIALS_SUCCESS,
 GET_VIDEO_TESTIMONIALS_FAILURE,
 ADD_VIDEO_TESTIMONIAL_REQUEST,
 ADD_VIDEO_TESTIMONIAL_SUCCESS,
 ADD_VIDEO_TESTIMONIAL_FAILURE,
 UPDATE_VIDEO_TESTIMONIAL_REQUEST,
 UPDATE_VIDEO_TESTIMONIAL_SUCCESS,
 UPDATE_VIDEO_TESTIMONIAL_FAILURE,
 DELETE_VIDEO_TESTIMONIAL_REQUEST,
 DELETE_VIDEO_TESTIMONIAL_SUCCESS,
 DELETE_VIDEO_TESTIMONIAL_FAILURE,
} from "./actionTypes";

const initialState = {
 videoTestimonials: [],
 offers: [],
 isLoading: false,
 isError: false,
 errorMessage: "",
};

const reducer = (state = initialState, action) => {
 switch (action.type) {
  case GET_VIDEO_TESTIMONIALS_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: "",
   };
  case GET_VIDEO_TESTIMONIALS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    videoTestimonials: action.payload,
   };
  case GET_VIDEO_TESTIMONIALS_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.payload,
   };

  case ADD_VIDEO_TESTIMONIAL_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: "",
   };
  case ADD_VIDEO_TESTIMONIAL_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    videoTestimonials: [...state.videoTestimonials, action.payload],
   };
  case ADD_VIDEO_TESTIMONIAL_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.payload,
   };

  case UPDATE_VIDEO_TESTIMONIAL_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: "",
   };
  case UPDATE_VIDEO_TESTIMONIAL_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    videoTestimonials: state.videoTestimonials.map((video) =>
     video._id === action.payload._id ? action.payload : video
    ),
   };
  case UPDATE_VIDEO_TESTIMONIAL_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.payload,
   };

  case DELETE_VIDEO_TESTIMONIAL_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: "",
   };
  case DELETE_VIDEO_TESTIMONIAL_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    videoTestimonials: state.videoTestimonials.filter(
     (video) => video._id !== action.payload
    ),
   };
  case DELETE_VIDEO_TESTIMONIAL_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.payload,
   };

  case types.GET_OFFERS_REQUEST:
  case types.ADD_OFFER_REQUEST:
  case types.UPDATE_OFFER_REQUEST:
  case types.DELETE_OFFER_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };

  case types.GET_OFFERS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    offers: action.payload,
   };

  case types.ADD_OFFER_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    offers: [...state.offers, action.payload],
   };

  case types.UPDATE_OFFER_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    offers: state.offers.map((offer) =>
     offer._id === action.payload._id ? action.payload : offer
    ),
   };

  case types.DELETE_OFFER_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    offers: state.offers.filter((offer) => offer._id !== action.payload),
   };

  case types.GET_OFFERS_FAILURE:
  case types.ADD_OFFER_FAILURE:
  case types.UPDATE_OFFER_FAILURE:
  case types.DELETE_OFFER_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };
  default:
   return state;
 }
};

export { reducer };

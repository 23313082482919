import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Fab, FormControl, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
 getProducts,
 addProduct,
 getCategories,
 getSubCategories,
 fetchSubcategories,
 updateProduct,
 getProductById,
 deleteProduct,
} from "../../redux/products/action";
import ProductTable from "./ProductTable";
import ProductModal from "./ProductModal";
import ProductList from "./ProductList";

const Products = () => {
 const dispatch = useDispatch();
 const {
  products,
  categories,
  singleProduct,
  subCategories,
  subCatById,
  isLoading,
  isError,
 } = useSelector((state) => state.product);

 const [open, setOpen] = useState(false);
 const [openAdd, setOpenAdd] = useState(false);
 const [searchTerm, setSearchTerm] = useState("");
 const [currentProduct, setCurrentProduct] = useState({});
 const [newProduct, setNewProduct] = useState({
  categoryId: "",
  subCategoryId: "",
  productName: "",
  productImage: "",
  highlights: [],
  tags: [],
  ratings: 0,
  time: "",
  details: "",
  dummyPriceMrp: 0,
  dummyPriceActual: 0,
 });

 useEffect(() => {
  dispatch(getProducts());
  dispatch(getCategories());
  dispatch(getSubCategories());
 }, [dispatch]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleOpenAdd = () => setOpenAdd(true);
 const handleCloseAdd = () => setOpenAdd(false);

 const handleDelete = (id) => {
  // Implement delete functionality
  console.log("delete id:", id);
  if (id) {
   dispatch(deleteProduct(id));
  }
 };

 const handleEdit = async (id) => {
  console.log("edited id:", id);
  if (id) {
   await dispatch(getProductById(id));
   if (
    singleProduct &&
    singleProduct.categoryId &&
    singleProduct.subCategoryId
   ) {
    setCurrentProduct({
     ...singleProduct,
     categoryId: singleProduct.categoryId._id,
     subCategoryId: singleProduct.subCategoryId._id,
    });
   }
   handleOpen();
  }
 };

 const handleAddProduct = async (data) => {
  await dispatch(addProduct(data));
  handleCloseAdd();
 };

 const handleSubmit = (data) => {
  console.log("data:", data);
  dispatch(updateProduct(data._id, { ...currentProduct, ...data }));
  handleClose();
 };

 return (
  <>
   <ProductList />
  </>
 );
};

export default Products;

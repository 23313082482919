import * as types from "./actionTypes";

const initialState = {
 isLoading: false,
 isError: false,
 role: null,
 userDetails: JSON.parse(sessionStorage.getItem("userDetails")) || {},
 isAuth: JSON.parse(sessionStorage.getItem("isAuth")) || false, // Storing as boolean
};

const reducer = (state = initialState, action) => {
 const { payload, type } = action;
 switch (type) {
  case types.REQUEST_OTP_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.REQUEST_OTP_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
    role: payload,
   };
  case types.REQUEST_OTP_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.UPDATE_VENDOR_DETAILS_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.UPDATE_VENDOR_DETAILS_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
   };
  case types.UPDATE_VENDOR_DETAILS_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.VERIFY_OTP_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.VERIFY_OTP_SUCCESS:
   return {
    ...state,
    isLoading: false,
    isError: false,
   };
  case types.VERIFY_OTP_FAILURE:
   return {
    ...state,
    isLoading: false,
    isError: true,
   };

  case types.LOGIN_USER_REQUEST:
   return {
    ...state,
    isLoading: true,
    isError: false,
   };
  case types.LOGIN_USER_SUCCESS:
   const details = JSON.stringify(payload);
   sessionStorage.setItem("userDetails", details);
   sessionStorage.setItem("isAuth", true);
   return {
    ...state,
    isLoading: false,
    isError: false,
    userDetails: payload,
    isAuth: true,
    role: payload.role, // Assuming payload has the role field
   };
  case types.LOGIN_USER_FAILURE:
   sessionStorage.removeItem("userDetails");
   sessionStorage.setItem("isAuth", false);
   return {
    ...state,
    isLoading: false,
    isError: true,
    isAuth: false, // Reset isAuth on failure
   };

  case types.LOGOUT_USER: // Handle logout
   return {
    ...state,
    isAuth: false,
    userDetails: {},
    role: null,
   };

  default:
   return state;
 }
};

export { reducer };

// import * as types from "./actionTypes";

// const initialState = {
//   isLoading: false,
//   isError: false,
//   role: null,
//   userDetails: JSON.parse(sessionStorage.getItem("userDetails")) || [],
//   isAuth: JSON.parse(sessionStorage.getItem("isAuth")) || "",
// };

// const reducer = (state = initialState, action) => {
//   const { payload, type } = action;
//   switch (type) {
//     case types.REQUEST_OTP_REQUEST:
//       return {
//         ...state,
//         isLoading: true,
//         isError: false,
//       };
//     case types.REQUEST_OTP_SUCCESS:
//       return {
//         ...state,
//         isLoading: false,
//         isError: false,
//         role: payload,
//       };
//     case types.REQUEST_OTP_FAILURE:
//       return {
//         ...state,
//         isLoading: false,
//         isError: true,
//       };

//     case types.UPDATE_VENDOR_DETAILS_REQUEST:
//       return {
//         ...state,
//         isLoading: true,
//         isError: false,
//       };
//     case types.UPDATE_VENDOR_DETAILS_SUCCESS:
//       return {
//         ...state,
//         isLoading: false,
//         isError: false,
//         // users: payload,
//       };
//     case types.UPDATE_VENDOR_DETAILS_FAILURE:
//       return {
//         ...state,
//         isLoading: false,
//         isError: true,
//       };

//     case types.VERIFY_OTP_REQUEST:
//       return {
//         ...state,
//         isLoading: true,
//         isError: false,
//       };
//     case types.VERIFY_OTP_SUCCESS:
//       return {
//         ...state,
//         isLoading: false,
//         isError: false,
//         // brands: payload,
//       };
//     case types.VERIFY_OTP_FAILURE:
//       return {
//         ...state,
//         isLoading: false,
//         isError: true,
//       };

//     case types.LOGIN_USER_REQUEST:
//       return {
//         ...state,
//         isLoading: true,
//         isError: false,
//       };
//     case types.LOGIN_USER_SUCCESS:
//       const details = JSON.stringify(payload);
//       sessionStorage.setItem("userDetails", details);
//       sessionStorage.setItem("isAuth", true);
//       return {
//         ...state,
//         isLoading: false,
//         isError: false,
//         userDetails: payload,
//         isAuth: true,
//       };
//     case types.LOGIN_USER_FAILURE:
//       return {
//         ...state,
//         isLoading: false,
//         isError: true,
//       };

//     default:
//       return state;
//   }
// };

// export { reducer };

import React from "react";

import { useState } from "react";
import AWS from "aws-sdk";

const useFileUpload = () => {
 const [isUploading, setIsUploading] = useState(false);
 const [error, setError] = useState(null);
 const [uploadedFileUrl, setUploadedFileUrl] = useState("");

 const uploadFile = async (file) => {
  setIsUploading(true);
  setError(null);

  const S3 = new AWS.S3({
   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
   region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
   Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
   Key: `${Date.now()}-${file.name}`, // unique file name
   Body: file,
   ContentType: file.type,
   ACL: "public-read", // adjust as per your requirement
  };

  try {
   const uploadResult = await S3.upload(params).promise();
   setUploadedFileUrl(uploadResult.Location);
  } catch (err) {
   setError(err.message);
  } finally {
   setIsUploading(false);
  }
 };

 return { uploadFile, isUploading, error, uploadedFileUrl };
};

const AdminProfile = () => {
 const { uploadFile, isUploading, error, uploadedFileUrl } = useFileUpload();

 const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   uploadFile(file);
  }
 };
 return (
  <div>
   AdminProfile
   <input type="file" onChange={handleFileChange} />
   {isUploading && <p>Uploading...</p>}
   {error && <p>Error: {error}</p>}
   {uploadedFileUrl && <p>File uploaded to: {uploadedFileUrl}</p>}
  </div>
 );
};

export default AdminProfile;

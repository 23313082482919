import React from "react";
import BulkUploadCarPriceForm from "./BulkUploadCarPriceForm";

const BulkProductPriceAcordingCar = () => {
 return (
  <div>
   <h1>Upload Car Price Excel File</h1>
   <BulkUploadCarPriceForm />
  </div>
 );
};

export default BulkProductPriceAcordingCar;

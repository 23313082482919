import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
 Box,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 Fab,
 FormControl,
 TextField,
 Modal,
 Fade,
 Backdrop,
 Button,
 Snackbar,
 Alert,
 TextareaAutosize,
} from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
 fetchReviews,
 addReview,
 updateReview,
 deleteReview,
} from "../../redux/reviews/action";

const style = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

const CustomerReviews = () => {
 const dispatch = useDispatch();
 const { reviews, isLoading, isError } = useSelector(
  (state) => state.reviewReducer
 );

 const [open, setOpen] = useState(false);
 const [openAdd, setOpenAdd] = useState(false);
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [snackbarSeverity, setSnackbarSeverity] = useState("success");
 const [editId, setEditId] = useState(null);

 const { register, handleSubmit, reset, setValue, control } = useForm();
 const { fields, append, remove } = useFieldArray({
  control,
  name: "images",
 });

 useEffect(() => {
  dispatch(fetchReviews());
 }, [dispatch]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setOpen(false);
  reset();
 };

 const handleOpenAdd = () => {
  setOpenAdd(true);
  append({}); // Initialize with an empty image field
 };

 const handleCloseAdd = () => {
  setOpenAdd(false);
  reset();
 };

 const handleSnackbarClose = (event, reason) => {
  if (reason === "clickaway") {
   return;
  }
  setSnackbarOpen(false);
 };

 const onSubmit = (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("rating", data.rating);
  formData.append("review", data.review);
  formData.append("description", data.description);
  data.images.forEach((image, index) => {
   formData.append("images", image[0]);
  });

  if (editId) {
   dispatch(updateReview(editId, formData));
   setEditId(null);
  } else {
   dispatch(addReview(formData));
  }

  setSnackbarMessage("Form submitted successfully!");
  setSnackbarSeverity("success");
  setSnackbarOpen(true);
  handleCloseAdd();
  handleClose();
 };

 const handleOpenEdit = (id) => {
  setEditId(id);
  const review = reviews.find((review) => review._id === id);
  setValue("name", review.name);
  setValue("rating", review.rating);
  setValue("review", review.review);
  setValue("description", review.description);
  setValue("images", []);
  setOpen(true);
 };

 const handleDelete = (id) => {
  dispatch(deleteReview(id));
  setSnackbarMessage("Review deleted successfully!");
  setSnackbarSeverity("success");
  setSnackbarOpen(true);
 };

 return (
  <Box sx={{ width: "100%", height: "40vh" }}>
   <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
    Customer Reviews
   </Typography>
   <Box
    sx={{
     border: "1px solid #1976D2",
     padding: "10px",
     display: "flex",
     alignItems: "center",
     gap: "50px",
    }}
   >
    <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
     <AddIcon />
    </Fab>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>Name</TableCell>
       <TableCell sx={{ color: "white" }}>Rating</TableCell>
       <TableCell sx={{ color: "white" }}>Review</TableCell>
       <TableCell sx={{ color: "white" }}>Description</TableCell>
       <TableCell sx={{ color: "white" }}>Images</TableCell>
       <TableCell sx={{ color: "white" }}>Action</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {reviews.map((review) => (
       <TableRow key={review._id}>
        <TableCell>{review.name}</TableCell>
        <TableCell>{review.rating}</TableCell>
        <TableCell>{review.review}</TableCell>
        <TableCell>{review.description}</TableCell>
        <TableCell>
         {review.images.map((img, index) => (
          <img
           key={index}
           src={img}
           alt="review"
           style={{ width: "50px", height: "50px", marginRight: "5px" }}
          />
         ))}
        </TableCell>
        <TableCell
         sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
         }}
        >
         <Fab
          color="secondary"
          aria-label="edit"
          onClick={() => handleOpenEdit(review._id)}
         >
          <EditIcon />
         </Fab>
         <Fab
          color="secondary"
          aria-label="delete"
          sx={{ marginLeft: "5px" }}
          onClick={() => handleDelete(review._id)}
         >
          <DeleteIcon />
         </Fab>
        </TableCell>
       </TableRow>
      ))}
     </TableBody>
    </Table>
   </TableContainer>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
   >
    <Fade in={open}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       Edit Review
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <FormControl fullWidth>
        <TextField
         {...register("name", { required: "Name is required" })}
         label="Name"
         margin="normal"
        />
        <TextField
         {...register("rating", { required: "Rating is required" })}
         label="Rating"
         type="number"
         margin="normal"
        />
        <TextField
         {...register("review", { required: "Review is required" })}
         label="Review"
         margin="normal"
        />
        <TextareaAutosize
         {...register("description", { required: "Description is required" })}
         aria-label="Description"
         minRows={3}
         placeholder="Description"
         style={{ width: "100%", marginTop: "16px", padding: "10px" }}
        />
        {fields.map((field, index) => (
         <Box key={field.id} display="flex" alignItems="center">
          <input
           type="file"
           {...register(`images.${index}`)}
           accept="image/*"
           style={{ marginTop: "16px" }}
          />
          <Button
           variant="contained"
           color="secondary"
           onClick={() => remove(index)}
           style={{ marginLeft: "10px" }}
          >
           Remove
          </Button>
         </Box>
        ))}
        <Button
         variant="contained"
         color="primary"
         onClick={() => append({})}
         style={{ marginTop: "10px" }}
        >
         Add Image
        </Button>
       </FormControl>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={openAdd}
    onClose={handleCloseAdd}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
   >
    <Fade in={openAdd}>
     <Box sx={style}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
       Add Review
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <FormControl fullWidth>
        <TextField
         {...register("name", { required: "Name is required" })}
         label="Name"
         margin="normal"
        />
        <TextField
         {...register("rating", { required: "Rating is required" })}
         label="Rating"
         type="number"
         margin="normal"
        />
        <TextField
         {...register("review", { required: "Review is required" })}
         label="Review"
         margin="normal"
        />
        <TextareaAutosize
         {...register("description", { required: "Description is required" })}
         aria-label="Description"
         minRows={3}
         placeholder="Description"
         style={{ width: "100%", marginTop: "16px", padding: "10px" }}
        />
        {fields.map((field, index) => (
         <Box key={field.id} display="flex" alignItems="center">
          <input
           type="file"
           {...register(`images.${index}`)}
           accept="image/*"
           style={{ marginTop: "16px" }}
          />
          <Button
           variant="contained"
           color="secondary"
           onClick={() => remove(index)}
           style={{ marginLeft: "10px" }}
          >
           Remove
          </Button>
         </Box>
        ))}
        <Button
         variant="contained"
         color="primary"
         onClick={() => append({})}
         style={{ marginTop: "10px" }}
        >
         Add Image
        </Button>
       </FormControl>
       <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
         Add
        </Button>
        <Button variant="outlined" onClick={handleCloseAdd}>
         Cancel
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
   <Snackbar
    open={snackbarOpen}
    autoHideDuration={3000}
    onClose={handleSnackbarClose}
   >
    <Alert
     onClose={handleSnackbarClose}
     severity={snackbarSeverity}
     variant="filled"
     sx={{ width: "100%" }}
    >
     {snackbarMessage}
    </Alert>
   </Snackbar>
  </Box>
 );
};

export default CustomerReviews;

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Fab,
//   FormControl,
//   TextField,
//   Modal,
//   Fade,
//   Backdrop,
//   Button,
//   Snackbar,
//   Alert,
//   TextareaAutosize,
// } from "@mui/material";
// import { useForm, useFieldArray } from "react-hook-form";
// import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// const CustomerReviews = () => {
//   const [reviews, setReviews] = useState([]); // Replace with actual data fetching
//   const [open, setOpen] = useState(false);
//   const [openAdd, setOpenAdd] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//   const [editId, setEditId] = useState(null);

//   const { register, handleSubmit, reset, setValue, control } = useForm();
//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "images",
//   });

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => {
//     setOpen(false);
//     reset();
//   };

//   const handleOpenAdd = () => {
//     setOpenAdd(true);
//     append({}); // Initialize with an empty image field
//   };

//   const handleCloseAdd = () => {
//     setOpenAdd(false);
//     reset();
//   };

//   const handleSnackbarClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbarOpen(false);
//   };

//   const onSubmit = (data) => {
//     console.log(data);
//     setSnackbarMessage("Form submitted successfully!");
//     setSnackbarSeverity("success");
//     setSnackbarOpen(true);
//     handleCloseAdd();
//   };

//   const handleOpenEdit = (id) => {
//     setEditId(id);
//     const review = reviews.find((review) => review._id === id);
//     setValue("name", review.name);
//     setValue("rating", review.rating);
//     setValue("review", review.review);
//     setValue("description", review.description);
//     setValue("images", []);
//     setOpen(true);
//   };

//   const handleDelete = (id) => {
//     // Implement the delete functionality here
//     console.log(`Deleting review with id: ${id}`);
//     setSnackbarMessage("Review deleted successfully!");
//     setSnackbarSeverity("success");
//     setSnackbarOpen(true);
//   };

//   return (
//     <Box sx={{ width: "100%", height: "40vh" }}>
//       <Typography fontSize={"25px"} paddingLeft="5px" fontWeight={"600"}>
//         Customer Reviews
//       </Typography>
//       <Box
//         sx={{
//           border: "1px solid #1976D2",
//           padding: "10px",
//           display: "flex",
//           alignItems: "center",
//           gap: "50px",
//         }}
//       >
//         <Fab color="secondary" aria-label="add" onClick={handleOpenAdd}>
//           <AddIcon />
//         </Fab>
//       </Box>
//       <TableContainer component={Paper} sx={{ margin: "auto" }}>
//         <Table sx={{ minWidth: 650 }} aria-label="simple table">
//           <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
//             <TableRow>
//               <TableCell sx={{ color: "white" }}>Name</TableCell>
//               <TableCell sx={{ color: "white" }}>Rating</TableCell>
//               <TableCell sx={{ color: "white" }}>Review</TableCell>
//               <TableCell sx={{ color: "white" }}>Description</TableCell>
//               <TableCell sx={{ color: "white" }}>Images</TableCell>
//               <TableCell sx={{ color: "white" }}>Action</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {reviews.map((review) => (
//               <TableRow key={review._id}>
//                 <TableCell>{review.name}</TableCell>
//                 <TableCell>{review.rating}</TableCell>
//                 <TableCell>{review.review}</TableCell>
//                 <TableCell>{review.description}</TableCell>
//                 <TableCell>
//                   {review.images.map((img, index) => (
//                     <img
//                       key={index}
//                       src={img}
//                       alt="review"
//                       style={{ width: "50px", height: "50px", marginRight: "5px" }}
//                     />
//                   ))}
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Fab
//                     color="secondary"
//                     aria-label="edit"
//                     onClick={() => handleOpenEdit(review._id)}
//                   >
//                     <EditIcon />
//                   </Fab>
//                   <Fab
//                     color="secondary"
//                     aria-label="delete"
//                     sx={{ marginLeft: "5px" }}
//                     onClick={() => handleDelete(review._id)}
//                   >
//                     <DeleteIcon />
//                   </Fab>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={open}>
//           <Box sx={style}>
//             <Typography id="transition-modal-title" variant="h6" component="h2">
//               Edit Review
//             </Typography>
//             <form onSubmit={handleSubmit(onSubmit)}>
//               <FormControl fullWidth>
//                 <TextField
//                   {...register("name", { required: "Name is required" })}
//                   label="Name"
//                   margin="normal"
//                 />
//                 <TextField
//                   {...register("rating", { required: "Rating is required" })}
//                   label="Rating"
//                   type="number"
//                   margin="normal"
//                 />
//                 <TextField
//                   {...register("review", { required: "Review is required" })}
//                   label="Review"
//                   margin="normal"
//                 />
//                 <TextareaAutosize
//                   {...register("description", { required: "Description is required" })}
//                   aria-label="Description"
//                   minRows={3}
//                   placeholder="Description"
//                   style={{ width: "100%", marginTop: "16px", padding: "10px" }}
//                 />
//                 {fields.map((field, index) => (
//                   <Box key={field.id} display="flex" alignItems="center">
//                     <input
//                       type="file"
//                       {...register(`images.${index}`)}
//                       accept="image/*"
//                       style={{ marginTop: "16px" }}
//                     />
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={() => remove(index)}
//                       style={{ marginLeft: "10px" }}
//                     >
//                       Remove
//                     </Button>
//                   </Box>
//                 ))}
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => append({})}
//                   style={{ marginTop: "10px" }}
//                 >
//                   Add Image
//                 </Button>
//               </FormControl>
//               <Box mt={2} display="flex" justifyContent="space-between">
//                 <Button variant="contained" color="primary" type="submit">
//                   Save
//                 </Button>
//                 <Button variant="outlined" onClick={handleClose}>
//                   Cancel
//                 </Button>
//               </Box>
//             </form>
//           </Box>
//         </Fade>
//       </Modal>
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={openAdd}
//         onClose={handleCloseAdd}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={openAdd}>
//           <Box sx={style}>
//             <Typography id="transition-modal-title" variant="h6" component="h2">
//               Add Review
//             </Typography>
//             <form onSubmit={handleSubmit(onSubmit)}>
//               <FormControl fullWidth>
//                 <TextField
//                   {...register("name", { required: "Name is required" })}
//                   label="Name"
//                   margin="normal"
//                 />
//                 <TextField
//                   {...register("rating", { required: "Rating is required" })}
//                   label="Rating"
//                   type="number"
//                   margin="normal"
//                 />
//                 <TextField
//                   {...register("review", { required: "Review is required" })}
//                   label="Review"
//                   margin="normal"
//                 />
//                 <TextareaAutosize
//                   {...register("description", { required: "Description is required" })}
//                   aria-label="Description"
//                   minRows={3}
//                   placeholder="Description"
//                   style={{ width: "100%", marginTop: "16px", padding: "10px" }}
//                 />
//                 {fields.map((field, index) => (
//                   <Box key={field.id} display="flex" alignItems="center">
//                     <input
//                       type="file"
//                       {...register(`images.${index}`)}
//                       accept="image/*"
//                       style={{ marginTop: "16px" }}
//                     />
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={() => remove(index)}
//                       style={{ marginLeft: "10px" }}
//                     >
//                       Remove
//                     </Button>
//                   </Box>
//                 ))}
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => append({})}
//                   style={{ marginTop: "10px" }}
//                 >
//                   Add Image
//                 </Button>
//               </FormControl>
//               <Box mt={2} display="flex" justifyContent="space-between">
//                 <Button variant="contained" color="primary" type="submit">
//                   Add
//                 </Button>
//                 <Button variant="outlined" onClick={handleCloseAdd}>
//                   Cancel
//                 </Button>
//               </Box>
//             </form>
//           </Box>
//         </Fade>
//       </Modal>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={3000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default CustomerReviews;

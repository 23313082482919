import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
 Box,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Typography,
 IconButton,
 Modal,
 Fade,
 Backdrop,
 Button,
 TextField,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import {
 fetchSlots,
 createSlot,
 updateSlot,
 deleteSlot,
} from "../../redux/cart/action";

const BookingSlots = () => {
 const { control, handleSubmit, reset } = useForm();
 const dispatch = useDispatch();
 const { slots, isLoading, isError } = useSelector(
  (state) => state.reducerCart
 );
 const [editBookingSlot, setEditBookingSlot] = useState(null);
 const [openModal, setOpenModal] = useState(false);

 console.log("Component Rendered");

 useEffect(() => {
  console.log("Fetching slots...");
  dispatch(fetchSlots());
 }, [dispatch]);

 useEffect(() => {
  console.log("Slots state:", slots);
 }, [slots]);

 const onSubmit = (data) => {
  if (editBookingSlot) {
   dispatch(updateSlot(editBookingSlot._id, data));
  } else {
   dispatch(createSlot(data));
  }
  setEditBookingSlot(null);
  setOpenModal(false);
  reset();
 };

 const handleEditClick = (slot) => {
  setEditBookingSlot(slot);
  setOpenModal(true);
  reset(slot);
 };

 const handleDeleteClick = (id) => {
  dispatch(deleteSlot(id));
 };

 return (
  <Box sx={{ width: "100%", height: "40vh" }}>
   <Typography variant="h5" paddingLeft="5px">
    Booking Slots
   </Typography>
   <Box
    sx={{
     display: "flex",
     alignItems: "center",
     justifyContent: "space-between",
     gap: "10px",
    }}
   >
    <IconButton
     color="primary"
     aria-label="add"
     onClick={() => setOpenModal(true)}
    >
     <Add />
    </IconButton>
   </Box>
   <TableContainer component={Paper} sx={{ margin: "auto" }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead sx={{ bgcolor: "#1976d2", color: "#ffff" }}>
      <TableRow>
       <TableCell sx={{ color: "white" }}>Time</TableCell>
       <TableCell sx={{ color: "white" }}>Max Bookings</TableCell>
       <TableCell sx={{ color: "white" }}>Discount (%)</TableCell>
       <TableCell sx={{ color: "white" }}>Actions</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {isLoading && (
       <TableRow>
        <TableCell colSpan={4}>Loading...</TableCell>
       </TableRow>
      )}
      {isError && (
       <TableRow>
        <TableCell colSpan={4}>Error loading slots</TableCell>
       </TableRow>
      )}
      {slots?.data &&
       slots.data.map((slot) => (
        <TableRow key={slot._id}>
         <TableCell>{slot.time}</TableCell>
         <TableCell>{slot.maxBookings}</TableCell>
         <TableCell>{slot.discount}</TableCell>
         <TableCell>
          <IconButton onClick={() => handleEditClick(slot)}>
           <Edit />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(slot._id)}>
           <Delete />
          </IconButton>
         </TableCell>
        </TableRow>
       ))}
     </TableBody>
    </Table>
   </TableContainer>

   <Modal
    open={openModal}
    onClose={() => setOpenModal(false)}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
   >
    <Fade in={openModal}>
     <Box sx={{ ...modalStyle }}>
      <Typography variant="h6" component="h2">
       {editBookingSlot ? "Edit Booking Slot" : "Add Booking Slot"}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
       <Controller
        name="time"
        control={control}
        defaultValue=""
        render={({ field }) => (
         <TextField label="Time" fullWidth margin="normal" {...field} />
        )}
       />
       <Controller
        name="maxBookings"
        control={control}
        defaultValue={0}
        render={({ field }) => (
         <TextField
          label="Max Bookings"
          type="number"
          fullWidth
          margin="normal"
          {...field}
         />
        )}
       />
       <Controller
        name="discount"
        control={control}
        defaultValue={0}
        render={({ field }) => (
         <TextField
          label="Discount (%)"
          type="number"
          fullWidth
          margin="normal"
          {...field}
         />
        )}
       />
       <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
         type="button"
         onClick={() => {
          setOpenModal(false);
          reset();
         }}
         sx={{ marginRight: 2 }}
        >
         Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
         Save
        </Button>
       </Box>
      </form>
     </Box>
    </Fade>
   </Modal>
  </Box>
 );
};

const modalStyle = {
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 border: "2px solid #000",
 boxShadow: 24,
 p: 4,
};

export default BookingSlots;

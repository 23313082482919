import {
 applyMiddleware,
 combineReducers,
 compose,
 legacy_createStore,
} from "redux";
import { thunk } from "redux-thunk";

import { reducer as admin } from "./admin/reducer";
import { reducer as auth } from "./auth/reducer";
import { reducer as product } from "./products/reducer";
import { reducer as city } from "./city/reducer";
import { reducer as advertisement } from "./advertisement/reducer";
import { reviewReducer } from "./reviews/reducer";
import { aboutReducer } from "./about-termsConditions-privacyPolicy/reducer";
import { referAndEarnReducer } from "./account/reducer";
import { reducerCart } from "./cart/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
 admin,
 auth,
 product,
 city,
 advertisement,
 reviewReducer,
 aboutReducer,
 referAndEarnReducer,
 reducerCart
});

const store = legacy_createStore(
 rootReducer,
 composeEnhancers(applyMiddleware(thunk))
);

export { store };

// src/components/Charts/RevenueChart.js
import React from "react";
import {
 LineChart,
 Line,
 XAxis,
 YAxis,
 CartesianGrid,
 Tooltip,
 Legend,
} from "recharts";

const data = [
 { name: "Jan", revenue: 4000 },
 { name: "Feb", revenue: 3000 },
 // Add more data points
];

const RevenueChart = () => (
 <LineChart
  width={500}
  height={300}
  data={data}
  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
 >
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="name" />
  <YAxis />
  <Tooltip />
  <Legend />
  <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
 </LineChart>
);

export default RevenueChart;

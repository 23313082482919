import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/auth/action";
import { useNavigate } from "react-router-dom";

export default function AccountMenu() {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const [anchorEl, setAnchorEl] = React.useState(null);
 const open = Boolean(anchorEl);

 const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const handleLogout = () => {
  dispatch(logoutUser()); // Dispatch the logout action
  navigate("/"); // Redirect to home or login page
 };

 return (
  <React.Fragment>
   <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
    <Tooltip title="Account settings">
     <IconButton
      onClick={handleClick}
      size="small"
      sx={{ ml: 2 }}
      aria-controls={open ? "account-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
     >
      <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
     </IconButton>
    </Tooltip>
   </Box>
   <Menu
    anchorEl={anchorEl}
    id="account-menu"
    open={open}
    onClose={handleClose}
    PaperProps={{
     elevation: 0,
     sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
       width: 32,
       height: 32,
       ml: -0.5,
       mr: 1,
      },
      "&::before": {
       content: '""',
       display: "block",
       position: "absolute",
       top: 0,
       right: 14,
       width: 10,
       height: 10,
       bgcolor: "background.paper",
       transform: "translateY(-50%) rotate(45deg)",
       zIndex: 0,
      },
     },
    }}
    transformOrigin={{ horizontal: "right", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
   >
    <MenuItem onClick={handleClose}>
     <Avatar /> Profile
    </MenuItem>
    <MenuItem onClick={handleClose}>
     <Avatar /> My account
    </MenuItem>
    <Divider />
    <MenuItem onClick={handleClose}>
     <ListItemIcon>
      <PersonAdd fontSize="small" />
     </ListItemIcon>
     Add another account
    </MenuItem>
    <MenuItem onClick={handleClose}>
     <ListItemIcon>
      <Settings fontSize="small" />
     </ListItemIcon>
     Settings
    </MenuItem>
    <MenuItem onClick={handleLogout}>
     {" "}
     {/* Logout function attached to the MenuItem */}
     <ListItemIcon>
      <Logout fontSize="small" />
     </ListItemIcon>
     Logout
    </MenuItem>
   </Menu>
  </React.Fragment>
 );
}

// import * as React from "react";
// import Box from "@mui/material/Box";
// import Avatar from "@mui/material/Avatar";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
// import PersonAdd from "@mui/icons-material/PersonAdd";
// import Settings from "@mui/icons-material/Settings";
// import Logout from "@mui/icons-material/Logout";
// import { useDispatch } from "react-redux";
// import { logoutUser } from "../redux/auth/action";
// import { useNavigate } from "react-router-dom";

// export default function AccountMenu() {
//  const dispatch = useDispatch();
//  const navigate = useNavigate();
//  const [anchorEl, setAnchorEl] = React.useState(null);
//  const open = Boolean(anchorEl);
//  const handleClick = (event) => {
//   setAnchorEl(event.currentTarget);
//  };
//  const handleClose = () => {
//   setAnchorEl(null);
//  };
//  const handleLogout = () => {
//   dispatch(logoutUser()); // Dispatch the logout action
//   navigate("/"); // Redirect to home or login page
//  };

//  return (
//   <React.Fragment>
//    <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
//     <Tooltip title="Account settings">
//      <IconButton
//       onClick={handleClick}
//       size="small"
//       sx={{ ml: 2 }}
//       aria-controls={open ? "account-menu" : undefined}
//       aria-haspopup="true"
//       aria-expanded={open ? "true" : undefined}
//      >
//       <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
//      </IconButton>
//     </Tooltip>
//    </Box>
//    <Menu
//     anchorEl={anchorEl}
//     id="account-menu"
//     open={open}
//     onClose={handleClose}
//     onClick={handleClose}
//     PaperProps={{
//      elevation: 0,
//      sx: {
//       overflow: "visible",
//       filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
//       mt: 1.5,
//       "& .MuiAvatar-root": {
//        width: 32,
//        height: 32,
//        ml: -0.5,
//        mr: 1,
//       },
//       "&::before": {
//        content: '""',
//        display: "block",
//        position: "absolute",
//        top: 0,
//        right: 14,
//        width: 10,
//        height: 10,
//        bgcolor: "background.paper",
//        transform: "translateY(-50%) rotate(45deg)",
//        zIndex: 0,
//       },
//      },
//     }}
//     transformOrigin={{ horizontal: "right", vertical: "top" }}
//     anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
//    >
//     <MenuItem onClick={handleClose}>
//      <Avatar /> Profile
//     </MenuItem>
//     <MenuItem onClick={handleClose}>
//      <Avatar /> My account
//     </MenuItem>
//     <Divider />
//     <MenuItem onClick={handleClose}>
//      <ListItemIcon>
//       <PersonAdd fontSize="small" />
//      </ListItemIcon>
//      Add another account
//     </MenuItem>
//     <MenuItem onClick={handleClose}>
//      <ListItemIcon>
//       <Settings fontSize="small" />
//      </ListItemIcon>
//      Settings
//     </MenuItem>
//     <MenuItem onClick={handleClose}>
//      <ListItemIcon>
//       <Logout fontSize="small" onClick={handleLogout} />
//      </ListItemIcon>
//      Logout
//     </MenuItem>
//    </Menu>
//   </React.Fragment>
//  );
// }

import * as types from "./actionTypes";

const initialState = {
 cart: {
  data: null,
  isLoading: false,
  isError: false,
 },
 referrals: {
  data: null,
  isLoading: false,
  isError: false,
 },
 orders: {
  data: null,
  isLoading: false,
  isError: false,
 },
 slots: {
  data: null,
  isLoading: false,
  isError: false,
 },
 coupons: {
  data: null,
  isLoading: false,
  isError: false,
 },
};

const reducerCart = (state = initialState, action) => {
 const { payload, type } = action;
 switch (type) {
  case types.GET_CART_REQUEST:
  case types.ADD_TO_CART_REQUEST:
  case types.REMOVE_FROM_CART_REQUEST:
  case types.APPLY_COUPON_REQUEST:
   return {
    ...state,
    cart: { ...state.cart, isLoading: true, isError: false },
   };
  case types.GET_CART_SUCCESS:
   return {
    ...state,
    cart: { data: payload, isLoading: false, isError: false },
   };
  case types.ADD_TO_CART_SUCCESS:
  case types.REMOVE_FROM_CART_SUCCESS:
  case types.APPLY_COUPON_SUCCESS:
   return {
    ...state,
    cart: { data: payload, isLoading: false, isError: false },
   };
  case types.GET_CART_FAILURE:
  case types.ADD_TO_CART_FAILURE:
  case types.REMOVE_FROM_CART_FAILURE:
  case types.APPLY_COUPON_FAILURE:
   return {
    ...state,
    cart: { ...state.cart, isLoading: false, isError: true },
   };

  case types.GET_REFERRALS_REQUEST:
  case types.CREATE_REFERRAL_REQUEST:
   return {
    ...state,
    referrals: { ...state.referrals, isLoading: true, isError: false },
   };
  case types.GET_REFERRALS_SUCCESS:
   return {
    ...state,
    referrals: { data: payload, isLoading: false, isError: false },
   };
  case types.CREATE_REFERRAL_SUCCESS:
   return {
    ...state,
    referrals: { ...state.referrals, isLoading: false, isError: false },
   };
  case types.GET_REFERRALS_FAILURE:
  case types.CREATE_REFERRAL_FAILURE:
   return {
    ...state,
    referrals: { ...state.referrals, isLoading: false, isError: true },
   };

  case types.GET_ORDER_HISTORY_REQUEST:
  case types.CANCEL_ORDER_REQUEST:
  case types.REFUND_ORDER_REQUEST:
   return {
    ...state,
    orders: { ...state.orders, isLoading: true, isError: false },
   };
  case types.GET_ORDER_HISTORY_SUCCESS:
   return {
    ...state,
    orders: { data: payload, isLoading: false, isError: false },
   };
  case types.CANCEL_ORDER_SUCCESS:
  case types.REFUND_ORDER_SUCCESS:
   return {
    ...state,
    orders: { ...state.orders, isLoading: false, isError: false },
   };
  case types.GET_ORDER_HISTORY_FAILURE:
  case types.CANCEL_ORDER_FAILURE:
  case types.REFUND_ORDER_FAILURE:
   return {
    ...state,
    orders: { ...state.orders, isLoading: false, isError: true },
   };

  case types.GET_SLOTS_REQUEST:
  case types.CREATE_SLOT_REQUEST:
  case types.UPDATE_SLOT_REQUEST:
   return {
    ...state,
    slots: { ...state.slots, isLoading: true, isError: false },
   };
  case types.GET_SLOTS_SUCCESS:
   return {
    ...state,
    slots: { data: payload, isLoading: false, isError: false },
   };
  case types.CREATE_SLOT_SUCCESS:
  case types.UPDATE_SLOT_SUCCESS:
   return {
    ...state,
    slots: { ...state.slots, isLoading: false, isError: false },
   };
  case types.GET_SLOTS_FAILURE:
  case types.CREATE_SLOT_FAILURE:
  case types.UPDATE_SLOT_FAILURE:
   return {
    ...state,
    slots: { ...state.slots, isLoading: false, isError: true },
   };

  case types.GET_COUPONS_REQUEST:
  case types.CREATE_COUPON_REQUEST:
  case types.UPDATE_COUPON_REQUEST:
   return {
    ...state,
    coupons: { ...state.coupons, isLoading: true, isError: false },
   };
  case types.GET_COUPONS_SUCCESS:
   return {
    ...state,
    coupons: { data: payload, isLoading: false, isError: false },
   };
  case types.CREATE_COUPON_SUCCESS:
  case types.UPDATE_COUPON_SUCCESS:
   return {
    ...state,
    coupons: { ...state.coupons, isLoading: false, isError: false },
   };
  case types.GET_COUPONS_FAILURE:
  case types.CREATE_COUPON_FAILURE:
  case types.UPDATE_COUPON_FAILURE:
   return {
    ...state,
    coupons: { ...state.coupons, isLoading: false, isError: true },
   };

  default:
   return state;
 }
};

export { reducerCart };

// import * as types from "./actionTypes";

// const initialState = {
//  cart: {
//   data: null,
//   isLoading: false,
//   isError: false,
//  },
//  referrals: {
//   data: null,
//   isLoading: false,
//   isError: false,
//  },
//  orders: {
//   data: null,
//   isLoading: false,
//   isError: false,
//  },
//  slots: {
//   data: null,
//   isLoading: false,
//   isError: false,
//  },
//  coupons: {
//   data: null,
//   isLoading: false,
//   isError: false,
//  },
// };

// const reducerCart = (state = initialState, action) => {
//  const { payload, type } = action;
//  switch (type) {
//   case types.GET_CART_REQUEST:
//   case types.ADD_TO_CART_REQUEST:
//   case types.REMOVE_FROM_CART_REQUEST:
//   case types.APPLY_COUPON_REQUEST:
//    return {
//     ...state,
//     cart: { ...state.cart, isLoading: true, isError: false },
//    };
//   case types.GET_CART_SUCCESS:
//    return {
//     ...state,
//     cart: { data: payload, isLoading: false, isError: false },
//    };
//   case types.ADD_TO_CART_SUCCESS:
//   case types.REMOVE_FROM_CART_SUCCESS:
//   case types.APPLY_COUPON_SUCCESS:
//    return {
//     ...state,
//     cart: { data: payload, isLoading: false, isError: false },
//    };
//   case types.GET_CART_FAILURE:
//   case types.ADD_TO_CART_FAILURE:
//   case types.REMOVE_FROM_CART_FAILURE:
//   case types.APPLY_COUPON_FAILURE:
//    return {
//     ...state,
//     cart: { ...state.cart, isLoading: false, isError: true },
//    };

//   case types.GET_REFERRALS_REQUEST:
//   case types.CREATE_REFERRAL_REQUEST:
//    return {
//     ...state,
//     referrals: { ...state.referrals, isLoading: true, isError: false },
//    };
//   case types.GET_REFERRALS_SUCCESS:
//    return {
//     ...state,
//     referrals: { data: payload, isLoading: false, isError: false },
//    };
//   case types.CREATE_REFERRAL_SUCCESS:
//    return {
//     ...state,
//     referrals: { ...state.referrals, isLoading: false, isError: false },
//    };
//   case types.GET_REFERRALS_FAILURE:
//   case types.CREATE_REFERRAL_FAILURE:
//    return {
//     ...state,
//     referrals: { ...state.referrals, isLoading: false, isError: true },
//    };

//   case types.GET_ORDER_HISTORY_REQUEST:
//   case types.CANCEL_ORDER_REQUEST:
//   case types.REFUND_ORDER_REQUEST:
//    return {
//     ...state,
//     orders: { ...state.orders, isLoading: true, isError: false },
//    };
//   case types.GET_ORDER_HISTORY_SUCCESS:
//    return {
//     ...state,
//     orders: { data: payload, isLoading: false, isError: false },
//    };
//   case types.CANCEL_ORDER_SUCCESS:
//   case types.REFUND_ORDER_SUCCESS:
//    return {
//     ...state,
//     orders: { ...state.orders, isLoading: false, isError: false },
//    };
//   case types.GET_ORDER_HISTORY_FAILURE:
//   case types.CANCEL_ORDER_FAILURE:
//   case types.REFUND_ORDER_FAILURE:
//    return {
//     ...state,
//     orders: { ...state.orders, isLoading: false, isError: true },
//    };

//   case types.GET_SLOTS_REQUEST:
//   case types.CREATE_SLOT_REQUEST:
//   case types.UPDATE_SLOT_REQUEST:
//    return {
//     ...state,
//     slots: { ...state.slots, isLoading: true, isError: false },
//    };
//   case types.GET_SLOTS_SUCCESS:
//    return {
//     ...state,
//     slots: { data: payload, isLoading: false, isError: false },
//    };
//   case types.CREATE_SLOT_SUCCESS:
//   case types.UPDATE_SLOT_SUCCESS:
//    return {
//     ...state,
//     slots: { ...state.slots, isLoading: false, isError: false },
//    };
//   case types.GET_SLOTS_FAILURE:
//   case types.CREATE_SLOT_FAILURE:
//   case types.UPDATE_SLOT_FAILURE:
//    return {
//     ...state,
//     slots: { ...state.slots, isLoading: false, isError: true },
//    };

//   case types.GET_COUPONS_REQUEST:
//   case types.CREATE_COUPON_REQUEST:
//   case types.UPDATE_COUPON_REQUEST:
//    return {
//     ...state,
//     coupons: { ...state.coupons, isLoading: true, isError: false },
//    };
//   case types.GET_COUPONS_SUCCESS:
//    return {
//     ...state,
//     coupons: { data: payload, isLoading: false, isError: false },
//    };
//   case types.CREATE_COUPON_SUCCESS:
//   case types.UPDATE_COUPON_SUCCESS:
//    return {
//     ...state,
//     coupons: { ...state.coupons, isLoading: false, isError: false },
//    };
//   case types.GET_COUPONS_FAILURE:
//   case types.CREATE_COUPON_FAILURE:
//   case types.UPDATE_COUPON_FAILURE:
//    return {
//     ...state,
//     coupons: { ...state.coupons, isLoading: false, isError: true },
//    };

//   default:
//    return state;
//  }
// };

// export { reducerCart };

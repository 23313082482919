import React, { useEffect, useState } from "react";
import axios from "axios";

const BookingFeeCard = () => {
 const [bookingFee, setBookingFee] = useState(null);
 const [amount, setAmount] = useState("");
 const [isEditing, setIsEditing] = useState(false);

 useEffect(() => {
  // Fetch the current booking fee when the component mounts
  axios
   .get("https://app-api.carexpert.org.in/api/bookingFee")
   .then((response) => {
    if (response.data) {
     setBookingFee(response.data);
     setAmount(response.data.amount);
    }
   })
   .catch((error) => {
    console.error("Error fetching the booking fee:", error);
   });
 }, []);

 const handleUpdateBookingFee = () => {
  axios
   .put("https://app-api.carexpert.org.in/api/bookingFee/update", { amount })
   .then((response) => {
    setBookingFee(response.data.bookingFee);
    setIsEditing(false);
    alert("Booking fee updated successfully");
   })
   .catch((error) => {
    console.error("Error updating the booking fee:", error);
    alert("Failed to update booking fee");
   });
 };

 return (
  <div className="card">
   <h3>Booking Fee</h3>
   {isEditing ? (
    <>
     <input
      type="number"
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
     />
     <button onClick={handleUpdateBookingFee}>Save</button>
     <button onClick={() => setIsEditing(false)}>Cancel</button>
    </>
   ) : (
    <>
     <p>Current Booking Fee: {bookingFee ? bookingFee.amount : "Not Set"}</p>
     <button onClick={() => setIsEditing(true)}>Edit</button>
    </>
   )}
  </div>
 );
};

export default BookingFeeCard;

import React, { useState } from "react";
import {
 Checkbox,
 FormControlLabel,
 FormGroup,
 Typography,
 Box,
 Paper,
} from "@mui/material";

const tasks = [
 {
  task: "1. Bulk Excel Insert or Update",
  subtasks: [
   "Insert/update car models",
   "Insert/update cities",
   "Insert/update product prices by car",
   "Insert/update spare part product prices by car",
   "Add wallet amount to users by user ID",
  ],
 },
 {
  task: "2. Bulk Excel Download",
  subtasks: [
   "Download user data",
   "Download car data",
   "Download product prices",
   "Download spare part product prices",
   "Download city data",
   "Download order history",
  ],
 },
 {
  task: "3. Fix Slot Issue",
  subtasks: [],
 },
 {
  task: "4. Refer & Earn",
  subtasks: [
   "Implement the backend logic for referral system",
   "Frontend integration for Refer & Earn",
  ],
 },
 {
  task: "5. Contact Us API Integration",
  subtasks: [
   "Integrate the Contact Us API on the frontend",
   "Ensure proper form submission and validation",
   "Handle API responses and user notifications",
  ],
 },
 {
  task: "6. Payment Method Integration",
  subtasks: [
   "Include booking fees and full payment options",
   "Implement discount logic",
   "Add COD option",
  ],
 },
 {
  task: "7. Order Creation on Behalf of User",
  subtasks: [],
 },
 {
  task: "8. Order History",
  subtasks: [
   "Display detailed order history for users",
   "Include all relevant order information",
  ],
 },
 {
  task: "9. User Cart",
  subtasks: [
   "Manage items in the user cart",
   "Handle cart updates and removals",
  ],
 },
 {
  task: "10. Order Status Update",
  subtasks: [
   "Allow admins to update the status of orders",
   "Notify users of status changes",
  ],
 },
 {
  task: "11. Revenue Dashboard",
  subtasks: [
   "Include charts and analytics for revenue",
   "Break down revenue by different categories (e.g., product sales, services)",
  ],
 },
 {
  task: "12. Brand Logo API",
  subtasks: ["Handle image uploads", "Manage brand names and logos"],
 },
 {
  task: "13. Trending Service API",
  subtasks: [
   "Display trending services based on user interactions",
   "Ensure the API provides accurate and timely data",
  ],
 },
];

const ToDoList = () => {
 const initialCheckedState = tasks.map((task) =>
  task.subtasks.map(() => false)
 );
 const [checked, setChecked] = useState(initialCheckedState);

 const handleCheck = (taskIndex, subtaskIndex) => {
  const updatedChecked = [...checked];
  updatedChecked[taskIndex][subtaskIndex] =
   !updatedChecked[taskIndex][subtaskIndex];
  setChecked(updatedChecked);
 };

 return (
  <Box sx={{ padding: 4 }}>
   <Paper elevation={3} sx={{ padding: 4 }}>
    <Typography variant="h4" gutterBottom>
     Admin Panel To-Do List
    </Typography>
    {tasks.map((task, taskIndex) => (
     <Box key={taskIndex} sx={{ marginBottom: 3 }}>
      <Typography variant="h6">{task.task}</Typography>
      <FormGroup sx={{ marginLeft: 2 }}>
       {task.subtasks.length > 0 ? (
        task.subtasks.map((subtask, subtaskIndex) => (
         <FormControlLabel
          key={subtaskIndex}
          control={
           <Checkbox
            checked={checked[taskIndex][subtaskIndex]}
            onChange={() => handleCheck(taskIndex, subtaskIndex)}
            color="primary"
           />
          }
          label={<Typography variant="body1">{subtask}</Typography>}
         />
        ))
       ) : (
        <FormControlLabel
         control={
          <Checkbox
           checked={checked[taskIndex][0]}
           onChange={() => handleCheck(taskIndex, 0)}
           color="primary"
          />
         }
         label={<Typography variant="body1">No specific subtasks</Typography>}
        />
       )}
      </FormGroup>
     </Box>
    ))}
   </Paper>
  </Box>
 );
};

export default ToDoList;

import React, { useState, useEffect } from "react";
import {
 Box,
 Button,
 TextField,
 Typography,
 Alert,
 AlertTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, forgotPassword } from "../redux/auth/action";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

const LeftContainer = styled("div")({
 backgroundColor: "#f8f8f8",
 padding: "40px",
 width: "50%",
 height: "100vh",
 display: "flex",
 flexDirection: "column",
 justifyContent: "center",
});

const RightContainer = styled("div")({
 backgroundImage:
  "url(https://ideogram.ai/assets/image/lossless/response/KmhWDQ8KTUCubq5M17UASg)",
 backgroundSize: "cover",
 backgroundPosition: "center",
 width: "50%",
 height: "100vh",
 display: "flex",
 alignItems: "center",
 justifyContent: "center",
});

const CustomButton = styled(Button)({
 backgroundColor: "#1976D2",
 color: "white",
 "&:hover": {
  backgroundColor: "#1976D2",
 },
 marginTop: "20px",
});

function LoginRegister() {
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const [formData, setFormData] = useState({
  email: "",
  password: "",
 });
 const [alert, setAlert] = useState({
  visible: false,
  message: "",
  severity: "",
 });
 const { userDetails, role, isAuth } = useSelector((store) => store.auth);

 const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
 };

 // Login function
 const handleLogin = async () => {
  try {
   const response = await dispatch(
    loginUser(formData.email, formData.password)
   );
   if (response.success) {
    setAlert({
     visible: true,
     message: "Login successful",
     severity: "success",
    });
   } else {
    setAlert({
     visible: true,
     message: "Login failed. Please check your credentials.",
     severity: "error",
    });
   }
  } catch (error) {
   setAlert({
    visible: true,
    message: "Error during login. Please try again.",
    severity: "error",
   });
  }
 };

 // Forgot password logic
 const handleForgotPassword = async () => {
  if (!formData.email) {
   setAlert({
    visible: true,
    message: "Please enter your email to reset your password.",
    severity: "warning",
   });
   return;
  }

  try {
   const response = await fetch(
    "https://app-api.carexpert.org.in/api/forgot-password",
    {
     method: "POST",
     headers: {
      "Content-Type": "application/json",
     },
     body: JSON.stringify({ email: formData.email }),
    }
   );

   const data = await response.json();

   if (response.ok) {
    setAlert({
     visible: true,
     message: "Password reset email sent. Please check your inbox.",
     severity: "success",
    });
   } else {
    setAlert({
     visible: true,
     message: data.message || "Failed to send reset email.",
     severity: "error",
    });
   }
  } catch (error) {
   setAlert({
    visible: true,
    message: "Error occurred while sending reset email. Please try again.",
    severity: "error",
   });
  }
 };

 // Redirection logic after login
 useEffect(() => {
  if (isAuth) {
   if (
    userDetails?.vendor?.role === "admin" ||
    userDetails?.vendor?.role === "vendor"
   ) {
    console.log("admin:", role === "admin");
    return navigate("/dashboard");
   } else if (userDetails?.vendor?.role === "user") {
    setAlert({
     visible: true,
     message: "Your account is not verified as a vendor",
     severity: "error",
    });
   }
  }
 }, [role, isAuth, navigate]);
 console.log("role:", userDetails?.vendor?.role);
 return (
  <Box display="flex" height="100vh">
   <LeftContainer>
    <Box mb={4}>
     <Typography variant="h4" fontWeight="bold">
      Car-Expert
     </Typography>
     <Typography>Welcome to Car-Expert – Login as admin</Typography>
    </Box>

    {alert.visible && (
     <Alert
      severity={alert.severity}
      onClose={() => setAlert({ visible: false, message: "", severity: "" })}
     >
      <AlertTitle>
       {alert.severity === "success" ? "Success" : "Error"}
      </AlertTitle>
      {alert.message}
     </Alert>
    )}

    <TextField
     fullWidth
     label="Email"
     margin="normal"
     type="email"
     name="email"
     value={formData.email}
     onChange={handleInputChange}
     sx={{ backgroundColor: "#fff", borderRadius: "5px" }}
    />
    <TextField
     fullWidth
     label="Password"
     margin="normal"
     type="password"
     name="password"
     value={formData.password}
     onChange={handleInputChange}
     sx={{ backgroundColor: "#fff", borderRadius: "5px" }}
    />
    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
     <Button onClick={handleForgotPassword}>Forgot password?</Button>
    </Box>

    <CustomButton fullWidth variant="contained" onClick={handleLogin}>
     Sign in
    </CustomButton>
   </LeftContainer>

   <RightContainer>
    <Typography variant="h4" color="white" textAlign="center"></Typography>
   </RightContainer>
  </Box>
 );
}

export default LoginRegister;

// import React, { useEffect, useState } from "react";
// import {
//  Container,
//  Tabs,
//  Tab,
//  Box,
//  Button,
//  TextField,
//  Checkbox,
//  FormControlLabel,
//  Typography,
//  IconButton,
//  Alert,
//  AlertTitle,
// } from "@mui/material";
// import { Facebook, Twitter, Google, GitHub } from "@mui/icons-material";
// import { useDispatch, useSelector } from "react-redux";
// import {
//  loginUser,
//  requestOtp,
//  updateVendorDetails,
//  verifyOtp,
// } from "../redux/auth/action";
// import { useNavigate } from "react-router-dom";

// function LoginRegister() {
//  const navigate = useNavigate();
//  const dispatch = useDispatch();
//  const [activeTab, setActiveTab] = useState("login");
//  const [formData, setFormData] = useState({
//   name: "",
//   email: "",
//   password: "",
//   phoneNumber: "",
//   otp: "",
//  });
//  const [error, setError] = useState("");
//  const [step, setStep] = useState(1);
//  const [alert, setAlert] = useState({
//   visible: false,
//   message: "",
//   severity: "",
//  });
//  const { userDetails } = useSelector((store) => store.auth);
//  const { role } = userDetails;

//  const handleTabChange = (event, newValue) => {
//   setActiveTab(newValue);
//   setError(""); // Clear error message when switching tabs
//   setStep(1); // Reset step when switching tabs
//  };

//  const handleInputChange = (e) => {
//   const { name, value } = e.target;
//   setFormData({ ...formData, [name]: value });
//  };

//  const handleLogin = async () => {
//   try {
//    const response = await dispatch(
//     loginUser(formData.email, formData.password)
//    );
//    console.log("response:", response);
//    if (response.success) {
//     setAlert({
//      visible: true,
//      message: "This is a success Alert.",
//      severity: "success",
//     });
//    } else {
//     setAlert({
//      visible: true,
//      message: "Login failed.",
//      severity: "error",
//     });
//    }
//    if (response.role === "user") {
//     setAlert({
//      visible: true,
//      message: "Id is not verified as vendor",
//      severity: "success",
//     });
//    } else if (response.role === "admin") {
//     navigate("/dashboard");
//    } else if (response.role === "vendor") {
//     navigate("/dashboard");
//    }
//   } catch (error) {
//    console.error("Login failed:", error);
//    setAlert({
//     visible: true,
//     message: "Login failed. Please try again.",
//     severity: "error",
//    });
//   }
//  };

//  const handleSendOtp = async () => {
//   const response = await dispatch(requestOtp(formData.phoneNumber));
//   if (response.success) {
//    setStep(2); // Move to OTP verification step
//   } else {
//    setError(response.message);
//   }
//  };

//  const handleVerifyOtp = async () => {
//   const response = await dispatch(
//    verifyOtp(formData.phoneNumber, formData.otp)
//   );
//   if (response.success) {
//    setStep(3); // Move to complete registration step
//   } else {
//    setError(response.message);
//   }
//  };

//  const handleRegister = async () => {
//   const response = await dispatch(
//    updateVendorDetails(
//     formData.name,
//     formData.email,
//     formData.password,
//     formData.phoneNumber
//    )
//   );
//   if (response.success) {
//    navigate("/dashboard");
//   } else {
//    setError(response.message);
//   }
//  };

//  useEffect(() => {
//   if (userDetails && role === "vendor") {
//    navigate("/dashboard");
//    console.log("role:", role);
//   }
//  }, [userDetails, role]);

//  return (
//   <Container maxWidth="sm" sx={{ mt: 5 }}>
//    <Tabs
//     value={activeTab}
//     onChange={handleTabChange}
//     variant="fullWidth"
//     indicatorColor="primary"
//     textColor="primary"
//    >
//     <Tab label="Login" value="login" />
//     <Tab label="Register" value="register" />
//    </Tabs>

//    <Box sx={{ mt: 3 }}>
//     {error && (
//      <Typography color="error" align="center" gutterBottom>
//       {error}
//      </Typography>
//     )}

//     {alert.visible && (
//      <Alert
//       severity={alert.severity}
//       onClose={() => setAlert({ visible: false, message: "", severity: "" })}
//      >
//       <AlertTitle>
//        {alert.severity === "success" ? "Success" : "Error"}
//       </AlertTitle>
//       {alert.message}
//      </Alert>
//     )}

//     {activeTab === "login" && (
//      <Box>
//       <Typography variant="h6" align="center" gutterBottom>
//        Sign in with:
//       </Typography>
//       <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
//        <IconButton color="primary">
//         <Facebook />
//        </IconButton>
//        <IconButton color="primary">
//         <Twitter />
//        </IconButton>
//        <IconButton color="primary">
//         <Google />
//        </IconButton>
//        <IconButton color="primary">
//         <GitHub />
//        </IconButton>
//       </Box>

//       <Typography variant="body1" align="center" gutterBottom>
//        or:
//       </Typography>

//       <TextField
//        fullWidth
//        label="Email address"
//        margin="normal"
//        type="email"
//        name="email"
//        value={formData.email}
//        onChange={handleInputChange}
//       />
//       <TextField
//        fullWidth
//        label="Password"
//        margin="normal"
//        type="password"
//        name="password"
//        value={formData.password}
//        onChange={handleInputChange}
//       />

//       <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
//        <FormControlLabel
//         control={<Checkbox name="remember" />}
//         label="Remember me"
//        />
//        <Button>Forgot password?</Button>
//       </Box>

//       <Button
//        fullWidth
//        variant="contained"
//        color="primary"
//        sx={{ mb: 2 }}
//        onClick={handleLogin}
//       >
//        Sign in
//       </Button>
//       <Typography variant="body1" align="center">
//        Not a member?{" "}
//        <Button onClick={() => setActiveTab("register")}>Register</Button>
//       </Typography>
//      </Box>
//     )}

//     {activeTab === "register" && (
//      <Box>
//       {step === 1 && (
//        <Box>
//         <TextField
//          fullWidth
//          label="Phone Number"
//          margin="normal"
//          type="text"
//          name="phoneNumber"
//          value={formData.phoneNumber}
//          onChange={handleInputChange}
//         />
//         <Button
//          fullWidth
//          variant="contained"
//          color="primary"
//          sx={{ mb: 2 }}
//          onClick={handleSendOtp}
//         >
//          Send OTP
//         </Button>
//        </Box>
//       )}

//       {step === 2 && (
//        <Box>
//         <TextField
//          fullWidth
//          label="OTP"
//          margin="normal"
//          type="text"
//          name="otp"
//          value={formData.otp}
//          onChange={handleInputChange}
//         />
//         <Button
//          fullWidth
//          variant="contained"
//          color="primary"
//          sx={{ mb: 2 }}
//          onClick={handleVerifyOtp}
//         >
//          Verify OTP
//         </Button>
//        </Box>
//       )}

//       {step === 3 && (
//        <Box>
//         <TextField
//          fullWidth
//          label="Name"
//          margin="normal"
//          type="text"
//          name="name"
//          value={formData.name}
//          onChange={handleInputChange}
//         />
//         <TextField
//          fullWidth
//          label="Email"
//          margin="normal"
//          type="email"
//          name="email"
//          value={formData.email}
//          onChange={handleInputChange}
//         />
//         <TextField
//          fullWidth
//          label="Password"
//          margin="normal"
//          type="password"
//          name="password"
//          value={formData.password}
//          onChange={handleInputChange}
//         />
//         <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
//          <FormControlLabel
//           control={<Checkbox name="terms" />}
//           label="I have read and agree to the terms"
//          />
//         </Box>

//         <Button
//          fullWidth
//          variant="contained"
//          color="primary"
//          sx={{ mb: 2 }}
//          onClick={handleRegister}
//         >
//          Sign up
//         </Button>
//        </Box>
//       )}
//      </Box>
//     )}
//    </Box>
//   </Container>
//  );
// }

// export default LoginRegister;

// // import React, { useEffect, useState } from "react";
// // import {
// //   Container,
// //   Tabs,
// //   Tab,
// //   Box,
// //   Button,
// //   TextField,
// //   Checkbox,
// //   FormControlLabel,
// //   Typography,
// //   IconButton,
// //   Alert,
// // } from "@mui/material";
// // import { Facebook, Twitter, Google, GitHub } from "@mui/icons-material";
// // import { useDispatch, useSelector } from "react-redux";
// // import {
// //   loginUser,
// //   requestOtp,
// //   updateVendorDetails,
// //   verifyOtp,
// // } from "../redux/auth/action";
// // import { useNavigate } from "react-router-dom";

// // function LoginRegister() {
// //   const navigate = useNavigate();
// //   const dispatch = useDispatch();
// //   const [activeTab, setActiveTab] = useState("login");
// //   const [formData, setFormData] = useState({
// //     name: "",
// //     email: "",
// //     password: "",
// //     phoneNumber: "",
// //     otp: "",
// //   });
// //   const [error, setError] = useState("");
// //   const [step, setStep] = useState(1);
// //   const [alert, setAlert] = useState({ visible: false, message: '', severity: '' });
// //   const { userDetails } = useSelector((store) => store.auth);
// //   const { role } = userDetails;

// //   const handleTabChange = (event, newValue) => {
// //     setActiveTab(newValue);
// //     setError(""); // Clear error message when switching tabs
// //     setStep(1); // Reset step when switching tabs
// //   };

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({ ...formData, [name]: value });

// //   };
// //   const handleLogin = async () => {
// //     try {
// //         const response = await dispatch(loginUser(formData.email, formData.password));
// //         console.log('response:', response);
// //         if (response.success) {
// //            return  setAlert({ visible: true, message: 'This is a success Alert.', severity: 'success' });
// //         } else {
// //            return setAlert({ visible: true, message: 'Login failed.', severity: 'error' });
// //         }
// //     } catch (error) {
// //         console.error('Login failed:', error);
// //        return setAlert({ visible: true, message: 'Login failed. Please try again.', severity: 'error' });
// //     }
// // };

// //   const handleSendOtp = async () => {
// //     const response = await dispatch(requestOtp(formData.phoneNumber));
// //     if (response.success) {
// //       setStep(2); // Move to OTP verification step
// //     } else {
// //       setError(response.message);
// //     }
// //   };

// //   const handleVerifyOtp = async () => {
// //     const response = await dispatch(
// //       verifyOtp(formData.phoneNumber, formData.otp)
// //     );
// //     if (response.success) {
// //       setStep(3); // Move to complete registration step
// //     } else {
// //       setError(response.message);
// //     }
// //   };

// //   const handleRegister = async () => {
// //     const response = await dispatch(
// //       updateVendorDetails(
// //         formData.name,
// //         formData.email,
// //         formData.password,
// //         formData.phoneNumber
// //       )
// //     );
// //     if (response.success) {
// //       navigate("/dashboard");
// //     } else {
// //       setError(response.message);
// //     }
// //   };

// //   useEffect(() => {
// //     if (userDetails && role === "vendor") {
// //       navigate("/dashboard");
// //       console.log("role:", role);
// //     }
// //   }, [userDetails, role]);

// //   return (
// //     <Container maxWidth="sm" sx={{ mt: 5 }}>
// //       <Tabs
// //         value={activeTab}
// //         onChange={handleTabChange}
// //         variant="fullWidth"
// //         indicatorColor="primary"
// //         textColor="primary"
// //       >
// //         <Tab label="Login" value="login" />
// //         <Tab label="Register" value="register" />
// //       </Tabs>

// //       <Box sx={{ mt: 3 }}>
// //         {error && (
// //           <Typography color="error" align="center" gutterBottom>
// //             {error}
// //           </Typography>
// //         )}

// //         {activeTab === "login" && (
// //           <Box>
// //             <Typography variant="h6" align="center" gutterBottom>
// //               Sign in with:
// //             </Typography>
// //             <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
// //               <IconButton color="primary">
// //                 <Facebook />
// //               </IconButton>
// //               <IconButton color="primary">
// //                 <Twitter />
// //               </IconButton>
// //               <IconButton color="primary">
// //                 <Google />
// //               </IconButton>
// //               <IconButton color="primary">
// //                 <GitHub />
// //               </IconButton>
// //             </Box>

// //             <Typography variant="body1" align="center" gutterBottom>
// //               or:
// //             </Typography>

// //             <TextField
// //               fullWidth
// //               label="Email address"
// //               margin="normal"
// //               type="email"
// //               name="email"
// //               value={formData.email}
// //               onChange={handleInputChange}
// //             />
// //             <TextField
// //               fullWidth
// //               label="Password"
// //               margin="normal"
// //               type="password"
// //               name="password"
// //               value={formData.password}
// //               onChange={handleInputChange}
// //             />

// //             <Box
// //               sx={{ display: "flex", justifyContent: "space-between", my: 2 }}
// //             >
// //               <FormControlLabel
// //                 control={<Checkbox name="remember" />}
// //                 label="Remember me"
// //               />
// //               <Button>Forgot password?</Button>
// //             </Box>

// //             <Button
// //               fullWidth
// //               variant="contained"
// //               color="primary"
// //               sx={{ mb: 2 }}
// //               onClick={handleLogin}
// //             >
// //               Sign in
// //             </Button>
// //             <Typography variant="body1" align="center">
// //               Not a member?{" "}
// //               <Button onClick={() => setActiveTab("register")}>Register</Button>
// //             </Typography>
// //           </Box>
// //         )}

// //         {activeTab === "register" && (
// //           <Box>
// //             {step === 1 && (
// //               <Box>
// //                 <TextField
// //                   fullWidth
// //                   label="Phone Number"
// //                   margin="normal"
// //                   type="text"
// //                   name="phoneNumber"
// //                   value={formData.phoneNumber}
// //                   onChange={handleInputChange}
// //                 />
// //                 <Button
// //                   fullWidth
// //                   variant="contained"
// //                   color="primary"
// //                   sx={{ mb: 2 }}
// //                   onClick={handleSendOtp}
// //                 >
// //                   Send OTP
// //                 </Button>
// //               </Box>
// //             )}

// //             {step === 2 && (
// //               <Box>
// //                 <TextField
// //                   fullWidth
// //                   label="OTP"
// //                   margin="normal"
// //                   type="text"
// //                   name="otp"
// //                   value={formData.otp}
// //                   onChange={handleInputChange}
// //                 />
// //                 <Button
// //                   fullWidth
// //                   variant="contained"
// //                   color="primary"
// //                   sx={{ mb: 2 }}
// //                   onClick={handleVerifyOtp}
// //                 >
// //                   Verify OTP
// //                 </Button>
// //               </Box>
// //             )}

// //             {step === 3 && (
// //               <Box>
// //                 <TextField
// //                   fullWidth
// //                   label="Name"
// //                   margin="normal"
// //                   type="text"
// //                   name="name"
// //                   value={formData.name}
// //                   onChange={handleInputChange}
// //                 />
// //                 <TextField
// //                   fullWidth
// //                   label="Email"
// //                   margin="normal"
// //                   type="email"
// //                   name="email"
// //                   value={formData.email}
// //                   onChange={handleInputChange}
// //                 />
// //                 <TextField
// //                   fullWidth
// //                   label="Password"
// //                   margin="normal"
// //                   type="password"
// //                   name="password"
// //                   value={formData.password}
// //                   onChange={handleInputChange}
// //                 />
// //                 <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
// //                   <FormControlLabel
// //                     control={<Checkbox name="terms" />}
// //                     label="I have read and agree to the terms"
// //                   />
// //                 </Box>

// //                 <Button
// //                   fullWidth
// //                   variant="contained"
// //                   color="primary"
// //                   sx={{ mb: 2 }}
// //                   onClick={handleRegister}
// //                 >
// //                   Sign up
// //                 </Button>
// //               </Box>
// //             )}
// //           </Box>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // }

// // export default LoginRegister;

// // // import React, { useEffect, useState } from "react";
// // // import {
// // //   Container,
// // //   Tabs,
// // //   Tab,
// // //   Box,
// // //   Button,
// // //   TextField,
// // //   Checkbox,
// // //   FormControlLabel,
// // //   Typography,
// // //   IconButton,
// // // } from "@mui/material";
// // // import { Facebook, Twitter, Google, GitHub } from "@mui/icons-material";
// // // import { useDispatch, useSelector } from "react-redux";
// // // import {
// // //   loginUser,
// // //   requestOtp,
// // //   updateVendorDetails,
// // //   verifyOtp,
// // // } from "../redux/auth/action";
// // // import {useNavigate} from "react-router-dom"
// // // function LoginRegister() {
// // //   const navigate=useNavigate()
// // //   const dispatch = useDispatch();
// // //   const [activeTab, setActiveTab] = useState("login");
// // //   const [formData, setFormData] = useState({
// // //     name: "",
// // //     email: "",
// // //     password: "",
// // //     phoneNumber: "",
// // //     otp: "",
// // //   });
// // //   const [error, setError] = useState("");
// // //   const [step, setStep] = useState(1);
// // //   const { userDetails } = useSelector((store) => store.auth);
// // //   const { role } = userDetails;
// // //   const handleTabChange = (event, newValue) => {
// // //     setActiveTab(newValue);
// // //     setError(""); // Clear error message when switching tabs
// // //     setStep(1); // Reset step when switching tabs
// // //   };

// // //   const handleInputChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setFormData({ ...formData, [name]: value });
// // //   };

// // //   const handleLogin = async () => {
// // //     dispatch(loginUser(formData.email, formData.password));
// // //   };

// // //   const handleSendOtp = async () => {
// // //     dispatch(requestOtp(formData.phoneNumber));
// // //   };

// // //   const handleVerifyOtp = async () => {
// // //     dispatch(verifyOtp(formData.phoneNumber, formData.otp));
// // //   };

// // //   const handleRegister = async () => {
// // //     dispatch(
// // //       updateVendorDetails(
// // //         formData.name,
// // //         formData.email,
// // //         formData.password,
// // //         formData.phoneNumber
// // //       )
// // //     );
// // //   };
// // //   console.log("userDetails:", userDetails);
// // //   useEffect(() => {
// // //     if (userDetails && role==="vendor") {
// // //       navigate("/dashboard")
// // //       console.log("role:", role);
// // //     }
// // //   }, [userDetails]);
// // //   return (
// // //     <Container maxWidth="sm" sx={{ mt: 5 }}>
// // //       <Tabs
// // //         value={activeTab}
// // //         onChange={handleTabChange}
// // //         variant="fullWidth"
// // //         indicatorColor="primary"
// // //         textColor="primary"
// // //       >
// // //         <Tab label="Login" value="login" />
// // //         <Tab label="Register" value="register" />
// // //       </Tabs>

// // //       <Box sx={{ mt: 3 }}>
// // //         {error && (
// // //           <Typography color="error" align="center" gutterBottom>
// // //             {error}
// // //           </Typography>
// // //         )}

// // //         {activeTab === "login" && (
// // //           <Box>
// // //             <Typography variant="h6" align="center" gutterBottom>
// // //               Sign in with:
// // //             </Typography>
// // //             <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
// // //               <IconButton color="primary">
// // //                 <Facebook />
// // //               </IconButton>
// // //               <IconButton color="primary">
// // //                 <Twitter />
// // //               </IconButton>
// // //               <IconButton color="primary">
// // //                 <Google />
// // //               </IconButton>
// // //               <IconButton color="primary">
// // //                 <GitHub />
// // //               </IconButton>
// // //             </Box>

// // //             <Typography variant="body1" align="center" gutterBottom>
// // //               or:
// // //             </Typography>

// // //             <TextField
// // //               fullWidth
// // //               label="Email address"
// // //               margin="normal"
// // //               type="email"
// // //               name="email"
// // //               value={formData.email}
// // //               onChange={handleInputChange}
// // //             />
// // //             <TextField
// // //               fullWidth
// // //               label="Password"
// // //               margin="normal"
// // //               type="password"
// // //               name="password"
// // //               value={formData.password}
// // //               onChange={handleInputChange}
// // //             />

// // //             <Box
// // //               sx={{ display: "flex", justifyContent: "space-between", my: 2 }}
// // //             >
// // //               <FormControlLabel
// // //                 control={<Checkbox name="remember" />}
// // //                 label="Remember me"
// // //               />
// // //               <Button>Forgot password?</Button>
// // //             </Box>

// // //             <Button
// // //               fullWidth
// // //               variant="contained"
// // //               color="primary"
// // //               sx={{ mb: 2 }}
// // //               onClick={handleLogin}
// // //             >
// // //               Sign in
// // //             </Button>
// // //             <Typography variant="body1" align="center">
// // //               Not a member?{" "}
// // //               <Button onClick={() => setActiveTab("register")}>Register</Button>
// // //             </Typography>
// // //           </Box>
// // //         )}

// // //         {activeTab === "register" && (
// // //           <Box>
// // //             {step === 1 && (
// // //               <Box>
// // //                 <TextField
// // //                   fullWidth
// // //                   label="Phone Number"
// // //                   margin="normal"
// // //                   type="text"
// // //                   name="phoneNumber"
// // //                   value={formData.phoneNumber}
// // //                   onChange={handleInputChange}
// // //                 />
// // //                 <Button
// // //                   fullWidth
// // //                   variant="contained"
// // //                   color="primary"
// // //                   sx={{ mb: 2 }}
// // //                   onClick={handleSendOtp}
// // //                 >
// // //                   Send OTP
// // //                 </Button>
// // //               </Box>
// // //             )}

// // //             {step === 2 && (
// // //               <Box>
// // //                 <TextField
// // //                   fullWidth
// // //                   label="OTP"
// // //                   margin="normal"
// // //                   type="text"
// // //                   name="otp"
// // //                   value={formData.otp}
// // //                   onChange={handleInputChange}
// // //                 />
// // //                 <Button
// // //                   fullWidth
// // //                   variant="contained"
// // //                   color="primary"
// // //                   sx={{ mb: 2 }}
// // //                   onClick={handleVerifyOtp}
// // //                 >
// // //                   Verify OTP
// // //                 </Button>
// // //               </Box>
// // //             )}

// // //             {step === 3 && (
// // //               <Box>
// // //                 <TextField
// // //                   fullWidth
// // //                   label="Name"
// // //                   margin="normal"
// // //                   type="text"
// // //                   name="name"
// // //                   value={formData.name}
// // //                   onChange={handleInputChange}
// // //                 />
// // //                 <TextField
// // //                   fullWidth
// // //                   label="Email"
// // //                   margin="normal"
// // //                   type="email"
// // //                   name="email"
// // //                   value={formData.email}
// // //                   onChange={handleInputChange}
// // //                 />
// // //                 <TextField
// // //                   fullWidth
// // //                   label="Password"
// // //                   margin="normal"
// // //                   type="password"
// // //                   name="password"
// // //                   value={formData.password}
// // //                   onChange={handleInputChange}
// // //                 />
// // //                 <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
// // //                   <FormControlLabel
// // //                     control={<Checkbox name="terms" />}
// // //                     label="I have read and agree to the terms"
// // //                   />
// // //                 </Box>

// // //                 <Button
// // //                   fullWidth
// // //                   variant="contained"
// // //                   color="primary"
// // //                   sx={{ mb: 2 }}
// // //                   onClick={handleRegister}
// // //                 >
// // //                   Sign up
// // //                 </Button>
// // //               </Box>
// // //             )}
// // //           </Box>
// // //         )}
// // //       </Box>
// // //     </Container>
// // //   );
// // // }

// // // export default LoginRegister;

import React from "react";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import { Facebook, Twitter, Google, GitHub } from "@mui/icons-material";
import { useSelector } from "react-redux";
import UploadForm from "../components/UploadForm";

const VendorProfile = () => {
  const { userDetails } = useSelector((store) => store.auth);
  console.log("userDetails:", userDetails);
  const {vendor}=userDetails
  return (
    <>
      <Box
        sx={{
          fontFamily: "Arial, sans-serif",
          backgroundColor: "whitesmoke",
          width: "100%",

          margin: 0,
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40vh",
          textAlign: "center",
          animation: "slideIn 1s ease-in",
          "@keyframes slideIn": {
            from: {
              opacity: 0,
              transform: "translateY(-20px)",
            },
            to: {
              opacity: 1,
              transform: "translateY(0)",
            },
          },
        }}
      >
        <Box>
          <Avatar
            sx={{
              width: 150,
              height: 150,
              margin: "0 auto",
              border: "5px solid #3498db",
            }}
            src="https://via.placeholder.com/150"
            alt="Profile Picture"
          />
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Playfair Display, serif",
              color: "#333",
              marginTop: 2,
            }}
          ></Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#ccc",
              fontSize: 18,
              fontWeight: "bold",
              marginBottom: 2,
            }}
          >
            {vendor.name}
          </Typography>
          <Box>
            <IconButton
              href="https://facebook.com"
              target="_blank"
              sx={{
                color: "#3498db",
                fontSize: 30,
                margin: "0 10px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#2ecc71",
                },
              }}
            >
              <Facebook />
            </IconButton>
            <IconButton
              href="https://twitter.com"
              target="_blank"
              sx={{
                color: "#3498db",
                fontSize: 30,
                margin: "0 10px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#2ecc71",
                },
              }}
            >
              <Twitter />
            </IconButton>
            <IconButton
              href="https://google.com"
              target="_blank"
              sx={{
                color: "#3498db",
                fontSize: 30,
                margin: "0 10px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#2ecc71",
                },
              }}
            >
              <Google />
            </IconButton>
            <IconButton
              href="https://github.com"
              target="_blank"
              sx={{
                color: "#3498db",
                fontSize: 30,
                margin: "0 10px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#2ecc71",
                },
              }}
            >
              <GitHub />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <UploadForm/>
    </>
  );
};

export default VendorProfile;
